import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import theme from 'theme';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';

// https://github.com/mui-org/material-ui-pickers/issues/1901
const customTheme = createTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        '&:focus': {
          border: 0,
          outline: 'none',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.colors.primary,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          border: `solid 1px ${theme.colors.borderSecondary}`,
          borderWidth: 1,
        },
        '&:hover  $notchedOutline': {
          border: `solid 1px ${theme.colors.borderSecondary}`,
          borderWidth: 1,
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    width: 160,
  },
  inputRoot: {
    padding: '0 12px 0 0',
    cursor: 'pointer',
  },
  input: {
    cursor: 'pointer',
    padding: '7px 0 7px 14px',
    fontSize: 15,
    height: 24,
    lineHeight: 1.6,
    color: theme.colors.textPrimary,
    letterSpacing: 0.08,
  },
  notchedOutline: {
    border: `solid 1px ${theme.colors.borderSecondary}`,
    borderRadius: 8,
  },
  popoverRoot: {
    marginTop: 5,
    borderRadius: 8,
  },
  icon: {
    color: theme.colors.textSecondary,
  },
}));

function DatePickerComp({ defaultDate, handleChange }) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(defaultDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = () => {
    handleChange(selectedDate);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          className={classes.root}
          disableToolbar
          id="date-picker"
          variant="inline"
          format="YYYY/MM/DD"
          inputVariant="outlined"
          value={selectedDate}
          onChange={handleDateChange}
          onClose={handleClose}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <InputAdornment position="end">
                {' '}
                <ArrowDropDownIcon className={classes.icon} />{' '}
              </InputAdornment>
            ),
            labelWidth: 0,
            notched: false,
          }}
          PopoverProps={{
            PaperProps: {
              classes: {
                root: classes.popoverRoot,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

DatePickerComp.propTypes = {
  handleChange: PropTypes.func,
  defaultDate: PropTypes.instanceOf(Date),
};

export default DatePickerComp;
