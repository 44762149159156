import React, { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fetchNotificationList } from 'lib/westernAppointmentService';
import {
  Loading,
  EmptyInfo,
  NotificationContainer,
  UpdateDescription,
  ExpectedSendHint,
  Content,
  Container,
  Header,
  HeaderTitle,
  StatusContainer,
  Divided,
} from './styledComponents';
import StatusComponent from './StatusComponent';
import { SuccessIcon, FailedIcon } from './StatusComponent/icons';
import { t } from 'i18n/config';

const defaultState = {
  list: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        loading: true,
      };
    case 'fetchDone':
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    default:
      return state;
  }
};

function History({ clientID, appointmentID }) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    async function fetchData() {
      return fetchNotificationList({ clientID, id: appointmentID });
    }

    dispatch({ type: 'fetching' });
    fetchData()
      .then((res) => {
        dispatch({ type: 'fetchDone', payload: res });
      })
      .catch(() => {
        dispatch({ type: 'fetchDone', payload: [] });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderHint = useCallback((deliveryAt, status, expectedDeliveryAt, contentformat) => {
    if (deliveryAt) {
      return (
        <ExpectedSendHint>
          {t('sent')} <Content>{contentformat}</Content>
        </ExpectedSendHint>
      );
    }
    if (status === -1) {
      return (
        <ExpectedSendHint>
          {t('sent')} <Content>{contentformat}</Content> {t('sendFailed')}
        </ExpectedSendHint>
      );
    }
    return (
      <ExpectedSendHint>
        {t('willSendAt')}
        {format(new Date(expectedDeliveryAt), 'yyyy/MM/dd HH:mm')}
        {t('send')}
        <Content>{contentformat}</Content>
      </ExpectedSendHint>
    );
  });

  return (
    <Container>
      <Header>
        <HeaderTitle>{t('viewSendingRecord')}</HeaderTitle>
      </Header>
      {state.loading && <Loading size={23} />}
      {!state.loading && state.list.length === 0 && (
        <EmptyInfo>
          {t('noSendingRecord')}
          <span role="img" aria-label="cry">
            🥺
          </span>
        </EmptyInfo>
      )}
      {state.list.length > 0 && (
        <StatusContainer>
          <StatusComponent
            icon={<SuccessIcon />}
            title={t('successfulSendCount')}
            count={state.list.filter((a) => a.status === 2).length}
          />
          <Divided />
          <StatusComponent
            icon={<FailedIcon />}
            title={t('failedSendCount')}
            count={state.list.filter((a) => a.status === -1).length}
          />
        </StatusContainer>
      )}
      {state.list.map(({ updatedAt, expectedDeliveryAt, deliveryAt, id, status, contentformat }) => (
        <NotificationContainer key={id}>
          <UpdateDescription>
            {t('at')}
            {format(new Date(updatedAt), 'yyyy/MM/dd HH:mm')} {t('edit')}
          </UpdateDescription>
          {renderHint(deliveryAt, status, expectedDeliveryAt, contentformat)}
        </NotificationContainer>
      ))}
    </Container>
  );
}

History.propTypes = {
  goToMain: PropTypes.func,
  clientID: PropTypes.string,
  appointmentID: PropTypes.number,
};

export default History;
