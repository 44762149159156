/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Ratings from 'components/Ratings';
import { Platform } from '../../utils/appContant';
import { fetchCommentsReplied, replyReview, mappingCustomersWithSocialPlatform } from '../../reducers/reviews';
import { isFbReplyEnabled, isGoogleReplyEnabled } from '../../reducers/auth';
import { t } from 'i18n/config';

const mapStateToProps = (state) => ({
  fbPosts: state.review.fbPosts,
  googleReviews: state.review.googleReviews,
  fbReviewLink: state.auth.user.fbReviewLink,
  taReviewListLink: state.auth.user.taReviewListLink,
  isGoogleSharingOnFbEnabled: state.auth.features.googleSharingOnFb,
  isMappingEnabled: state.auth.features.reviewMapping,
});

const mapDispatchToProps = {
  fetchCommentsReplied,
  replyReview,
  isFbReplyEnabled,
  isGoogleReplyEnabled,
  mappingCustomersWithSocialPlatform,
};

@connect(mapStateToProps, mapDispatchToProps)
export default class ReviewInfoCell extends Component {
  static propTypes = {
    currentSelectedPlatform: PropTypes.string,
    info: PropTypes.shape({
      rating: PropTypes.string,
      googleReviewId: PropTypes.string,
      reply: PropTypes.string,
      author_name: PropTypes.string,
      clientId: PropTypes.string,
      companyName: PropTypes.string,
      createdAt: PropTypes.number,
      profile_photo_url: PropTypes.string,
      relative_time_description: PropTypes.string,
      text: PropTypes.string,
      shareLink: PropTypes.string,
      locationUrl: PropTypes.string,
      viaImageUrl: PropTypes.string,
    }),
    onClickShareOnFb: PropTypes.func,
    toggleAlleypinModal: PropTypes.func,
    toggleCustomerModal: PropTypes.func,
    fetchCommentsReplied: PropTypes.func,
    isFbReplyEnabled: PropTypes.func,
    fbReviewLink: PropTypes.string,
    isGoogleReplyEnabled: PropTypes.func,
    isMappingEnabled: PropTypes.bool,
    replyReview: PropTypes.func,
    fbPosts: PropTypes.shape({
      byId: PropTypes.object,
    }),
    googleReviews: PropTypes.shape({
      byId: PropTypes.object,
    }),
    isLoading: PropTypes.bool,
    isGoogleSharingOnFbEnabled: PropTypes.bool,
    mappingCustomersWithSocialPlatform: PropTypes.func,
    taReviewListLink: PropTypes.string,
  };

  static defaultProps = {
    info: {
      reviewId: '',
    },
  };

  state = {
    isShowTextInput: false,
    reply: '',
  };

  componentDidMount() {
    // NOTE: we know google reply content beforehand, but fb reply content we have to fetch in
    // frontend by ourselves
    if (this.props.currentSelectedPlatform === Platform.FACEBOOK) {
      this.onFetchComments(this.props.info);
    } else if (this.props.currentSelectedPlatform === Platform.GOOGLE && this.props.info.reply) {
      this.onFetchComments(this.props.info);
    }
  }

  onFetchComments = ({ fbPostId, googleReviewId, reply }) => {
    this.props.fetchCommentsReplied({
      platform: this.props.currentSelectedPlatform,
      fbPostId,
      googleReviewId,
      reply,
    });
  };

  onClickResponse = (info) => {
    const { currentSelectedPlatform } = this.props;
    switch (currentSelectedPlatform) {
      case Platform.GOOGLE:
        if (this.props.isGoogleReplyEnabled({ reviewId: info.reviewId })) {
          this.toggleTextInput({
            platform: Platform.GOOGLE,
            info,
          });
          return;
        }

        window.open('https://www.google.com.tw/intl/zh-TW/business/', '_blank');
        break;
      case Platform.FACEBOOK:
        if (this.props.isFbReplyEnabled()) {
          this.toggleTextInput({ platform: Platform.FACEBOOK, info });
          return;
        }
        window.open(this.props.fbReviewLink, '_blank');
        break;
      case Platform.TRIPADVISOR:
        window.open(this.props.taReviewListLink, '_blank');
        break;
      case Platform.ALLEYPIN:
        this.props.toggleAlleypinModal(info);
        break;
      case Platform.ALLEYPIN_FEEDBACK:
        this.props.toggleAlleypinModal(info);
        break;
      default:
        break;
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  toggleTextInput = ({ info: { fbPostId, googleReviewId, googleAccountId, googleLocationId }, platform }) => {
    if (this.state.isShowTextInput && this.state.reply.length) {
      this.props.replyReview({
        platform,
        reply: this.state.reply,
        fbPostId,
        googleReviewId,
        googleAccountId,
        googleLocationId,
      });
    }
    this.setState({
      isShowTextInput: !this.state.isShowTextInput,
      reply: '',
    });
  };

  renderComments = ({ currentSelectedPlatform, info }) => {
    if (currentSelectedPlatform === Platform.FACEBOOK) {
      return (
        <div className="review-comment">
          {this.props.fbPosts.byId[info.fbPostId] ? this.props.fbPosts.byId[info.fbPostId].reply : null}
        </div>
      );
    } else if (currentSelectedPlatform === Platform.GOOGLE) {
      return (
        <div className="review-comment">
          {this.props.googleReviews.byId[info.googleReviewId]
            ? this.props.googleReviews.byId[info.googleReviewId].reply
            : null}
        </div>
      );
    }
    //  else if (currentSelectedPlatform === Platform.TRIPADVISOR) {
    //   return (
    //     <div className="review-comment" >
    //       {info.reply}
    //     </div>);
    // }
    return null;
  };

  renderMappingDetail = () => {
    const {
      info: { clientId, createdAt: commentTime },
      currentSelectedPlatform: platform,
    } = this.props;

    if (!this.props.isMappingEnabled) {
      return null;
    }
    if (platform !== Platform.GOOGLE && platform !== Platform.FACEBOOK) {
      return null;
    }

    const customer = this.props.mappingCustomersWithSocialPlatform({
      clientId,
      commentTime,
      platform,
    });
    if (customer) {
      return (
        <Button
          style={{ marginLeft: '8px' }}
          outline
          color="primary"
          onClick={() => {
            this.props.toggleCustomerModal({
              ...this.props.info,
              ...customer,
            });
          }}
        >
          {t('findThisCustomerInSystem')}
        </Button>
      );
    }
    return null;
  };

  render() {
    const { info, currentSelectedPlatform, isGoogleSharingOnFbEnabled } = this.props;
    const { reply } = this.state;
    return (
      <li className="list-group-item">
        <img className="review-avatar" src={info.profile_photo_url} alt="" />
        <span className="review-name"> {info.author_name}</span>
        <span className="review-time">
          {info.relative_time_description} {info.companyName != null ? ` - ${info.companyName} ` : ''}
        </span>
        <div className="review-text">
          <p>{info.text}</p>
        </div>
        <Ratings value={info.rating} />
        {this.renderComments({ currentSelectedPlatform, info })}
        <Input
          name="reply"
          type="text"
          placeholder={t('enterResponseMessage')}
          value={reply}
          onChange={this.handleChange}
          hidden={!this.state.isShowTextInput}
        />
        <Button
          outline
          color="primary"
          onClick={() => {
            this.onClickResponse(info);
          }}
        >
          {t('respondToCustomer')}
        </Button>
        <Button
          style={{ marginLeft: '8px' }}
          outline
          color="primary"
          onClick={() => {
            this.props.onClickShareOnFb(info.shareLink);
          }}
          hidden={!info.shareLink || !isGoogleSharingOnFbEnabled}
        >
          {t('shareOnFacebook')}
        </Button>
        {this.renderMappingDetail()}
        <div className="review-source">
          <a target="_blank" href={info.locationUrl} className="review-sourceLink">
            via <img src={info.viaImageUrl} alt="" />
          </a>
        </div>
      </li>
    );
  }
}
