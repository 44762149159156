import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { Confirm } from 'components/MaterialDesign/Buttons';
import Button from 'components/Dashboard_v2/Button';
import { dashboard_v2 } from 'theme';
import { Loading } from 'components/MaterialDesign/Loading';
import { ROLE } from 'lib/openaiService';
import Tooltip from 'components/Dashboard_v2/Tooltip';
import HintInfo from 'components/Dashboard_v2/HintInfo';
import HintImage from 'components/Dashboard_v2/HintInfo/openaiSendMessageHint.png';
import { EmptyEditedTextInfo, PageRouteButton } from './styledComponents';
import {
  EditContainer as Container,
  EditHeader as Header,
  EditSection,
  EditHeaderTitle as Title,
  TextArea,
  TextRow,
  Alert,
  BreakLine,
} from '../../styledComponents';
import { Footer, FooterLeftAction, FooterRightAction } from '../styledComponents';
import { t } from 'i18n/config';

const TEXT_MAX_LENGTH = 500;

function EditMessageComponent({ draftValue, setDraftValue, onSend, goToHistory, openSnackbar, handleOpenAI }) {
  const textAreaRef = useRef(null);
  const [startTyping, setStartTyping] = useState(false);
  const [openaiLoading, setOpenaiLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDisplayTextExceedToast = useCallback(
    useAsyncDebounce(() => {
      openSnackbar({ message: t('exceededWordLimit'), status: 'error' });
    }, 200),
    []
  );

  const onChangeText = useCallback(
    (e) => {
      setDraftValue(e.target.value);
      if (e.target.value.length > TEXT_MAX_LENGTH) {
        onDisplayTextExceedToast();
      }
    },
    [setDraftValue, onDisplayTextExceedToast]
  );

  const handleClickTyping = useCallback(() => {
    setStartTyping(true);
  }, []);

  const handleClickOpenAI = useCallback(async () => {
    setOpenaiLoading(true);
    await handleOpenAI({
      role: ROLE.CLINIC_MESSAGE_BOT,
      content: draftValue,
      args: [50],
      callback: (msg) => {
        onChangeText({ target: { value: msg } });
      },
    });
    setOpenaiLoading(false);
  }, [onChangeText, draftValue, handleOpenAI]);

  const isexceedText = draftValue.length > TEXT_MAX_LENGTH;

  const openAIEnabled = handleOpenAI != null;

  return (
    <Container>
      <Header>
        <Title>{t('directMessage')}</Title>
      </Header>
      <BreakLine />
      <EditSection customcolor={dashboard_v2.colors.SHADES_400}>
        {draftValue.length === 0 && !startTyping && (
          <EmptyEditedTextInfo onClick={handleClickTyping}>
            {t('reminderBeforeSending')}
            <span role="img" aria-label="heart">
              💛
            </span>{' '}
            {t('reminderForLineOrSms')}
            <span role="img" aria-label="bow">
              🙇🏻‍♀️
            </span>
          </EmptyEditedTextInfo>
        )}
        {(startTyping || draftValue.length > 0) && (
          <TextArea autoFocus={startTyping} ref={textAreaRef} value={draftValue} onChange={onChangeText} />
        )}
      </EditSection>
      <TextRow>
        {isexceedText && <Alert className="ri-alert-fill" />} {draftValue.length} / {TEXT_MAX_LENGTH}
      </TextRow>
      <TextRow justifyContent={'flex-start'}>
        <Button
          variant="outline"
          color="secondary"
          size="small"
          fontSize={14}
          onClick={handleClickOpenAI}
          disabled={!openAIEnabled || openaiLoading}
        >
          {openaiLoading ? (
            <Loading size={18} customcolor={dashboard_v2.colors.SHADES_400}></Loading>
          ) : (
            <Tooltip
              popoverContent={
                <HintInfo
                  image={HintImage}
                  title={t('autoGenerateMessage')}
                  description={t('clinicChooseAppointmentsForLineMessage')}
                ></HintInfo>
              }
              type={'CENTER_LEFT'}
              popoverStyle={{ margin: -12 }}
            >
              <i className="ri-sparkling-2-fill"></i>
            </Tooltip>
          )}
        </Button>
      </TextRow>
      <BreakLine />
      <Footer>
        <FooterLeftAction>
          <PageRouteButton color="primary" onClick={goToHistory}>
            {t('sendingRecord')}
          </PageRouteButton>
        </FooterLeftAction>
        <FooterRightAction>
          <Confirm disabled={isexceedText || draftValue.length === 0} width={76} onClick={onSend}>
            {t('sendNow')}
          </Confirm>
        </FooterRightAction>
      </Footer>
    </Container>
  );
}

EditMessageComponent.propTypes = {
  draftValue: PropTypes.string,
  setDraftValue: PropTypes.func,
  onSend: PropTypes.func,
  handleOpenAI: PropTypes.func,
  goToHistory: PropTypes.func,
  openSnackbar: PropTypes.func,
};

export default EditMessageComponent;
