import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import removeTag from 'img/remove_tag.png';
import './Dropdown.scss';
import TitleDropdown from './TitleDropdown';
import ValueDropdown from './ValueDropdown';

const propTypes = {
  onDelete: PropTypes.func,
  titleOptions: PropTypes.arrayOf(PropTypes.object),
  defaultTitleOption: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  defaultValueOption: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  classes: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {};

const DropdownColumn = ({ titleOptions, defaultTitleOption, defaultValueOption, onDelete, classes, onChange }) => {
  const [valueOptions, setValueOptions] = useState(
    titleOptions.find((option) => option.id === defaultTitleOption.id).values
  );
  const [titleOption, setTitleOption] = useState(defaultTitleOption);
  const [valueOption, setValueOption] = useState(defaultValueOption);
  useEffect(() => {
    onChange(titleOption, valueOption);
  }, [titleOption, valueOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDropdown = () => {
    return (
      <div className="column">
        <TitleDropdown
          values={titleOptions}
          defaultValue={titleOption}
          onChange={(to) => {
            const { values } = titleOptions.find((option) => to.id === option.id);
            setValueOptions(values);
            setTitleOption(to);
            setValueOption(values[0]);
          }}
          clickable
        />
        <ValueDropdown
          optionType={titleOption.valueType}
          values={valueOptions}
          defaultValue={valueOption}
          onChange={(vo) => {
            setValueOption(vo);
          }}
          clickable
        />
      </div>
    );
  };

  return (
    <div className={`${classes} dropdownComponent`}>
      {renderDropdown()}

      {onDelete && (
        <button className="line-add-button" onClick={onDelete}>
          <img className="line-add-button-image" src={removeTag} alt="" />
        </button>
      )}
    </div>
  );
};

DropdownColumn.propTypes = propTypes;
DropdownColumn.defaultProps = defaultProps;

export default DropdownColumn;
