import { AUTH_LOGOUT } from 'reducers/auth';
import { fetchAppointmentsApi, appendCustomTagApi, updateReminderApi } from 'lib/appointmentService';
import { demoMiddleWare } from 'demo';
import { normalizeIds } from 'helpers';

const SET_APPOINTMENTS = 'APPOINTMENTS/SET_APPOINTMENTS';
// const MODIFY_APPOINTMENT = 'APPOINTMENTS/MODIFY_APPOINTMENT';
const APPEND_CUSTOMER_TAG = 'APPOINTMENTS/APPEND_CUSTOMER_TAG';
const UPDATE_APPOINTMENT_REMINDER = 'APPOINTMENTS/UPDATE_REMINDER';
const CLEAR_APPOINTMENTSIDS = 'APPOINTMENTS/CLEAR_APPOINTMENTSIDS';

const normalizeById = (ary) => {
  const byId = {};
  ary.forEach(
    ({
      id,
      appointmentID,
      appointmentTime,
      status,
      name,
      phone,
      patientID,
      lineID,
      birth,
      gender,
      doctor,
      lineStatus,
      responseStatus,
      revisedCount,
      reminder,
      deleted,
      note,
      draft,
      processState,
      rejectedBy,
    }) => {
      byId[id] = {
        id,
        appointmentID,
        appointmentTime,
        status,
        name,
        phone,
        patientID,
        lineID,
        birth,
        gender,
        doctor,
        lineStatus,
        responseStatus,
        revisedCount,
        reminder,
        deleted,
        note,
        draft,
        processState,
        rejectedBy,
      };
    }
  );
  return byId;
};

const normalizeAppointmentByPhone = (ary) => {
  const byId = {};
  ary.forEach(({ tags, customTags, phone }) => {
    byId[phone] = {
      tags,
      customTags,
    };
  });
  return byId;
};
const defaultState = {};

const setAppointments = (appointmentsByClientId) => ({
  type: SET_APPOINTMENTS,
  payload: appointmentsByClientId,
});

const clearAppointmentsIds = ({ loading }) => ({
  type: CLEAR_APPOINTMENTSIDS,
  payload: {
    loading,
  },
});

export const fetchTotalAppointmentsInQue =
  ({ startTime: start }) =>
  async (dispatch, getState) => {
    const { selectedIds } = getState().clients;
    dispatch(clearAppointmentsIds({ loading: true }));
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);

    const promises = selectedIds.map((id) =>
      demoMiddleWare(
        clients,
        {
          clientId: id,
          startTime: start.startOf('day').unix() * 1000,
          endTime: start.endOf('day').unix() * 1000,
        },
        fetchAppointmentsApi
      )
    );
    Promise.all(promises).then((appointmentsArray) => {
      console.log('appointmentsArray = ', appointmentsArray);
      const appointmentsByClientId = {};
      selectedIds.forEach((clientId, i) => {
        appointmentsByClientId[clientId] = {
          byPhone: normalizeAppointmentByPhone(appointmentsArray[i]),
          byId: normalizeById(appointmentsArray[i]),
          Ids: normalizeIds(appointmentsArray[i]),
          loading: false,
        };
      });
      dispatch(setAppointments(appointmentsByClientId));
    });
  };

export const fetchAppointments =
  ({ clientId, startTime: start }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    const appointmentResp = await demoMiddleWare(
      clients,
      {
        clientId,
        startTime: start.startOf('day').unix() * 1000,
        endTime: start.endOf('day').unix() * 1000,
      },
      fetchAppointmentsApi
    );
    return appointmentResp;
  };

export const appendCustomTag =
  ({ clientId, id, phone, customTags, tagToAppend }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((cid) => getState().clients.byId[cid]);
    await demoMiddleWare(
      clients,
      {
        clientId,
        phone,
        tagToAppend,
      },
      appendCustomTagApi
    );
    dispatch({
      type: APPEND_CUSTOMER_TAG,
      payload: { clientId, id, customTags },
    });
  };

export const updateReminder =
  ({ clientId, id, reminder }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((cid) => getState().clients.byId[cid]);
    await demoMiddleWare(
      clients,
      {
        clientId,
        id,
        reminder,
      },
      updateReminderApi
    );
    dispatch({
      type: UPDATE_APPOINTMENT_REMINDER,
      payload: { clientId, id, reminder },
    });
  };

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return defaultState;
    case SET_APPOINTMENTS:
      return {
        ...state,
        ...action.payload,
      };
    // case MODIFY_APPOINTMENT: {
    //   const { clientId, appointment } = action.payload;
    //   return {
    //     ...state,
    //     [clientId]: {
    //       byId: {
    //         ...state[clientId].byId,
    //         [appointment.id]: appointment
    //       },
    //       Ids: state[clientId].Ids
    //     }
    //   };
    // }
    case UPDATE_APPOINTMENT_REMINDER: {
      const { clientId, id, reminder } = action.payload;
      const targetAppointment = state[clientId].byId[id];
      const updatedAppointment = {
        ...targetAppointment,
        reminder,
      };
      return {
        ...state,
        [clientId]: {
          byPhone: state[clientId].byPhone,
          byId: {
            ...state[clientId].byId,
            [id]: updatedAppointment,
          },
          Ids: state[clientId].Ids,
        },
      };
    }
    case APPEND_CUSTOMER_TAG: {
      const { clientId, id, customTags } = action.payload;
      const { phone } = state[clientId].byId[id];
      return {
        ...state,
        [clientId]: {
          byPhone: {
            ...state[clientId].byPhone,
            [phone]: {
              ...state[clientId].byPhone[phone],
              customTags,
            },
          },
          byId: state[clientId].byId,
          Ids: state[clientId].Ids,
        },
      };
    }
    case CLEAR_APPOINTMENTSIDS: {
      return Object.keys(state).reduce((sum, temp) => {
        const res = sum;
        res[temp] = {
          ...state[temp],
          Ids: [],
          loading: action.payload.loading,
        };
        return sum;
      }, {});
    }
    default:
      return state;
  }
};
