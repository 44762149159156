import React, { memo } from 'react';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
const { fonts, colors } = dashboard_v2;

const TextWrapper = styled.span`
  display: ${({ block }) => (block ? 'flex' : 'inline-flex')};
  align-items: center;
  color: ${({ color }) => colors[color]};
  padding: 0;
  margin: 0;
  ${({ font }) => fonts[font]};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}px;`};
  ${({ paddingRight }) => paddingRight && `padding-right:${paddingRight}px;`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}px;`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}px;`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`};
  ${({ marginRight }) => marginRight && `margin-right:${marginRight}px;`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px;`};
  ${({ cursor }) => cursor && `cursor: ${cursor};`};
`;

const Text = ({
  children,
  font = 'Body/16px_Regular',
  color = 'SHADES_900',
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  block,
  cursor,
  as, // styled component dynamic tag name, e.g. as="button", as="p"
  ...restProps
}) => {
  return (
    <TextWrapper
      font={font}
      color={color}
      padding={padding}
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      block={block}
      cursor={cursor}
      as={as}
      {...restProps}
    >
      {children}
    </TextWrapper>
  );
};

export default memo(Text);
