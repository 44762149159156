import React, { memo, useState, useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import nanoid from 'nanoid';
import Popover from '@material-ui/core/Popover';
import Tag from 'components/Dashboard_v2/Tag';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_400};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  i.ri-add-circle-line {
    margin-left: 8px;
    color: ${colors.SHADES_400};
  }
`;

const SelectedCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  padding: 16px 16px 0 16px;
  margin-bottom: -4px;
`;

const PopoverWrapper = styled.div`
  width: 400px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: ${({ selectedWrapper }) => (selectedWrapper ? '0' : '16px')};
  font-size: 12px;
  input {
    flex: 1;
    font-size: 16px;
    border: none;
    min-width: ${({ hasTag }) => (hasTag ? '100px' : '255px')};
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.SHADES_400};
    }
    :-ms-input-placeholder {
      color: ${colors.SHADES_400};
    }
  }
`;

const TagSelectorInput = ({ selectedTags = [], setSelectedTags }) => {
  const tags = useSelector((state) => state.member.tags);
  const inputContainerRef = useRef();
  const keywordInputRef = useRef();
  const [keyword, setKeyword] = useState('');
  const [isTagsOpen, setIsTagsOpen] = useState(false);

  const filteredTags = useMemo(() => {
    if (!keyword) {
      return tags;
    }
    return tags.filter((tag) => tag.name.includes(keyword));
  }, [tags, keyword]);

  const inputFocus = useCallback(() => {
    keywordInputRef.current.focus();
  }, []);

  const openTags = useCallback(() => {
    setIsTagsOpen(true);
    inputFocus();
  }, [inputFocus]);

  const closeTags = useCallback(() => {
    setIsTagsOpen(false);
  }, []);

  const getSelectedTagIndex = (tag) => selectedTags.findIndex((selectedTag) => selectedTag.name === tag.name);

  const removeTag = (tagIndex) => {
    setSelectedTags((prev) => {
      const newArray = [...prev];
      newArray.splice(tagIndex, 1);
      return newArray;
    });
  };

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (!isTagsOpen) {
        openTags();
      }
      setKeyword(value);
    },
    [isTagsOpen, openTags]
  );

  const handleSelect = (tag) => {
    const tagIndex = getSelectedTagIndex(tag);
    if (tagIndex === -1) {
      setSelectedTags((prev) => [...prev, tag]);
    } else {
      removeTag(tagIndex);
    }
  };

  const handleRemove = (e, tag) => {
    e.stopPropagation();
    const tagIndex = getSelectedTagIndex(tag);
    if (tagIndex !== -1) {
      removeTag(tagIndex);
    }
  };

  return (
    <div>
      <InputContainer ref={inputContainerRef} onClick={openTags}>
        <TagsWrapper selectedWrapper onClick={inputFocus} hasTag={selectedTags.length > 0}>
          {selectedTags.map((tag) => (
            <Tag
              name={tag.name}
              onClick={(e) => e.stopPropagation()}
              onClickRemove={(e) => handleRemove(e, tag)}
              key={nanoid()}
            />
          ))}
          <input
            ref={keywordInputRef}
            value={keyword}
            onChange={handleInputChange}
            placeholder={selectedTags.length ? '' : t('searchTagsPlaceholder')}
            type="text"
          />
        </TagsWrapper>
        <i className="ri-add-circle-line" />
      </InputContainer>

      {isTagsOpen && (
        <Popover
          open={isTagsOpen || keyword}
          anchorEl={inputContainerRef.current}
          onClose={closeTags}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
            maxHeight: '300px',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <PopoverWrapper>
            <SelectedCount>
              {t('searchResults', { info: `${filteredTags.length}/${tags.length}` })}
              <br />
              {t('trackingTag.tagHint')}
            </SelectedCount>
            <TagsWrapper>
              {filteredTags.map((tag) => (
                <Tag
                  name={tag.name}
                  onClick={() => handleSelect(tag)}
                  isSelected={getSelectedTagIndex(tag) !== -1}
                  key={nanoid()}
                />
              ))}
            </TagsWrapper>
          </PopoverWrapper>
        </Popover>
      )}
    </div>
  );
};

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedMemberIds: PropTypes.array,
};

TagSelectorInput.propTypes = propTypes;

export default memo(TagSelectorInput);
