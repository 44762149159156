import React from 'react';
import styled from 'styled-components';
import { Cancel, Confirm } from 'components/MaterialDesign/Buttons';
import PropTypes from 'prop-types';
import { western } from 'theme';
import { t } from 'i18n/config';

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
`;

const MCancel = styled(Cancel)`
  margin-right: 8px;
`;

const Container = styled.div`
  margin: 16px;
`;

const Description = styled.div`
  margin: 30px 16px;
`;

const Count = styled.span`
  font-size: 20px;
  color: ${western.common.primary};
`;

function BatchConfirm({ count, onCancel, onConfirm }) {
  return (
    <Container>
      <Description>
        {t('confirmSendMessages')} <Count>{count}</Count> {t('cannotUndoAfterSending')}
      </Description>
      <ActionContainer>
        <MCancel onClick={onCancel} height={42} width={80}>
          {t('cancel')}
        </MCancel>
        <Confirm onClick={onConfirm} height={42} width={133} borderradius={4}>
          {t('confirmSend')}
        </Confirm>
      </ActionContainer>
    </Container>
  );
}

BatchConfirm.propTypes = {
  count: PropTypes.number,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default BatchConfirm;
