import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import { fetchFilter, createFilter, updateFilter } from 'lib/memberService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import FormControl from 'components/Dashboard_v2/FormControl';
import Input from 'components/Dashboard_v2/Input';
import Select from 'components/Dashboard_v2/Select';
import Text from 'components/Dashboard_v2/Text';
import { t } from 'i18n/config';

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: #fff;
  border-bottom: 1px solid ${dashboard_v2.colors.SHADES_200};
  display: flex;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const SettingWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const TIME_UNIT = {
  M: t('months'),
  y: t('yearsOld'),
};

const TIME_UNIT_OPTIONS = Object.keys(TIME_UNIT).map((key) => ({ id: key, name: TIME_UNIT[key], value: key }));

const TYPE_OPTIONS = [
  { id: 1, name: t('ageRange'), value: 'between', hint: t('between') },
  { id: 2, name: t('aboveTimeInterval'), value: 'after', hint: t('above') },
  { id: 3, name: t('belowTimeInterval'), value: 'before', hint: t('below') },
];

const MODE_NAME_MAP = {
  create: {
    title: t('add'),
    description: t('tagNameRequired'),
    saveButton: t('saveAndAdd'),
  },
  edit: {
    title: t('edit'),
    description: t('tagNameRequired'),
    saveButton: t('updateAndClose'),
  },
};

const AgeTagForm = ({ mode, match }) => {
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const { renderAlert, setAlert } = useAlert();
  const filterId = match.params.id;
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectToList, setRedirectToList] = useState(false);
  const [type, setType] = useState(TYPE_OPTIONS[0]);
  const [timeUnit, setTimeUnit] = useState(TIME_UNIT_OPTIONS[0]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1);
  const [errors, setErrors] = useState({});

  const checkValidation = useCallback(() => {
    const errors = {};

    if (['between', 'after'].includes(type.value) && (!minValue || minValue % 1 !== 0)) {
      errors.minValue = t('enterIntegerGreaterThanOne');
    }
    if (['between', 'before'].includes(type.value) && (!maxValue || maxValue % 1 !== 0)) {
      errors.maxValue = t('enterIntegerGreaterThanOne');
    }
    if (type.value === 'between' && minValue > maxValue) {
      errors.between = t('enterCorrectAgeRange');
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, [minValue, maxValue, type]);

  const handleSubmit = async () => {
    if (isLoading) return;

    if (checkValidation()) {
      setIsLoading(true);
      try {
        const getName = () => {
          if (type.value === 'between') {
            return `${minValue} ~ ${maxValue} ${TIME_UNIT[timeUnit.value]}`;
          }
          if (type.value === 'after') {
            return `${minValue} ${TIME_UNIT[timeUnit.value]}${type.hint}`;
          }
          if (type.value === 'before') {
            return `${maxValue} ${TIME_UNIT[timeUnit.value]}${type.hint}`;
          }
        };
        const filter = {
          type: 'age_range',
          name: getName(),
          value: {
            range: {
              birthday: {
                ...(['between', 'after'].includes(type.value) ? { lt: `now-${minValue}${timeUnit.value}/d` } : {}),
                ...(['between', 'before'].includes(type.value) ? { gte: `now-${maxValue}${timeUnit.value}/d` } : {}),
              },
            },
          },
        };
        if (mode === 'create') {
          await createFilter({ clientId, payload: filter });
        }
        if (mode === 'edit') {
          await updateFilter({ clientId, filterId, payload: filter });
        }
        setAlert({ type: 'success', title: t('tagActionSuccess', { title: MODE_NAME_MAP[mode].title }) });
        setRedirectToList(true);
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('tagActionFailed', { title: MODE_NAME_MAP[mode].title }) });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      checkValidation();
    }
  }, [minValue, maxValue, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mode === 'edit') {
      const getFilterData = async () => {
        try {
          /* 
            參數上：lt = 小於, gte = 大於等於
            但由於我們參數是 not-{value}, 所以反過來最小值會是 get, lt 會是最大值 
            e.g. gte now-10y/d === gte 2012
            age_range	30 歲以上:
              X {"range": {"birthday": {"gte": "now-30y/d"}}}	
              O {"range": {"birthday": {"lt": "now-30y/d"}}}
          */
          const { value } = await fetchFilter({ clientId, filterId });
          const { lt, gte } = value.range.birthday;
          const type = lt && gte ? 'between' : lt ? 'after' : 'before';
          const timeUnit = (lt || gte).replace(/now-|\/d|\d/g, '');
          const min = lt ? parseInt(lt.replace(/now-|\/d|M|y/g, ''), 10) : 1;
          const max = gte ? parseInt(gte.replace(/now-|\/d|M|y/g, ''), 10) : 0;

          setType(TYPE_OPTIONS.find((item) => item.value === type) || TYPE_OPTIONS[0]);
          setTimeUnit(TIME_UNIT_OPTIONS.find((item) => item.value === timeUnit) || TIME_UNIT_OPTIONS[0]);
          setMinValue(min);
          setMaxValue(max);
          setIsInit(true);
        } catch (e) {
          setAlert({ type: 'error', title: t('failedToLoadTagData') });
          setTimeout(() => {
            setRedirectToList(true);
          }, 1000);
        }
      };
      getFilterData();
    } else {
      setIsInit(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return redirectToList ? (
    <Redirect push to="/members/tags?tagListType=ageTag" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/members/tags?tagListType=ageTag">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/members/tags?tagListType=ageTag">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button color="primary" variant="filled" onClick={handleSubmit}>
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
          </Button>
        </FormActions>
      </Header>
      {isInit && (
        <Main>
          <Block title={t('tagSettings')}>
            <FormControl>
              <Select options={TYPE_OPTIONS} selectedOption={type} setSelectedOption={(option) => setType(option)} />
            </FormControl>
            <SettingWrapper>
              {type.value !== 'before' && (
                <Input
                  value={minValue}
                  onInputChange={(e) => setMinValue(parseInt(e.target.value, 10))}
                  error={!!errors.minValue || !!errors.between}
                  width={80}
                  type="number"
                  min="1"
                />
              )}
              {type.value === 'between' && <Text>~</Text>}
              {type.value !== 'after' && (
                <Input
                  value={maxValue}
                  onInputChange={(e) => setMaxValue(parseInt(e.target.value, 10))}
                  error={!!errors.maxValue || !!errors.between}
                  width={80}
                  type="number"
                  min="1"
                />
              )}
              <Select
                options={TIME_UNIT_OPTIONS}
                selectedOption={timeUnit}
                setSelectedOption={(option) => setTimeUnit(option)}
                width={100}
              />
              <Text>{type.hint}</Text>
            </SettingWrapper>
            {Object.keys(errors).length !== 0 && (
              <Text font="Heading/Small/Regular" color="SYSTEM_ERROR_500" marginTop={8}>
                {errors.between || errors.minValue || errors.maxValue}
              </Text>
            )}
          </Block>
        </Main>
      )}
    </Container>
  );
};

export default connectData(AgeTagForm);
