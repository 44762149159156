import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { dashboard_v2 } from 'theme';
import Text from 'components/Dashboard_v2/Text';
import FilterableTable from 'components/Table/FilterableTable';
import EmptyIcon from 'components/icons/EmptyIcon';
import { getFormattedDate } from '..';
import SurveyDetail from 'feature/MemberCenter/MemberProfile/components/surveyDetail';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: relative;
  tr {
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 24px;
  i {
    font-size: 20px;
    color: ${colors.SHADES_400};
  }
`;

const StatusDot = styled.div`
  margin-right: 12px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;

const Block = styled.div`
  ${({ flex }) => flex && `display: flex;`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ column }) => column && `flex-direction: column;`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${colors.SHADES_500};
  background-color: ${colors.SHADES_100};
  border-radius: 4px;
  white-space: nowrap;
`;

const StatusLabel = (status) => {
  const settingMap = {
    RAW: { text: 'RAW', dotColor: colors.SYSTEM_ERROR_400 },
    FINISH: { text: t('completed'), dotColor: colors.SYSTEM_INFO_400 },
    done: { text: t('ended'), dotColor: colors.SHADES_300 },
  };
  const setting = settingMap[status] || { text: status, dotColor: null };
  return (
    <StatusWrapper>
      <StatusDot color={setting.dotColor} />
      {setting.text}
    </StatusWrapper>
  );
};

const DateTimeLabel = (timeStamp) => {
  return (
    <Block flex column>
      <Text font="Heading/X-Small/Regular" color="SHADES_900" block>
        {getFormattedDate(timeStamp, 'date')}
      </Text>
      <Text font="Heading/X-Small/Regular" color="SHADES_400">
        {getFormattedDate(timeStamp, 'time')}
      </Text>
    </Block>
  );
};

const surveyListColumns = () => [
  {
    Header: 'surveyList',
    columns: [
      {
        Header: t('survey'),
        accessor: 'title',
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value: status }) => {
          switch (status) {
            case 'FINISH':
              return StatusLabel('FINISH');
            default:
              console.log('unknown status', status);
              return StatusLabel('unknown');
          }
        },
      },
      {
        Header: t('submissionTime'),
        accessor: 'submitTime',
        Cell: ({ value }) => DateTimeLabel(value),
      },
    ],
  },
];

const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};

const SurveyList = ({ userId, surveyList }) => {
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  return (
    <Wrapper>
      <Title>
        <Text font="Heading/H6/Medium">{t('survey')}</Text>
      </Title>
      {surveyList.length ? (
        <FilterableTable
          tableStyles={tableStyles}
          tableHeaderColumns={surveyListColumns}
          onClickRow={(data) => setSelectedSurvey({ id: data.id, title: data.title })}
          data={surveyList}
        />
      ) : (
        <Block flex column alignItems="center" justifyContent="center" margin="100px 0 0 0">
          <EmptyIcon />
          <Text font="Body/12px_Regular" color="SHADES_400" marginTop={40}>
            {t('noSurveyFilled')}
          </Text>
        </Block>
      )}
      {selectedSurvey && (
        <SurveyDetail
          id={selectedSurvey.id}
          title={selectedSurvey.title}
          userId={userId}
          clientId={clientId}
          handleClose={() => setSelectedSurvey(null)}
        />
      )}
    </Wrapper>
  );
};

export default memo(SurveyList);
