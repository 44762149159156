import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';
import { bulkUploadMembers } from 'lib/memberService';
import { getTags } from 'reducers/members';

import Block from 'components/Dashboard_v2/Block';
import Button from 'components/Dashboard_v2/Button';
import FormControl from 'components/Dashboard_v2/FormControl';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import TagSelectorInput from 'components/Dashboard_v2/TagSelectorInput';
import { dashboard_v2 } from 'theme';
import FileUploader from './FileUploader';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const FlexCenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;
  i {
    margin-right: 8px;
    font-size: 14px;
  }
`;

const BlockActions = styled.a`
  display: inline-block;
  margin-bottom: 24px;
  text-decoration: none;
`;

const MemberImport = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { renderAlert, setAlert } = useAlert();
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleSubmit = async () => {
    if (isLoading || !uploadedData) return;

    setIsLoading(true);
    try {
      await bulkUploadMembers({ clientId, users: uploadedData, tags: selectedTags });
      setAlert({ type: 'success', title: t('dataAddedSuccessMessage') });
      setRedirectToList(true);
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: t('dataImportFailed', { error }) });
      console.error(errRes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getTags({ clientId }));
  }, [dispatch, clientId]);

  return redirectToList ? (
    <Redirect push to="/members" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/members">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/members">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button
            color="primary"
            variant="filled"
            onClick={handleSubmit}
            disabled={!uploadedData || !uploadedData.length}
          >
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : t('saveAndAdd')}
          </Button>
        </FormActions>
      </Header>
      <Main>
        <Block
          title={
            <FlexCenterWrapper>
              {t('importList')}
              <InfoPopover
                popoverContent={
                  <React.Fragment>
                    <div>{t('fieldFormatDescription')}</div>
                    <div>{t('nameRequired')}</div>
                    <div>{t('phoneFormatRequired')}</div>
                    <div>{t('birthdayFormatRequired')}</div>
                    <div>{t('genderRequired')}</div>
                    <div>{t('identifierRequired')}</div>
                    <div>{t('idFormatCheck')}</div>
                  </React.Fragment>
                }
                style={{ marginLeft: 8, color: colors.SHADES_400 }}
                type="TOP_RIGHT"
              />
            </FlexCenterWrapper>
          }
        >
          <BlockActions
            href="https://storage.googleapis.com/alleypin-179404/import-members-template.xlsx"
            download={t('memberListTemplate')}
          >
            <IconButton color="primary" variant="outline">
              <i className="ri-download-2-fill"></i>
              {t('memberListTemplateXlsx')}
            </IconButton>
          </BlockActions>
          <FileUploader setUploadedData={setUploadedData} />
        </Block>
        <Block title={t('tags')} description={t('addTagsOptional')}>
          <FormControl margin={'none'}>
            <TagSelectorInput selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
          </FormControl>
        </Block>
      </Main>
    </Container>
  );
};

export default connectData(MemberImport);
