import React, { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/zh-tw';
import styled from 'styled-components';
import Dialog from 'components/Dashboard_v2/Dialog';
import Select from 'components/Dashboard_v2/Select';
import Radio from 'components/Dashboard_v2/Radio';
import Text from 'components/Dashboard_v2/Text';
import DatePicker from 'components/Dashboard_v2/DatePicker/Input';
import { FlowIcon } from './EditBlocks';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.SHADES_800};
  margin-bottom: 24px;
`;

const ScheduleTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 16px 0 16px 8px;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  > span {
    margin: 0 4px 8px 8px;
  }
`;

const TIME_OPTIONS = {
  // HOURS: 00, 01 ... 23
  HOURS: Array.from(Array(24)).map((n, i) => ({ id: i, name: `${i}`.padStart(2, '0'), value: i })),
  // MINUTES: 00, 05, ... 55
  MINUTES: Array.from(Array(12)).map((n, i) => ({ id: i, name: `${i * 5}`.padStart(2, '0'), value: i * 5 })),
};

const getHourOption = (date) =>
  date
    ? TIME_OPTIONS.HOURS.find((hour) => hour.name === moment(date).add(5, 'minute').format('HH'))
    : TIME_OPTIONS.HOURS[0];

const getMinuteOption = (date) => {
  const option =
    date &&
    TIME_OPTIONS.MINUTES.find(
      (minute) => minute.name === `${parseInt(moment(date).add(5, 'minute').format('mm') / 5) * 5}`.padStart(2, '0')
    );
  return option || TIME_OPTIONS.MINUTES[0];
};

const getTimeSettings = (data) => {
  const date = data || moment(new Date());
  return {
    date: moment(date).format('YYYY/MM/DD'),
    hour: getHourOption(date),
    minute: getMinuteOption(date),
  };
};

export const getDisplayTime = (date) => {
  if (!date) return;
  const time = moment(date).locale('zh-tw').format('YYYY/MM/DD (dd) ah:mm');
  return t('scheduledToSendAtTime', { time });
};

const ScheduleTimeModal = ({ data, onConfirm, onClose }) => {
  const [scheduleType, setScheduleType] = useState(data.scheduleType);
  const [timeSetting, setTimeSetting] = useState(getTimeSettings(data.scheduleTime));

  const selectedTime = useMemo(() => {
    const { date, hour, minute } = timeSetting;
    return moment(`${date} ${hour.value}:${minute.value}`, 'YYYY/MM/DD HH:mm').toDate();
  }, [timeSetting]);

  const isDisabled = useMemo(() => {
    if (scheduleType !== 'immediately') {
      return !moment(selectedTime).isValid();
    }
  }, [scheduleType, selectedTime]);

  const handleChange = (key, value) => {
    setTimeSetting((prev) => ({ ...prev, [key]: value }));
  };

  const handleConfirm = () => {
    onConfirm({
      scheduleType,
      scheduleTime: scheduleType === 'scheduled' ? selectedTime : null,
    });
    onClose();
  };

  return (
    <Dialog
      open={true}
      title={<FlowIcon type="time" />}
      description={
        <div>
          <ModalTitle>{t('setSendingTime')}</ModalTitle>
          <Radio
            name="scheduleType"
            value={scheduleType}
            onChange={(value) => setScheduleType(value)}
            items={[
              {
                label: t('sendImmediately'),
                value: 'immediately',
              },
              {
                label: t('scheduleSending'),
                value: 'scheduled',
                content: (
                  <React.Fragment>
                    <ScheduleTimeWrapper>
                      <DatePicker
                        label={t('sendDate')}
                        width={160}
                        value={timeSetting.date}
                        onChange={(date) => handleChange('date', date)}
                      />
                      <TimeWrapper>
                        <Select
                          label={t('sendTime')}
                          options={TIME_OPTIONS.HOURS}
                          selectedOption={timeSetting.hour}
                          setSelectedOption={(option) => handleChange('hour', option)}
                          width={80}
                        />
                        <span>:</span>
                        <Select
                          options={TIME_OPTIONS.MINUTES}
                          selectedOption={timeSetting.minute}
                          setSelectedOption={(option) => handleChange('minute', option)}
                          width={80}
                        />
                      </TimeWrapper>
                    </ScheduleTimeWrapper>
                    <Text font="Body/14px_Regular" color="SHADES_500" marginLeft={28}>
                      {getDisplayTime(selectedTime)}
                    </Text>
                  </React.Fragment>
                ),
              },
            ]}
            direction="column"
            gap={8}
            required
            fullWidth
          />
        </div>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={isDisabled}
    />
  );
};

const propTypes = {
  data: PropTypes.shape({
    scheduleType: PropTypes.string,
    scheduleTime: PropTypes.instanceOf(Date),
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ScheduleTimeModal.propTypes = propTypes;

export default memo(ScheduleTimeModal);
