import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18n/config';

const ID_TYPES = [
  { id: 1, name: t('idOrResidentNumber'), value: 'twID' },
  { id: 2, name: t('medicalRecordNumber'), value: 'hisID' },
  { id: 3, name: t('memberNumber'), value: 'memberID' },
  { id: 4, name: t('passportNumber'), value: 'pdID' },
];

const useMemberIdTypes = () => {
  const features = useSelector((state) => state.auth.features);
  const { patientIdType } = features;
  const [idTypes, setIdTypes] = useState();

  useEffect(() => {
    const types = [...ID_TYPES];
    const patientIdTypeIndex = types.findIndex((type) => type.value === patientIdType);
    if (patientIdTypeIndex !== -1) {
      const [patientIdType] = types.splice(patientIdTypeIndex, 1);
      types.unshift(patientIdType);
      setIdTypes(types);
    } else {
      setIdTypes(types);
    }
  }, [patientIdType]);

  return { idTypes, patientIdType };
};

export default useMemberIdTypes;
