import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import down from 'img/down.png';
import moment from 'moment';

const propTypes = {
  isOpen: PropTypes.bool,
  option: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  defaultOption: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.string || PropTypes.number,
  clickable: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

const DropdownComp = ({ defaultOption, options, clickable = true, onChange, type }) => {
  const [toggle, setToggle] = useState(false);
  const [date, setDate] = useState(moment(new Date()));

  const renderdiv = (o) => {
    if (type === 'date') {
      return (
        <SingleDatePicker
          isOutsideRange={() => false}
          displayFormat="YYYY/MM/DD"
          date={moment(new Date(date))}
          onDateChange={(d) => {
            const newO = {
              ...o,
              name: d.format('YYYY/MM/DD'),
              title: d.format('YYYY/MM/DD'),
            };
            onChange(newO);
            setDate(d);
          }}
          focused={toggle}
          onFocusChange={({ focused }) => {
            setToggle(focused);
          }}
          id={o.id}
          key={o.id}
        />
      );
    }
    return (
      <div
        className="menu"
        key={o.id}
        onClick={() => {
          onChange(o);
          setToggle(false);
        }}
      >
        {o.title}
      </div>
    );
  };
  return (
    <Dropdown
      className="mr-1 drop"
      isOpen={toggle}
      toggle={() => {
        setToggle(!toggle);
      }}
    >
      <DropdownToggle tag="span" onClick={setToggle} data-toggle="dropdown" aria-expanded={toggle}>
        <div className="title">{defaultOption.title}</div>
        <img className="line-down-icon" src={down} alt="" />
      </DropdownToggle>
      {clickable && options.length !== 0 && <DropdownMenu>{options.map((o) => renderdiv(o))}</DropdownMenu>}
    </Dropdown>
  );
};
DropdownComp.propTypes = propTypes;

export default DropdownComp;
