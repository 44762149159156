import styled from 'styled-components';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

export const Styles = styled.div`
  display: block;
  max-width: 100%;
  border-spacing: 0;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.textPrimary};
  overflow: hidden;
  tr:last-child {
    td:before {
      border-bottom: none;
    }
  }
`;

export const TableWrap = styled.div`
  display: block;
  max-width: 100%;
  overflow: hidden;
`;

export const ScrollableWrap = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  & ~ div table {
    border-top: 1px solid ${(props) => props.theme.colors.border};
  }
`;

export const container = styled.div``;

export const TitleContainer = styled.div`
  margin: 64px 0 24px 0;
  display: flex;
  flex-direction: row;
`;

export const Reload = styled.i`
  font-size: 20px;
  color: ${(props) => props.theme.colors.textSecondary};
  margin: 0 9px 0 9px;
  cursor: pointer;
`;

export const Title = styled.h5`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.textPrimary};
  margin: 0 9px 0 0;
`;

export const ToolTitle = styled.div``;

export const TableComp = styled.table`
  width: 100%;
`;
export const Td = styled.td`
  height: ${(props) => props.rowHeight || '175px'};
  padding: ${(props) => props.rowPadding || '0px 18px 0 8px'};
  margin: 0;
  font-size: ${(props) => props.fontsize || '13px'};
  color: #0f172a;
  letter-spacing: 0.09px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    width: 100%;
  }

  &:first-child {
    padding-left: 19px;
    &:before {
      left: 16px;
    }
  }
  &:last-child {
    padding-right: 19px;
    &:before {
      right: 16px;
    }
  }
`;
export const Th = styled.th`
  padding: 0px 18px 0 8px;
  color: #64748b;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-right: 0;
  border-left: 0;
  border-top: 0;
  height: 48px;
  white-space: nowrap;
  &:first-child {
    padding-left: 19px;
  }
  &:last-child {
    padding-right: 19px;
  }
`;
export const Thead = styled.thead`
  background-color: ${colors.SHADES_100};
  th {
    div {
      display: flex;
      align-content: center;
      .sortIcon {
        margin-left: 8px;
      }
    }
  }
`;
export const Tbody = styled.tbody``;
export const Tr = styled.tr`
  &:hover {
    background-color: ${(props) => props.hover || 'unset'};
    opacity: ${(props) => (props.hover ? '0.9' : 'unset')};
  }
`;

// border-bottom: 1px solid ${props => props.theme.colors.border}
export const EmptyTr = styled.tr`
  height: 103px;
`;

export const EmptyTd = styled.td`
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: center;
  color: ${(props) => props.theme.colors.textSecondary};
  position: relative;
  &:before {
    left: 16px;
    right: 16px;
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    width: calc(100% - 32px);
  }
`;

export const Footer = styled.div`
  padding: 16px 24px;
`;
