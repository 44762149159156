import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

const NoteContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PopoverContent = styled.div`
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  padding: 16px;
`;

function NoteCell({ value }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
          }}
        >
          <PopoverContent>{value}</PopoverContent>
        </Popover>
      )}
      <NoteContent onClick={handleClick}>{value}</NoteContent>
    </div>
  );
}

NoteCell.propTypes = {
  value: PropTypes.string,
};

export default NoteCell;
