import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import CsvDataSet from 'models/csvDataSet';
import { t } from 'i18n/config';

export default class CSVFileUploader extends Component {
  static propTypes = {
    onFinishedRead: PropTypes.func,
    platform: PropTypes.string,
    vendor: PropTypes.string,
  };

  getAsText = (fileToRead) => {
    const reader = new FileReader();
    try {
      if (this.props.platform === 'windows') {
        reader.readAsText(fileToRead, 'big5');
      } else {
        reader.readAsText(fileToRead);
      }
    } catch (error) {
      alert(t('reupload'));
      this.props.onFinishedRead([]);
    }

    // Handle errors load
    reader.onload = this.fileReadingFinished;
    reader.onerror = this.errorHandler;
  };

  handleFiles = (event) => {
    this.props.onFinishedRead([]);
    if (window.FileReader) {
      const file = event.target.files[0];
      // FileReader are supported.
      this.getAsText(file);
    }
  };

  processData = (rawData) => {
    try {
      const csvDataSetModel = new CsvDataSet(rawData, this.props.vendor);
      const convertedCsvJson = csvDataSetModel.convertToJson();
      this.props.onFinishedRead(convertedCsvJson);
    } catch (error) {
      console.error('error', error);
      alert(t('formatErrorContactSupport'));
    }
  };

  fileReadingFinished = (event) => {
    const rawData = event.target.result;
    this.processData(rawData);
  };

  errorHandler = (event) => {
    if (event.target.error.name === 'NotReadableError') {
      alert('Cannot read file!');
    }
  };

  render() {
    return <Input type="file" onChange={this.handleFiles} accept=".csv,.txt" />;
  }
}
