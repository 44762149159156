import React from 'react';
import PropTypes from 'prop-types';
import NotResponding from 'components/icons/尚未回覆.svg';
import Cancel from 'components/icons/病患取消.svg';
import Confirm from 'components/icons/確定要去.svg';
import Deleted from 'components/icons/已刪除.svg';
import LineMessageBlockedIcon from 'components/icons/LineMessageBlockedIcon';
import LinkIcon from 'components/icons/LinkIcon';
import UnlinkIcon from 'components/icons/UnlinkIcon';
import { t } from 'i18n/config';

export const getAppointmentLight = ({ responseStatus, lineStatus, deleted, rejectedBy }) => {
  switch (true) {
    case deleted === 1:
      return '6';
    case lineStatus === -1:
      return '0';
    case lineStatus === 0:
      return '1';
    case responseStatus === 0:
      return '3';
    case responseStatus === 1:
      return '5';
    case responseStatus === -1: {
      if (rejectedBy === 'patient') {
        return '4.1';
      }
      return '4';
    }
    default:
      return null;
  }
};

export function getIcons({ status }) {
  switch (status) {
    case '0':
    case 'block':
      return <LineMessageBlockedIcon tooltip />;
    case '1':
    case 'notbinding':
      return <UnlinkIcon tooltip />;
    case '2':
    case 'binding':
      return <LinkIcon tooltip />;
    case '3':
    case 'notresponding':
      return <img src={NotResponding} alt="notresponding" />;
    case '4':
    case 'cancel':
      return <img src={Cancel} alt="cancel" />;
    case '4.1':
      return <img src={Cancel} alt="cancel" />;
    case '5':
    case 'Confirm':
      return <img src={Confirm} alt="confirm" />;
    case '6':
    case 'Deleted':
      return <img src={Deleted} alt="Deleted" />;
    default:
      return null;
  }
}

getIcons.propTypes = {
  status: PropTypes.string || PropTypes.number,
};

export function getName(status) {
  switch (status) {
    case '0':
    case 'block':
      return t('blocked');
    case '1':
    case 'notbinding':
      return t('notBoundLine');
    case '2':
    case 'binding':
      return t('bound');
    case '3':
    case 'notresponding':
      return t('notReplied');
    case '4':
    case 'cancel':
      return t('clinicCancelled');
    case '4.1':
      return t('patientCancelled');
    case '5':
    case 'Confirm':
      return t('confirmedGoing');
    case '6':
    case 'Deleted':
      return t('deleted');
    default:
      return null;
  }
}
