import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';
import MorePopover from 'components/WesternAppointments/table/Columns/More/MorePopover';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Icon = styled.i`
  font-size: 24px;
  margin: 0 8px;
  color: ${theme.colors.default};
  cursor: pointer;
`;

export function LoadingCell() {
  return <Box />;
}

function MoreCell({ row, initialState: { clientID } }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Icon className="ri-more-fill" onClick={handleClick} />
      {open && (
        <MorePopover
          key={`${row.original.id}-MorePopover`}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          appointmentID={row.original.id}
          clientID={clientID}
        />
      )}
    </Fragment>
  );
}

MoreCell.propTypes = {
  initialState: PropTypes.shape({
    clientID: PropTypes.string,
  }),
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default MoreCell;
