import React, { useState, useMemo, useEffect, useRef, memo } from 'react';
import styled from 'styled-components';
import Input from 'components/Dashboard_v2/Input';
import Dialog from 'components/Dashboard_v2/Dialog';
import InfoHead from 'feature/MemberCenter/MemberList/AllMembers/table/InfoHead';
import { Styles, Thead, Tbody, Tr, Th, Td, EmptyTr, EmptyTd } from 'components/Table/styles';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const CheckboxWrapper = styled.label`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
  .checkedIcon {
    display: none;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .uncheckedIcon {
      display: none;
    }
    &:checked ~ .checkedIcon {
      display: block;
    }
  }
`;

const StyledCheckBox = (props) => {
  return (
    <CheckboxWrapper>
      <input {...props} type="checkbox" />
      <svg className="uncheckedIcon" focusable="false" viewBox="0 0 24 24" fill="#929CB7">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
      </svg>
      <svg className="checkedIcon" focusable="false" viewBox="0 0 24 24" fill="#5cc2e7">
        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
      </svg>
    </CheckboxWrapper>
  );
};

const Container = styled.div`
  .ri-search-line {
    cursor: pointer;
  }
  table {
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  > div:first-child {
    margin-bottom: 16px;
  }
  > div:last-child {
    max-height: 50vh;
    overflow: auto;
  }
`;

const IconName = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-left: 12px;
    font-size: 18px;
    line-height: 1;
    color: ${colors.SHADES_400};
  }
`;

const getUserName = (user) => {
  const { name, lineName, hisName } = user;
  if (name) {
    return name;
  }
  if (hisName) {
    return (
      <IconName>
        {hisName} <i className="ri-health-book-line" />
      </IconName>
    );
  }
  if (lineName) {
    return (
      <IconName>
        {lineName} <i className="ri-line-fill" />
      </IconName>
    );
  }
  return t('misterMiss');
};

const tableSetting = [
  {
    header: ({ isSelectedAll, toggleSelectAllRows, disabled }) => (
      <StyledCheckBox checked={isSelectedAll} onChange={toggleSelectAllRows} disabled={disabled} />
    ),
    cell: ({ user, isSelected, handleSelect, disabled }) => (
      <StyledCheckBox
        className="userModalCheckbox"
        checked={isSelected}
        onChange={(e) => handleSelect(e, user.id)}
        disabled={disabled}
      />
    ),
    key: 'checkbox',
    width: 50,
  },
  { header: () => <InfoHead accessor="name" />, key: 'displayName', width: 120 },
  { header: t('phone'), key: 'phone', width: 120 },
  { header: t('birthdate'), key: 'birthday', width: 120 },
  { header: t('id'), key: 'idNum', width: 120 },
];

const UserListModal = ({ data, viewMode, onConfirm, onClose }) => {
  const ref = useRef();
  const [userList, setUserList] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedIds, setSelectedIds] = useState(data.selectedUserIds.map((user) => user.id));
  const [displayRowCount, setDisplayRowCount] = useState(100);
  const [filteredData, setFilteredData] = useState(null);

  const filteredList = useMemo(
    () => filteredData && filteredData.slice(0, displayRowCount),
    [filteredData, displayRowCount]
  );

  const filterData = ({ reset }) => {
    ref.current.scrollTop = 0;
    setDisplayRowCount(100);
    setFilteredData(() =>
      searchKeyword && !reset
        ? userList.filter((user) => Object.values(user).join(' ').includes(searchKeyword))
        : userList
    );
  };

  const handleInputClear = () => {
    setSearchKeyword('');
    filterData({ reset: true });
  };

  const toggleSelectAllRows = (e) => {
    const isChecked = e.target.checked;
    const filterIds = filteredData.map((user) => user.id);
    const newSelectedIds = isChecked
      ? [...new Set([...selectedIds, ...filterIds])]
      : selectedIds.filter((id) => !filterIds.includes(id));
    setSelectedIds(newSelectedIds);
  };

  const handleSelect = (e, userId) => {
    const isChecked = e.target.checked;
    const newSelectedIds = isChecked ? [...selectedIds, userId] : selectedIds.filter((id) => id !== userId);
    setSelectedIds(newSelectedIds);
  };

  const handleConfirm = () => {
    const excludedIds = userList.filter((user) => !selectedIds.includes(user.id)).map((user) => user.id);
    onConfirm({ excludedUserIDs: excludedIds });
    onClose();
  };

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (offsetHeight + scrollTop > scrollHeight - scrollHeight / 4) {
      setDisplayRowCount((prev) => prev + 100);
    }
  };

  useEffect(() => {
    if (!userList || filteredData) return;
    setFilteredData(userList);
  }, [userList, filteredData]);

  useEffect(() => {
    const selectedUserIds = new Set(data.selectedUserIds.map((user) => user.id));
    const sortedUsers = data.users
      .map((user) => ({ ...user, displayName: getUserName(user) }))
      .sort((a, b) => selectedUserIds.has(b.id) - selectedUserIds.has(a.id));
    setUserList(sortedUsers);
  }, []); // eslint-disable-line

  return (
    <Dialog
      open={true}
      width={700}
      title={t('sendList')}
      description={
        filteredList && (
          <Container>
            <Input
              onChange={(e) => setSearchKeyword(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && filterData(e)}
              onInputClear={handleInputClear}
              showClearButton={!!searchKeyword}
              value={searchKeyword}
              suffix={<i className="ri-search-line" onClick={filterData} />}
              placeholder={t('searchKeyword')}
              decoration
            />
            <Styles onScroll={handleScroll} ref={ref}>
              <table>
                <Thead>
                  <Tr>
                    {tableSetting.map(({ header, key, width }) => (
                      <Th key={key} style={{ width }}>
                        {typeof header === 'function'
                          ? header({
                              isSelectedAll:
                                filteredList.length && filteredList.every((item) => selectedIds.includes(item.id)),
                              toggleSelectAllRows,
                              disabled: viewMode,
                            })
                          : header}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredList.length ? (
                    filteredList.map((user) => (
                      <Tr key={user.id}>
                        {tableSetting.map(({ cell, key, width }, index) => (
                          <Td key={index} rowHeight="48px" style={{ width }}>
                            {cell
                              ? cell({
                                  user,
                                  handleSelect,
                                  isSelected: selectedIds.includes(user.id),
                                  disabled: viewMode,
                                })
                              : user[key]}
                          </Td>
                        ))}
                      </Tr>
                    ))
                  ) : (
                    <EmptyTr>
                      <EmptyTd colSpan={tableSetting.length}>{t('noDataFound')}</EmptyTd>
                    </EmptyTr>
                  )}
                </Tbody>
              </table>
            </Styles>
          </Container>
        )
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      viewMode={viewMode}
      disabled={!selectedIds.length}
    />
  );
};

export default memo(UserListModal);
