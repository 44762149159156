import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import styled from 'styled-components';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div``;

export function LoadingCell() {
  return <Box />;
}

function LastTeethScalingCell({ value: initialValue }) {
  if (initialValue == null) {
    return <Container>{initialValue}</Container>;
  }
  return <Container>{format(initialValue, 'yyyy/MM/dd')} </Container>;
}

LastTeethScalingCell.propTypes = {
  value: PropTypes.instanceOf(Date),
};

export default LastTeethScalingCell;
