export const sendingStatusToApiKey = {
  0: 'NotSendYet',
  1: 'Sended',
  2: 'Failed',
};

const periodOrdering = {
  上午: 0,
  下午: 1,
  晚間: 2,
  夜診: 3,
  不分時段: 4,
};

export const appointmentSerializer = (sendingStatus) => (res) =>
  res[sendingStatusToApiKey[sendingStatus]].sort((x, y) => {
    if (periodOrdering[x.period] > periodOrdering[y.period]) return 1;
    if (periodOrdering[x.period] < periodOrdering[y.period]) return -1;
    if (x.roomID > y.roomID) return 1;
    if (x.roomID < y.roomID) return -1;
    if (x.bookingNumber > y.bookingNumber) return 1;
    if (x.bookingNumber < y.bookingNumber) return -1;
    return 0;
  });

export const notificationSerializer = (res) => {
  if (res == null) {
    return [];
  }
  res.forEach((notification) => {
    notification.expectedDeliveryDate = new Date(notification.expectedDeliveryAt);
  });
  return res.sort((x, y) => y.expectedDeliveryDate - x.expectedDeliveryDate);
};
