import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';
import Popover from 'components/MaterialDesign/Popover';
import HistoryPopover from 'components/WesternAppointments/table/Columns/More/History/HistoryPopover';
import { t } from 'i18n/config';

const Icon = styled.i`
  font-size: 24px;
  margin: 0 8px;
  color: ${theme.colors.default};
`;
const Container = styled.div`
  width: 160px;
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(41, 39, 95, 0.2);
  border: ${(props) => props.theme.boxBorder.active};
  background-color: ${(props) => props.theme.colors.white};
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 52px;
  &:hover {
    background: ${(props) => props.theme.background.default};
  }
`;
export default function UnsendListPopover({ handleClose, anchorEl, open, appointmentID, clientID }) {
  const [anchorHistoryEl, setAnchorHistoryEl] = React.useState(null);

  const handleClickHistory = useCallback((event) => {
    setAnchorHistoryEl(event.currentTarget);
  }, []);

  const handleCloseHistory = useCallback(() => {
    setAnchorHistoryEl(null);
  }, []);

  const openHistory = Boolean(anchorHistoryEl);
  return (
    <Fragment>
      <Popover open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <Container>
          <OptionContainer onClick={handleClickHistory}>
            <Icon className="ri-history-line" />
            {t('viewSendingRecord')}
          </OptionContainer>
        </Container>
      </Popover>
      {openHistory && (
        <HistoryPopover
          key={`${appointmentID}-HistoryPopover`}
          open={openHistory}
          anchorEl={anchorHistoryEl}
          handleClose={handleCloseHistory}
          appointmentID={appointmentID}
          clientID={clientID}
        />
      )}
    </Fragment>
  );
}

UnsendListPopover.propTypes = {
  handleClose: PropTypes.func,
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  appointmentID: PropTypes.number,
  clientID: PropTypes.string,
};
