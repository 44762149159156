import moment from 'moment';
import { t } from 'i18n/config';

export const messageHistorySerializer = (response) =>
  (response || [])
    .filter(({ messageAPIStatus, smsAPIStatus }) => messageAPIStatus != null || smsAPIStatus != null)
    .map(({ content, messageAPIStatus, smsAPIStatus, updated_at }) => {
      let sentType;
      switch (true) {
        case messageAPIStatus === 'success':
          sentType = 'LINE';
          break;
        case smsAPIStatus === 'success':
          sentType = t('sms');
          break;
        default:
          break;
      }
      const time = moment(updated_at).format('YYYY/MM/DD HH:mm');
      return {
        content,
        sentType,
        time,
        failed: messageAPIStatus === 'failed' || smsAPIStatus === 'failed',
      };
    });

export const appointmentSerializer = () => [];
