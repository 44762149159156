import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/MaterialDesign/Modal';
import { Confirm } from 'components/MaterialDesign/Buttons';
import FileUploader from './FileUploader';
import { t } from 'i18n/config';

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  align-self: flex-start;
  margin: 16px 0;
`;

const CloseIcon = styled.i`
  font-size: 30px;
  cursor: pointer;
  align-self: flex-end;
  color: #6f788b;
`;
const CloseButton = styled(Confirm)`
  margin: 30px 0;
  color: #6f788b;
  font-size: 18px;
  width: 70px;
  height: 45px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  align-self: flex-start;
  margin: 16px 0;
`;

function UploadModal(props) {
  const { visible, onClose, onUploaded, vendor } = props;
  return (
    <Modal open={visible} onClose={onClose} styles={{ maxWidth: '500px', minWidth: '500px', borderRadius: '16px' }}>
      <Container>
        <CloseIcon className="ri-close-line" onClick={onClose} />
        <Title>{t('uploadList')}</Title>
        <FileUploader onUploaded={onUploaded} vendor={vendor} />
        <Description>
          {t('fileFormat')}
          <br /> {t('fileCapacity')}
        </Description>
        <CloseButton onClick={onClose} customizedcolor="#e2e7f0">
          {t('close')}
        </CloseButton>
      </Container>
    </Modal>
  );
}

UploadModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onUploaded: PropTypes.func,
  vendor: PropTypes.string,
};

export default UploadModal;
