import XLSX from 'xlsx';
import { isValid, set } from 'date-fns';
import { phoneFilter } from 'lib/vendors/teetchScalingParser/utils';

function parseTWDateToAD(twdateString) {
  if (twdateString != null && twdateString !== '') {
    const arr = twdateString.split('/');
    const y = parseInt(arr[0], 10) + 1911;
    const m = parseInt(arr[1], 10);
    const d = parseInt(arr[2], 10);
    const res = new Date(`${y}/${m}/${d}`);
    return res;
  }
  return undefined;
}

export default function Fiadigital() {
  this.parse = (xls, rABS) => {
    const wb = XLSX.read(xls, { type: rABS ? 'binary' : 'array' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 'A' });
    const res = data
      .filter((d, i) => i > 5)
      .filter((d) => d.I != null && d.I !== '')
      .map((d) => {
        let phone = `${d.I}`;
        if (phone[0] !== '0') {
          phone = `0${phone}`;
        }
        return {
          hisID: d.A,
          name: d.C,
          phone,
          doctor: d.P,
          birth: parseTWDateToAD(d.E),
          lastVisit: parseTWDateToAD(d.O),
          lastTeethScaling: parseTWDateToAD(d.O), // 只有就診日，所以選d.O，因為是匯出洗牙名單
        };
      })
      .filter((d) => isValid(d.lastTeethScaling) && isValid(d.lastVisit))
      .filter(phoneFilter);

    return res;
  };

  this.bindCampaign = (list, campaign, options) => {
    const { delaySeconds } = campaign;
    return list.map((l) => {
      let estimatedDeliveryTime = new Date(l.lastTeethScaling.getTime() + delaySeconds * 1000);
      if (options && options.setDateValues) {
        estimatedDeliveryTime = set(estimatedDeliveryTime, options.setDateValues);
      }
      return {
        ...l,
        estimatedDeliveryTime,
      };
    });
  };
}
