import { Component } from 'react';

class SidebarHeader extends Component {
  render() {
    return null;
    // Uncomment following code lines to add Sidebar Header
    // return (
    //   <div className="sidebar-header"></div>
    // )
  }
}

export default SidebarHeader;
