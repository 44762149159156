import React, { useState, useCallback, memo } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { range } from 'lodash-es';
import styled from 'styled-components';
import { getYear, getMonth } from 'date-fns';
import { dashboard_v2 } from 'theme';
import tw from 'date-fns/locale/zh-TW';
import jp from 'date-fns/locale/ja';
import en from 'date-fns/locale/en-US';
import Select from 'components/Dashboard_v2/Select';
import { t, currentLanguage } from 'i18n/config';

const localeMap = { tw, jp, en };

registerLocale(currentLanguage, localeMap[currentLanguage]);

const { colors } = dashboard_v2;

const DatePickerWrapper = styled.div`
  padding: 24px 16px;
  .react-datepicker {
    border: none;
  }
  .react-datepicker__header {
    background-color: ${colors.SHADES_000};
    border-bottom: none;
    padding: 0;
  }
  .react-datepicker__week {
    display: flex;
    align-items: center;
  }
  .react-datepicker__day-names {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  .react-datepicker__day-name {
    font-weight: 700;
  }
  .react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled):hover {
    color: ${colors.PRIMARY_400};
  }
  .react-datepicker__day--selected {
    background-color: ${colors.PRIMARY_400};
  }
`;
const CustomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 8px;
  > div:first-child {
    margin-right: 24px;
  }
`;

const getSelectedOption = (value) => ({ id: value, name: `${value}`, value });

const Wrapper = ({ selectedDate, yearRange, maxDate, minDate, onChange }) => {
  const [draftDate, setDraftDate] = useState(selectedDate);
  const yearOptions = range(yearRange.startYear, yearRange.endYear + 1).map((value, index) => ({
    id: index,
    name: `${value}`,
    value,
  }));
  const monthOptions = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ].map((value, index) => ({ id: index, name: `${value}`, value }));

  const handleChange = useCallback(
    (date) => {
      setDraftDate(date);
      onChange(date);
    },
    [onChange]
  );

  return (
    <DatePickerWrapper>
      <ReactDatePicker
        locale={currentLanguage}
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <CustomHeader>
            <Select
              options={yearOptions}
              selectedOption={getSelectedOption(getYear(date))}
              setSelectedOption={(option) => changeYear(option.value)}
              variant="text"
              width={90}
            />
            <Select
              options={monthOptions}
              selectedOption={monthOptions[getMonth(date)]}
              setSelectedOption={(option) => changeMonth(option.id)}
              variant="text"
              width={90}
            />
          </CustomHeader>
        )}
        selected={draftDate}
        onMonthChange={handleChange}
        onYearChange={handleChange}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        inline
      />
    </DatePickerWrapper>
  );
};

Wrapper.propTypes = {};

export default memo(Wrapper);
