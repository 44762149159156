import { getToken, MARKETING_URL, ENV_STAGE } from '../helpers';
import { responseHandler } from './responseHandler';

const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  'AP-stage': ENV_STAGE,
});

/* Templates */

export const getTemplates = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplates`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const getTemplate = async ({ clientId, templateId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplates/${templateId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createTemplate = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplates`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const updateTemplate = async ({ clientId, templateId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplates/${templateId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const deleteTemplate = async ({ clientId, templateId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplates/${templateId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(responseHandler);

export const getTemplateCategories = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplateCategories`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const updateTemplateCategories = ({ clientId, categories }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/messageTemplateCategories`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(categories),
  }).then(responseHandler);

/* Patient Trackings */

export const getPatientTrackings = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const getPatientTracking = async ({ clientId, patientTrackingId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings/${patientTrackingId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createPatientTracking = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const updatePatientTracking = async ({ clientId, patientTrackingId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings/${patientTrackingId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const patchPatientTracking = async ({ clientId, patientTrackingId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings/${patientTrackingId}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const deletePatientTracking = async ({ clientId, patientTrackingId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings/${patientTrackingId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(responseHandler);

export const enablePatientTracking = async ({ clientId, patientTrackingId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings/${patientTrackingId}/enable`, {
    method: 'POST',
    headers: getHeaders(),
  }).then(responseHandler);

export const disablePatientTracking = async ({ clientId, patientTrackingId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/patientTrackings/${patientTrackingId}/disable`, {
    method: 'POST',
    headers: getHeaders(),
  }).then(responseHandler);

export const updateUserPatientTrackings = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/usersWithPatientTrackings`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

/* Group Messages */

export const getGroupMessages = async ({ clientId, size, page }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupMessages?size=${size}&page=${page}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const getGroupMessage = async ({ clientId, groupMessageId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupMessages/${groupMessageId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createGroupMessage = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupMessages`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const updateGroupMessage = async ({ clientId, groupMessageId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupMessages/${groupMessageId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const sendGroupMessage = async ({ clientId, groupMessageId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupMessages/${groupMessageId}/send`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const deleteGroupMessage = async ({ clientId, groupMessageId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupMessages/${groupMessageId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(responseHandler);

let groupingUsersController;
export const abortGetGroupingUsers = () => groupingUsersController && groupingUsersController.abort();
export const getGroupingUsers = async ({ clientId, payload }) => {
  groupingUsersController = new AbortController();
  const signal = groupingUsersController.signal;
  return fetch(`${MARKETING_URL}/v2/clients/${clientId}/groupingUsers`, {
    method: 'POST',
    headers: getHeaders(),
    signal,
    body: JSON.stringify(payload),
  }).then(responseHandler);
};
