import React from 'react';

const Tag = (props) => (
  <div className={props.classNames.selectedTag + (props.isSelected ? ' selected' : '')} onClick={props.onSelectTag}>
    <div>{props.tag.name}</div>
    <i className="ri-close-circle-fill" onClick={props.onDelete}></i>
  </div>
);

export default Tag;
