import React, { memo, useMemo, useState, useRef, useEffect } from 'react';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';
import Input from 'components/Dashboard_v2/Input';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: ${({ fullWidth, width }) => (fullWidth ? '100%' : `${width}px`)};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ allowSearch }) =>
    !allowSearch &&
    `
    input {
      cursor: pointer;
      caret-color: transparent;
    }
  `}
`;

const TextSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > div,
  > i {
    font-size: 16px;
    font-weight: 500;
    color: ${colors.SHADES_400};
  }
  > div {
    margin-right: 16px;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: ${({ direction }) => (direction == 'top' ? `calc(-250px - 8px);` : `calc(100% + 8px);`)};
  width: 100%;
  max-height: 250px;
  overflow: auto;
  padding: 8px 0;
  background: ${colors.SHADES_000};
  border-radius: 8px;
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
    0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  z-index: 100;
`;

const Option = styled.div`
  padding: 8px 16px;
  color: ${({ isSelected }) => (isSelected ? colors.SHADES_000 : colors.SHADES_900)};
  ${({ isSelected }) => isSelected && `background-color: ${colors.PRIMARY_400}`};
  ${({ isSelected, isDisabled }) => !isSelected && isDisabled && `color: ${colors.SHADES_300}`};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${({ isSelected }) => (isSelected ? colors.SHADES_000 : colors.PRIMARY_400)};
    ${({ isSelected, isDisabled }) => !isSelected && isDisabled && `color: ${colors.SHADES_300}`};
  }
`;

const FilterCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  padding: 16px 16px 8px 16px;
`;

const Select = ({
  options,
  selectedOption,
  setSelectedOption,
  allowAdd = false,
  allowSearch = false,
  showArrow = true,
  decoration = false,
  variant = 'input', // input, text
  width = 320,
  margin,
  fullWidth,
  label,
  disabled,
  required,
  direction = 'bottom',
  ...restProps
}) => {
  const optionsRef = useRef();
  const [isOpen, setIsOpen] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(get(selectedOption, 'name') || '');
  const filteredOptions = useMemo(() => {
    if (!allowSearch || !searchKeyword || (selectedOption && selectedOption.name === searchKeyword)) {
      return options;
    }
    return options.filter((option) => option.name.includes(searchKeyword));
  }, [options, searchKeyword, selectedOption, allowSearch]);

  const handleOpen = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleClose = () => setIsOpen(false);

  const handleInputChange = (e) => {
    if (!allowSearch) return;
    setSelectedOption(null);
    setSearchKeyword(e.target.value);
  };

  const handleInputEnter = (e) => e.keyCode === 13 && closeOptions();

  const handleInputClear = () => {
    if (!allowSearch) return;
    setSelectedOption(null);
    setSearchKeyword('');
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setSearchKeyword(option.name);
    closeOptions();
  };

  const closeOptions = () => {
    const existOption = options.find((option) => option.name === searchKeyword);
    if (!selectedOption && existOption) {
      setSelectedOption(existOption);
    } else if (allowAdd && searchKeyword && !selectedOption && !filteredOptions.length) {
      setSelectedOption({ id: null, name: searchKeyword });
    }
    handleClose(false);
  };

  useEffect(() => {
    if (selectedOption) {
      setSearchKeyword(selectedOption.name);
    }
    if (optionsRef.current && isOpen && selectedOption) {
      const optionWrapper = optionsRef.current;
      const selectedOption = optionWrapper.querySelector('.selectedOption');
      if (selectedOption && optionWrapper.scrollHeight > optionWrapper.offsetHeight) {
        const selectedOptionHeight = selectedOption.offsetHeight;
        const selectedOptionPosition = optionWrapper.querySelector('.selectedOption').offsetTop;
        optionsRef.current.scroll({
          top: selectedOptionPosition - selectedOptionHeight * 3,
        });
      }
    }
  }, [isOpen, selectedOption]);

  return (
    <ClickAwayListener onClickAway={() => isOpen && closeOptions()} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <SelectWrapper allowSearch={allowSearch} width={width} fullWidth={fullWidth} margin={margin}>
        {variant === 'text' && (
          <TextSelectorWrapper onClick={handleToggle}>
            <div>{searchKeyword || t('pleaseChoose')}</div>
            {showArrow ? <i className={isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} /> : null}
          </TextSelectorWrapper>
        )}
        {variant === 'input' && (
          <div onClick={handleOpen}>
            <Input
              onChange={handleInputChange}
              onKeyDown={handleInputEnter}
              onInputClear={handleInputClear}
              showClearButton={allowSearch && !!searchKeyword && isOpen}
              value={searchKeyword}
              suffix={showArrow ? <i className={isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} /> : null}
              placeholder={t('pleaseChoose')}
              decoration={decoration}
              fullWidth={fullWidth}
              label={label}
              disabled={disabled}
              required={required}
              {...restProps}
            />
          </div>
        )}
        <OptionsWrapper hidden={!isOpen} ref={optionsRef} direction={direction}>
          {allowSearch && (
            <FilterCount>{t('searchResults', { info: `${filteredOptions.length}/${options.length}` })}</FilterCount>
          )}
          {filteredOptions.map((option, index) => {
            const isSelected = selectedOption && selectedOption.name === option.name;
            return (
              <Option
                className={isSelected ? 'selectedOption' : ''}
                onClick={() => !option.disabled && handleSelect(option)}
                isSelected={isSelected}
                isDisabled={option.disabled}
                key={option.id || index}
              >
                {option.name}
              </Option>
            );
          })}
        </OptionsWrapper>
      </SelectWrapper>
    </ClickAwayListener>
  );
};

const optionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
});

const propTypes = {
  options: PropTypes.arrayOf(optionType),
  selectedOption: optionType,
  setSelectedOption: PropTypes.func,
  allowAdd: PropTypes.bool,
  allowSearch: PropTypes.bool,
  showArrow: PropTypes.bool,
  decoration: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.number,
  label: PropTypes.string,
  margin: PropTypes.string,
};

Select.propTypes = propTypes;

export default memo(Select);
