import React from 'react';
import LineMessageBlockedIcon from 'components/icons/LineMessageBlockedIcon';
import LinkIcon from 'components/icons/LinkIcon';
import UnlinkIcon from 'components/icons/UnlinkIcon';
import { t } from 'i18n/config';

export const Icons = {
  '-1': <LineMessageBlockedIcon tooltip />,
  0: <UnlinkIcon tooltip />,
  1: <LinkIcon tooltip />,
};

export const Names = {
  '-1': t('blockedStatus'),
  0: t('notBoundLine'),
  1: t('bound'),
};

export function getName(status) {
  return Names[status];
}
