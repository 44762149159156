export default function SearchSvg() {
  return (
    <svg width="160" height="124" viewBox="0 0 160 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_123_10335)">
        <path
          d="M108.406 14.4516C115.222 3.365 133.103 8.5828 132.869 21.632C132.891 25.5226 131.103 29.3476 128.116 31.8297"
          fill="#54B2D1"
        />
        <path
          d="M32.3625 31.8295C19.3687 20.5801 35.3625 0.770003 49.0625 11.143C50.2406 12.0664 51.2593 13.1838 52.0718 14.4515"
          fill="#54B2D1"
        />
        <path
          d="M145.891 123.509L139.4 68.6075C139.4 32.9656 112.944 5.80615 80.3096 5.80615C47.6752 5.80615 21.2158 32.9688 21.2158 68.6075L14.6689 123.509H145.891Z"
          fill="#DDE9EF"
        />
        <path
          d="M85.4279 33.2163C86.6186 33.2163 86.6186 34.0113 86.6186 35.4042C86.6186 36.7971 86.6186 37.1946 85.4279 37.1946C84.2373 37.1946 84.2373 36.7971 84.2373 35.4042C84.2373 34.0113 84.2373 33.2163 85.4279 33.2163Z"
          fill="#040000"
        />
        <path
          d="M75.05 33.2163C76.2406 33.2163 76.2406 34.0113 76.2406 35.4042C76.2406 36.7971 76.2406 37.1946 75.05 37.1946C73.8594 37.1946 73.8594 36.7971 73.8594 35.4042C73.8594 34.0113 73.8594 33.2163 75.05 33.2163Z"
          fill="#040000"
        />
        <path
          d="M86.7811 45.5736C104.212 51.0982 99.5686 66.8141 80.2342 66.2789C60.7904 66.8204 56.2779 50.9479 73.8529 45.5298"
          fill="white"
        />
        <path
          d="M73.856 45.5265C70.6217 39.2038 87.3529 38.7594 87.2435 44.0491C86.2779 50.0463 76.4248 50.6661 73.856 45.5265Z"
          fill="#54B2D1"
        />
        <path
          d="M80.2373 76.6799C75.5342 76.6799 70.8311 76.1133 66.7248 74.9771C66.3092 74.8613 66.0655 74.4325 66.1811 74.0131C66.2967 73.5968 66.728 73.3526 67.1436 73.4653C75.1061 75.6689 85.3873 75.6657 93.3373 73.4653C93.753 73.3495 94.1842 73.5936 94.2998 74.0099C94.4155 74.4262 94.1717 74.8582 93.7561 74.974C89.653 76.1102 84.9498 76.6799 80.2436 76.6799H80.2373Z"
          fill="#9BB8C4"
        />
        <path
          d="M59.9376 123.509L80.2376 109.527L100.538 123.509H107.322L89.5813 96.8812C88.9813 95.9829 87.6657 95.9735 87.0532 96.8624L80.2345 106.794L73.4157 96.8624C72.8032 95.9704 71.4876 95.9798 70.8876 96.8812L53.147 123.509H59.9313H59.9376Z"
          fill="white"
        />
        <path
          d="M99.4097 68.4087L104.37 65.641L118.026 90.1961C118.686 91.3829 118.26 92.8858 117.075 93.5469L116.411 93.917C115.227 94.5781 113.726 94.1505 113.066 92.9638L99.4097 68.4087Z"
          fill="#DCDCDC"
          stroke="#DCDCDC"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.187 59.531L95.3677 63.3359L99.1665 70.1665L105.986 66.3615L102.187 59.531Z"
          fill="#959595"
          stroke="#959595"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M101.673 56.0802C108.995 48.7473 108.995 36.8582 101.673 29.5253C94.3523 22.1923 82.4824 22.1923 75.1613 29.5253C67.8402 36.8582 67.8402 48.7473 75.1613 56.0802C82.4824 63.4132 94.3523 63.4132 101.673 56.0802Z"
          fill="white"
          stroke="#B1B1B1"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.3655 22.9306C66.4061 29.0436 62.4718 42.9004 68.5749 53.8743C74.678 64.8514 88.5124 68.7922 99.4686 62.6792C110.425 56.5662 114.362 42.7094 108.259 31.7355C102.156 20.7584 88.3218 16.8176 77.3655 22.9306ZM97.5624 59.2486C88.4968 64.3068 77.0499 61.0453 71.9999 51.965C66.9499 42.8847 70.2061 31.4193 79.2718 26.3611C88.3374 21.303 99.7843 24.5645 104.834 33.6448C109.884 42.7251 106.628 54.1905 97.5624 59.2486Z"
          fill="#DCDCDC"
          stroke="#DCDCDC"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.647 26.834C89.647 26.834 99.4251 28.2832 101.928 35.4135"
          stroke="#DCDCDC"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.961 40.3278C104.539 39.7978 104.579 38.8989 104.049 38.3201C103.52 37.7413 102.623 37.7017 102.045 38.2317C101.467 38.7617 101.428 39.6606 101.957 40.2394C102.486 40.8183 103.383 40.8578 103.961 40.3278Z"
          fill="#DCDCDC"
        />
        <path
          d="M101.673 56.0802C108.995 48.7473 108.995 36.8582 101.673 29.5253C94.3523 22.1923 82.4824 22.1923 75.1613 29.5253C67.8402 36.8582 67.8402 48.7473 75.1613 56.0802C82.4824 63.4132 94.3523 63.4132 101.673 56.0802Z"
          fill="#EEF4F7"
        />
        <mask
          id="mask0_123_10335"
          style={{ 'mask-type': 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="69"
          y="24"
          width="39"
          height="38"
        >
          <path
            d="M101.673 56.0807C108.995 48.7477 108.995 36.8587 101.673 29.5257C94.3523 22.1928 82.4824 22.1928 75.1613 29.5257C67.8402 36.8587 67.8402 48.7478 75.1613 56.0807C82.4824 63.4136 94.3523 63.4136 101.673 56.0807Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_123_10335)">
          <path
            d="M86.4652 29.9111C87.8934 29.9111 87.8934 30.8658 87.8934 32.5341C87.8934 34.2024 87.8934 34.6813 86.4652 34.6813C85.0371 34.6813 85.0371 34.2056 85.0371 32.5341C85.0371 30.8627 85.0371 29.9111 86.4652 29.9111Z"
            fill="#040000"
          />
          <path
            d="M74.0121 29.9111C75.4402 29.9111 75.4402 30.8658 75.4402 32.5341C75.4402 34.2024 75.4402 34.6813 74.0121 34.6813C72.584 34.6813 72.584 34.2056 72.584 32.5341C72.584 30.8627 72.584 29.9111 74.0121 29.9111Z"
            fill="#040000"
          />
          <path
            d="M88.0905 44.738C109.006 51.3674 103.434 70.226 80.2342 69.5812C56.903 70.2323 51.4873 51.1859 72.578 44.6816"
            fill="white"
          />
          <path
            d="M72.5812 44.6816C68.7 37.0944 88.775 36.5591 88.6468 42.91C87.4906 50.106 75.6656 50.8509 72.5812 44.6816Z"
            fill="#54B2D1"
          />
        </g>
        <path
          d="M154.275 123.509C145.069 88.3017 129.103 79.9883 126.962 78.3387C123.225 75.4622 112.559 72.1913 105.756 84.843C100.215 95.1471 103.609 111.636 107.359 123.509H154.278H154.275Z"
          fill="#DDE9EF"
        />
        <path
          d="M106.538 123.509H108.175C107.594 121.687 107.022 119.753 106.491 117.753C103.844 107.79 101.688 94.0551 106.441 85.2127C109.003 80.4488 112.322 77.5973 116.306 76.7459C120.816 75.7788 124.825 77.6818 126.481 78.9589C126.669 79.1029 126.931 79.2782 127.263 79.5035C129.756 81.1812 132.056 83.1375 134.156 85.2879C135.247 86.4053 136.288 87.5728 137.275 88.7841C137.703 89.31 138.325 90.8468 139.291 90.3304C140.688 89.5854 138.078 87.2379 137.55 86.6244C136.363 85.2409 135.103 83.9138 133.772 82.668C132.094 81.0967 130.294 79.6694 128.391 78.383C128.306 78.3235 128.219 78.2671 128.134 78.2077C127.825 77.998 127.581 77.8352 127.434 77.7225C124.928 75.7913 120.413 74.2701 115.978 75.2216C111.525 76.1763 107.853 79.2907 105.063 84.4772C101.05 91.9424 101.022 103.283 104.984 118.182C105.475 120.028 106 121.812 106.534 123.512L106.538 123.509Z"
          fill="#9BB8C4"
        />
        <path
          d="M26.4314 123.509L18.6439 91.7417C11.8064 105.057 6.7752 115.317 3.04395 123.509H26.4314Z"
          fill="#DDE9EF"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_10335">
          <rect width="160" height="123.509" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
