import { AUTH_LOGOUT } from './auth';

const SET_NAV = 'SET_NAV';

const defaultState = {
  items: [],
};

export const setNav = (navRoutes) => ({
  type: SET_NAV,
  payload: navRoutes,
});

export const setBrandNav = (storeIds, currentNav) => {
  console.log('storeIds', storeIds);
  console.log('currentNavs', currentNav);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_NAV:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
