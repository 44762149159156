import React, { memo } from 'react';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const InputHintArea = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ borderColor }) => borderColor || colors.SHADES_300};
  ${({ type, backgroundColor }) => {
    switch (type) {
      case 'dashed':
        return `
          padding: 12px 0 24px 0;
          border-style: dashed;
        `;
      default:
        return `
          padding: 48px 0;
          background: ${backgroundColor || colors.SHADES_50};
        `;
    }
  }}
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const UploadIcon = styled.i`
  font-size: 30px;
  cursor: pointer;
  color: ${colors.SHADES_400};
`;

const UploadDescription = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const UploadHint = styled.div`
  font-size: 13px;
  color: ${colors.SHADES_400};
  margin-top: 12px;
`;

const TextClick = styled.span`
  color: ${colors.PRIMARY_500};
`;

const InputArea = ({ status, hint, type }) => {
  if (status === UPLOAD_STATUS.INITIAL) {
    return (
      <InputHintArea type={type}>
        <UploadIcon className="ri-upload-cloud-2-line" />
        <UploadDescription>
          {t('dragAndDropFilesHereOr')} <TextClick>{t('uploadFile')}</TextClick>
          <UploadHint>{hint}</UploadHint>
        </UploadDescription>
      </InputHintArea>
    );
  }
  if (status === UPLOAD_STATUS.UPLOADING) {
    return (
      <InputHintArea backgroundColor={colors.PRIMARY_100} borderColor={colors.PRIMARY_300} type={type}>
        <UploadDescription>{t('uploading')}</UploadDescription>
      </InputHintArea>
    );
  }
  return null;
};

export const UPLOAD_STATUS = {
  INITIAL: 'initial',
  UPLOADING: 'uploading',
  DONE: 'done',
};

const UploadArea = ({ innerRef, status, hint, accept, onFileSelect, type }) => {
  return (
    <Wrapper>
      <InputArea status={status} hint={hint} type={type} />
      <Input ref={innerRef} onChange={onFileSelect} type="file" accept={accept} />
    </Wrapper>
  );
};

export default memo(UploadArea);
