import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Confirm } from 'components/MaterialDesign/Buttons';
import { t } from 'i18n/config';

const Container = styled.div`
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 30px;
`;

const Button = styled(Confirm)`
  width: 100px;
  font-size: 18px;
`;

function Footer(props) {
  const { data, onClick, loading } = props;
  if (data.length === 0) {
    return null;
  }
  return (
    <Container>
      <Button disabled={loading} onClick={onClick} endIcon={<i className="ri-arrow-right-line" />}>
        {t('nextStep')}
      </Button>
    </Container>
  );
}

Footer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  sendTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Footer;
