import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Progress } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';

const propTypes = {
  header: PropTypes.string,
  smallText: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.node,
  variant: PropTypes.string,
  reviewNumber: PropTypes.number,
  reviewTitle: PropTypes.string,
  SMSNumber: PropTypes.number,
  SMSTitle: PropTypes.string,
  headerTitle: PropTypes.string,
};

const defaultProps = {
  header: '89.9%',
  // color: '',
  value: '25',
  variant: '',
};

class ProgressWidget extends Component {
  render() {
    const {
      className,
      cssModule,
      headerTitle,
      color,
      value,
      variant,
      reviewTitle,
      reviewNumber,
      SMSTitle,
      SMSNumber,
      ...attributes
    } = this.props;

    // demo purposes only
    const progress = { style: '', color, value };
    const card = { style: '', bgColor: '' };

    if (variant === 'inverse') {
      progress.style = 'progress-white';
      progress.color = '';
      card.style = 'text-white';
      card.bgColor = `bg-${color}`;
    }

    const classes = mapToCssModules(classNames(className, card.style, card.bgColor, 'widgetMinHeight'), cssModule);
    progress.style = classNames('progress-xs my-3', progress.style);

    return (
      <Card className={classes} {...attributes}>
        <CardHeader>{headerTitle}</CardHeader>
        <CardBody>
          <ul className="horizontal-bars type-2">
            <li>
              <span className="title">{reviewTitle}</span>
              <span className="value">{reviewNumber}</span>
              <div className="bars">
                <Progress className="progress-xs" color="warning" value={(reviewNumber / SMSNumber) * 100} />
              </div>
            </li>
            <li>
              <span className="title">{SMSTitle}</span>
              <span className="value">{SMSNumber}</span>
              <div className="bars">
                <Progress className="progress-xs" color="warning" value="100" />
              </div>
            </li>
          </ul>
        </CardBody>
      </Card>
    );
  }
}

ProgressWidget.propTypes = propTypes;
ProgressWidget.defaultProps = defaultProps;

export default ProgressWidget;
