import React, { memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import MuiPagination from '@material-ui/lab/Pagination';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiPagination-ul {
    border-radius: 4px;
    border: solid 1px ${colors.SHADES_300};
    overflow: hidden;
    li {
      min-height: 32px;
      &:not(:last-child) {
        border-right: solid 1px ${colors.SHADES_300};
      }
      button {
        border-radius: 0;
        border: none;
        margin: 0;
        color: ${colors.SHADES_600};
        background-color: ${colors.SHADES_50};
        &.MuiPaginationItem-page.Mui-selected {
          background-color: #fff;
        }
      }
      div {
        height: 32px;
        margin: 0;
        padding-top: 6px;
        border-radius: 0;
        color: ${colors.SHADES_600};
        background-color: ${colors.SHADES_50};
      }
    }
  }
`;

const PageSizeSelector = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.SHADES_500};
  cursor: pointer;
  span {
    margin-left: 8px;
  }
  i {
    font-size: 20px;
  }
`;

const PopoverContent = styled.div`
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  div {
    padding: 12px 24px;
    &:hover {
      background-color: ${colors.SHADES_50};
    }
  }
`;

const pageSize = [20, 50, 100];

const Pagination = ({ pageObj }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePageSizeChange = useCallback(
    (size) => {
      pageObj.setCurrentPage(1);
      pageObj.setCurrentPageSize(size);
      setAnchorEl(null);
    },
    [pageObj]
  );

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <PageSizeSelector onClick={handleClick}>
        {t('displayAmountPerPage')}
        <span>{pageObj.currentPageSize}</span>
        <i className="ri-arrow-drop-down-line" />
      </PageSizeSelector>
      <MuiPagination
        page={pageObj.currentPage || 1}
        count={Math.ceil(pageObj.total / pageObj.currentPageSize)}
        onChange={(e, p) => pageObj.setCurrentPage(p)}
        variant="outlined"
        shape="rounded"
      />
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
          }}
        >
          <PopoverContent>
            {pageSize.map((size) => (
              <div key={size} onClick={() => handlePageSizeChange(size)}>
                {size}
              </div>
            ))}
          </PopoverContent>
        </Popover>
      )}
    </Wrapper>
  );
};

export default memo(Pagination);
