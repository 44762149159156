import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';

import { dashboard_v2 } from 'theme';
import Block from 'components/Dashboard_v2/Block';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import Loading from 'components/Dashboard_v2/Loading';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const FlexCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin-bottom: 40px;
`;

const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  canvas {
    z-index: 1;
  }
`;

const MemberCount = styled.div`
  margin-bottom: 48px;
  color: ${colors.SHADES_400};
`;

const PredictValue = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    font-size: 40px;
    font-weight: 700;
    color: ${colors.SHADES_900};
    margin-bottom: 8px;
  }
  span {
    font-size: 12px;
    color: ${colors.SHADES_400};
  }
`;

const ChartLabel = styled.div`
  &:not(:first-child) {
    margin-left: 20px;
  }
  > div {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 12px;
    color: ${colors.SHADES_400};
    &::before {
      content: '';
      margin-right: 12px;
      border-radius: 100%;
      min-width: 8px;
      min-height: 8px;
      max-width: 8px;
      max-height: 8px;
      background-color: ${({ color }) => color};
    }
  }
  span {
    color: ${colors.PRIMARY_800};
    font-size: 24px;
    font-weight: 500;
    margin-left: 20px;
  }
`;

const ChartLabels = ({ chartDataValues, isChartRendering }) => {
  const labels = [
    {
      color: colors.PRIMARY_300,
      title: t('lineSendableMemberCount'),
      popoverContent: t('lineSendableDescription'),
      value: isChartRendering ? 0 : chartDataValues.active,
    },
    {
      color: colors.SHADES_300,
      title: t('lineNotSendableMemberCount'),
      popoverContent: t('lineNotSendableDescription'),
      value: isChartRendering ? 0 : chartDataValues.inactive,
    },
  ];
  return labels.map((data, index) => (
    <ChartLabel color={data.color} key={index}>
      <div>
        {data.title}
        <InfoPopover popoverContent={data.popoverContent} style={{ marginLeft: 4 }} type="TOP_CENTER" />
      </div>
      <span>{data.value}</span>
    </ChartLabel>
  ));
};

export const getChartSetting = (chartValues = {}) => {
  const { activeLineMembers = 0, inactiveLineMembers = 0, totalMembers = 0 } = chartValues;
  const active = activeLineMembers;
  const inactive = inactiveLineMembers;
  const total = totalMembers;
  const isEmpty = active + inactive === 0;

  return {
    dataValues: { active, inactive, total },
    data: {
      labels: isEmpty ? [''] : [t('lineSendableMemberCount'), t('lineNotSendableMemberCount')],
      datasets: [
        {
          data: isEmpty ? [1] : [active, inactive],
          backgroundColor: isEmpty ? [colors.SHADES_300] : [colors.PRIMARY_300, colors.SHADES_300],
        },
      ],
    },
    options: {
      cutoutPercentage: 60,
      maintainAspectRatio: false,
      tooltips: {
        enabled: !isEmpty,
      },
      plugins: {
        legend: false,
      },
    },
  };
};

export const defaultChartData = getChartSetting();

const PredictChartBlock = ({ isChartRendering, chartSetting }) => {
  return (
    <Block
      title={
        <FlexCenterWrapper>
          {t('predictedSegmentSize')}
          <InfoPopover
            popoverContent={t('predictCustomerDistribution')}
            style={{ marginLeft: 8, color: colors.SHADES_400 }}
            type="TOP_CENTER"
          />
        </FlexCenterWrapper>
      }
      minWidth={435}
      fitContent
    >
      <MemberCount>{`${t('totalCustomerCount')}${isChartRendering ? 0 : chartSetting.dataValues.total}`}</MemberCount>
      {isChartRendering ? (
        <LoadingWrapper>
          <Loading size={150} />
        </LoadingWrapper>
      ) : (
        <ChartWrapper>
          <Doughnut width={250} height={250} {...chartSetting} />
          <PredictValue>
            <div>{chartSetting.dataValues.active}</div>
            <span>{t('estimatedSending')}</span>
          </PredictValue>
        </ChartWrapper>
      )}
      <FlexCenterWrapper>
        <ChartLabels chartDataValues={chartSetting.dataValues} isChartRendering={isChartRendering} />
      </FlexCenterWrapper>
    </Block>
  );
};

const propTypes = {
  isChartRendering: PropTypes.bool,
};

PredictChartBlock.propTypes = propTypes;

export default memo(PredictChartBlock);
