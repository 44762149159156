/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Spinner from 'react-spinkit';
import connectData from 'components/HOCs/connectData';
import Ratings from 'components/Ratings';
import { reportReviewDataHandler } from 'reducers/reviews';
import { isLogin, getMonthName, getLastMonthName } from '../../helpers';
import { fetchReportDataByTime, doneRefreshPage } from '../../reducers/customers';
import { fetchPnpConsumption } from '../../reducers/line';
import Widget04 from './Widget04';
import ProgressWidget from './ProgressWidget';
import { t } from 'i18n/config';

const mapStateToProps = (state) => ({
  isRefresh: state.ui.isRefresh,
  clients: state.clients,
  accountUser: state.auth.user,
});

const mapDispatchToProps = {
  reportReviewDataHandler,
  fetchReportDataByTime,
  doneRefreshPage,
  fetchPnpConsumption,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Dashboard extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    fetchReportDataByTime: PropTypes.func,
    reportReviewDataHandler: PropTypes.func,
    doneRefreshPage: PropTypes.func,
    isRefresh: PropTypes.bool,
    fetchPnpConsumption: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const today = new Date();
    const yr = today.getFullYear();
    const m = today.getMonth();
    const firstDay = new Date(yr, m, 1);
    this.firstDay = firstDay;
    this.toggle = this.toggle.bind(this);

    this.state = {
      mixedReviews30days: [],
      dropdownOpen: false,
      isloading: true,
      averageStars: 0,
      totalReviewNumber: 0,
      currentMonthReviewNumber: 0,
      currentSMSNumber: 0,
      reviewDataSet: [0, 0],
      SMSSet: [0, 0],
      pnpData: { display: false },
    };
  }

  componentDidMount() {
    if (!isLogin()) {
      this.props.history.push('/login');
    }
    this.onFetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRefresh === false && prevProps.isRefresh !== this.props.isRefresh) {
      // customer created, update sms number
      this.onFetchData();
    }
  }

  onLoading = () => {
    this.setState({
      isloading: true,
    });
  };

  onFetchData = async () => {
    console.log('on fetch data');
    this.onLoading();
    try {
      // choose anyone of the clients
      const fetchingClients = this.props.clients.selectedIds.join('');
      const firstClient = this.props.clients.byId[this.props.clients.selectedIds[0]];
      const startDay = firstClient.contractStartDay;
      const res = await this.props.fetchReportDataByTime(startDay, new Date());
      const pnpData = await this.props.fetchPnpConsumption({
        startDate: this.firstDay,
        endDate: new Date(),
      });
      const {
        mixedReviews30days,
        averageStars,
        totalReviewNumber,
        currentMonthReviewNumber,
        lastMonthReviewNumber,
        lastMonthSMSNumber,
        currentSMSNumber,
      } = await this.props.reportReviewDataHandler(res);
      const currentClients = this.props.clients.selectedIds.join('');
      // clients may be changed in redux during query,
      // hence there's a check to make sure the result is what users want
      // if not, then query again
      if (fetchingClients !== currentClients) {
        this.onFetchData();
        return;
      }
      this.setState({
        mixedReviews30days,
        averageStars,
        totalReviewNumber,
        currentMonthReviewNumber,
        currentSMSNumber,
        reviewDataSet: [lastMonthReviewNumber, currentMonthReviewNumber],
        SMSSet: [lastMonthSMSNumber, currentSMSNumber],
        pnpData,
        isloading: false,
      });
      this.props.doneRefreshPage();
    } catch (error) {
      const res = await error;
      console.log('fail to load report data', res);
    }
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  renderItem = (mixedReviews30days) =>
    mixedReviews30days.map((info) => (
      <li key={`${info.author_name}-${info.locationUrl}`} className="list-group-item">
        {' '}
        <img className="review-avatar" src={info.profile_photo_url} alt="" />
        <span className="review-name"> {info.author_name}</span>
        <span className="review-time">
          {' '}
          {info.relative_time_description} {info.companyName != null ? ` - ${info.companyName} ` : ''}
        </span>
        <div className="review-text">
          <p>{info.text}</p>
        </div>
        <Ratings value={info.rating} />
        <div className="review-source">
          <a target="_blank" href={info.locationUrl} className="review-sourceLink">
            via <img src={info.viaImageUrl} alt="" />
          </a>
        </div>
      </li>
    ));

  render() {
    const {
      totalReviewNumber,
      averageStars,
      reviewDataSet,
      SMSSet,
      currentMonthReviewNumber,
      currentSMSNumber,
      mixedReviews30days,
      pnpData,
    } = this.state;
    return (
      <div className="animated fadeIn">
        <Row style={{ marginTop: '1.5rem' }}>
          <Col xs="12" sm="6" lg="4">
            <Widget04 icon="icon-star" color="danger" value={`${(averageStars / 5) * 100}`} header={`${averageStars}`}>
              {t('totalAverageStarRating')}
            </Widget04>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widget04 icon="icon-map" color="success" value={`${100}`} header={`${totalReviewNumber}`}>
              {t('totalAccumulatedRatings')}
            </Widget04>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ProgressWidget
              headerTitle={t('currentMonthStatus')}
              reviewTitle={t('generateRating')}
              reviewNumber={currentMonthReviewNumber}
              SMSTitle={t('smsQuantity')}
              SMSNumber={currentSMSNumber}
              color="danger"
              value="95"
              header="345"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="8" sm="8" lg="8">
            <Card>
              <CardHeader>{t('lastMonthRating')}</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    {this.state.isloading && <Spinner name="circle" />}
                    <ul
                      hidden={this.state.isloading}
                      key={this.props.clients.selectedIds.join('')}
                      className="list-group review-section"
                    >
                      {this.renderItem(mixedReviews30days)}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="4" sm="4" lg="4">
            <Card>
              <CardHeader>{t('pastRatings')}</CardHeader>
              <CardBody>
                <div className="chart-wrapper">
                  <Bar
                    data={{
                      labels: [getLastMonthName(new Date()), getMonthName(new Date())],
                      datasets: [
                        {
                          label: t('smsQuantity'),
                          backgroundColor: 'rgba(179,181,198,0.2)',
                          borderColor: 'rgba(179,181,198,1)',
                          borderWidth: 1,
                          hoverBackgroundColor: 'rgba(179,181,198,1)',
                          hoverBorderColor: 'rgba(179,181,198,1)',
                          data: SMSSet,
                        },
                        {
                          label: t('ratingQuantity'),
                          backgroundColor: 'rgba(255,99,132,0.2)',
                          borderColor: 'rgba(255,99,132,1)',
                          borderWidth: 1,
                          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                          hoverBorderColor: 'rgba(255,99,132,1)',
                          data: reviewDataSet,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </CardBody>
            </Card>
            {pnpData.display && (
              <ProgressWidget
                headerTitle={t('thisMonthNotificationMessageVolume')}
                reviewTitle={t('lineNotificationMessage')}
                reviewNumber={pnpData.pnp}
                SMSTitle={t('notificationSms')}
                SMSNumber={pnpData.sms}
                color="danger"
                value="95"
                header="345"
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
