import React from 'react';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const StyledText = styled.span`
  ${({ invalid }) => invalid && `color: ${colors.SYSTEM_ERROR_500}`}
`;

const TextCell = ({ value, column, row }) => {
  const { invalidColumns } = row.original;
  return <StyledText invalid={invalidColumns.includes(column.id)}>{value}</StyledText>;
};

export const columns = [
  {
    Header: t('dataRow'),
    accessor: 'index',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    Header: t('idOrResidentNumber'),
    accessor: 'twID',
    Cell: TextCell,
    minWidth: 140,
    maxWidth: 140,
  },
  {
    Header: t('medicalRecordNumber'),
    accessor: 'hisID',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('memberNumber'),
    accessor: 'memberID',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('passportNumber'),
    accessor: 'pdID',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('name'),
    accessor: 'name',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('phone'),
    accessor: 'phone',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('birthday'),
    accessor: 'birthday',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('gender'),
    accessor: 'gender',
    Cell: TextCell,
    minWidth: 120,
    maxWidth: 120,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
