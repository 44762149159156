import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import connectData from 'components/HOCs/connectData';
import 'components/Customers/Customers.css';
import { fetchCustomersInQue, fetchTotalCustomersInQue, createCustomerV1 } from 'reducers/customers';
import { addBlackList, removeBlackList } from 'reducers/clients';
import { doneRefreshPage } from 'reducers/ui';
import { t } from 'i18n/config';

const emptyCustomers = [];

const mapStateToProps = (state) => ({
  isRefresh: state.ui.isRefresh,
  clients: state.clients,
  user: state.auth.user,
  management: state.customer.management,
});

const mapDispatchToProps = {
  fetchCustomersInQue,
  fetchTotalCustomersInQue,
  createCustomerV1,
  doneRefreshPage,
  addBlackList,
  removeBlackList,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Analytics extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    user: PropTypes.shape({
      operationType: PropTypes.string,
    }),
    management: PropTypes.shape(PropTypes.object),
    fetchTotalCustomersInQue: PropTypes.func,
    fetchCustomersInQue: PropTypes.func,
    createCustomerV1: PropTypes.func,
    isRefresh: PropTypes.bool,
    doneRefreshPage: PropTypes.func,
    addBlackList: PropTypes.func,
    removeBlackList: PropTypes.func,
  };

  static emptyCustomers = [];

  componentDidMount() {
    this.onFetchData();
  }

  onFetchData = async () => {
    try {
      await this.props.fetchTotalCustomersInQue();
    } catch (error) {
      const res = await error;
      console.log('fail to fetch customers in queue', res);
    }
  };

  onCustomerInfoDisplay = ({ name, phone }) =>
    // if (this.isOperationAccount) {
    //   return {
    //     name: maskName(name),
    //     phone: maskPhone(phone)
    //   };
    // }
    ({
      name,
      phone,
    });

  get isOperationAccount() {
    return this.props.user.operationType === 'operation';
  }

  createCustomer = async ({ clientId, customer }) => {
    try {
      await this.props.createCustomerV1({
        clientId,
        customer,
      });
      alert(t('sendSuccess'));
    } catch (error) {
      const res = await error;
      console.log('fail to create customer in queue', res);
      alert(res.message);
    }
  };

  addBlackList = ({ clientId, name, email, phone }) => {
    this.props.addBlackList({
      clientId,
      name,
      email,
      phone,
    });
  };

  removeBlackList = ({ clientId, phone, email }) => {
    this.props.removeBlackList({ clientId, phone, email });
  };

  renderTbody = ({ blackList = emptyCustomers, id: clientId }) => {
    console.log('this.props.management[id]', this.props.management, clientId);
    console.log('this.emptyCustomers', emptyCustomers);
    return (
      <tbody>
        {this.props.management[clientId] && this.props.management[clientId].customers
          ? this.props.management[clientId].customers.map((customer) => {
              const { name, phone, email, gender, serviceProviderName, receiveAt, sendAt, error = '' } = customer;
              let isBlackList = false;
              blackList.forEach((str) => {
                if (phone !== '' && str.includes(phone)) {
                  isBlackList = true;
                }
                if (email !== '' && str.includes(email)) {
                  isBlackList = true;
                }
              });

              const receiveAtStr = receiveAt ? `${new Date(receiveAt).toLocaleString()}` : '';
              const sendAtStr = sendAt ? `${new Date(sendAt).toLocaleString()}` : '';

              const { name: displayName, phone: displayPhone } = this.onCustomerInfoDisplay({ name, phone });
              return (
                <tr key={`${receiveAt}-${displayName}-${displayPhone}`}>
                  <td>{displayName}</td>
                  <td>{displayPhone}</td>
                  <td>{gender}</td>
                  <td>{serviceProviderName}</td>
                  <td>{receiveAtStr}</td>
                  <td>{sendAtStr}</td>
                  <td>{error}</td>
                  <td>
                    <Button
                      className="blacklist-button action-button"
                      color="danger"
                      disabled={isBlackList}
                      onClick={
                        isBlackList
                          ? () =>
                              this.removeBlackList({
                                phone,
                                email,
                              })
                          : () =>
                              this.addBlackList({
                                clientId,
                                name,
                                phone,
                                email,
                              })
                      }
                    >
                      {isBlackList ? t('alreadyInBlacklist') : t('addToBlacklist')}
                    </Button>

                    <Button
                      color="primary"
                      className="send-button action-button"
                      disabled={isBlackList}
                      onClick={() =>
                        this.createCustomer({
                          clientId,
                          customer,
                        })
                      }
                    >
                      {t('sendRating')}
                    </Button>
                  </td>
                </tr>
              );
            })
          : emptyCustomers}
      </tbody>
    );
  };

  renderBlackBody = ({ blackList = [], id: clientId }) => (
    <tbody>
      {blackList.map((blackstr) => {
        const [name, phone, email] = blackstr.split(':');
        const { name: displayName, phone: displayPhone } = this.onCustomerInfoDisplay({ name, phone });
        return (
          <tr key={blackstr}>
            <td>{displayName}</td>
            <td>{displayPhone}</td>
            <td>{email}</td>
            <td>
              <Button
                className="blacklist-button action-button"
                color="danger"
                onClick={() =>
                  this.removeBlackList({
                    clientId,
                    phone,
                    email,
                  })
                }
              >
                {t('removeFromBlacklist')}
              </Button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  renderPagination = ({ id: clientId }) => (
    <Pagination>
      <PaginationItem>
        <PaginationLink previous href="#" />
      </PaginationItem>
      {this.props.management[clientId] &&
        this.props.management[clientId].meta &&
        [...Array(this.props.management[clientId].meta.totalPage)].map((e, i) => {
          if (i + 1 === parseInt(this.props.management[clientId].meta.currentPage, 10)) {
            return (
              <PaginationItem active key={i}>
                <PaginationLink
                  href="#"
                  onClick={() =>
                    this.props.fetchCustomersInQue({
                      page: i + 1,
                      clientId,
                    })
                  }
                >
                  {`${i + 1}`}
                </PaginationLink>
              </PaginationItem>
            );
          }
          return (
            <PaginationItem key={i}>
              <PaginationLink
                href="#"
                onClick={() =>
                  this.props.fetchCustomersInQue({
                    page: i + 1,
                    clientId,
                  })
                }
              >
                {`${i + 1}`}
              </PaginationLink>
            </PaginationItem>
          );
        })}
      <PaginationItem>
        <PaginationLink next href="#" />
      </PaginationItem>
    </Pagination>
  );

  render() {
    const { byId, selectedIds } = this.props.clients;
    return (
      <div className="animated fadeIn">
        {selectedIds.map((id) => {
          const client = byId[id];
          return (
            <div key={id}>
              <Row>
                <Col xs="12" lg="12">
                  <Card>
                    {selectedIds.length > 1 && (
                      <CardHeader>
                        <div className="company-name">{`${client.companyName}`}</div>
                      </CardHeader>
                    )}
                    <CardHeader>
                      <i className="fa fa-align-justify" /> {t('registrationList')}
                    </CardHeader>
                    <CardBody>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t('name')}</th>
                            <th>{t('mobile')}</th>
                            <th>{t('gender')}</th>
                            <th>{t('seeingDoctor')}</th>
                            <th>{t('registrationTime')}</th>
                            <th>{t('sendTime')}</th>
                            <th>{t('error')}</th>
                            <th>{t('action')}</th>
                          </tr>
                        </thead>
                        {this.renderTbody(client)}
                      </Table>
                      {this.renderPagination(client)}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="12">
                  <Card>
                    <CardHeader>
                      <i className="fa fa-align-justify" /> {t('blacklist')}
                    </CardHeader>
                    <CardBody>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t('name')}</th>
                            <th>{t('mobile')}</th>
                            <th>Email</th>
                            <th>{t('action')}</th>
                          </tr>
                        </thead>
                        {this.renderBlackBody(client)}
                      </Table>
                      <Pagination>
                        <PaginationItem>
                          <PaginationLink previous href="#" />
                        </PaginationItem>
                        <PaginationItem active>
                          <PaginationLink href="#">1</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink next href="#" />
                        </PaginationItem>
                      </Pagination>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  }
}
