/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import connectData from 'components/HOCs/connectData';
import { doneRefreshPage } from 'reducers/ui';
import {
  TabTitles,
  headerColumnsMap,
  shouldRowRemovedMap,
  tableStyles,
} from 'components/Message/campaignTable/headerColumns';
import TabsSection from 'components/Message/campaignTable/TabsSection';
import FilterableTable from 'components/Table/FilterableTable';
import { TitleContainer, Title, Reload } from 'components/Table/styles';
import Tabs from 'components/Table/HorizontalTabs';
import Tab from 'components/Table/HorizontalTabs/HorizontalTab';
import TabPanel from 'components/Table/HorizontalTabs/TabPanel';
import CampaignDetailModal from 'components/Message/campaign/CampaignDetailModal';
import { fetchCampaignList } from 'lib/campaignService';
import { statusKeyMap } from 'components/Message/campaignTable/Columns/Status/StatusCell';
import { t } from 'i18n/config';

const mapStateToProps = (state) => ({
  clients: state.clients,
  user: state.auth.user,
});

const mapDispatchToProps = {
  doneRefreshPage,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Appointments extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    user: PropTypes.shape({
      operationType: PropTypes.string,
    }),
    appointments: PropTypes.shape({
      id: PropTypes.string,
    }),
    appendCustomTag: PropTypes.func,
    updateReminder: PropTypes.func,
    doneRefreshPage: PropTypes.func,
  };

  static emptyCustomers = [];

  constructor(props) {
    super(props);
    this.state = {
      clientTabIndex: 0,
      statusTabIndex: 0,
      campaignEditorVisible: false,
      selectedCampaign: undefined,
      loading: false,
      campaignList: [],
    };
  }

  componentDidMount() {
    this.onFetchData(this.state.statusTabIndex);
  }

  onFetchData = async () => {
    this.setState({ loading: true });
    try {
      const { selectedIds } = this.props.clients;
      const clientID = selectedIds[this.state.clientTabIndex];
      const campaignList = await fetchCampaignList({ clientID });
      this.setState({ campaignList });
    } catch (error) {
      console.log(error.message);
      alert('list failed');
    }
    this.setState({
      loading: false,
    });
  };

  handleChangeClientTab = (event, newtab) => {
    this.onFetchData(this.state.statusTabIndex);
    this.setState({
      clientTabIndex: newtab,
    });
  };

  handleChangeStatusTab = (event, newtab) => {
    this.onFetchData(newtab);
    this.setState({
      statusTabIndex: newtab,
    });
  };

  handleReload = () => {
    this.onFetchData(this.state.statusTabIndex);
  };

  handleClickCreateCampaign = (e) => {
    // TODO: create campaign
    this.setState({
      campaignEditorVisible: true,
      selectedCampaign: e,
    });
  };

  handleCloseCampaignEditor = () => {
    this.setState({
      campaignEditorVisible: false,
      selectedCampaign: undefined,
    });
  };

  handleUploadSuccess = () => {
    const list = this.state.campaignList.map((campaign) => {
      if (campaign.id === this.state.selectedCampaign.id) {
        return {
          ...campaign,
          status: statusKeyMap.PENDING,
        };
      }
      return campaign;
    });

    this.setState({
      campaignEditorVisible: false,
      selectedCampaign: undefined,
      campaignList: list,
    });
  };

  render() {
    const { byId, selectedIds } = this.props.clients;
    const { clientTabIndex, statusTabIndex } = this.state;
    const isMultiClietns = selectedIds.length > 1;
    return (
      <React.Fragment>
        {this.state.campaignEditorVisible && (
          <CampaignDetailModal
            visible={this.state.campaignEditorVisible}
            onClose={this.handleCloseCampaignEditor}
            campaign={this.state.selectedCampaign}
            client={byId[selectedIds[clientTabIndex]]}
            onUploadSuccess={this.handleUploadSuccess}
          />
        )}
        <div className="animated fadeIn appointment-page">
          <TitleContainer>
            <Title>{t('sendMessage')}</Title>
            <Reload className="ri-refresh-line" onClick={this.handleReload} />
          </TitleContainer>
          {isMultiClietns && (
            <Tabs value={clientTabIndex} onChange={this.handleChangeClientTab} aria-label="tabs">
              {selectedIds.map((id, index) => {
                const client = byId[id];
                return <Tab key={client.companyName} label={client.companyName} {...a11yProps(index)} />;
              })}
            </Tabs>
          )}
          {selectedIds.map((id, index) => (
            <TabPanel key={id} value={clientTabIndex} index={index}>
              <TabsSection
                statusTabIndex={statusTabIndex}
                handleChange={this.handleChangeStatusTab}
                clientIndex={index}
              >
                {/** <Confirm
                  startIcon={<i className='ri-add-line' />}
                  height={40}
                  width={110}
                  onClick={() => this.handleClickCreateCampaign()}
                >
                  建立活動
          </Confirm> */}
              </TabsSection>
              {TabTitles.map((status, statusIndex) => (
                <TabPanel key={status} value={statusTabIndex} index={statusIndex}>
                  <FilterableTable
                    key={`${id}`}
                    tableStyles={tableStyles}
                    status={status}
                    tableHeaderColumns={headerColumnsMap[statusIndex]}
                    clientID={id}
                    data={this.state.campaignList}
                    loading={this.state.loading}
                    onDateChange={this.onDateChange}
                    shouldRowsRemoved={shouldRowRemovedMap[status]}
                    onClickRow={this.handleClickCreateCampaign}
                  />
                </TabPanel>
              ))}
            </TabPanel>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
