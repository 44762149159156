import styled from 'styled-components';
import { CircularProgress, Button } from '@material-ui/core';

export const Loading = styled(CircularProgress)`
  margin: 16px auto;
`;

export const Header = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const Container = styled.div`
  height: 330px;
  width: 264px;
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const EmptyInfo = styled.div`
  margin: 16px auto;
  color: ${(props) => props.theme.colors.default};
`;

export const NotificationContainer = styled.div`
  padding: 8px;
  background: ${(props) => props.theme.background.default};
`;

export const UpdateDescription = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 12px;
  line-height: 1.58;
  letter-spacing: 0.3px;
`;

export const ExpectedSendHint = styled.div`
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.textPrimary};
`;
export const Content = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const PageRouteButton = styled(Button)`
  &:focus {
    border: 0px;
    outline: none;
  }
`;

export const StatusContainer = styled.div`
  padding: 4px;
  display: flex;
`;

export const Divided = styled.div`
  width: 1px;
  height: 51px;
  background: ${(props) => props.theme.colors.disabled};
`;
