import XLSX from 'xlsx';
import { set } from 'date-fns';
import { phoneFilter } from 'lib/vendors/teetchScalingParser/utils';

function parseTWDateToAD(twdateString) {
  const intres = parseInt(twdateString, 0);
  if (!Number.isNaN(intres)) {
    const y = parseInt(intres / 10000, 10) + 1911;
    const m = parseInt((intres % 10000) / 100, 10);
    const d = parseInt(intres % 100, 10);
    const res = new Date(`${y}/${m}/${d}`);
    return res;
  }
  return undefined;
}

export default function Vintech() {
  this.parse = (xls, rABS) => {
    const wb = XLSX.read(xls, { type: rABS ? 'binary' : 'array' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 'A', raw: false });
    const res = data
      .filter((d, i) => i > 5)
      .filter((d) => d.E != null && d.E.trimEnd() !== '')
      .map((d) => {
        let phone = `${d.E}`.trimEnd();
        if (phone[0] !== '0') {
          phone = `0${phone}`;
        }
        return {
          hisID: d.A.trimEnd(),
          name: d.B.trimEnd(),
          phone,
          doctor: d.F.trimEnd(),
          birth: parseTWDateToAD(d.C.trimEnd()),
          lastVisit: parseTWDateToAD(d.H.trimEnd()),
          lastTeethScaling:
            `${d.G}`.trimEnd().length > 0 ? parseTWDateToAD(`${d.G}`.trimEnd()) : parseTWDateToAD(`${d.H}`.trimEnd()),
        };
      })
      .filter(phoneFilter);

    return res;
  };

  this.bindCampaign = (list, campaign, options) => {
    const { delaySeconds } = campaign;
    return list.map((l) => {
      let estimatedDeliveryTime = new Date(l.lastTeethScaling.getTime() + delaySeconds * 1000);
      if (options && options.setDateValues) {
        estimatedDeliveryTime = set(estimatedDeliveryTime, options.setDateValues);
      }
      return {
        ...l,
        estimatedDeliveryTime,
      };
    });
  };
}
