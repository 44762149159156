import React, { useState } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const PopoverWrapper = styled.div`
  width: 190px;
  background-color: ${colors.SHADES_000};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    color: ${colors.SHADES_500};
    font-size: 16px;
    i {
      margin-right: 12px;
    }
    &:hover {
      color: ${colors.PRIMARY_500};
    }
  }
`;

const InfoBlockWrapper = styled.div`
  padding: 32px 24px;
  border: solid 1px ${colors.SHADES_300};
  border-radius: 8px;
  background-color: ${colors.SHADES_50};
  &:not(:first-child) {
    margin-top: 32px;
  }
  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`;

const Rows = styled.div``;

const Content = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 32px;
  }
  ${({ flexColumn }) =>
    flexColumn &&
    `
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    `}
`;

const Title = styled.div`
  width: 136px;
  margin-right: 24px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.SHADES_900};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${({ isEmpty }) => (isEmpty ? colors.SHADES_300 : colors.SHADES_900)};
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 440px;
`;

const FlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  padding: 24px 0;
  width: 100%;
  border: solid 1px ${colors.SHADES_300};
  border-radius: 8px;
  background-color: ${colors.SHADES_50};
  &:not(:first-child) {
    margin-top: 32px;
  }
`;

const FlowContent = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  &:not(:first-child) {
    margin-top: 24px;
  }
  > div {
    display: flex;
    margin-right: 24px;
  }
  button {
    white-space: nowrap;
  }
`;

const FlowTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
  i {
    font-size: 20px;
    color: ${colors.PRIMARY_300};
    line-height: 1;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  width: 40px;
  height: 40px;
  color: ${colors.SHADES_000};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  i {
    font-size: 24px;
  }
`;

const FlowInfo = styled.div`
  color: ${colors.SHADES_900};
  > div {
    margin-top: 4px;
    font-size: 12px;
  }
  > div:first-child {
    margin-top: 0;
    font-size: 13px;
    font-weight: 500;
  }
`;

const FlowButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 32px;
  margin-bottom: -18px;
  padding: 6px 56px 0 56px;
  border-top: solid 1px ${colors.SHADES_300};
`;

const FlowButton = styled.div`
  cursor: pointer;
  &:hover {
    i {
      color: ${colors.PRIMARY_500};
    }
  }
  i {
    font-size: 18px;
    color: ${colors.SHADES_400};
  }
`;

const Arrow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ hideArrow }) => (hideArrow ? '3px' : '2px')};
  height: 20px;
  background-color: ${colors.SHADES_400};
  transition: 0.3s;
  &:after {
    content: '';
    position: absolute;
    display: ${({ hideArrow }) => (hideArrow ? 'none' : 'block')};
    top: 100%;
    border-style: solid;
    border-width: 10.4px 6px 0 6px;
    border-color: ${colors.SHADES_400} transparent transparent transparent;
  }
`;

const AddButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: solid 1px ${colors.SHADES_400};
  border-radius: 100%;
  background-color: ${colors.SHADES_000};
  opacity: 0;
  transition: 0.2s;
  cursor: pointer;
  i {
    margin-left: 1px;
    font-size: 20px;
    color: ${colors.PRIMARY_300};
  }
`;

const ArrowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding-bottom: 10px;
  ${({ isHover, disabled }) =>
    !disabled &&
    isHover &&
    `
      ${Arrow} {
        height: 60px;
      }
      ${AddButton} {
        opacity: 1;
      }
    `};
  ${({ disabled }) =>
    disabled &&
    `
      ${AddButton} {
        cursor: unset;
        }
    `}
`;

export const FlowIcon = ({ type }) => {
  const setting = {
    time: { backgroundColor: colors.SYSTEM_INFO_300, icon: 'ri-time-fill' },
    group: { backgroundColor: colors.SECONDARY_500, icon: 'ri-alarm-warning-fill' },
    message: { backgroundColor: colors.SYSTEM_ERROR_300, icon: 'ri-send-plane-fill' },
  };
  return (
    <Icon backgroundColor={setting[type].backgroundColor}>
      <i className={setting[type].icon} />
    </Icon>
  );
};

const FlowBlock = ({ onAddButtonClick, eventInfo, children, disabled }) => {
  const [isHover, setIsHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);
  const handlePopoverClose = () => {
    setIsHover(false);
    setAnchorEl(null);
  };
  const handleAdd = () => {
    onAddButtonClick();
    handlePopoverClose();
  };
  return (
    <FlowContainer>
      {children}
      <ArrowContainer
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => !isPopoverOpen && setIsHover(false)}
        isHover={isHover}
        disabled={disabled}
      >
        <Arrow hideArrow={eventInfo} />
        <AddButton onClick={(e) => !disabled && setAnchorEl(e.currentTarget)}>
          <i className="ri-add-line" />
        </AddButton>
      </ArrowContainer>
      {eventInfo && (
        <FlowTimeInfo>
          <i className="ri-flag-fill" />
          <Text font="Heading/Small/Medium" color="SHADES_400">
            {eventInfo.range}
          </Text>
          <Text font="Heading/Small/Medium" color="SHADES_400">
            {t('sendProcessToEndDate', { recyclable: eventInfo.recyclable ? t('cycle') : '' })}
          </Text>
        </FlowTimeInfo>
      )}
      {isPopoverOpen && (
        <Popover
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          style={{ marginLeft: '8px' }}
        >
          <PopoverWrapper>
            <div onClick={handleAdd}>
              <i className="ri-mail-settings-line" />
              {t('timeAndMessage')}
            </div>
          </PopoverWrapper>
        </Popover>
      )}
    </FlowContainer>
  );
};

const InfoBlock = ({
  contents = { title: '', value: '' },
  onEditButtonClick,
  flexColumn,
  additionalElement,
  disabled,
  viewMode,
  hiddenButton,
}) => (
  <InfoBlockWrapper>
    <div>
      <Rows>
        {contents.map((content, index) => (
          <Content flexColumn={flexColumn} key={index}>
            <Title>{content.title}</Title>
            <Description isEmpty={!content.value}>{content.value || content.placeholder}</Description>
          </Content>
        ))}
      </Rows>
      <Button
        variant="outline"
        color="secondary"
        onClick={onEditButtonClick}
        disabled={disabled}
        viewMode={viewMode}
        hidden={hiddenButton}
      >
        {viewMode ? t('view') : t('edit')}
      </Button>
    </div>
    {additionalElement}
  </InfoBlockWrapper>
);

const GroupBlock = ({ group, exclusiveGroup, onAddButtonClick, onEditButtonClick }) => (
  <FlowBlock onAddButtonClick={onAddButtonClick}>
    <FlowWrapper>
      <FlowContent>
        <div>
          <FlowIcon type="group" />
          <FlowInfo>
            <div>{t('targetAudience')}</div>
            <div>
              {t('audience')}
              {group}
            </div>
            {exclusiveGroup && (
              <div>
                {t('exclude')} {exclusiveGroup}
              </div>
            )}
          </FlowInfo>
        </div>
        <Button variant="outline" color="secondary" fontSize={14} onClick={onEditButtonClick}>
          {t('edit')}
        </Button>
      </FlowContent>
    </FlowWrapper>
  </FlowBlock>
);

const ActionBlock = ({
  time,
  alias,
  eventInfo,
  onAddButtonClick,
  onEditTimeClick,
  onEditMessageClick,
  onCloneButtonClick,
  onDeleteButtonClick,
  hideDelete,
  disabled,
}) => (
  <FlowBlock eventInfo={eventInfo} onAddButtonClick={onAddButtonClick} disabled={disabled}>
    <FlowWrapper>
      <FlowContent>
        <div>
          <FlowIcon type="time" />
          <FlowInfo>
            <div>{t('waitTime')}</div>
            <div>{time}</div>
          </FlowInfo>
        </div>
        <Button variant="outline" color="secondary" fontSize={14} onClick={onEditTimeClick} disabled={disabled}>
          {t('edit')}
        </Button>
      </FlowContent>
      <FlowContent>
        <div>
          <FlowIcon type="message" />
          <FlowInfo>
            <div>{t('sendMessage')}</div>
            <div>{alias}</div>
          </FlowInfo>
        </div>
        <Button variant="outline" color="secondary" fontSize={14} onClick={onEditMessageClick}>
          {disabled ? t('view') : t('edit')}
        </Button>
      </FlowContent>
      {!disabled && (
        <FlowButtons>
          {!hideDelete && (
            <FlowButton onClick={onDeleteButtonClick}>
              <i className="ri-delete-bin-line" />
            </FlowButton>
          )}
          <FlowButton onClick={onCloneButtonClick}>
            <i className="ri-file-copy-line" />
          </FlowButton>
        </FlowButtons>
      )}
    </FlowWrapper>
  </FlowBlock>
);

export { InfoBlock, GroupBlock, ActionBlock };
