import { getToken, APPOINT_URL, MARKETING_URL, ENV_STAGE } from 'helpers';
import { responseHandler } from './responseHandler';
import { messageHistorySerializer } from './appointmentSerializer';

export const createAppoint = ({ messages }) =>
  fetch(APPOINT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'AP-stage': ENV_STAGE,
    },
    body: JSON.stringify({
      messages,
    }),
  }).then(responseHandler);

export const fetchAppointmentsApi = ({ clientId, startTime, endTime }) =>
  fetch(`${MARKETING_URL}/v1/appointments/clients/${clientId}?startTime=${startTime}&endTime=${endTime}`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  }).then(responseHandler);

export const appendCustomTagApi = ({ clientId, phone, tagsToAppend }) => {
  const url = `${MARKETING_URL}/v1/tag-management/clients/${clientId}/phone/${phone}/tags`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tags: tagsToAppend.map((t) => ({ name: t.name, type: 'custom' })),
    }),
  }).then(responseHandler);
};

export const updateReminderApi = ({ id, reminder }) => {
  console.log('updatereminder, id, reminder', reminder);
  const url = `${MARKETING_URL}/v1/appointments/${id}`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
      reminder,
    }),
  }).then(responseHandler);
};

export const updateDraftApi = ({ clientID, id, draft }) => {
  console.log('update draft = ', clientID, id, draft);
  const url = `${MARKETING_URL}/v2/clients/${clientID}/appointments/${id}/draft`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      draft,
    }),
  }).then(responseHandler);
};

export const sendDraftMessageApi = ({ clientID, ids }) => {
  console.log('send message api, ids = ', ids);
  const url = `${MARKETING_URL}/v2/clients/${clientID}/batch-messages`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ids),
  }).then(responseHandler);
};

export const fetchMessageHistory = ({ clientID, id }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientID}/appointments/${id}/message-history`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .then(messageHistorySerializer);

export const updateProgressStateApi = ({ id, progress }) => {
  console.log('update progress, id, reminder', progress);
  const url = `${MARKETING_URL}/v1/appointments/${id}`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
      processState: {
        status: progress,
      },
    }),
  }).then(responseHandler);
};
