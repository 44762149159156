import {
  findName,
  validatePhoneNumber,
  translateMr,
  isValidCustomerInput,
  findPhone,
  findEmail,
  findNote,
  findServiceProvider,
} from 'helpers/customerHelpers';
import { VENDORS, LOCALES, CSV_ATTRIBUTE } from 'helpers/constants';
import CsvAttribute from 'models/csvAttribute';
import { validateGlobalPhoneNumber } from '../helpers';

class CsvDataSet {
  constructor(rawData, vendor) {
    this.rawData = rawData;
    this.vendor = vendor;
    const allTextLines = rawData
      .replace(/"(.*[^"\s])\n"/g, '$1')
      .replace(/"/g, '')
      .split(/\r\n|\n/);
    const titleIndex = allTextLines.findIndex(findName);
    this.firstLine = allTextLines[titleIndex];
    this.dataSet = allTextLines.filter((info, idx) => idx > titleIndex).map((data) => data.split(';')[0].split(','));
  }

  dataSetToFilteredJson = (dataSet, indexMap) => {
    const emailList = {};
    const phoneList = {};
    const filteredJson = [];
    const { nameObj, phoneObj, emailObj, noteObj, serviceProviderObj } = indexMap;
    const nameIdx = nameObj.index;
    const emailIdx = emailObj.index;
    const phoneIdx = phoneObj.index;
    const noteIdx = noteObj.index;
    const serviceProviderIdx = serviceProviderObj.index;
    for (let i = 0; i < dataSet.length; i++) {
      const infoArray = dataSet[i];
      const name = infoArray[nameIdx];
      const email = infoArray[emailIdx] || '';
      const phone = infoArray[phoneIdx] || '';
      const note = infoArray[noteIdx];
      const serviceProviderName = infoArray[serviceProviderIdx];
      if (
        !isValidCustomerInput({
          name,
          email,
          phone,
        })
      ) {
        continue;
      }
      if (email.length && emailList[email]) {
        continue;
      }
      if (phone.length && phoneList[phone]) {
        continue;
      }
      emailList[email] = true;
      phoneList[phone] = true; // use phone instead of validatePhoneNumber
      const json = {
        name,
        email,
        phone: validateGlobalPhoneNumber(phone),
        note: note || undefined,
        serviceProviderName: serviceProviderName || undefined,
      };
      filteredJson.push(json);
    }
    return filteredJson;
  };

  getIndexes = (line) => {
    if (line == null) {
      throw new Error('wrong format');
    }
    const map = {
      nameObj: {},
      phoneObj: {},
      emailObj: {},
      noteObj: {},
      serviceProviderObj: {},
    };
    line.split(',').forEach((element, idx) => {
      if (findName(element)) {
        const csvAttributeModel = new CsvAttribute(CSV_ATTRIBUTE.NAME);
        map.nameObj = csvAttributeModel.getObject(map.nameObj, element, idx);
      }

      if (findPhone(element)) {
        const csvAttributeModel = new CsvAttribute(CSV_ATTRIBUTE.PHONE);
        map.phoneObj = csvAttributeModel.getObject(map.phoneObj, element, idx);
      }

      if (findEmail(element)) {
        const csvAttributeModel = new CsvAttribute(CSV_ATTRIBUTE.EMAIL);
        map.emailObj = csvAttributeModel.getObject(map.emailObj, element, idx);
      }

      if (findNote(element)) {
        const csvAttributeModel = new CsvAttribute(CSV_ATTRIBUTE.NOTE);
        map.noteObj = csvAttributeModel.getObject(map.noteObj, element, idx);
      }
      if (findServiceProvider(element)) {
        const csvAttributeModel = new CsvAttribute(CSV_ATTRIBUTE.SERVICE_PROVIDER_NAME);
        map.serviceProviderObj = csvAttributeModel.getObject(map.serviceProviderObj, element, idx);
      }
    });
    return map;
  };

  convertToJson() {
    if (this.vendor === VENDORS.OPERA) {
      const emailList = {};
      const phoneList = {};
      return this.dataSet
        .filter((column) => column.length > 3)
        .map((column) => {
          const locale = column[12];
          const mr = column[2];
          const lastName = column[8];
          let name;
          let country;
          const email = column[10];
          const rawPhone = column[20];
          const phone = validatePhoneNumber(rawPhone);
          switch (locale) {
            case 'E':
              name = `${mr} ${lastName}`;
              country = LOCALES.EN;
              break;
            case 'J':
              name = `${mr} ${lastName}`;
              country = LOCALES.JA;
              break;
            case 'ZH-T':
              name = `${column[4]} ${translateMr(mr)}`;
              country = LOCALES.ZH_TW;
              break;
            default:
              name = `${column[4]} ${translateMr(mr)}`;
              country = LOCALES.ZH_TW;
              break;
          }
          // check invalid or duplicate customer
          if (
            !isValidCustomerInput({
              name,
              email,
              phone,
            }) ||
            emailList[email] ||
            phoneList[rawPhone]
          ) {
            return null;
          }
          emailList[email] = true;
          phoneList[rawPhone] = true;
          return {
            name,
            email,
            phone,
            country,
          };
        })
        .filter((customer) => customer !== null);
    }
    return this.dataSetToFilteredJson(this.dataSet, this.getIndexes(this.firstLine));
  }
}

export default CsvDataSet;
