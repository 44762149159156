import React, { useRef, useState, memo } from 'react';
import styled from 'styled-components';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import { dashboard_v2 } from 'theme';
import { EDITOR_VARIABLES, getDisplayContent } from '../../utils';
import { Loading } from 'components/MaterialDesign/Loading';
import { ROLE } from 'lib/openaiService';
import Tooltip from 'components/Dashboard_v2/Tooltip';
import HintInfo from 'components/Dashboard_v2/HintInfo';
import HintImage from 'components/Dashboard_v2/HintInfo/openaiMessageHint.png';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const MessageEditorWrapper = styled.div`
  margin-bottom: 28px;
`;

const ActionButtons = styled.div`
  display: flex;
  ${({ center }) =>
    center &&
    `
    align-items: center;
    justify-content: center;
  `}
  > button {
    margin-right: 16px;
  }
`;

const CountWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TextInputWrapper = styled.div`
  position: relative;
  min-height: 140px;
  padding: 8px 16px;
  resize: vertical;
  overflow: auto;
  white-space: pre-line;
  border: 1px solid ${({ disabled }) => (disabled ? colors.SHADES_200 : colors.SHADES_400)};
  border-radius: 8px;
  textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    color: transparent;
    border: none;
    background-color: transparent;
    caret-color: ${colors.SHADES_900};
    overflow: hidden;
    resize: none;
    ${({ disabled }) => disabled && `color: ${colors.SHADES_400};background-color:${colors.SHADES_100}`}
  }
`;

const TextMessageInput = ({ value, onInputChange, handleOpenAI, disabled }) => {
  const [openaiLoading, setOpenaiLoading] = useState(false);
  const TEXT_LIMIT = 400;
  const ref = useRef();
  const addVariable = (variable) => {
    if (ref.current) {
      const { selectionStart, selectionEnd } = ref.current;
      let position;
      if (selectionStart !== selectionEnd) {
        position = selectionStart + variable.length;
      } else {
        position = selectionEnd + variable.length;
      }
      ref.current.setRangeText(variable);
      onInputChange(ref.current.value);
      ref.current.focus();
      ref.current.setSelectionRange(position, position);
    }
  };

  const handleClickOpenAI = async () => {
    setOpenaiLoading(true);
    await handleOpenAI({
      role: ROLE.CLINIC_MESSAGE_BOT,
      content: value,
      args: [20],
      callback: onInputChange,
    });
    setOpenaiLoading(false);
  };

  const openAIEnabled = handleOpenAI != null;

  return (
    <MessageEditorWrapper>
      <TextInputWrapper disabled={disabled}>
        <div dangerouslySetInnerHTML={{ __html: getDisplayContent(value, 'editor') }} />
        <textarea
          ref={ref}
          value={value}
          onChange={(e) => onInputChange(e.target.value)}
          maxLength={TEXT_LIMIT}
          disabled={disabled}
        />
      </TextInputWrapper>
      <CountWrapper>
        <Text font="Body/14px_Medium" color="SHADES_500">{`${value.length}/${TEXT_LIMIT}`}</Text>
      </CountWrapper>
      <ActionButtons>
        <Button
          variant="outline"
          color="secondary"
          size="small"
          fontSize={14}
          onClick={handleClickOpenAI}
          disabled={disabled || !openAIEnabled || openaiLoading}
        >
          {openaiLoading ? (
            <Loading size={18} customcolor={dashboard_v2.colors.SHADES_400}></Loading>
          ) : (
            <Tooltip
              popoverContent={
                <HintInfo
                  image={HintImage}
                  title={t('autoGenerateMessage')}
                  description={t('autoGenerateMessageDescription')}
                ></HintInfo>
              }
              type={'BOTTOM_CENTER'}
              disabled={!openAIEnabled || openaiLoading}
              popoverStyle={{ margin: 12 }}
            >
              <i className="ri-sparkling-2-line"></i>
            </Tooltip>
          )}
        </Button>

        <Button
          variant="outline"
          color="secondary"
          size="small"
          fontSize={14}
          onClick={() => addVariable(EDITOR_VARIABLES.name)}
          disabled={disabled}
        >
          {t('name')}
        </Button>
        {/* @TODO: hide for later phase */}
        {/* <Button
          variant="outline"
          color="secondary"
          size="small"
          fontSize={14}
          onClick={() => addVariable(EDITOR_VARIABLES.clinicPhone)}
          disabled={disabled}
        >
          診所電話
        </Button> */}
      </ActionButtons>
    </MessageEditorWrapper>
  );
};

export default memo(TextMessageInput);
