import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/zh-tw';
import { dashboard_v2 } from 'theme';
import ActionCell from './ActionCell';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${colors.SHADES_500};
  background-color: ${colors.SHADES_100};
  border-radius: 4px;
`;

const StatusDot = styled.span`
  display: ${({ color }) => (color ? 'inline-block' : 'none')};
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

const StatusLabel = ({ status }) => {
  const settingMap = {
    draft: { text: t('draft') },
    pending: { text: t('scheduling') },
    done: { text: t('sent') },
  };
  const setting = settingMap[status] || { text: status };
  return (
    <StatusWrapper>
      <StatusDot color={setting.dotColor} />
      {setting.text}
    </StatusWrapper>
  );
};

export const columns = [
  {
    Header: t('titleName'),
    accessor: 'title',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('target'),
    accessor: 'groupName',
    minWidth: 120,
    maxWidth: 120,
    Cell: ({ row }) => {
      const { original } = row;
      const { groupMessageFilters } = original;
      const { groups, excludedGroups } = groupMessageFilters;
      const groupNames = groups && groups.map((item) => item.name).join(', ');
      const excludedGroupNames = excludedGroups && excludedGroups.map((item) => item.name).join(', ');
      return groupNames || excludedGroupNames
        ? `${groupNames}${excludedGroupNames ? `/${t('exclude')}: ${excludedGroupNames}` : ''}`
        : '';
    },
  },
  {
    Header: t('recipientCount'),
    accessor: 'usersCount',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('status'),
    accessor: 'status',
    Cell: ({ value: status }) => <StatusLabel status={status} />,
  },
  {
    Header: t('editTime'),
    accessor: 'updatedAt',
    minWidth: 120,
    maxWidth: 120,
    Cell: ({ value: updatedAt }) => moment(updatedAt).locale('zh-tw').format('YYYY/MM/DD (dd) ah:mm'),
  },
  {
    Header: '',
    accessor: 'action',
    Cell: ActionCell,
    disableSortBy: true,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
