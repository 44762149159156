import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

const propTypes = {
  clickable: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

const TitleDropdown = ({ clickable, values, defaultValue, onChange }) => (
  <Dropdown defaultOption={defaultValue} options={values} onChange={onChange} clickable={clickable} />
);

TitleDropdown.propTypes = propTypes;

export default TitleDropdown;
