import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { cancelNotification } from 'lib/westernAppointmentService';
import { CircularProgress } from '@material-ui/core';
import { NotificationContainer, ExpectedSendHint, Content, CancelButton } from './styledComponents';
import { t } from 'i18n/config';

function UnSendNotification({
  id,
  taskID,
  clientID,
  appointmentID,
  expectedDeliveryAt,
  contentformat,
  onCancelSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const handleCancel = useCallback(
    () => async () => {
      try {
        setLoading(true);
        await cancelNotification({ id, clientID, appointmentID, taskID });
        onCancelSuccess({ taskID });
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <NotificationContainer>
      <ExpectedSendHint>
        {t('willSendAt')} {format(new Date(expectedDeliveryAt), 'yyyy/MM/dd HH:mm')} {t('send')}
        <Content>{contentformat}</Content>
      </ExpectedSendHint>
      <CancelButton disabled={loading} onClick={handleCancel(taskID)}>
        {loading && <CircularProgress size={23} />}
        {!loading && t('cancelSchedule')}
      </CancelButton>
    </NotificationContainer>
  );
}

UnSendNotification.propTypes = {
  taskID: PropTypes.string,
  clientID: PropTypes.string,
  appointmentID: PropTypes.number,
  expectedDeliveryAt: PropTypes.string,
  contentformat: PropTypes.string,
  onCancelSuccess: PropTypes.func,
};

export default UnSendNotification;
