import React, { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import { isValidURL, getDefaultReplyButtonData, getDefaultReplyContentData } from '../../utils';
import Switch from 'components/Dashboard_v2/Switch';
import Text from 'components/Dashboard_v2/Text';
import Input from 'components/Dashboard_v2/Input';
import Italic from 'components/Dashboard_v2/Italic';
import Select from 'components/Dashboard_v2/Select';
import FieldBase from 'components/Dashboard_v2/FieldBase';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import FormControl from 'components/Dashboard_v2/FormControl';
import Radio from 'components/Dashboard_v2/Radio';
import Checkbox from 'components/Dashboard_v2/Checkbox';
import { Loading } from 'components/MaterialDesign/Loading';
import titleInfoImg from 'img/MessageCenter/flexEditorInfo.png';
import notifyInfoImg from 'img/MessageCenter/notifyInfo.png';
import Tooltip from 'components/Dashboard_v2/Tooltip';
import HintInfo from 'components/Dashboard_v2/HintInfo';
import HintImage from 'components/Dashboard_v2/HintInfo/openaiMessageHint.png';
import TextMessageInput from './TextMessageInput';
import ImageEditor from './ImageEditor';
import { ROLE } from 'lib/openaiService';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const ModalSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  > div {
    margin-right: 32px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 16px;
  ${({ center }) => center && 'align-items: center;'};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`};
`;

const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 8px;
  ${({ disabled }) => disabled && `pointer-events: none;`};
`;

const CustomColorButton = styled.div`
  padding: 2px;
  border-radius: 12px;
  ${({ selected }) => selected && `border: 2px solid ${colors.PRIMARY_300}; `};
  > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    width: 46px;
    height: 20px;
    input {
      position: absolute;
      appearance: none;
      background: transparent;
      border: red;
      width: 100px;
      height: 100px;
      cursor: pointer;
    }
  }
`;

const ColorButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  ${({ selected }) => selected && `border: 2px solid ${colors.PRIMARY_300}; `};
  border-radius: 100%;
  cursor: pointer;
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 100%;
    background-color: ${({ color }) => color};
  }
`;

const Block = styled.div`
  padding: 24px;
  margin-top: 24px;
  border: 1px solid ${colors.SHADES_300};
  box-shadow: ${({ showShadow = true }) =>
    showShadow && `0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)`};
  border-radius: 16px;
`;

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  i {
    font-size: 20px;
    margin-left: 24px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${colors.SHADES_400};
    cursor: pointer;
    i {
      margin-left: 1px;
      font-size: 18px;
      color: ${colors.PRIMARY_300};
      line-height: 1;
    }
  }
`;

const ReplyButtonHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    font-size: 20px;
    margin-left: 32px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
`;

const ButtonLayoutSampleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: ${({ type }) => (type === 'vertical' ? 'column' : 'row')};
  padding: 12px 16px;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 8px;
  margin-top: 12px;
  margin-right: 32px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 76px;
    color: ${colors.SHADES_000};
    background-color: ${colors.SHADES_400};
    border-radius: 8px;
  }
  > div:last-child {
    color: ${colors.SHADES_400};
    background-color: ${colors.SHADES_000};
    border: 1px solid ${colors.SHADES_400};
  }
`;

const ButtonLayoutSample = ({ type }) => (
  <ButtonLayoutSampleWrapper type={type}>
    <div>{t('button1')}</div>
    <div>{t('button2')}</div>
  </ButtonLayoutSampleWrapper>
);

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.SHADES_300};
  margin: 24px 0;
`;

const BUTTON_VARIANT_OPTIONS = [
  { id: 1, name: t('fill'), value: 'filled' },
  { id: 2, name: t('border'), value: 'outlined' },
  { id: 3, name: t('none'), value: 'text' },
];

const REPLY_TYPE_OPTIONS = [
  { id: 1, name: t('sameAsButtonText'), value: null },
  { id: 2, name: t('image'), value: 'image' },
  { id: 3, name: t('link'), value: 'link' },
  { id: 4, name: t('keyword'), value: 'keyword' },
  { id: 5, name: t('customReplyText'), value: 'text' },
];

const DEFAULT_BUTTON_COLORS = [
  colors.SECONDARY_900,
  colors.SYSTEM_SUCCESS_600,
  colors.PRIMARY_500,
  colors.SYSTEM_ERROR_500,
  colors.SECONDARY_600,
];

const FlexEditor = ({
  data,
  handleChange,
  handleMessageMove,
  handleMessageDelete,
  handleOpenAI,
  allowMoveUp,
  allowMoveDown,
  disabled,
}) => {
  const {
    layout,
    alertText,
    textTitle,
    textContent,
    showReplyButtons,
    replyButtons: originReplyButtons,
    replyContents: originReplyContents,
  } = data;

  const [replyContents, setReplyContents] = useState(
    originReplyContents
      ? JSON.parse(JSON.stringify(originReplyContents))
      : originReplyButtons?.map(() => getDefaultReplyContentData())
  );

  const [replyButtons, setReplyButtons] = useState(originReplyButtons);

  const errors = useMemo(
    () =>
      replyContents?.map(({ replyType, replyContent }) => {
        if (replyType === 'link' && !isValidURL(replyContent)) {
          return t('enterLink');
        }
      }),
    [replyContents]
  );

  const handleReplyContentChange = (index, key, value) => {
    const newReplyContents = [...replyContents];
    newReplyContents[index] = { ...newReplyContents[index], [key]: value };
    if (key === 'replyType') {
      newReplyContents[index].replyContent = '';
      newReplyContents[index].loading = false;
    }
    setReplyContents(newReplyContents);
    handleChange('replyContents', newReplyContents);
  };

  const handleReplyButtonChange = (index, key, value) => {
    const newReplyButtons = [...replyButtons];
    newReplyButtons[index][key] = value;
    setReplyButtons(newReplyButtons);
    handleChange('replyButtons', newReplyButtons);
  };

  const handleReplyButtonAdd = () => {
    if (replyButtons?.length >= 2) return;

    const newReplyContents = [...replyContents, getDefaultReplyContentData()];
    setReplyContents(newReplyContents);
    handleChange('replyContents', newReplyContents);

    const newReplyButtons = [...replyButtons, getDefaultReplyButtonData()];
    setReplyButtons(newReplyButtons);
    handleChange('replyButtons', newReplyButtons);
  };

  const handleRemoveReplyButton = (index) => {
    const newReplyContents = [...replyContents];
    newReplyContents.splice(index, 1);
    setReplyContents(newReplyContents);
    handleChange('replyContents', newReplyContents);

    const newReplyButtons = [...replyButtons];
    newReplyButtons.splice(index, 1);
    setReplyButtons(newReplyButtons);
    handleChange('replyButtons', newReplyButtons);
  };

  const handleOpenAIInReply = async (index, contentType) => {
    handleReplyContentChange(index, 'loading', true);
    await handleOpenAI({
      role: ROLE.CLINIC_REPLY_BOT,
      content: replyButtons[index].buttonText,
      args: [20, textContent, replyContents[index].replyContent],
      callback: (msg) => {
        handleReplyContentChange(index, contentType, msg);
      },
    });
  };

  const openAIEnabled = handleOpenAI != null;

  return (
    <Block>
      <BlockTitle>
        <Text font="Heading/Large/Medium">{t('interactiveMessage')}</Text>
        {!disabled && (
          <div>
            {allowMoveUp && <i className="ri-arrow-up-s-line" onClick={() => handleMessageMove('up')} />}
            {allowMoveDown && <i className="ri-arrow-down-s-line" onClick={() => handleMessageMove('down')} />}
            <i className="ri-delete-bin-line" onClick={handleMessageDelete} />
          </div>
        )}
      </BlockTitle>
      <Input
        label={
          <FlexDiv center>
            {t('incomingNotificationText')}
            <InfoPopover
              popoverContent={
                <>
                  <div>
                    <Text font="Heading/Medium/Heavy" color={colors.SHADES_000}>
                      {t('incomingNotificationText')}
                    </Text>
                  </div>
                  <div>
                    <Text font="Body/16px_Regular" color={colors.SHADES_000} marginTop={4} marginBottom={8}>
                      {t('incomingNotificationTextDescription')}
                    </Text>
                  </div>
                  <img src={titleInfoImg} />
                </>
              }
              type="TOP_RIGHT"
              style={{ marginLeft: -12 }}
            />
          </FlexDiv>
        }
        value={alertText}
        onInputChange={(e) => handleChange('alertText', e.target.value)}
        marginBottom={24}
        disabled={disabled}
        fullWidth
      />
      <Divider />
      <Input
        label={t('messageTitle')}
        value={textTitle}
        onInputChange={(e) => handleChange('textTitle', e.target.value)}
        marginBottom={24}
        disabled={disabled}
        fullWidth
      />
      <TextMessageInput
        handleOpenAI={handleOpenAI}
        value={textContent}
        onInputChange={(value) => handleChange('textContent', value)}
        maxLength={400}
        disabled={disabled}
        fullWidth
      />
      <ModalSwitchWrapper>
        <div>
          <Text font="Heading/Medium/Medium" marginBottom={12}>
            {t('customizeReplyButton')}
          </Text>
          <Text font="Body/14px_Regular" color="SHADES_500">
            {t('customizeReplyButtonDescription')}
          </Text>
        </div>
        <Switch
          checked={showReplyButtons}
          onChange={(e) => handleChange('showReplyButtons', e.target.checked)}
          disabled={disabled}
        />
      </ModalSwitchWrapper>
      {showReplyButtons && (
        <>
          <FormControl>
            <Radio
              label={t('buttonLayout')}
              value={layout}
              onChange={(value) => handleChange('layout', value)}
              items={[
                {
                  label: t('vertical'),
                  content: <ButtonLayoutSample type="vertical" />,
                  value: 'vertical',
                },
                {
                  label: t('horizontal'),
                  content: <ButtonLayoutSample type="horizontal" />,
                  value: 'horizontal',
                },
              ]}
              disabled={disabled}
            />
          </FormControl>
          {replyButtons?.map((replyButton, index) => (
            <Block showShadow={false} key={index}>
              <ReplyButtonHeader>
                <Text font="Heading/Medium/Medium" marginBottom={12}>
                  {t('button')}
                  {index + 1}
                </Text>
                {!disabled && replyButtons.length > 1 && (
                  <i className="ri-delete-bin-line" onClick={() => handleRemoveReplyButton(index)} />
                )}
              </ReplyButtonHeader>
              <FlexDiv marginBottom={24}>
                <Select
                  label={t('buttonStyle')}
                  options={BUTTON_VARIANT_OPTIONS}
                  selectedOption={BUTTON_VARIANT_OPTIONS.find((option) => option.value === replyButton.variant)}
                  setSelectedOption={(option) => handleReplyButtonChange(index, 'variant', option.value)}
                  disabled={disabled}
                  fullWidth
                />
                <FieldBase label={t('color')}>
                  <ColorWrapper disabled={disabled}>
                    {DEFAULT_BUTTON_COLORS.map((color) => (
                      <ColorButton
                        color={color}
                        onClick={() => handleReplyButtonChange(index, 'color', color)}
                        selected={color === replyButton.color}
                        key={index + color}
                      />
                    ))}
                    <CustomColorButton selected={!DEFAULT_BUTTON_COLORS.includes(replyButton.color)}>
                      <div>
                        <input
                          value={replyButton.color}
                          onChange={(e) => handleReplyButtonChange(index, 'color', e.target.value)}
                          type="color"
                        />
                      </div>
                    </CustomColorButton>
                  </ColorWrapper>
                </FieldBase>
              </FlexDiv>
              <Input
                label={t('buttonContent')}
                value={replyButton.buttonText}
                onInputChange={(e) => handleReplyButtonChange(index, 'buttonText', e.target.value)}
                marginBottom={24}
                maxLength={40}
                disabled={disabled}
                fullWidth
              />
              <Divider />
              <Select
                label={t('replyTypeAfterClick')}
                options={REPLY_TYPE_OPTIONS}
                selectedOption={REPLY_TYPE_OPTIONS.find((option) => option.value === replyContents[index].replyType)}
                setSelectedOption={(option) => handleReplyContentChange(index, 'replyType', option.value)}
                marginBottom={replyContents[index].replyType ? 24 : 0}
                disabled={disabled}
                fullWidth
              />
              {replyContents[index].replyType && (
                <>
                  {replyContents[index].replyType === 'image' ? (
                    <ImageEditor
                      data={{ previewImageUrl: replyContents[index].replyContent }}
                      handleChange={(url) => handleReplyContentChange(index, 'replyContent', url)}
                      showTitle={false}
                      handleOpenAI={handleOpenAI}
                      type="dashed"
                    />
                  ) : (
                    <Input
                      label={`${
                        REPLY_TYPE_OPTIONS.find((option) => option.value === replyContents[index].replyType).name
                      }${replyContents[index].replyType === 'keyword' ? t('advancedSettings') : ''}`}
                      value={replyContents[index].replyContent}
                      onInputChange={(e) => handleReplyContentChange(index, 'replyContent', e.target.value)}
                      maxLength={300}
                      suffixDisabled={!openAIEnabled}
                      suffix={
                        replyContents[index].replyType == 'text' &&
                        (replyContents[index].loading ? (
                          <Loading customcolor={dashboard_v2.colors.SHADES_400} size={14} />
                        ) : (
                          <Tooltip
                            popoverContent={
                              <HintInfo
                                image={HintImage}
                                title={t('autoGenerateMessage')}
                                description={t('autoGenerateMessageDescription')}
                              ></HintInfo>
                            }
                            type={'BOTTOM_CENTER'}
                            popoverStyle={{ margin: 18 }}
                          >
                            <Italic
                              className="ri-sparkling-2-line"
                              onClick={() => {
                                handleOpenAIInReply(index, 'replyContent');
                              }}
                              disabled={!openAIEnabled}
                            ></Italic>
                          </Tooltip>
                        ))
                      }
                      disabled={disabled}
                      error={errors[index]}
                      helperText={errors[index] || ''}
                      fullWidth
                    />
                  )}
                </>
              )}

              {![null, 'link'].includes(replyContents[index].replyType) && (
                <>
                  <Divider />
                  <Checkbox
                    onChange={(isChecked) => handleReplyButtonChange(index, 'showReplyNotify', isChecked)}
                    checked={replyButton.showReplyNotify}
                    disabled={disabled}
                  >
                    <FlexDiv center>
                      <Text font="Body/16px_Regular">{t('activelyNotifyInChatList')}</Text>
                      <InfoPopover
                        popoverContent={
                          <>
                            <div>
                              <Text font="Heading/Medium/Heavy" color={colors.SHADES_000}>
                                {t('activelyNotifyInChatList')}
                              </Text>
                            </div>
                            <div>
                              <Text font="Body/16px_Regular" color={colors.SHADES_000} marginTop={4} marginBottom={8}>
                                {t('notifyWhenCustomerReplies')}
                              </Text>
                            </div>
                            <img src={notifyInfoImg} />
                          </>
                        }
                        type="TOP_RIGHT"
                        style={{ marginLeft: -12 }}
                      />
                    </FlexDiv>
                    <Text font="Body/14px_Regular" color="SHADES_500">
                      {t('showInChatListWhenCustomerReplies')}
                    </Text>
                  </Checkbox>
                </>
              )}
            </Block>
          ))}
          {!disabled && replyButtons?.length < 2 && (
            <AddButton>
              <div onClick={handleReplyButtonAdd}>
                <i className="ri-add-line" />
              </div>
            </AddButton>
          )}
        </>
      )}
    </Block>
  );
};

const propTypes = {
  data: PropTypes.shape({
    textContent: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    showReplyButtons: PropTypes.bool,
  }),
  handleChange: PropTypes.func,
  handleMessageMove: PropTypes.func,
  handleMessageDelete: PropTypes.func,
  allowMoveUp: PropTypes.bool,
  allowMoveDown: PropTypes.bool,
  disabled: PropTypes.bool,
};

FlexEditor.propTypes = propTypes;

export default memo(FlexEditor);
