import React, { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import UnsendListPopover from 'components/WesternAppointments/table/Columns/Action/UnSend/UnsendListPopover';
import { t } from 'i18n/config';

const UnsendContainer = styled.div`
  padding-top: 12px;
  display: flex;
  cursor: pointer;
`;

const Icon = styled.i`
  margin-right: 4px;
`;

const Count = styled.span`
  margin: 0 4px;
  color: ${(props) => props.theme.colors.primary};
`;

function UnsendComponent({ clientID, appointment, onCancelNotification }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCancelNotification = useCallback(() => {
    onCancelNotification();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const open = Boolean(anchorEl);

  if (appointment.unsendCount === 0) {
    return null;
  }

  return (
    <Fragment>
      <UnsendContainer onClick={handleClick}>
        <Icon className="ri-mail-send-line" />
        {t('unsent')} <Count>{appointment.unsendCount}</Count>
      </UnsendContainer>
      {open && (
        <UnsendListPopover
          key={`${appointment.id}-UnsendListPopover`}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          appointmentID={appointment.id}
          clientID={clientID}
          onCancelNotification={handleCancelNotification}
        />
      )}
    </Fragment>
  );
}

UnsendComponent.propTypes = {
  clientID: PropTypes.string,
  appointment: PropTypes.shape({
    id: PropTypes.number,
    unsendCount: PropTypes.number,
  }),
  onCancelNotification: PropTypes.func,
};

export default UnsendComponent;
