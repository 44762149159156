import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import format from 'date-fns/format';
import { zhTW } from 'date-fns/locale';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div``;

export function LoadingCell() {
  return <Box />;
}

function EstimatedDeliveryTimeStampCell({ value }) {
  const date = format(new Date(value), 'yyyy/MM/dd (ccccc)', { locale: zhTW });
  const time = format(new Date(value), 'a hh:mm', { locale: zhTW });
  return (
    <Container>
      {date} <br /> {time}{' '}
    </Container>
  );
}

EstimatedDeliveryTimeStampCell.propTypes = {
  value: PropTypes.string,
};

export default EstimatedDeliveryTimeStampCell;
