import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/MaterialDesign/Modal';
import { Confirm } from 'components/MaterialDesign/Buttons';
import ImportUsers from './importUsers';
import { t } from 'i18n/config';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const BackButton = styled(Confirm)`
  left: 30px;
  position: absolute;
`;

const Header = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: fixed;
  background: white;
`;

function CampaignDetailModal(props) {
  const { visible, onClose, campaign, client, onUploadSuccess } = props;
  return (
    <Modal open={visible} onClose={onClose} styles={{ maxWidth: '100%', minWidth: '100%', maxHeight: '100%' }}>
      <Container>
        <ImportUsers
          campaign={campaign}
          clientID={client.id}
          vendor={client.vendor}
          onClose={onClose}
          onUploadSuccess={onUploadSuccess}
        />
        <Header>
          <BackButton
            variant="outlined"
            onClick={onClose}
            startIcon={<i className="ri-arrow-left-s-line" />}
            height={40}
            width={110}
          >
            {t('goBack')}
          </BackButton>
          <Title>{t('uploadList')}</Title>
        </Header>
      </Container>
    </Modal>
  );
}

CampaignDetailModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  campaign: PropTypes.shape({
    id: PropTypes.string,
  }),
  client: PropTypes.shape({
    id: PropTypes.string,
    vendor: PropTypes.string,
  }),
  onUploadSuccess: PropTypes.func,
};

export default CampaignDetailModal;
