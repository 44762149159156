import { t } from 'i18n/config';

const pnpMap = {
  updatedAt: t('inputDate'),
  appointmentTime: t('appointmentDate'),
  name: t('customerName'),
  phone: t('phone'),
  doctorName: t('doctorName'),
  pnpApiStatus: t('lineNotificationMessage'),
  smsApiStatus: t('notificationSms'),
  alias: t('notificationItem'),
};

const pnpaliasToHeaderTitle = {
  PNPAppointmentNotify: t('appointment'),
  PNPAppointmentCancel: t('cancellation'),
  PNPAppointmentDateChange: t('rescheduling'),
};
const apiStatusToValue = {
  success: '✔',
};

const displayDate = (time) => {
  if (!time) return '';
  if (typeof time === 'number' || (typeof time === 'string' && time.match(/^\d+$/))) {
    const timeStamp = Number(time);
    const date = new Date(timeStamp);
    return isNaN(date.getTime()) ? time : date.toLocaleString();
  } else {
    return time;
  }
};

export const convertPnpKeyToTitle = (json = []) =>
  json.map(({ updatedAt, appointmentTime, name, phone, doctorName, pnpApiStatus, smsApiStatus, alias }) => ({
    [pnpMap.updatedAt]: new Date(updatedAt).toLocaleString(),
    [pnpMap.appointmentTime]: displayDate(appointmentTime),
    [pnpMap.name]: name,
    [pnpMap.phone]: phone,
    [pnpMap.doctorName]: doctorName,
    [pnpMap.pnpApiStatus]: apiStatusToValue[pnpApiStatus],
    [pnpMap.smsApiStatus]: apiStatusToValue[smsApiStatus],
    [pnpMap.alias]: pnpaliasToHeaderTitle[alias],
  }));

export const test = true;
