import XLSX from 'xlsx';
import { set } from 'date-fns';
import { phoneFilter } from 'lib/vendors/teetchScalingParser/utils';
import { t } from 'i18n/config';

function parseDashTWDateToAD(twdateString) {
  if (twdateString != null && twdateString !== '') {
    const arr = twdateString.split('-');
    const y = parseInt(arr[0], 10);
    const m = parseInt(arr[1], 10);
    const d = parseInt(arr[2], 10);
    const res = new Date(`${y}/${m}/${d}`);
    return res;
  }
  return undefined;
}

function parseDotTWDateToAD(twdateString) {
  if (twdateString != null && twdateString !== '') {
    const arr = twdateString.split('.');
    const y = parseInt(arr[0], 10) + 1911;
    const m = parseInt(arr[1], 10);
    const d = parseInt(arr[2], 10);
    const res = new Date(`${y}/${m}/${d}`);
    return res;
  }
  return undefined;
}

function formatPhoneNumber(raw) {
  let phone = `${raw}`;
  if (phone[0] !== '0') {
    phone = `0${phone}`;
  }
  return phone;
}

export default function Yousing() {
  this.parse = (xls, rABS) => {
    const wb = XLSX.read(xls, { type: rABS ? 'binary' : 'array', cellDates: true });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 'A', raw: true });
    // v1
    if (data[0].A === t('visitDate')) {
      const res = data
        .filter((d, i) => i !== 0 && d.L != null && d.L !== '')
        .map((d) => {
          const phone = formatPhoneNumber(d.L);
          return {
            hisID: d.G,
            name: d.H,
            phone,
            doctor: d.F,
            birth: '',
            lastVisit: parseDotTWDateToAD(d.E),
            lastTeethScaling: parseDashTWDateToAD(d.D),
          };
        })
        .filter(phoneFilter);
      return res;
    }
    // v2
    if (data[0].A === t('medicalRecordNumber')) {
      const res = data
        .filter((d, i) => i !== 0 && d.K != null && d.K !== '')
        .map((d) => {
          const phone = formatPhoneNumber(d.K);
          return {
            hisID: d.A,
            name: d.C,
            phone,
            doctor: d.I,
            birth: '',
            lastVisit: parseDotTWDateToAD(d.B),
            lastTeethScaling: parseDotTWDateToAD(d.G),
          };
        })
        .filter(phoneFilter);

      return res;
    }

    return [];
  };

  this.bindCampaign = (list, campaign, options) => {
    const { delaySeconds } = campaign;
    return list.map((l) => {
      let estimatedDeliveryTime = new Date(l.lastTeethScaling.getTime() + delaySeconds * 1000);
      if (options && options.setDateValues) {
        estimatedDeliveryTime = set(estimatedDeliveryTime, options.setDateValues);
      }
      return {
        ...l,
        estimatedDeliveryTime,
      };
    });
  };
}
