import { AUTH_LOGOUT } from 'reducers/auth';
import { fetchTags } from 'lib/memberService';
import { getPatientTrackings as fetchPatientTrackings } from 'lib/messageCenterService';

const SET_PROFILE = 'MEMBER/SET_PROFILE';
const SET_TAGS = 'MEMBER/SET_TAGS';
const SET_PATIENT_TRACKINGS = 'MEMBER/SET_PATIENT_TRACKINGS';
const SET_TAGGABLE_PATIENT_TRACKINGS = 'MEMBER/SET_TAGGABLE_PATIENT_TRACKINGS';

const defaultState = {
  profile: null,
  tags: [],
  patientTrackings: [],
  taggablePatientTrackings: [],
};

export const setProfile = (profileData) => ({
  type: SET_PROFILE,
  payload: profileData,
});

const setTags = (tags) => ({
  type: SET_TAGS,
  payload: tags,
});

const setPatientTrackings = (patientTrackings) => ({
  type: SET_PATIENT_TRACKINGS,
  payload: patientTrackings,
});

const setTaggablePatientTrackings = (taggablePatientTrackings) => ({
  type: SET_TAGGABLE_PATIENT_TRACKINGS,
  payload: taggablePatientTrackings,
});

export const getTags =
  ({ clientId }) =>
  async (dispatch) => {
    const data = await fetchTags({ clientId });
    dispatch(setTags(data.tags || []));
  };

export const getPatientTrackings =
  ({ clientId }) =>
  async (dispatch) => {
    const patientTrackings = await fetchPatientTrackings({ clientId });
    const now = Date.now();
    dispatch(setPatientTrackings(patientTrackings || []));
    dispatch(
      setTaggablePatientTrackings(
        patientTrackings.filter((tracking) => {
          const isEnabled = tracking.status === 'enabled';
          const isRunAtSelectable = tracking.runAtSelectable;
          const isExpired = now >= new Date(tracking.endTime);
          return isEnabled && !isRunAtSelectable && !isExpired;
        }) || []
      )
    );
  };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case SET_PATIENT_TRACKINGS:
      return {
        ...state,
        patientTrackings: action.payload,
      };
    case SET_TAGGABLE_PATIENT_TRACKINGS:
      return {
        ...state,
        taggablePatientTrackings: action.payload,
      };
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
