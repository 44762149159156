import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/MaterialDesign/Popover';
import UnsendList from './UnsendList';

export default function UnsendListPopover({
  handleClose,
  anchorEl,
  open,
  appointmentID,
  clientID,
  onCancelNotification,
}) {
  return (
    <Popover open={open} anchorEl={anchorEl} handleClose={handleClose}>
      <UnsendList clientID={clientID} appointmentID={appointmentID} onCancelNotification={onCancelNotification} />
    </Popover>
  );
}

UnsendListPopover.propTypes = {
  handleClose: PropTypes.func,
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  appointmentID: PropTypes.number,
  clientID: PropTypes.string,
  onCancelNotification: PropTypes.func,
};
