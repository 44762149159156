import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import format from 'date-fns/format';
import { zhTW } from 'date-fns/locale';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.28;
  color: ${(props) => (props.status === 'inactive' ? props.theme.colors.SHADES_300 : props.theme.colors.SHADES_500)};
`;

export function LoadingCell() {
  return <Box />;
}

function UpdatedAtCell({ value, row }) {
  const {
    original: { status },
  } = row;
  const date = format(new Date(value), 'yyyy/MM/dd a hh:mm', { locale: zhTW });
  return <Container status={status}>{date}</Container>;
}

UpdatedAtCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
};

export default UpdatedAtCell;
