import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex }) => zIndex};
`;

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  ${({ maxWidth }) => `max-width: ${typeof width === 'number' ? `${maxWidth}px` : maxWidth}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  max-height: 90vh;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.SHADES_000};
  padding: ${({ padding }) => padding};
  border-radius: 16px;
  overflow: auto;
  margin-left: 200px;
  @media (max-width: 992px) {
    margin-left: 0;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 24px;
  color: ${colors.SHADES_400};
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${colors.SHADES_800};
  margin-bottom: 24px;
`;

const Modal = ({
  open = false,
  width = '480px',
  padding = '48px 40px 40px 40px',
  zIndex = 100,
  minHeight,
  maxWidth,
  backgroundColor,
  title,
  showCloseButton = true,
  onClose,
  children,
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);
  return open ? (
    <Backdrop zIndex={zIndex}>
      <Wrapper
        width={width}
        maxWidth={maxWidth}
        minHeight={minHeight}
        backgroundColor={backgroundColor}
        padding={padding}
      >
        {showCloseButton && (
          <CloseButton onClick={onClose}>
            <i className="ri-close-line" />
          </CloseButton>
        )}
        {Title && <Title>{title}</Title>}
        {children}
      </Wrapper>
    </Backdrop>
  ) : null;
};

const propTypes = {
  open: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  zIndex: PropTypes.number,
  padding: PropTypes.string,
  minHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.string,
};

Modal.propTypes = propTypes;

export default memo(Modal);
