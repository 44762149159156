import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_300};
  border-radius: 8px;
  margin-bottom: 24px;
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ fitContent }) => fitContent && 'height: fit-content;'}
`;

const Title = styled.div`
  width: 100%;
  font-size: 23px;
  font-weight: 500;
  line-height: 29.9px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  margin-bottom: 24px;
`;

const BlockBody = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

const Block = ({ children, title, description, minWidth, maxWidth, fitContent, gap }) => {
  return (
    <StyledBlock minWidth={minWidth} maxWidth={maxWidth} fitContent={fitContent} gap={gap}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      <BlockBody>{children}</BlockBody>
    </StyledBlock>
  );
};

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minWidth: PropTypes.number,
  fitContent: PropTypes.bool,
  gap: PropTypes.number,
};

Block.propTypes = propTypes;

export default memo(Block);
