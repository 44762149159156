/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import MessageList from 'components/Line/GroupMessage/MessageList';
import './Line.css';
import './Line.scss';
import { isLogin } from '../../helpers';
import { t } from 'i18n/config';

const defaultState = {
  isShowLocationSelection: false,
  isShowAccountSelection: false,
  locationModalStateMessage: {
    error: null,
    message: '',
  },
  googleAccounts: [],
  googleLocations: [],
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class Line extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }
  state = defaultState;
  componentDidMount() {
    if (!isLogin()) {
      this.props.history.push('/login');
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Row style={{ marginTop: '1.5rem' }}>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' }, 'clock-tabs')}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  {t('bulkMessages')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <MessageList />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}
