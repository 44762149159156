export const defaultState = {
  data: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetching': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'fetchDone': {
      const res = action.payload;
      const data = res.map(({ phone, passcode, expirationTime, lineID }) => ({
        lineID,
        phone,
        passcode,
        expirationTime: new Date(expirationTime * 1000).toLocaleTimeString(),
      }));
      return {
        ...state,
        loading: false,
        data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
