import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  width: ${(props) => props.width}px;
  height: 12px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 4px;
  border: 0px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

function LoadingCell() {
  return (
    <Container>
      <Box width={80} marginBottom={8} />
      <Box width={104} marginTop={8} marginBottom={12} />
      <Box width={80} marginTop={12} marginBottom={8} />
      <Box width={104} marginTop={8} />
    </Container>
  );
}

export default LoadingCell;
