import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import FieldBase from 'components/Dashboard_v2/FieldBase';
import Indicator from './Indicator';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const stateColorSetting = (color, bgColor, borderColor = color) => css`
  color: ${color};
  border-color: ${borderColor};
  background-color: ${bgColor};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '320px')};

  ${({ error }) => error && stateColorSetting(colors.SYSTEM_ERROR_500, colors.SYSTEM_ERROR_100)}
`;

const RadioItemWrapper = styled.div`
  ${({ direction }) =>
    direction != 'row' &&
    css`
      &:not(:first-child) {
        margin-top: 24px;
      }
    `}
`;

const RadioItem = styled.div`
  flex: 1;
  display: flex;
  align-items: ${({ itemAlign }) => itemAlign || 'end'};
  position: relative;
  margin-right: 24px;
  ${({ gap }) => gap && `gap: ${gap}px;`}
  > input[type='radio'] {
    display: none;
  }

  > label {
    flex: 1;
    padding-left: 12px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  ${({ disabled }) => disabled && `color: ${colors.SHADES_300}`}
`;

const Radio = ({
  name,
  label,
  helperText,
  direction = 'row',
  itemAlign,
  gap,
  value,
  items = [],
  onChange = () => {},
  error,
  disabled,
  fullWidth,
  required,
  checked,
  single,
}) => {
  const [currentItem, setCurrentItem] = useState(value);

  const handleSelect = (value) => {
    setCurrentItem(value);
    onChange(value);
  };

  return (
    <FieldBase
      fullWidth={fullWidth}
      required={required}
      name={name}
      label={label}
      helperText={helperText}
      error={error}
    >
      <Wrapper fullWidth={fullWidth} direction={direction}>
        {items.map((item, i) => {
          const isDisabled = disabled || item.disabled;
          return (
            <RadioItemWrapper direction={direction} key={i}>
              <RadioItem onClick={() => !isDisabled && handleSelect(item.value)} itemAlign={itemAlign} gap={gap}>
                <Indicator
                  checked={single ? checked : item.value === currentItem}
                  error={error}
                  disabled={isDisabled}
                />
                <input type="radio" value={item.value} name={name} />
                <Label disabled={isDisabled}>{item.label}</Label>
              </RadioItem>
              {item.content && <div>{item.content}</div>}
            </RadioItemWrapper>
          );
        })}
      </Wrapper>
    </FieldBase>
  );
};

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      content: PropTypes.node,
    })
  ).isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  initialValue: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
};

Radio.propTypes = propTypes;

export default memo(Radio);
