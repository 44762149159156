import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sendAppointmentNotify } from 'lib/westernAppointmentService';
import { checkAppointmentsValidToSend } from 'lib/westernAppointmentService/validation';
import ScheduledModal from 'components/WesternAppointments/table/Columns/Action/Scheduled';
import ActionButton from 'components/WesternAppointments/table/Columns/Action/ActionButton';
import { getTitle, getPnpTitle } from 'components/WesternAppointments/table/Columns/Action/action';
import { Container } from './styledComponents';
import Reducer, { toggleScheduled, setAction, setLoading } from './reducer';
import { t } from 'i18n/config';

function ActionComponent({
  clientID,
  type,
  disabled,
  appointments,
  onSuccess,
  onFail,
  shouldConfirmAgain,
  increaseUnsend,
  isPnpEnabled,
}) {
  const isPnpUser = isPnpEnabled && appointments[0].binding !== '1';
  const title = isPnpUser ? getPnpTitle(type) : getTitle(type);
  const [state, dispatch] = useReducer(Reducer, {
    loading: false,
    type,
    title,
    scheduledVisible: false,
  });

  useEffect(() => {
    dispatch(setAction({ type, title, disabled }));
  }, [type, title, disabled]);

  const sendNotification = async (date, contentFormat) => {
    if (state.loading) {
      return;
    }
    dispatch(setLoading(true));
    try {
      checkAppointmentsValidToSend(appointments, date);
      // compare date & appointment time
      await sendAppointmentNotify({
        clientID,
        appointments,
        deliveryTime: date.getTime(),
        contentFormat,
      });
      if (date > new Date()) {
        increaseUnsend();
      }
      onSuccess(t('messageSent'));
    } catch (error) {
      onFail(error.message);
    }
    dispatch(setLoading(false));
  };

  const handleInitial = async (item) => {
    const contentFormat = isPnpUser ? t('appointmentReminderNotificationPnp') : t('appointmentReminder');
    if (item.id === 2) {
      dispatch(toggleScheduled(true, undefined, contentFormat));
      return;
    }
    const now = new Date();
    if (shouldConfirmAgain) {
      dispatch(toggleScheduled(true, now, contentFormat));
      return;
    }
    await sendNotification(now, contentFormat);
  };

  const handleScheduled = async (date, contentFormat) => {
    await sendNotification(date, contentFormat);
  };

  const handleScheduledClose = () => {
    dispatch(toggleScheduled(false));
  };

  const handlePending = async () => {
    // const now = new Date();
    // if (shouldConfirmAgain) {
    //   dispatch(toggleScheduled(true, now));
    //   return;
    // }
    // await sendNotification(now, 'ContentFormat Pending');
  };

  const handleDeleted = async () => {
    const contentFormat = isPnpUser ? t('deleteNotificationNotificationPnp') : t('deleteNotification');
    const now = new Date();
    if (shouldConfirmAgain) {
      dispatch(toggleScheduled(true, now, contentFormat));
      return;
    }
    await sendNotification(now, contentFormat);
  };

  return (
    <Container>
      {state.scheduledVisible && (
        <ScheduledModal
          rows={appointments}
          shouldConfirmAgain={shouldConfirmAgain}
          scheduledDate={state.scheduledDate}
          onClose={handleScheduledClose}
          onScheduled={handleScheduled}
          visible={state.scheduledVisible}
          contentFormat={state.contentFormat}
        />
      )}
      <ActionButton
        loading={state.loading}
        title={state.title}
        type={state.type}
        onInitial={handleInitial}
        onPending={handlePending}
        onDeleted={handleDeleted}
        disabled={state.disabled}
      />
    </Container>
  );
}

ActionComponent.defaultProps = {
  increaseUnsend: () => {},
};

ActionComponent.propTypes = {
  clientID: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  shouldConfirmAgain: PropTypes.bool,
  increaseUnsend: PropTypes.func,
};

export default ActionComponent;
