import { findNumbers } from 'libphonenumber-js/mobile';
import { NAME_OPTIONS, PHONE_OPTIONS, EMAIL_OPTIONS, NOTE_OPTIONS, SERVICE_PROVIDER_NAME_OPTIONS } from './constants';
import { isValidPhoneNumber } from '../utils/phone';
import { t } from 'i18n/config';

export const isNeedCreateObject = (optionsArray, currentObject, newTitle) =>
  Object.keys(currentObject).length === 0 && optionsArray.find((title) => title === newTitle);

export const isPriorityHigher = (priority, newTitle, currentTitle) => priority[newTitle] < priority[currentTitle];

// Fit TW cellphone number only
export const validatePhoneNumber = (phone = '') => {
  const returnPhone = phone.replace(/-|x/g, '').match('[9][0-9]{8}');
  if (returnPhone == null) {
    return '';
  }
  return `0${returnPhone[0]}`;
};

export const validateGlobalPhoneNumber = (phone = '') => {
  const detectArr = findNumbers(phone, '', { v2: true });
  if (detectArr == null || detectArr.length === 0) {
    const returnPhone = phone.replace(/-|x/g, '').match('[9][0-9]{8}');
    if (returnPhone == null) {
      return '';
    }
    return `0${returnPhone[0]}`;
  }
  return phone;
};

export const isValidEmail = (rawEmail) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(rawEmail).toLowerCase());
};

export const isValidCustomerInput = ({ name = '', email = '', phone = '' }) => {
  if (name.trim().length === 0) {
    return false;
  }
  if (email.length === 0 && !isValidPhoneNumber(phone)) {
    return false;
  }
  return true;
};

export const isValidBlackList = ({ email = '', phone = '' }) => {
  if ((email.length === 0 || !isValidEmail(email)) && !isValidPhoneNumber(phone)) {
    return false;
  }
  return true;
};

export const findName = (el) => el.includes(NAME_OPTIONS.NAME) || el.includes(NAME_OPTIONS.NAME_EN);

export const findPhone = (el) =>
  el.includes(PHONE_OPTIONS.PHONE) || el.includes(PHONE_OPTIONS.CELLPHONE) || el.includes(PHONE_OPTIONS.PHONE_A_TEL);

export const findEmail = (el) =>
  el.includes(EMAIL_OPTIONS.EMAIL) ||
  el.includes(EMAIL_OPTIONS.MAIL) ||
  el.includes(EMAIL_OPTIONS.EMAIL_ENG) ||
  el.includes(EMAIL_OPTIONS.EMAIL_EN_UNDERSCORE);

export const findNote = (el) => el.includes(NOTE_OPTIONS.NOTE);

export const findServiceProvider = (el) =>
  el.includes(SERVICE_PROVIDER_NAME_OPTIONS.SERVICE_PROVIDER_NAME) ||
  el.includes(SERVICE_PROVIDER_NAME_OPTIONS.DOCTOR_NAME);

// TODO: create Localized model
export const translateMr = (MR) => {
  switch (MR) {
    case 'Mr':
      return t('mister');
    case 'Ms':
      return t('miss');
    case 'Mrs':
      return t('madam');
    default:
      return '';
  }
};
