import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from 'components/MaterialDesign/menu';
import { t } from 'i18n/config';

const ALL = t('selectAll');
function DefaultColumnFilter({ column, filterOptionsByAccessor: defaultFilterOptionsByAccessor, selectedDate }) {
  const defaultToDisplayString = useCallback((a) => a, []);
  const {
    setFilter,
    filterValue,
    toDisplayString = defaultToDisplayString,
    filterOptions = defaultFilterOptionsByAccessor,
  } = column;
  const [name, setName] = useState(toDisplayString(filterValue) || ALL);
  useEffect(() => {
    setName(toDisplayString(filterValue) || ALL);
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickItem = useCallback((item) => {
    setFilter(item.value !== ALL ? item.value : undefined);
    setName(item.name);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const items = useMemo(
    () =>
      [{ id: `${0}`, name: ALL, value: ALL }].concat(
        filterOptions[column.id].map((e, i) => ({
          id: `${i + 1}`,
          name: `${toDisplayString(e)}`,
          value: `${e}`,
        }))
      ),
    [filterOptions, column] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <Menu items={items} name={name} onClickItem={onClickItem} />;
}

DefaultColumnFilter.propTypes = {
  filterOptionsByAccessor: PropTypes.shape({}),
  column: PropTypes.shape({
    id: PropTypes.string,
    setFilter: PropTypes.func,
    filterValue: PropTypes.string,
    toDisplayString: PropTypes.func,
    filterOptions: PropTypes.shape({}),
  }),
  selectedDate: PropTypes.instanceOf(Date),
};
export default DefaultColumnFilter;
