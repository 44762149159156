import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Filter from 'components/Dashboard_v2/Filter';
import { t } from 'i18n/config';

// sync lineBinding / Message values to src/feature/MemberCenter/MemberList/AllMembers/table/columns.js
const lineBindingOptions = [
  { id: 'binding', name: t('bound') },
  { id: 'unbinding', name: t('unbound') },
];
const lineMessageOptions = [
  { id: 'active', name: t('lineSendable') },
  { id: 'inactive', name: t('lineNotSendable') },
  { id: 'blocked', name: t('blocked') },
];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const CustomFilter = ({ onFilterUpdate }) => {
  const tags = useSelector((state) => state.member.tags);
  const patientTrackings = useSelector((state) => state.member.patientTrackings);
  const patientTrackingItems = useMemo(
    () =>
      patientTrackings
        .filter((item) => ['enabled', 'disabled'].includes(item.status))
        .sort((a, b) => (a.status === 'enabled' ? -1 : b.status === 'enabled' ? 1 : 0))
        .map((item) => ({ ...item, name: item.title })),
    [patientTrackings]
  );
  const [filter, setFilter] = useState({
    lineBinding: [],
    lineMessage: [],
    tag: [],
    patientTracking: [],
  });

  const handleStateFilterChanged = useCallback((key, selectedIds) => {
    setFilter((prev) => ({ ...prev, [key]: selectedIds }));
  }, []);

  useEffect(() => {
    if (onFilterUpdate) {
      onFilterUpdate(filter);
    }
  }, [filter, onFilterUpdate]);

  return (
    <Wrapper>
      <Filter
        label={t('bindingStatus')}
        iconClass={'ri-line-fill'}
        items={lineBindingOptions}
        onSelected={(selectedIds) => handleStateFilterChanged('lineBinding', selectedIds)}
      />
      <Filter
        label={t('sendingChannel')}
        iconClass={'ri-send-plane-fill'}
        items={lineMessageOptions}
        onSelected={(selectedIds) => handleStateFilterChanged('lineMessage', selectedIds)}
      />
      <Filter
        label={t('tags')}
        itemType="tag"
        iconClass={'ri-price-tag-3-fill'}
        items={tags}
        onSelected={(selectedIds) => handleStateFilterChanged('tag', selectedIds)}
      />
      <Filter
        label={t('patientTracking')}
        itemType="patientTracking"
        iconClass={'ri-organization-chart'}
        items={patientTrackingItems}
        onSelected={(selectedIds) => handleStateFilterChanged('patientTracking', selectedIds)}
      />
    </Wrapper>
  );
};

export default memo(CustomFilter);
