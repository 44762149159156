const UPDATE_REMOTE = 'updateRemoteProgress';
export const SET_PROGRESS = 'setProgress';

export const updateRemote = () => ({
  type: UPDATE_REMOTE,
});

export const setProgress = (progress) => ({
  type: SET_PROGRESS,
  payload: progress,
});

export default (state, action) => {
  switch (action.type) {
    case UPDATE_REMOTE:
      return {
        ...state,
        loading: true,
      };
    case SET_PROGRESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
      };
    default:
      return state;
  }
};
