import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TabTitles } from 'components/Message/campaignTable/headerColumns';
import Tabs from 'components/Table/HorizontalTabs';
import Tab from 'components/Table/HorizontalTabs/HorizontalTab';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function TabsSection(props) {
  const { statusTabIndex, handleChange, clientIndex, children } = props;
  return (
    <Container>
      <Tabs value={statusTabIndex} onChange={handleChange} aria-label="tabs">
        {TabTitles.map((status) => (
          <Tab key={status} label={status} {...a11yProps(clientIndex)} />
        ))}
      </Tabs>
      {children}
    </Container>
  );
}

TabsSection.propTypes = {
  statusTabIndex: PropTypes.number,
  clientIndex: PropTypes.number,
  handleChange: PropTypes.func,
  children: PropTypes.node,
};

export default TabsSection;
