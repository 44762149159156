import { getToken, MARKETING_URL, ENV_STAGE } from '../helpers';
import { responseHandler } from './responseHandler';

const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  'AP-stage': ENV_STAGE,
});

export const listSurveyByUser = async ({ userId, clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users/${userId}/surveycakes`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(responseHandler);

export const fetchSurveyByUser = async ({ userId, clientId, surveyId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users/${userId}/surveycakes/${surveyId}`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(responseHandler);
