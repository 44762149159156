import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import { Loading as LoadingCircular } from 'components/MaterialDesign/Loading';
import Menu from 'components/MaterialDesign/menu';
import { Button, Disabled } from './styledComponents';
import { t } from 'i18n/config';

function ActionButton({ loading, title, type, onInitial, onDeleted, disabled }) {
  if (type === 'initial' || type === 'pending') {
    return (
      <Menu
        items={[
          { id: 1, name: t('immediateNotification'), value: 'i' },
          { id: 2, name: t('scheduleNotification'), value: '2' },
        ]}
        loading={loading}
        name={title}
        onClickItem={onInitial}
        styles={{
          color: 'white',
          backgroundColor: theme.colors.primary,
          circularProgressColor: 'white',
          justifyContent: 'center',
          width: 120,
        }}
      />
    );
  }
  const buttonTitle = loading ? <LoadingCircular size={20} /> : title;
  if (type === 'deleted') {
    return (
      <Button width={80} height={40} customizedcolor={theme.colors.error} onClick={onDeleted}>
        {buttonTitle}
      </Button>
    );
  }
  if (type === 'smsToggleBlock') {
    return (
      <Disabled width={100} height={40} customizedcolor={theme.colors.disabled} textcolor="white" disabled={disabled}>
        {buttonTitle}
      </Disabled>
    );
  }
  return null;
}

ActionButton.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onInitial: PropTypes.func,
  onPending: PropTypes.func,
  onDeleted: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ActionButton;
