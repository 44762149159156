import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n/config';

function LocationSelector({ fetchLocations, selectGoogleLocation, showMessage }) {
  const [locations, setLocations] = useState([]);
  const [inputText, setInputText] = useState('');
  const handleSearchLocations = async () => {
    try {
      const locs = await fetchLocations(inputText);
      setLocations(locs);
      if (locs.length === 0) {
        showMessage({
          error: new Error(t('noLocationFound')),
        });
        return;
      }
      showMessage({
        message: t('pleaseChooseMerchant'),
      });
    } catch (error) {
      showMessage({
        error,
      });
    }
  };
  const handleInput = (e) => {
    setInputText(e.target.value);
  };

  const handleSelectLocation = (location) => async () => {
    const response = await selectGoogleLocation(location);
    showMessage(response);
  };

  return (
    <div>
      <input type="text" onChange={handleInput} value={inputText} />
      <button onClick={handleSearchLocations}>{t('search')}</button>
      {locations.map((location) => (
        <div className="list-item" key={location.name}>
          <button onClick={handleSelectLocation(location)}>{`${location.title}`}</button>
        </div>
      ))}
    </div>
  );
}

LocationSelector.propTypes = {
  fetchLocations: PropTypes.func,
  selectGoogleLocation: PropTypes.func,
  showMessage: PropTypes.func,
};

export default LocationSelector;
