import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMessageList, fetchLineUsersByQueryType, rulesSerializer } from 'reducers/line';
import connectData from 'components/HOCs/connectData';
import MessageListCard from './MessageListCard';
import CreateMessage from './CreateMessage';
import { appointemntsTag } from '../../../selectors/tagsSelectors';
import { t } from 'i18n/config';

const propTypes = {
  fetchMessageList: PropTypes.func,
  clients: PropTypes.shape({
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.object,
  }),
  messageListByClientID: PropTypes.shape(PropTypes.object),
  fetchLineUsersByQueryType: PropTypes.func,
};

const defaultProps = {};

const mapStateToProps = (state) => ({
  clients: state.clients,
  messageListByClientID: state.line.messageListByClientID,
});

const mapDispatchToProps = {
  fetchMessageList,
  fetchLineUsersByQueryType,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
class MessageList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchData = () => {
    this.props.fetchMessageList();
  };
  onCreateMessage = ({ clientId }) => {
    this.toggle({ clientId });
  };

  onCreateMessageSelectionUpdated = async ({ clientId: cid, rules }) => {
    const lineusers = await this.props.fetchLineUsersByQueryType({
      clientId: cid,
      types: Object.keys(rules).length === 0 ? undefined : rulesSerializer({ rules }),
    });
    return lineusers;
  };

  toggle = ({ clientId }) => {
    this.setState({
      isOpen: !this.state.isOpen,
      clientId,
    });
    this.props.fetchMessageList();
  };

  isMultipleSelectedStores = () => this.props.clients.selectedIds.length > 1;

  renderMessages = ({ list = [] }) => {
    const a = list.map(({ title = '', type = '', deliveryTime = 0, expectedDeliveryNumbers = 0, id }) => (
      <MessageListCard
        key={id}
        isDelivered={new Date() > new Date(deliveryTime)}
        title={title}
        type={type}
        deliveryTime={deliveryTime}
        number={expectedDeliveryNumbers}
      />
    ));
    return <div>{a}</div>;
  };

  render() {
    const { isOpen, clientId } = this.state;
    const { clients, messageListByClientID } = this.props;
    if (isOpen) {
      return (
        <CreateMessage
          isOpen={isOpen}
          onOpen={this.props.fetchLineUsersByQueryType}
          onUpdate={this.onCreateMessageSelectionUpdated}
          tagsOverride={[appointemntsTag({ selectDate: new Date() })]}
          toggle={this.onCreateMessage}
          clientId={clientId}
        />
      );
    }
    return clients.selectedIds.map((id) => (
      <div key={id} className="line-message-list-container">
        <div className="title-container">
          <div className="title">
            {t('bulkMessages')} {this.isMultipleSelectedStores() ? this.props.clients.byId[id].companyName : ''}
          </div>
          <button
            className="create-message-button"
            onClick={() => {
              this.onCreateMessage({ clientId: id });
            }}
          >
            {t('addBulkMessage')}
          </button>
        </div>
        <div>
          {this.renderMessages({
            list: messageListByClientID[id],
          })}
        </div>
      </div>
    ));
  }
}

MessageList.propTypes = propTypes;
MessageList.defaultProps = defaultProps;

export default MessageList;
