import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Loading from 'components/Dashboard_v2/Loading';

const { colors } = dashboard_v2;

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex }) => zIndex || 100};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}px` : '480px')};
  background-color: ${colors.SHADES_000};
  padding: 48px 40px 40px 40px;
  border-radius: 16px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 24px;
  color: ${colors.SHADES_400};
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${colors.SHADES_800};
  margin-bottom: 24px;
  width: 100%;
  text-align: left;
`;

const Description = styled.div`
  width: 100%;
  color: ${colors.SHADES_900};
  font-size: 16px;
  text-align: left;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
  button {
    margin-left: 16px;
  }
`;

const Dialog = ({
  open = false,
  width,
  zIndex,
  title,
  description,
  showCloseButton = true,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  confirmColorType,
  disabled,
  isLoading,
  viewMode,
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';
  }, [open]);
  useEffect(() => () => (document.body.style.overflow = ''), []);
  return open ? (
    <Backdrop zIndex={zIndex}>
      <Wrapper width={width}>
        {showCloseButton && !isLoading && (
          <CloseButton onClick={onCancel}>
            <i className="ri-close-line" />
          </CloseButton>
        )}
        {Title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
        <ActionButtons hidden={viewMode}>
          {isLoading && <Loading />}
          {!isLoading && cancelText && (
            <Button color="secondary" variant="filled" onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          {!isLoading && confirmText && (
            <Button color={confirmColorType} variant="filled" onClick={onConfirm} disabled={disabled}>
              {confirmText}
            </Button>
          )}
        </ActionButtons>
      </Wrapper>
    </Backdrop>
  ) : null;
};

const propTypes = {
  open: PropTypes.bool,
  width: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showCloseButton: PropTypes.bool,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmColorType: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  viewMode: PropTypes.bool,
};

Dialog.propTypes = propTypes;

export default memo(Dialog);
