import { AUTH_LOGOUT } from 'reducers/auth';
import {
  downloadReviewXlsx,
  createCustomerApiV1,
  fetchReportDataApi,
  fetchReportCSVApi,
  fetchSurveyCSVApi,
  fetchFeedbackCSVApi,
  createCustomerApi,
  bulkUploadApi,
  getMonthlyReportIframeUrlPath,
  fetchCustomersInQueApi,
} from 'lib/customerService';
import { refreshPage } from 'reducers/ui';
import { normalizeById } from 'helpers';
import { setReviews } from 'reducers/reviews';
import { demoMiddleWare } from 'demo';

const SET_CUSTOMERS = 'CUSTOMERS/SET_CUSTOMERS';
const SET_CUSTOMERS_MANAGEMENT = 'CUSTOMERS/MANAGEMENT';

const defaultState = {
  byId: {},
  management: {},
};

const setCustomers = (customers) => {
  const normalizeCustomers = normalizeById(customers);
  return {
    type: SET_CUSTOMERS,
    payload: normalizeCustomers,
  };
};

const setManagement = (customersByClientId) => ({
  type: SET_CUSTOMERS_MANAGEMENT,
  payload: customersByClientId,
});

export const createCustomer = (name, email, phone, note, country) => async (dispatch) => {
  const res = await createCustomerApi(name, email, phone, note, country);
  dispatch(refreshPage(true));
  return res;
};

export const createCustomerV1 =
  ({ customer, clientId }) =>
  async () => {
    const res = await createCustomerApiV1({
      clientId,
      customer,
    });
    return res;
  };

export const bulkUpload = (body) => async () => {
  const res = await bulkUploadApi(body);
  console.log(res);
  // bulk upload doesn't have to refresh page cs u don't know when to refresh sms count
  return res;
};

export const doneRefreshPage = () => async (dispatch) => {
  dispatch(refreshPage(false));
};

export const fetchReportDataByTime = (startTime, endTime) => async (dispatch, getState) => {
  const { selectedIds } = getState().clients;
  const clientIds = selectedIds.join(',');
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const res = await demoMiddleWare(
    clients,
    {
      clientId: clientIds,
      startTimeStamp: new Date(startTime).getTime(),
      endTimeStamp: new Date(endTime).getTime(),
    },
    fetchReportDataApi
  );
  dispatch(setCustomers(res.customers));
  dispatch(
    setReviews({
      reviews: res.reviews.sort((x, y) => +new Date(y.createdAt) - +new Date(x.createdAt)),
    })
  );
  return res;
};

export const fetchReviewReportXlsx = (startTime, endTime, title) => async (dispatch, getState) => {
  const { selectedIds } = getState().clients;
  const clientIds = selectedIds.join(',');
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const res = await demoMiddleWare(
    clients,
    {
      clientId: clientIds,
      startTime,
      endTime,
      title,
    },
    downloadReviewXlsx
  );
  return res;
};

export const fetchReportCSV = (startTimeString, endTimeString, title) => async (dispatch, getState) => {
  const { selectedIds } = getState().clients;
  const clientIds = selectedIds.join(',');
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const res = await demoMiddleWare(
    clients,
    {
      clientId: clientIds,
      startTimeString,
      endTimeString,
      title,
    },
    fetchReportCSVApi
  );
  return res;
};

export const fetchFeedbackCSV = (startTimeString, endTimeString, title) => async (dispatch, getState) => {
  const { selectedIds } = getState().clients;
  const clientIds = selectedIds.join(',');
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const res = await demoMiddleWare(
    clients,
    {
      clientId: clientIds,
      startTimeString,
      endTimeString,
      title,
    },
    fetchFeedbackCSVApi
  );
  return res;
};

export const fetchSurveyCSV =
  ({ startTimeString, endTimeString, clientId }) =>
  async () => {
    const res = await fetchSurveyCSVApi({
      startTime: startTimeString,
      endTime: endTimeString,
      clientId,
    });
    return res;
  };

export const fetchTotalCustomersInQue = () => async (dispatch, getState) => {
  const { selectedIds } = getState().clients;
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);

  const promises = selectedIds.map((id) =>
    demoMiddleWare(
      clients,
      {
        clientId: id,
      },
      fetchCustomersInQueApi
    )
  );
  Promise.all(promises).then((customersArray) => {
    const customersByClientId = {};
    selectedIds.forEach((clientId, i) => {
      customersByClientId[clientId] = customersArray[i];
    });
    dispatch(setManagement(customersByClientId));
  });
};

export const fetchCustomersInQue =
  ({ page, clientId }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    const customersResp = await demoMiddleWare(
      clients,
      {
        clientId,
        page,
      },
      fetchCustomersInQueApi
    );
    dispatch(
      setManagement({
        [clientId]: customersResp,
      })
    );
  };

export const getMonthlyReportIframeUrl = async ({ clientIds, startDate, endDate, clients }) => {
  const url = await demoMiddleWare(clients, { clientIds, startDate, endDate }, getMonthlyReportIframeUrlPath);
  return url;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return defaultState;
    case SET_CUSTOMERS:
      return {
        ...state,
        byId: action.payload,
      };
    case SET_CUSTOMERS_MANAGEMENT:
      return {
        ...state,
        management: {
          ...state.management,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
