import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';
import { t } from 'i18n/config';

export const statusKeyMap = {
  ACTIVE: 'active',
  PENDING: 'inProgress',
  INACTIVE: 'inactive',
};

const statusMap = {
  [statusKeyMap.ACTIVE]: t('ready'),
  [statusKeyMap.PENDING]: t('preparing'),
  [statusKeyMap.INACTIVE]: t('closed'),
};

const statusStyles = {
  [statusKeyMap.ACTIVE]: {
    backgroundColor: theme.colors.SYSTEM_SUCCESS_100,
    color: theme.colors.SYSTEM_SUCCESS_900,
  },
  [statusKeyMap.PENDING]: {
    backgroundColor: theme.colors.SYSTEM_WARNING_100,
    color: theme.colors.SYSTEM_WARNING_900,
  },
  [statusKeyMap.INACTIVE]: {
    backgroundColor: theme.colors.SYSTEM_ERROR_100,
    color: theme.colors.SYSTEM_ERROR_900,
  },
};

export const parseStatusToTerm = (status) => statusMap[status];

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 32px;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor};
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.28;
  color: ${(props) => props.color};
`;

export function LoadingCell() {
  return <Box />;
}

function StatusCell({ value }) {
  return <Container {...statusStyles[value]}>{parseStatusToTerm(value)} </Container>;
}

StatusCell.propTypes = {
  value: PropTypes.string,
};

export default StatusCell;
