import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { dashboard_v2 } from 'theme';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import ActionCell from './ActionCell';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const DATE_FORMAT = 'YYYY/MM/DD HH:mm';

const InfoHead = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 8px;
  }
`;

const DateLabel = styled.div`
  white-space: pre-line;
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${colors.SHADES_500};
  background-color: ${colors.SHADES_100};
  border-radius: 4px;
`;

const StatusDot = styled.span`
  display: ${({ color }) => (color ? 'inline-block' : 'none')};
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

const StatusLabel = ({ status }) => {
  const settingMap = {
    enabled: { text: t('enabled'), dotColor: colors.SYSTEM_SUCCESS_500 },
    disabled: { text: t('disabled'), dotColor: colors.SYSTEM_ERROR_500 },
    draft: { text: t('draft'), dotColor: null },
  };
  const setting = settingMap[status] || { text: status, dotColor: null };
  return (
    <StatusWrapper>
      <StatusDot color={setting.dotColor} />
      {setting.text}
    </StatusWrapper>
  );
};

export const columns = [
  {
    Header: t('titleName'),
    accessor: 'title',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: () => (
      <InfoHead>
        <InfoPopover
          popoverContent={
            <div>
              {t('postExpiryMessage')}
              <br />
              {t('duringValidPeriodMessage')}
            </div>
          }
        />
        <span>{t('activityValidityPeriod')}</span>
      </InfoHead>
    ),
    Cell: ({ row }) => {
      const data = row.original;
      const { startTime, endTime } = data;
      return (
        <DateLabel>{`${moment(startTime).format(DATE_FORMAT)} - ${moment(endTime).format(DATE_FORMAT)}`}</DateLabel>
      );
    },
    accessor: 'startTime',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('status'),
    accessor: 'status',
    Cell: ({ value: status }) => <StatusLabel status={status} />,
  },
  {
    Header: t('recurringEvent'),
    accessor: 'recyclable',
    Cell: ({ value: recyclable }) => (recyclable ? t('opened') : t('none')),
  },
  {
    Header: t('trackingTime'),
    accessor: 'runAtSelectable',
    Cell: ({ value }) => (value ? t('specifiedDate') : t('current')),
  },
  {
    Header: '',
    accessor: 'action',
    Cell: ActionCell,
    disableSortBy: true,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
