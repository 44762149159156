import { SERVER_URL } from 'helpers/envHelpers';

const loginUrl = `${SERVER_URL}api/login`;
console.log(loginUrl);

export const getTodos = () => fetch(SERVER_URL).then((res) => res.json());

export const loginUser = (username, password) =>
  fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  }).then((res) => {
    if (res.status > 200) {
      throw res.json();
    }
    return res.json();
  });

// export const updateTodo = todo => fetch(`${baseUrl}/${todo.id}`, {
//   method: 'PUT',
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json'
//   },
//   body: JSON.stringify(todo)
// })
//   .then(res => res.json());

// export const destroyTodo = id => fetch(`${baseUrl}/${id}`, {
//   method: 'DELETE',
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json'
//   }
// });
