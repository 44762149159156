import React, { memo, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import PatientTrackingSelectorPopover from 'components/Dashboard_v2/PatientTrackingSelectorPopover';
import PatientTrackingTag from 'components/Dashboard_v2/PatientTrackingTag';
import { useSelector } from 'react-redux';
import useAlert from 'hooks/useAlert';
import { updateUserPatientTrackings } from 'lib/messageCenterService';
import { updateTag } from 'lib/memberService';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  display: flex;
  white-space: nowrap;
  width: 100%;
  min-height: 30px;
`;

const MoreTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 42px;
  height: 30px;
  border: 1px solid ${colors.SHADES_300};
  border-radius: 4px;
  margin-left: 4px;
  cursor: pointer;
  i {
    line-height: 1;
    font-size: 18px;
    color: ${colors.SHADES_400};
  }
`;

const sortPatientTrackings = (array) => {
  return array
    .sort((a, b) => (new Date(a.runAt) > new Date(b.runAt) ? 1 : -1))
    .sort((a) =>
      a.status === 'enabled' && (new Date() < new Date(a.expiredAt) || new Date() < new Date(a.endTime)) ? -1 : 1
    );
};

const PatientTrackingCell = ({ row, value, updateMyData, children, ellipsis = true, isTagList = false }) => {
  const clients = useSelector((state) => state.clients);
  const patientTrackings = useMemo(() => sortPatientTrackings(value), [value]);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { original, index } = row;
  const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const updateTagPatientTrackings = useCallback(
    async (selectedPatientTrackings) => {
      setIsLoading(true);
      try {
        updateTag({
          clientId,
          tagId: original.id,
          tag: {
            ...original,
            patientTrackingIds: selectedPatientTrackings.map((data) => data.id),
          },
        });
        setAlert({ type: 'success', title: t('tagActionSuccess', { title: t('edit') }) });
        updateMyData(index, 'patientTrackings', sortPatientTrackings(selectedPatientTrackings));
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('tagActionFailed', { title: t('edit') }) });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    },
    [clientId, original, updateMyData, index, setAlert]
  );

  const updateMemberPatientTrackings = useCallback(
    async (selectedPatientTrackings) => {
      setIsLoading(true);
      try {
        const runTimesByPatientTrackingId = selectedPatientTrackings.reduce((acc, patientTracking) => {
          const { id, runAt } = patientTracking;
          acc[id] = runAt;
          return acc;
        }, {});
        await updateUserPatientTrackings({
          clientId,
          payload: {
            userIds: [original.id],
            patientTrackingIds: selectedPatientTrackings.map((data) => data.id),
            runTimesByPatientTrackingId,
          },
        });
        setAlert({ type: 'success', title: t('successfullyUpdatedTracking') });
        updateMyData(index, 'patientTrackings', sortPatientTrackings(selectedPatientTrackings));
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('trackingUpdateFailed') });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    },
    [clientId, original.id, updateMyData, index, setAlert]
  );

  return (
    <PatientTrackingSelectorPopover
      memberPatientTrackings={patientTrackings || []}
      isTagList={isTagList}
      onClose={isTagList ? updateTagPatientTrackings : updateMemberPatientTrackings}
      isLoading={isLoading}
    >
      <Wrapper>
        {isLoading ? (
          <CircularProgress style={{ color: colors.SYSTEM_INFO_400 }} size={30} />
        ) : ellipsis ? (
          <React.Fragment>
            {patientTrackings && patientTrackings[0] && (
              <PatientTrackingTag
                name={`${patientTrackings[0].title} ${
                  !isTagList && patientTrackings[0].runAt
                    ? moment(patientTrackings[0].runAt).format('YYYY/MM/DD HH:mm')
                    : ''
                }`}
                setting={{
                  isExpired: moment(new Date()).isAfter(patientTrackings[0].expiredAt),
                  isEnabled: patientTrackings[0].status === 'enabled',
                  recyclable: patientTrackings[0].recyclable,
                }}
                key={patientTrackings[0].id + patientTrackings[0].runAt}
              />
            )}
            {patientTrackings && patientTrackings[1] && (
              <MoreTag>
                <i className="ri-more-fill" />
              </MoreTag>
            )}
          </React.Fragment>
        ) : (
          children
        )}
      </Wrapper>
    </PatientTrackingSelectorPopover>
  );
};

const propTypes = {
  value: PropTypes.array,
};

PatientTrackingCell.propTypes = propTypes;

export default memo(PatientTrackingCell);
