import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from 'i18n/config';

export const statusKeyMap = {
  SUCCESS: 'done',
  PENDING: 'pending',
  DISABLED: 'disabled',
};

const statusMap = {
  [statusKeyMap.SUCCESS]: t('sent'),
  [statusKeyMap.PENDING]: t('scheduling'),
  [statusKeyMap.DISABLED]: t('paused'),
};

const sendingStatusMap = {
  SUCCESS: 'success',
  FAILED: 'failed',
};
const successStatusTermMap = {
  Message: t('sentToLine'),
  Sms: t('sentToSms'),
};

const parseSuccessStatus = (smsApiStatus, messageApiStatus) => {
  if (messageApiStatus === sendingStatusMap.SUCCESS) {
    return successStatusTermMap.Message;
  }
  if (smsApiStatus === sendingStatusMap.SUCCESS) {
    return successStatusTermMap.Sms;
  }
  return '';
};

export const parseStatusToTerm = ({ status, messageApiStatus, smsApiStatus }) => {
  if (status === statusKeyMap.SUCCESS) {
    return parseSuccessStatus(smsApiStatus, messageApiStatus);
  }
  return statusMap[status];
};

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div``;

export function LoadingCell() {
  return <Box />;
}

function StatusCell({
  value,
  row: {
    original: { smsApiStatus, messageApiStatus },
  },
}) {
  return <Container>{parseStatusToTerm({ status: value, smsApiStatus, messageApiStatus })} </Container>;
}

StatusCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      smsApiStatus: PropTypes.string,
      messageApiStatus: PropTypes.string,
    }),
    index: PropTypes.number,
  }),
  value: PropTypes.string,
};

export default StatusCell;
