import { demoMiddleWare } from 'demo';
import moment from 'moment';
import { setPatientTags } from 'reducers/clients';
import { VendorCategory } from 'utils/vendors';
import {
  createMessageApi,
  createGroupMessageApi,
  fetchLineUsersByTagsApi,
  uploadImageApi,
  fetchMessageListApi,
  fetchTagListInClientApi,
  fetchPnpConsumptionApi,
  fetchPnpAppointmentDeliverySuccessListApi,
} from '../lib/lineService';
import { fetchAppointmentsApi } from '../lib/appointmentService';
import { normalizeById, isAuthRoleBrand } from '../helpers';

const SET_MESSAGE = 'LINE/SET_MESSAGE';
const SET_LINE_USERS = 'LINE/SET_LINE_USERS';
const SET_MESSAGE_LIST = 'LINE/SET_MESSAGE_LIST';
const CLEAR_LINE_USERS = 'LINE/CLEAR_LINE_USERS';

const defaultState = {
  selectedUsers: [],
  messageListByClientID: {},
};

export const rulesSerializer = ({ rules }) => {
  const tags = Object.keys(rules)
    .filter((id) => rules[id].title.type === 'tags')
    .map((id) => rules[id].value);
  const appointments = Object.keys(rules)
    .filter((id) => rules[id].title.type === 'appointments')
    .map((id) => rules[id].value)
    .reduce((sum, tmp) => {
      const newSum = sum;
      const selectedTime = moment(new Date(tmp.title));
      if (sum.startTime == null || selectedTime.isBefore(sum.startTime)) {
        newSum.startTime = selectedTime;
      }
      if (sum.endTime == null || selectedTime.isAfter(sum.endTime)) {
        newSum.endTime = selectedTime;
      }
      return newSum;
    }, {});
  return { tags, appointments };
};

export const setMessage = ({ type, message }) => ({
  type: SET_MESSAGE,
  payload: {
    type,
    message,
  },
});

export const setMessageList = ({ byClientID }) => ({
  type: SET_MESSAGE_LIST,
  payload: byClientID,
});

export const setLineUsers = (list) => ({
  type: SET_LINE_USERS,
  payload: list,
});

export const clearSelectedLineUsers = () => ({
  type: CLEAR_LINE_USERS,
});

export const createGroupMessage =
  ({ message, lineIDs, phones, clientId, title, type, deliveryTime }) =>
  async (dispatch, getState) => {
    const messages = message;
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    await demoMiddleWare(
      clients,
      {
        messages,
        lineIDs,
        phones,
        clientId,
        title,
        type,
        deliveryTime,
      },
      createGroupMessageApi
    );
  };

export const fetchMessageList = () => async (dispatch, getState) => {
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const { selectedIds } = getState().clients;
  const promises = selectedIds.map((id) =>
    demoMiddleWare(
      clients,
      {
        clientID: id,
      },
      fetchMessageListApi
    )
  );
  Promise.all(promises).then((messageListArr) => {
    const byClientID = {};
    messageListArr.forEach((m, i) => {
      const clientID = selectedIds[i];
      byClientID[clientID] = m;
    });
    dispatch(setMessageList({ byClientID }));
  });
};

export const createMessage =
  ({ type, message, lineIDs, clientId }) =>
  async (dispatch, getState) => {
    const messages = [
      {
        type,
        text: message,
      },
    ];
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    const reply = await demoMiddleWare(
      clients,
      {
        messages,
        lineIDs,
        clientId,
      },
      createMessageApi
    );
    console.log('done sending, reply = ', reply);
  };

/**
 *
 * @param clientId: client queried
 * @param type: type to query, ex: types: { 'tags': tags, 'appointments': {startTime, endTime}}
 */
export const fetchLineUsersByQueryType =
  ({ clientId, types }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    let users = {};
    if (types == null) {
      const lu = await demoMiddleWare(
        clients,
        {
          clientId,
          tags: [],
        },
        fetchLineUsersByTagsApi
      );
      users = {
        ...users,
        ...normalizeById(lu.filter((s) => s.status === 1)),
      };
    } else {
      if (types.tags.length !== 0) {
        const lu = await demoMiddleWare(
          clients,
          {
            clientId,
            tags: types.tags.map((v) => v.id),
          },
          fetchLineUsersByTagsApi
        );
        users = {
          ...users,
          ...normalizeById(lu.filter((s) => s.status === 1)),
        };
      }
      if (Object.keys(types.appointments).length !== 0) {
        const appointmentResp = await demoMiddleWare(
          clients,
          {
            clientId,
            startTime: types.appointments.startTime.startOf('day').unix() * 1000,
            endTime: types.appointments.endTime.endOf('day').unix() * 1000,
          },
          fetchAppointmentsApi
        );
        const client = getState().clients.byId[clientId];
        if (VendorCategory[client.vendor] === 'western') {
          const totalAppointment = [
            ...appointmentResp.Failed,
            ...appointmentResp.NotSendYet,
            ...appointmentResp.Sended,
          ];
          const lu = totalAppointment
            .filter((a) => a.binding === '1' && a.deleted !== '1')
            .map((a) => ({
              id: a.lineUserID,
              name: a.name,
              phone: a.phone,
              status: 1,
              tags: [],
            }));
          users = {
            ...normalizeById(lu),
          };
        } else {
          const normTags = types.tags.reduce((s, t) => ({ ...s, [t.name]: true }), {});
          const lu = appointmentResp
            .filter((a) => a.lineStatus === 1 && a.responseStatus !== -1 && a.deleted !== 1)
            .map((a) => ({
              id: a.lineID,
              name: a.name,
              phone: a.phone,
              status: 1,
              tags: [...a.customTags, ...a.tags],
            }))
            .filter((u) => {
              let t = false;

              if (types.tags.length > 0) {
                u.tags.forEach((tag) => {
                  if (normTags[tag]) {
                    t = true;
                  }
                });
                return t;
              }
              return true;
            });
          users = {
            ...normalizeById(lu),
          };
        }
      }
    }

    const lineUsers = Object.keys(users).map((id) => users[id]);
    dispatch(setLineUsers(lineUsers));
    return lineUsers;
  };

export const fetchTagListInClient = () => async (dispatch, getState) => {
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const { selectedIds } = getState().clients;
  const promises = selectedIds.map((id) =>
    demoMiddleWare(
      clients,
      {
        clientId: id,
      },
      fetchTagListInClientApi
    )
  );
  Promise.all(promises).then((tagsArray) => {
    tagsArray.forEach((t, i) => {
      const clientID = selectedIds[i];
      const tagsByID = t.reduce((sum, tag) => {
        const temp = sum;
        temp[tag.id] = tag;
        return temp;
      }, {});
      const defaultTags = t.filter(({ type }) => type === 'default');
      const customTags = t.filter(({ type }) => type === 'custom');
      const tags = {
        customTags,
        defaultTags,
        tagsByID,
      };
      dispatch(setPatientTags({ clientId: clientID, tags }));
    });
  });
};

export const uploadImage =
  ({ clientId, data }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    const res = await demoMiddleWare(
      clients,
      {
        clientId,
        data,
      },
      uploadImageApi
    );
    return res.url;
  };

export const fetchPnpConsumption =
  ({ startDate, endDate }) =>
  async (dispatch, getState) => {
    console.log('startDate = ', startDate);
    console.log('endDate = ', endDate);
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    if (isAuthRoleBrand(getState().auth.user)) {
      return {
        display: false,
      };
    }
    if (
      clients.length === 1 &&
      !(clients[0].features || {}).enablePNPNotify &&
      !(clients[0].features || {}).enableSMSNotify
    ) {
      return {
        display: false,
      };
    }
    const clientIdsString = clients.map(({ id }) => id);
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    const res = await demoMiddleWare(
      clients,
      {
        clientId: clientIdsString,
        startTime,
        endTime,
      },
      fetchPnpConsumptionApi
    );

    return {
      ...res,
      display: true,
    };
  };

export const fetchPnpAppointmentDeliveryList =
  ({ startDate, endDate }) =>
  async (dispatch, getState) => {
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
    if (isAuthRoleBrand(getState().auth.user)) {
      return {
        display: false,
        data: [],
      };
    }
    const clientIdsString = clients.map(({ id }) => id);
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    const res = await demoMiddleWare(
      clients,
      {
        clientId: clientIdsString,
        startTime,
        endTime,
      },
      fetchPnpAppointmentDeliverySuccessListApi
    );

    return {
      display: true,
      data: res,
    };
  };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_MESSAGE_LIST: {
      const s = state.messageListByClientID;
      return {
        ...state,
        messageListByClientID: {
          ...s,
          ...action.payload,
        },
      };
    }
    case SET_MESSAGE: {
      const s = state.messageList;
      const m = s.concat(action.payload);
      return {
        ...state,
        messages: m,
      };
    }
    case SET_LINE_USERS: {
      return {
        ...state,
        selectedUsers: action.payload,
      };
    }
    case CLEAR_LINE_USERS: {
      return {
        ...state,
        selectedUsers: [],
      };
    }
    default:
      return state;
  }
};
