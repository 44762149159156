import { getToken, isOpenApiVendor, MARKETING_URL, ENV_STAGE } from 'helpers';
import { responseHandler } from '../responseHandler';
import { appointmentSerializer, notificationSerializer } from './appointmentSerializer';
import { t } from 'i18n/config';

export const fetchAppointmentsApi = ({ clientId, startTime, endTime, sendingStatus }) =>
  fetch(`${MARKETING_URL}/v1/appointments/clients/${clientId}?startTime=${startTime}&endTime=${endTime}`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .then(appointmentSerializer(sendingStatus));

export const sendAppointmentNotify = ({ clientID, appointments, deliveryTime, contentFormat }) => {
  const url = `${MARKETING_URL}/v2/clients/${clientID}/appointments/common/notify`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: appointments,
      deliveryTime,
      contentFormat,
    }),
  })
    .then(responseHandler)
    .catch(() => {
      throw new Error(t('sendFailedTryAgain'));
    });
};

export const cancelNotification = ({ id, clientID, taskID, appointmentID }) => {
  const url = isOpenApiVendor()
    ? `${MARKETING_URL}/v2/clients/${clientID}/appointment/${appointmentID}/notification/${id}`
    : `${MARKETING_URL}/v2/clients/${clientID}/appointment/aomori:appointment:${appointmentID}/notification/${taskID}`;

  return fetch(url, {
    method: 'DELETE',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .catch(() => {
      throw new Error(t('sendFailedTryAgain'));
    });
};

export const fetchUnsendNotificationList = ({ clientID, id }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientID}/appointments/${id}/message-history?deliveryStatus=unsend`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .then(notificationSerializer);

export const fetchNotificationList = ({ clientID, id }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientID}/appointments/${id}/message-history`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .then(notificationSerializer);
