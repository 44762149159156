import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tag from 'components/Dashboard_v2/Tag';
import { CircularProgress } from '@material-ui/core';
import TagSelectorPopover from '../components/TagSelectorPopover';
import { useSelector } from 'react-redux';
import useAlert from 'hooks/useAlert';
import { updateMember } from 'lib/memberService';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  display: flex;
  white-space: nowrap;
  width: 100%;
  min-height: 30px;
`;

const MoreTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: 42px;
  height: 30px;
  border: 1px solid ${colors.SYSTEM_INFO_400};
  border-radius: 100px;
  margin-left: 4px;
  cursor: pointer;
  i {
    line-height: 1;
    font-size: 18px;
    color: ${colors.SYSTEM_INFO_400};
  }
`;

const TagCell = ({
  row = { index: 1, original: { id: 1 } },
  value: tags = [],
  updateMyData,
  children,
  ellipsis = true,
}) => {
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { index, original } = row;
  const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const updateMemberTag = useCallback(
    async (selectedTags) => {
      setIsLoading(true);
      try {
        await updateMember({ clientId, memberId: original.id, member: { tags: selectedTags } });
        const relatedPatientTrackings = selectedTags.reduce((arr, tag) => {
          if (tag.patientTrackings) {
            arr.push(...tag.patientTrackings);
          }
          return arr;
        }, []);
        if (relatedPatientTrackings.length) {
          const patientTrackings = [...original.patientTrackings];
          const existingPatientTrackingIds = patientTrackings.map((tracking) => tracking.id);

          relatedPatientTrackings.forEach((tracking) => {
            if (!existingPatientTrackingIds.includes(tracking.id)) {
              tracking.runAt = new Date().toISOString();
              patientTrackings.push(tracking);
            }
          });

          updateMyData(index, 'patientTrackings', patientTrackings);
        }
        setAlert({ type: 'success', title: t('successfullyUpdatedMemberTag') });
        updateMyData(index, 'tags', selectedTags);
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('tagUpdateFailed') });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    },
    [clientId, original, updateMyData, index, setAlert]
  );

  return (
    <TagSelectorPopover memberTags={tags || []} isLoading={isLoading} onClose={updateMemberTag}>
      <Wrapper>
        {isLoading ? (
          <CircularProgress style={{ color: colors.SYSTEM_INFO_400 }} size={30} />
        ) : ellipsis ? (
          <React.Fragment>
            {tags && tags[0] && <Tag name={tags[0].name} />}
            {tags && tags[1] && (
              <MoreTag>
                <i className="ri-more-fill" />
              </MoreTag>
            )}
          </React.Fragment>
        ) : (
          children
        )}
      </Wrapper>
    </TagSelectorPopover>
  );
};

const propTypes = {
  value: PropTypes.array,
};

TagCell.propTypes = propTypes;

export default memo(TagCell);
