import ActionCell from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/userNotificationTable/Columns/Action/ActionCell';
import EstimatedDeliveryTimeStampCell from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/userNotificationTable/Columns/EstimatedDeliveryTimeStamp/EstimatedDeliveryTimeStampCell';
import EventTimeCell from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/userNotificationTable/Columns/EventTime/EventTimeCell';
import StatusCell from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/userNotificationTable/Columns/Status/StatusCell';
import {
  RenderCheckboxHeader,
  RenderCheckboxCell,
  RenderLoadingCell,
} from 'components/Table/Columns//IndeterminateCheckbox';
import theme from 'theme';
import { t } from 'i18n/config';

export const columns = [
  {
    id: 'selection',
    Header: RenderCheckboxHeader,
    Cell: RenderCheckboxCell,
    Loading: RenderLoadingCell,
    maxWidth: 50,
  },
  {
    Header: t('patientName'),
    accessor: 'name',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('mobileNumber'),
    accessor: 'phone',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('lastDentalCleaningVisitDate'),
    accessor: 'eventTime',
    Cell: EventTimeCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('sendTime'),
    accessor: 'estimatedDeliveryTimestamp',
    Cell: EstimatedDeliveryTimeStampCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('sendStatus'),
    accessor: 'status',
    Cell: StatusCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: ' ',
    accessor: '',
    Cell: ActionCell,
    minWidth: 50,
    maxWidth: 50,
  },
];

export const sentColumns = [
  {
    Header: t('patientName'),
    accessor: 'name',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('mobileNumber'),
    accessor: 'phone',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('lastDentalCleaningVisitDate'),
    accessor: 'eventTime',
    Cell: EventTimeCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('sendTime'),
    accessor: 'estimatedDeliveryTimestamp',
    Cell: EstimatedDeliveryTimeStampCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('sendStatus'),
    accessor: 'status',
    Cell: StatusCell,
    filterable: false,
    maxWidth: 120,
  },
];

export const TabMap = {
  0: {
    name: t('scheduling'),
    query: 'pending',
    columns,
  },
  1: {
    name: t('sent'),
    query: 'done',
    columns: sentColumns,
  },
};

export const TabTitles = Object.keys(TabMap).map((k) => TabMap[k].name);

export const headerColumns = (k) => () =>
  [
    {
      Header: t('uploadList'),
      columns: TabMap[k].columns,
    },
  ];

export const tableStyles = {
  rowHeight: '100px',
  rowHover: theme.list.hover,
};
