import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { getIcons, getName } from './status';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

export function LoadingCell() {
  return <Box />;
}

function StatusCell({ value: initialValue }) {
  return (
    <Tooltip title={getName(initialValue)} aria-label={`${initialValue}`}>
      {getIcons({ status: initialValue })}
    </Tooltip>
  );
}

StatusCell.propTypes = {
  value: PropTypes.string,
};

export default StatusCell;
