import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import connectData from 'components/HOCs/connectData';
import { isLogin } from '../../helpers';
import { doneRefreshPage } from '../../reducers/customers';
import './Chat.scss';

const mapStateToProps = (state) => ({
  isRefresh: state.ui.isRefresh,
});

const mapDispatchToProps = {
  doneRefreshPage,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Report extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    fetchReportCSV: PropTypes.func,
    fetchSurveyCSV: PropTypes.func,
    fetchFeedbackCSV: PropTypes.func,
    fetchReviewReportXlsx: PropTypes.func,
    isRefresh: PropTypes.bool,
    doneRefreshPage: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      selectedId: this.isMultipleSelectedStores() ? undefined : this.props.clients.selectedIds[0],
    };
  }
  componentDidMount() {
    if (!isLogin()) {
      this.props.history.push('/login');
    }
  }

  isMultipleSelectedStores = () => this.props.clients.selectedIds.length > 1;

  renderIframe = () => {
    const { selectedId } = this.state;
    if (selectedId) {
      const client = this.props.clients.byId[selectedId];
      return (
        <iframe
          key={`${client.id}`}
          style={{
            width: '100%',
            height: `${document.body.scrollHeight - (this.isMultipleSelectedStores() ? 120 : 100)}px`,
            background: 'white',
          }}
          title="iframe"
          scrolling="no"
          src={`https://${client.host}/chat`}
          frameBorder="0"
        />
      );
    }
    return null;
  };
  renderChat = () => (
    <div className="chatPage">
      {this.isMultipleSelectedStores() && (
        <div className="title">
          {this.props.clients.selectedIds.map((id) => {
            const client = this.props.clients.byId[id];
            return (
              <button
                className={`clientButton ${id === this.state.selectedId ? 'selected' : ''}`}
                key={id}
                onClick={() => {
                  this.setState({
                    selectedId: id,
                  });
                }}
              >
                {client.companyName}
              </button>
            );
          })}
        </div>
      )}
      {this.renderIframe()}
    </div>
  );

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Row>
              <Col sm="12">{this.renderChat()}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
