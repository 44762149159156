import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Reminder, Note, PencilIcon, ReminderSection } from './styledComponents';
import { useSelector } from 'react-redux';
import { updateReminderApi } from 'lib/appointmentService';
import { generateContent } from 'lib/openaiService';
import EditReminderPopover from './EditReminderPopover';
import LoadingCell from './LoadingCell';
import { t } from 'i18n/config';

export const Loading = LoadingCell;

const TEXT_MAX_LENGTH = 500;
const emptyNote = t('appointmentNotesHere');
const emptyReminder = t('patientReminderHere');

const defaultValue = { note: '', reminder: '' };
function NoteReminderCell({
  value: initialValue = defaultValue,
  row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  state: { clientID },
  openSnackbar,
}) {
  const { index } = row;
  const { reminder, note } = initialValue;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editedReminder, setEditedReminder] = useState(reminder);
  const {
    features: { openAI: openAIEnabled },
  } = useSelector((state) => state.clients.byId[state.clients.selectedIds[0]]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    updateRemoteReminder();
    setAnchorEl(null);
  };

  const updateRemoteReminder = useCallback(async () => {
    const limitedReminderText = editedReminder.substring(0, TEXT_MAX_LENGTH);
    await updateReminderApi({ id: row.original.id, reminder: limitedReminderText })
      .then((res) => {
        console.log('res = ', res);
        updateMyData(index, id, { ...row.values[id], reminder: limitedReminderText });
      })
      .catch((err) => {
        console.log('err = ', err);
        openSnackbar({ message: t('saveFailedTryAgain'), status: 'error' });
      });
  }, [id, index, row.values, updateMyData, editedReminder, row.original.id, openSnackbar]);

  const handleOpenAI = async ({ role, content, args, callback }) => {
    await generateContent({
      clientID,
      role,
      content,
      args,
      callback: callback,
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Note isEmpty={!note}>{note || emptyNote}</Note>
        <ReminderSection onClick={handleClick}>
          <PencilIcon className="ri-pencil-line" />
          <Reminder isEmpty={!reminder}>{reminder || emptyReminder}</Reminder>
        </ReminderSection>
      </Container>
      {Boolean(anchorEl) && (
        <EditReminderPopover
          key={`${row.original.id}-editReminder`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleClose={handleClose}
          editedReminder={editedReminder}
          setEditedReminder={setEditedReminder}
          handleOpenAI={openAIEnabled ? handleOpenAI : undefined}
          openSnackbar={openSnackbar}
          maxLength={TEXT_MAX_LENGTH}
        />
      )}
    </React.Fragment>
  );
}

NoteReminderCell.propTypes = {
  value: PropTypes.shape({
    note: PropTypes.string,
    reminder: PropTypes.string,
  }),
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  column: PropTypes.shape({
    id: PropTypes.string,
  }),
  updateMyData: PropTypes.func,
  openSnackbar: PropTypes.func,
};

export default NoteReminderCell;
