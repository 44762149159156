import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 0px 12px;
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.textSecondary};
`;

function FilterWrapper({ select, title }) {
  return (
    <Container>
      <Title>{title}</Title>
      {select}
    </Container>
  );
}

FilterWrapper.propTypes = {
  select: PropTypes.node,
  title: PropTypes.string,
};

export default FilterWrapper;
