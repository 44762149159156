import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t } from 'i18n/config';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.28;
  color: ${(props) => (props.status === 'inactive' ? props.theme.colors.SHADES_300 : props.theme.colors.SHADES_800)};
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 20px;
  padding: 0 8px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.SYSTEM_ERROR_500};
  background-color: ${(props) => props.theme.colors.SHADES_000};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.24px;
  color: ${(props) => props.theme.colors.SYSTEM_ERROR_500};
`;

export function LoadingCell() {
  return <Box />;
}

function NameCell({ row, value }) {
  const { original } = row;
  return (
    <Container status={original.status}>
      {value}
      <br />
      <TagContainer>{t('regularReminder')}</TagContainer>
    </Container>
  );
}

NameCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      delaySeconds: PropTypes.number,
      status: PropTypes.string,
    }),
  }),
};

export default NameCell;
