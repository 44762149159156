import React from 'react';
import ClinicDeleted from 'components/icons/icons-ic-delete-clinic.svg';
import PatientDeleted from 'components/icons/icons-ic-delete-patient.svg';
import { t } from 'i18n/config';

export const Icons = {
  0: <div />,
  1: <img src={PatientDeleted} alt="cancel" />,
  2: <img src={ClinicDeleted} alt="clinicCancel" />,
};

export const Names = {
  0: '',
  1: t('patientDeleted'),
  2: t('clinicDeleted'),
};

export function getName(status) {
  return Names[status];
}
