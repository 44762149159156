import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  root: {
    width: 160,
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
    color: (props) => (props.selected ? theme.colors.primary : theme.colors.default),
    '@media screen and (max-width:647px)': {
      width: '50%',
    },
  },
}));
function CustomTab(props) {
  const classes = useStyles(props);
  return <Tab className={classes.root} {...props} />;
}

CustomTab.propTypes = {
  selected: PropTypes.bool,
};
export default CustomTab;

export function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
