import matchSorter from 'match-sorter';
import { t } from 'i18n/config';

export const progressValues = {
  InProgress: '0',
  Done: '1',
};

const titleMap = {
  [progressValues.InProgress]: t('pending'),
  [progressValues.Done]: t('processingComplete'),
};

export const getTitle = (status) => titleMap[status];

export const filterOptions = {
  progress: [progressValues.InProgress, progressValues.Done],
};

export function progressFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id].status] });
}
