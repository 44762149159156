import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: relative;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colors.SHADES_300};
  background: #fff;
`;

const idTypeMap = {
  twID: t('idOrResidentNumber'),
  hisID: t('medicalRecordNumber'),
  memberID: t('memberNumber'),
  pdID: t('passportNumber'),
};

export default function MergeHistory({ list }) {
  return (
    <Wrapper>
      <Title>{t('mergeMember.title')}</Title>
      <ListWrapper>
        {list.map((item) => {
          return (
            <ListItem key={item.id}>
              <div>{format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss')}</div>
              <div>{t('mergeMember.log', { idType: idTypeMap[item.oldIDType], idNum: item.oldIDNum })}</div>
            </ListItem>
          );
        })}
      </ListWrapper>
    </Wrapper>
  );
}
