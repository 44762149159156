import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px 4px 8px;
  height: 30px;
  border: 1px solid ${({ settings }) => settings.borderColor || colors.SYSTEM_INFO_400};
  border-radius: ${({ settings }) => settings.borderRadius || '100px'};
  line-height: 1;
  color: ${({ settings }) => settings.color || colors.SYSTEM_INFO_400};
  white-space: nowrap;
  ${({ isSelected, settings }) =>
    isSelected &&
    ` ${settings.selectedBorderColor && `border-color: ${settings.selectedBorderColor}`};
      background-color: ${settings.selectedBackgroundColor || colors.SYSTEM_INFO_100};
    `};
  ${({ onClick }) => onClick && `cursor: pointer;`}
  i {
    margin-right: 8px;
    font-size: 14px;
    line-height: 1;
    ${({ settings }) => settings.iconColor && `color: ${settings.iconColor}`};
  }
  i.ri-close-line {
    margin-left: 8px;
    margin-right: -8px;
    color: ${({ settings }) => settings.color || colors.SYSTEM_INFO_400};
    cursor: pointer;
  }
`;

const StatusDot = styled.div`
  margin-top: 1px;
  margin-right: 12px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;

const defaultSettings = {
  icon: 'ri-price-tag-3-fill',
  color: null,
  dotColor: null,
  iconColor: null,
  borderColor: null,
  borderRadius: null,
  selectedBorderColor: null,
  selectedBackgroundColor: null,
};

const Tag = ({ name, onClick, onClickRemove, isSelected, settings = {} }) => {
  const tagSettings = { ...defaultSettings, ...settings };
  return (
    <StyledTag onClick={onClick} isSelected={isSelected} settings={tagSettings}>
      {tagSettings.dotColor && <StatusDot color={tagSettings.dotColor} />}
      {tagSettings.icon && <i className={tagSettings.icon} />}
      {name}
      {onClickRemove && <i className="ri-close-line" onClick={onClickRemove} />}
    </StyledTag>
  );
};

const propTypes = {
  name: PropTypes.string.isRequired,
};

Tag.propTypes = propTypes;

export default memo(Tag);
