import React, { memo } from 'react';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const StyledLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  color: ${colors.SHADES_900};
  user-select: none;
  min-height: 24px;
  cursor: pointer;
  .ri-checkbox-blank-line,
  .ri-checkbox-fill {
    top: -5px;
    position: absolute;
    margin-right: 8px;
    font-size: 24px;
    &.ri-checkbox-blank-line {
      color: ${colors.SHADES_400};
    }
    &.ri-checkbox-fill {
      color: ${colors.PRIMARY_300};
    }
  }
  input[type='checkbox'] {
    display: none;
    &:checked ~ .ri-checkbox-blank-line {
      display: none;
    }
    &:not(:checked) ~ .ri-checkbox-fill {
      display: none;
    }
  }
  > div {
    margin-left: 28px;
    font-size: ${({ fontSize }) => `${fontSize}px`};
  }

  &:not(:last-child) {
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  }
`;

const Checkbox = ({ onChange, checked, disabled, children, marginBottom = 24, fontSize = 16 }) => (
  <StyledLabel marginBottom={marginBottom} fontSize={fontSize}>
    <span>
      <input onChange={(e) => onChange(e.target.checked)} checked={checked} disabled={disabled} type="checkbox" />
      <i className="ri-checkbox-blank-line" />
      <i className="ri-checkbox-fill" />
    </span>
    {children}
  </StyledLabel>
);

export default memo(Checkbox);
