import React, { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Dialog from 'components/Dashboard_v2/Dialog';
import Select from 'components/Dashboard_v2/Select';
import Text from 'components/Dashboard_v2/Text';
import DatePicker from 'components/Dashboard_v2/DatePicker/Input';
import Switch from 'components/Dashboard_v2/Switch';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const ModalSwitchWrapper = styled.div`
  margin-top: 24px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 24px;
  > div:first-child {
    margin-right: 16px;
  }
  > span {
    margin: 0 4px 8px 8px;
  }
`;

const Hint = styled.div`
  display: flex;
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${colors.SHADES_100};
  i {
    color: ${colors.SHADES_600};
    margin-top: -3px;
    margin-right: 12px;
  }
`;

const TIME_OPTIONS = {
  // HOURS: 00, 01 ... 23
  HOURS: Array.from(Array(24)).map((n, i) => ({ id: i, name: `${i}`.padStart(2, '0'), value: i })),
  // MINUTES: 00, 05, ... 55
  MINUTES: Array.from(Array(12)).map((n, i) => ({ id: i, name: `${i * 5}`.padStart(2, '0'), value: i * 5 })),
};

const getHourOption = (date) =>
  date
    ? TIME_OPTIONS.HOURS.find((hour) => hour.name === moment(date).add(5, 'minute').format('HH'))
    : TIME_OPTIONS.HOURS[0];

const getMinuteOption = (date) => {
  const option =
    date &&
    TIME_OPTIONS.MINUTES.find(
      (minute) => minute.name === `${parseInt(moment(date).add(5, 'minute').format('mm') / 5) * 5}`.padStart(2, '0')
    );
  return option || TIME_OPTIONS.MINUTES[0];
};

const getSettings = (data) => {
  const startTime = data.startTime || moment(new Date());
  const endTime = data.endTime || moment(new Date()).add(1, 'days');

  return {
    recyclable: data.recyclable,
    startDate: moment(startTime).format('YYYY/MM/DD'),
    startHour: getHourOption(data.startTime),
    startMinute: getMinuteOption(data.startTime),
    endDate: moment(endTime).format('YYYY/MM/DD'),
    endHour: getHourOption(data.endTime),
    endMinute: getMinuteOption(data.endTime),
  };
};

const DateRangeModal = ({ data, onConfirm, onClose }) => {
  const [date, setDate] = useState(getSettings(data));

  const isDisabled = useMemo(() => {
    const { startDate, startHour, startMinute, endDate, endHour, endMinute } = date;
    return moment(`${startDate} ${startHour.value}:${startMinute.value}`, 'YYYY/MM/DD HH:mm').isAfter(
      moment(`${endDate} ${endHour.value}:${endMinute.value}`, 'YYYY/MM/DD HH:mm')
    );
  }, [date]);

  const handleChange = (key, value) => {
    setDate((prev) => ({ ...prev, [key]: value }));
  };

  const handleConfirm = () => {
    const { recyclable, startDate, startHour, startMinute, endDate, endHour, endMinute } = date;
    onConfirm({
      recyclable: recyclable,
      startTime: moment(`${startDate} ${startHour.value}:${startMinute.value}`, 'YYYY/MM/DD HH:mm').toDate(),
      endTime: moment(`${endDate} ${endHour.value}:${endMinute.value}`, 'YYYY/MM/DD HH:mm').toDate(),
    });
    onClose();
  };

  return (
    <Dialog
      open={true}
      title={t('activityDateTimeSetting')}
      description={
        <div>
          <Text font="Body/14px_Regular" color="SHADES_400">
            {t('notificationAutoSend')}
          </Text>
          {/* Start Time */}
          <TimeWrapper>
            <DatePicker
              label={t('activityStartDate')}
              value={date.startDate}
              onChange={(date) => handleChange('startDate', date)}
              yearRange={{ startYear: new Date().getFullYear(), endYear: new Date().getFullYear() + 15 }}
              minDate={new Date()}
            />
            <Select
              label={t('activityTime')}
              options={TIME_OPTIONS.HOURS}
              selectedOption={date.startHour}
              setSelectedOption={(option) => handleChange('startHour', option)}
              width={80}
            />
            <span>:</span>
            <Select
              options={TIME_OPTIONS.MINUTES}
              selectedOption={date.startMinute}
              setSelectedOption={(option) => handleChange('startMinute', option)}
              width={80}
            />
          </TimeWrapper>
          {/* End Time */}
          <TimeWrapper>
            <DatePicker
              label={t('activityEndDate')}
              value={date.endDate}
              onChange={(date) => handleChange('endDate', date)}
              yearRange={{ startYear: new Date().getFullYear(), endYear: new Date().getFullYear() + 15 }}
              minDate={date.startDate ? moment(date.startDate, ['YYYY/MM/DD']).add(1, 'days').toDate() : new Date()}
            />
            <Select
              label={t('activityTime')}
              options={TIME_OPTIONS.HOURS}
              selectedOption={date.endHour}
              setSelectedOption={(option) => handleChange('endHour', option)}
              width={80}
            />
            <span>:</span>
            <Select
              options={TIME_OPTIONS.MINUTES}
              selectedOption={date.endMinute}
              setSelectedOption={(option) => handleChange('endMinute', option)}
              width={80}
            />
          </TimeWrapper>
          <Hint>
            <i className="ri-information-line" />
            <Text font="Body/12px_Regular" color="SHADES_600">
              {t('noMessageAfterExpiration')}
            </Text>
          </Hint>
          {/* Recyclable */}
          <ModalSwitchWrapper>
            <div>
              <Text font="Heading/Medium/Medium" marginBottom={12}>
                {t('cycleActivity')}
              </Text>
              <Switch checked={date.recyclable} onChange={(e) => handleChange('recyclable', e.target.checked)} />
            </div>
            <Text font="Body/14px_Regular" color="SHADES_500">
              {t('cycleActivityDescription')}
            </Text>
          </ModalSwitchWrapper>
        </div>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={isDisabled}
    />
  );
};

const propTypes = {
  data: PropTypes.shape({
    timeUnit: PropTypes.string,
    timeValue: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    delaySeconds: PropTypes.number,
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

DateRangeModal.propTypes = propTypes;

export default memo(DateRangeModal);
