import React from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'components/MaterialDesign/Buttons';
import { manualVerifyUser } from 'lib/lineService';
import { t } from 'i18n/config';

function ManualVerifyButton({ phone, passcode, lineID, expirationTime, clientID }) {
  return (
    <Confirm
      onClick={async () => {
        try {
          if (expirationTime < new Date().getTime() / 1000) {
            alert(t('verificationCodeExpired'));
            return;
          }
          await manualVerifyUser({
            clientID,
            lineID,
            passcode,
            phone,
          });
          alert(t('directBindSuccess'));
        } catch (error) {
          alert(error.message);
        }
      }}
    >
      press
    </Confirm>
  );
}

ManualVerifyButton.propTypes = {
  phone: PropTypes.string,
  clientID: PropTypes.string,
  passcode: PropTypes.string,
  expirationTime: PropTypes.number,
  lineID: PropTypes.string,
};

export default ManualVerifyButton;
