import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ActionComponent from 'components/WesternAppointments/table/Columns/Action/ActionComponent';
import UnsendComponent from 'components/WesternAppointments/table/Columns/Action/UnSend';
import LoadingCell from './LoadingCell';

const defaultValue = {};
function ActionCell({
  value: initialValue = defaultValue,
  row,
  removeRow,
  openSnackbar,
  initialState: { clientID },
  isPnpEnabled,
}) {
  const { original: rawOriginal, index } = row;
  const [original, setOriginal] = useState(rawOriginal);
  const { type, disabled } = initialValue;
  const handleSuccess = (msg) => {
    if (removeRow) {
      removeRow(index);
    }
    openSnackbar({ message: msg, status: 'success' });
  };

  const handleFail = (msg) => {
    openSnackbar({ message: msg, status: 'error', autoHideDuration: 5000 });
  };

  const increaseUnsend = useCallback(() => {
    setOriginal((o) => ({ ...o, unsendCount: o.unsendCount + 1 }));
  }, []);

  const decreaseUnsend = useCallback(() => {
    setOriginal((o) => ({ ...o, unsendCount: o.unsendCount - 1 }));
  }, []);

  return (
    <Fragment>
      <ActionComponent
        clientID={clientID}
        type={type}
        disabled={disabled}
        appointments={[original]}
        onSuccess={handleSuccess}
        increaseUnsend={increaseUnsend}
        onFail={handleFail}
        isPnpEnabled={isPnpEnabled}
      />
      <UnsendComponent clientID={clientID} appointment={original} onCancelNotification={decreaseUnsend} />
    </Fragment>
  );
}

ActionCell.propTypes = {
  initialState: PropTypes.shape({
    clientID: PropTypes.string,
  }),
  value: PropTypes.shape({
    time: PropTypes.string,
    status: PropTypes.string,
  }),
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unsendCount: PropTypes.number,
    }),
  }),
  updateMyData: PropTypes.func,
  removeRow: PropTypes.func,
  openSnackbar: PropTypes.func,
};

export default ActionCell;

export const Loading = LoadingCell;
