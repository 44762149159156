const reviewSettingReducer = (state, action) => {
  switch (action.type) {
    case 'change_emailInput': {
      return {
        ...state,
        feedbackEmail: action.input,
      };
    }
    case 'change_phoneInput': {
      return {
        ...state,
        cellPhoneNumber: action.input,
      };
    }
    case 'change_delayTimeInput': {
      return {
        ...state,
        uploadDelayTime: action.input,
      };
    }
    case 'change_daysInput': {
      return {
        ...state,
        customerExpireDay: action.input,
      };
    }
    case 'change_smsContentInput': {
      return {
        ...state,
        smsContent: action.content,
      };
    }
    case 'change_ageRange': {
      return {
        ...state,
        customerAgeRange: { max: action.max, min: action.min },
      };
    }
    default: {
      throw Error('Unknown action:' + action.type);
    }
  }
};

export default reviewSettingReducer;
