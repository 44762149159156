import React from 'react';
import MUIDataTable from 'mui-datatables';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
  },
}));
const Table = ({ onSelectionChange, columns, data, isLoading, selectEnabled, extraOptions, title = '' }) => {
  const classes = useStyles();

  const options = {
    textLabels: {
      body: {
        noMatch: '',
      },
    },
    filter: true,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: selectEnabled ? 'multiple' : 'none',
    selectableRowsOnClick: true,
    filterType: 'textField',
    tableBodyHeight: '700px',
    rowsPerPage: 10,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      onSelectionChange(rowsSelected.map((index) => data[index]));
    },
    rowsSelected: data
      .map((d, i) => {
        if (d.checked) {
          return i;
        }
        return undefined;
      })
      .filter((u) => u != null),
    selectToolbarPlacement: 'above',
    selectableRowsHeader: true,
    ...extraOptions,
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div style={{ position: 'absolute' }}>
          <CircularProgress />
        </div>
      )}
      <MUIDataTable
        className={classes.content}
        title={title}
        data={data}
        columns={columns}
        options={options}
        components={{
          TableToolbarSelect: () => null,
        }}
      />
    </React.Fragment>
  );
};

Table.propTypes = {
  isLoading: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      phone: PropTypes.string,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  selectEnabled: PropTypes.bool,
  extraOptions: PropTypes.shape(PropTypes.any),
  title: PropTypes.string,
};

export default Table;
