import React from 'react';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import { t, changeLanguage, currentLanguage } from 'i18n/config';

const StyledIcon = styled.i`
  font-size: 24px;
  margin: 0 16px 0 4px;
  cursor: pointer;
  color: ${dashboard_v2.colors.SHADES_500};
  &:hover {
    color: ${dashboard_v2.colors.PRIMARY_400};
  }
`;

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${dashboard_v2.colors.SHADES_000};
  border-radius: 8px;
`;

const LanguageLabel = styled.div`
  cursor: pointer;
  padding: 12px 24px;
  text-align: center;
  font-size: 16px;
  &:hover {
    color: ${dashboard_v2.colors.PRIMARY_400};
  }
  ${({ active }) => {
    if (active) {
      return `
        color: ${dashboard_v2.colors.SHADES_000} !important;
        background: ${dashboard_v2.colors.PRIMARY_400} !important;
      `;
    }
  }}
`;

export default function LanguageSwitchButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <StyledIcon className="ri-global-line" onClick={handleOpen} />
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
            marginLeft: '-16px',
          }}
          disableScrollLock
        >
          <PopoverWrapper>
            <LanguageLabel active={currentLanguage === 'tw'} onClick={() => changeLanguage('tw')}>
              {t('language_tw')}
            </LanguageLabel>
            <LanguageLabel active={currentLanguage === 'jp'} onClick={() => changeLanguage('jp')}>
              {t('language_jp')}
            </LanguageLabel>
            {/* <LanguageLabel active={currentLanguage === 'en'} onClick={() => changeLanguage('en')}>
              {t('language_en')}
            </LanguageLabel> */}
          </PopoverWrapper>
        </Popover>
      )}
    </React.Fragment>
  );
}
