import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n/config';

const propTypes = {
  isDelivered: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  deliveryTime: PropTypes.string,
  number: PropTypes.number,
};

const defaultProps = {};

const MessageListCard = ({ isDelivered, title, type, deliveryTime = 0, number }) => {
  return (
    <div className="card-container">
      <div className="m-card status-label">
        <div className="text">{isDelivered === true ? t('sent') : t('notSent')}</div>
      </div>
      <div className="m-card info">
        <div className="title">{title}</div>
        <div className="type">{type}</div>
        <div className="time">
          {t('sendTime')} {new Date(deliveryTime).toLocaleString()}
        </div>
      </div>
      <div className="m-card delivery-info">
        <div className="number">{number}</div>
        <div className="label">{t('numberOfRecipients')}</div>
      </div>
    </div>
  );
};

MessageListCard.propTypes = propTypes;
MessageListCard.defaultProps = defaultProps;

export default MessageListCard;
