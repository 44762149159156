import React from 'react';
import NotResponding from 'components/icons/尚未回覆.svg';
import Cancel from 'components/icons/病患取消.svg';
import Confirm from 'components/icons/確定要去.svg';
import PatientDeleted from 'components/icons/icons-ic-delete-patient.svg';
import ClinicDeleted from 'components/icons/icons-ic-delete-clinic.svg';
import { t } from 'i18n/config';

export const Icons = {
  '': <img src={NotResponding} alt="notresponding" />,
  0: <img src={NotResponding} alt="notresponding" />,
  1: <img src={Confirm} alt="confirm" />,
  2: <img src={Cancel} alt="cancel" />,
  3: <img src={PatientDeleted} alt="patientDeleted" />,
  4: <img src={ClinicDeleted} alt="clinicDeleted" />,
};

export const Names = {
  '': t('notReplied'),
  0: t('notReplied'),
  1: t('confirmedGoing'),
  2: t('patientCancelled'),
  3: t('patientDeleted'),
  4: t('clinicDeleted'),
};

export function getName(status) {
  return Names[status];
}
