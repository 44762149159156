import React, { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { useSelector } from 'react-redux';
import { deleteGroupMessage } from 'lib/messageCenterService';
import useAlert from 'hooks/useAlert';
import Dialog from 'components/Dashboard_v2/Dialog';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  text-align: right;
`;

const Icon = styled.i`
  color: ${colors.SHADES_400};
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
`;

const ActionMenu = styled.div`
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  a,
  div {
    color: ${colors.SHADES_900};
    display: block;
    padding: 12px 24px;
    &:hover {
      background-color: ${colors.SHADES_50};
      text-decoration: none;
    }
  }
`;

function ActionCell({ row, removeRow }) {
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { setAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true);
    handleClose();
  }, [handleClose]);

  const closeDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);

  const handleDelete = useCallback(async () => {
    try {
      await deleteGroupMessage({ clientId, groupMessageId: row.original.id });
      removeRow(row.index);
      setAlert({ type: 'success', title: t('successfullyDeleted') });
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: t('deleteFailed', { error: error || e.message }) });
      console.error(errRes);
    } finally {
      closeDeleteDialog();
    }
  }, [removeRow, setAlert, clientId, row, closeDeleteDialog]);

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <Icon className="ri-more-2-fill" onClick={handleClick} />
      {open && (
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
          <ActionMenu>
            <Link to={`/message_center/group_message/${row.original.id}`}>
              {row.original.status === 'done' ? t('view') : t('edit')}
            </Link>
            {row.original.status !== 'done' && <div onClick={openDeleteDialog}>{t('delete')}</div>}
          </ActionMenu>
        </Popover>
      )}
      <Dialog
        open={deleteDialogOpen}
        title={t('areYouSureToDelete')}
        confirmText={t('delete')}
        cancelText={t('cancel')}
        onConfirm={handleDelete}
        onCancel={closeDeleteDialog}
        confirmColorType="error"
      />
    </Wrapper>
  );
}

export default memo(ActionCell);
