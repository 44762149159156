import styled from 'styled-components';
import { CircularProgress, Button } from '@material-ui/core';

export const Loading = styled(CircularProgress)`
  margin: 16px auto;
`;

export const Header = styled.div`
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const Container = styled.div`
  height: 330px;
  width: 264px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const HeaderTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const EmptyInfo = styled.div`
  margin: 16px auto;
  color: ${(props) => props.theme.colors.default};
`;

export const NotificationContainer = styled.div`
  margin: 0 8px 16px 8px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.western.colors.grey};
`;

export const UpdateDescription = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 12px;
  line-height: 1.58;
  letter-spacing: 0.3px;
`;

export const ExpectedSendHint = styled.div`
  margin: 16px 16px 16px 8px;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.textPrimary};
`;
export const Content = styled.div`
  margin-top: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const PageRouteButton = styled(Button)`
  &:focus {
    border: 0px;
    outline: none;
  }
`;

export const CancelButton = styled(Button)`
  width: 100%;
  border-top: solid 1px ${(props) => props.theme.western.colors.grey};
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
  &:hover.Mui-disabled {
    background-color: ${(props) => props.customizedbackgroundcolor || props.theme.western.colors.grey};
    opacity: 0.9;
  }
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  border-radius: ${(props) => (props.borderradius ? `${props.borderradius}px` : '0px')};
  color: ${(props) => props.customizedcolor || props.theme.western.colors.primary};
`;
