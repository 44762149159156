import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

// Styles
import 'remixicon/fonts/remixicon.css';

// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
// Temp fix for reactstrap
import './scss/core/_dropdown-menu-right.scss';
import './index.css';
// Import Main styles for this application
import './scss/style.scss';
import App from './App';
import Login from './components/Login';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { ENV_STAGE, SENTRY_DSN } from './helpers/envHelpers';

if (ENV_STAGE === 'production' && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route path="/" name="Home" component={App} />
      </Switch>
    </Router>
  </Provider>
);

// <Route exact path="/login" name="Login Page" component={Login}/>
// <Route exact path="/register" name="Register Page" component={Register}/>
// <Route exact path="/404" name="Page 404" component={Page404}/>
// <Route exact path="/500" name="Page 500" component={Page500}/>
registerServiceWorker();
