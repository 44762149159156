import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';

export default withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    marginBottom: 16,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.28px',
    textAlign: 'center',
    color: theme.colors.primary,
    '@media screen and (max-width:647px)': {
      marginLeft: 8,
    },
    '& .MuiButtonBase-root': {
      width: 'fit-content',
      minWidth: 0,
    },
  },
  indicator: {
    backgroundColor: theme.colors.primary,
  },
})((props) => <Tabs {...props} />);
