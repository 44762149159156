/* eslint no-irregular-whitespace: "warn" */
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import React, { memo } from 'react';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import LinkIcon from 'components/icons/LinkIcon';
import UnlinkIcon from 'components/icons/UnlinkIcon';
import LineMessageActiveIcon from 'components/icons/LineMessageActiveIcon';
import LineMessageBlockedIcon from 'components/icons/LineMessageBlockedIcon';
import LineMessageInactiveIcon from 'components/icons/LineMessageInactiveIcon';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  i {
    font-size: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  div > span {
    display: block;
    margin-left: 90px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  i {
    font-size: 16px;
    color: ${colors.SHADES_000};
  }
`;

const StatusDot = styled.div`
  margin-right: 12px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;

const getHeadData = (accessor) => {
  switch (accessor) {
    case 'name':
      return {
        title: t('name'),
        popoverContent: (
          <ContentWrapper>
            <FlexWrapper>
              <i className="ri-health-book-line" />
              {t('hisName')}
            </FlexWrapper>
            <FlexWrapper>
              <i className="ri-line-fill" />
              {t('lineName')}
            </FlexWrapper>
          </ContentWrapper>
        ),
      };
    case 'lineName':
      return { title: 'LINE', popoverContent: t('lineName') };
    case 'hisName':
      return { title: 'HIS', popoverContent: t('hisName') };
    case 'patientTrackings':
      return {
        title: t('patientTracking'),
        popoverContent: (
          <ContentWrapper>
            <FlexWrapper>
              <StatusDot color={colors.SYSTEM_SUCCESS_500} />
              {t('enabledTracking')}
            </FlexWrapper>
            <FlexWrapper>
              <StatusDot color={colors.SYSTEM_ERROR_500} />
              {t('disabledTracking')}
            </FlexWrapper>
          </ContentWrapper>
        ),
      };
    case 'status':
      return {
        title: t('status'),
        popoverContent: (
          <ContentWrapper>
            <FlexWrapper>
              <StatusDot color={colors.SYSTEM_SUCCESS_500} />
              {t('validMember')}
            </FlexWrapper>
            <div>
              <FlexWrapper>
                <StatusDot color={colors.SECONDARY_500} />
                潛在用戶：1. 加入 LINE 官方帳號好友，但未完成手機綁定者，可以發送訊息。(診所帳號需認證)
              </FlexWrapper>
              <span>2. 未加入 LINE 官方帳號好友，但病歷系統或後台上傳完整資料，目前無法發送訊息。</span>
            </div>
            <FlexWrapper>
              <StatusDot color={colors.SYSTEM_ERROR_500} />
              {t('invalidMember')}
            </FlexWrapper>
            <FlexWrapper>
              <StatusDot color={colors.SYSTEM_ERROR_500} />
              {t('invalidUser')}
            </FlexWrapper>
          </ContentWrapper>
        ),
      };
    case 'lineBinding':
      return {
        title: t('bindingStatus'),
        popoverContent: (
          <ContentWrapper>
            <FlexWrapper>
              <LinkIcon />
              {t('lineBound')}
            </FlexWrapper>
            <FlexWrapper>
              <UnlinkIcon />
              {t('lineUnbound')}
            </FlexWrapper>
          </ContentWrapper>
        ),
      };
    case 'lineMessage':
      return {
        title: t('sendingChannel'),
        popoverContent: (
          <ContentWrapper>
            <FlexWrapper>
              <LineMessageActiveIcon />
              {t('lineSendable')}
            </FlexWrapper>
            <FlexWrapper>
              <LineMessageInactiveIcon />
              {t('lineNotSendable')}
            </FlexWrapper>
            <FlexWrapper>
              <LineMessageBlockedIcon />
              {t('lineBlocked')}
            </FlexWrapper>
          </ContentWrapper>
        ),
      };

    default:
      return {};
  }
};

const InfoHead = ({ accessor }) => {
  const headData = getHeadData(accessor);

  return (
    <Wrapper>
      <InfoPopover popoverContent={headData.popoverContent} style={{ marginRight: 8 }} />
      {headData.title}
    </Wrapper>
  );
};

export default memo(InfoHead);
