import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { parseRuleTimeToRule } from 'components/Message/campaignTable/utils';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.28;
  color: ${(props) => (props.status === 'inactive' ? props.theme.colors.SHADES_300 : props.theme.colors.SHADES_800)};
`;

export function LoadingCell() {
  return <Box />;
}

function SendTimeRuleCell({ row }) {
  const { original } = row;
  const rules = parseRuleTimeToRule(original.delaySeconds);
  return (
    <Container status={original.status}>
      {`${rules.rule}`}
      <br /> {`${rules.value}/${rules.unit}`}{' '}
    </Container>
  );
}

SendTimeRuleCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      delaySeconds: PropTypes.number,
      status: PropTypes.string,
    }),
  }),
};

export default SendTimeRuleCell;
