const UPDATE_REMOTE = 'updateRemoteProgress';
export const SET_ACTION = 'setAction';
const TOGGLE_SCHEDULED = 'toggleScheduled';
const SET_LOADING = 'SET_LOADING';

export const updateRemote = () => ({
  type: UPDATE_REMOTE,
});

export const setAction = ({ type, title, disabled }) => ({
  type: SET_ACTION,
  payload: { type, title, disabled },
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: { loading },
});

export const toggleScheduled = (toggled, scheduledDate, contentFormat) => ({
  type: TOGGLE_SCHEDULED,
  payload: {
    toggled,
    scheduledDate,
    contentFormat,
  },
});

export default (state, action) => {
  switch (action.type) {
    case UPDATE_REMOTE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }
    case TOGGLE_SCHEDULED: {
      const {
        payload: { toggled, scheduledDate, contentFormat },
      } = action;
      return {
        ...state,
        scheduledVisible: toggled,
        scheduledDate,
        contentFormat,
      };
    }
    case SET_ACTION:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};
