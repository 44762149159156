import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, Button, Badge } from 'reactstrap';
import connectData from 'components/HOCs/connectData';
import {
  handleSignInGoogle,
  handleSignOutGoogle,
  getGoogleAccounts,
  setGoogleAccount,
  getGoogleLocations,
  setGoogleLocation,
} from 'reducers/auth';
import { getDataStudioLink } from 'reducers/clients';
import SelectionModal from 'components/Platforms/SelectionModal';
import LocationSelector from 'components/Platforms/google/LocationSelector';
import { SERVER_URL, AUTH_URL } from 'helpers/envHelpers';
import './Platforms.css';
import './Platforms.scss';
import { isLogin } from '../../helpers';
import { t } from 'i18n/config';

const defaultState = {
  isShowLocationSelection: false,
  isShowAccountSelection: false,
  locationModalStateMessage: {
    error: null,
    message: '',
  },
  googleAccounts: [],
};

const mapStateToProps = (state) => ({
  google: state.auth.google,
  user: state.auth.user,
  clients: state.clients,
});

const mapDispatchToProps = {
  handleSignInGoogle,
  handleSignOutGoogle,
  getGoogleAccounts,
  setGoogleAccount,
  getGoogleLocations,
  setGoogleLocation,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Platforms extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    google: PropTypes.shape({
      isLogin: PropTypes.bool,
    }),
    user: PropTypes.shape({
      googleAccountId: PropTypes.string,
      googleLocationId: PropTypes.string,
      googleAccountName: PropTypes.string,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    handleSignInGoogle: PropTypes.func,
    handleSignOutGoogle: PropTypes.func,
    getGoogleAccounts: PropTypes.func,
    setGoogleAccount: PropTypes.func,
    getGoogleLocations: PropTypes.func,
    setGoogleLocation: PropTypes.func,
  };

  state = defaultState;

  componentDidMount() {
    if (!isLogin()) {
      this.props.history.push('/login');
    }
  }

  onSelectGoogleAccount = async () => {
    const accounts = await this.props.getGoogleAccounts();
    this.showAccountSelection(accounts);
  };

  onSelectGoogleLocation = async () => {
    this.showLocationSelection();
  };

  handleDataStudioClick = (id) => async () => {
    const { dataStudioLink } = await getDataStudioLink({ clientId: id });
    if (dataStudioLink == '') {
      alert(t('noReportGenerated'));
      return;
    }
    window.open(dataStudioLink, '_blank');
  };
  googleButtonsMap = {
    signIn: (
      <Button key="signIn" outline color="primary" onClick={this.props.handleSignInGoogle}>
        {t('login')}
      </Button>
    ),
    signOut: (
      <Button key="signOut" outline color="primary" onClick={this.props.handleSignOutGoogle}>
        {t('logoutAccount')}
      </Button>
    ),
    selectAccount: (
      <Button key="selectAccount" outline color="primary" onClick={this.onSelectGoogleAccount}>
        {t('setupManagementAccount')}
      </Button>
    ),
    selectLocation: (
      <Button key="selectLocation" outline color="primary" onClick={this.onSelectGoogleLocation}>
        {t('authorizedMerchant')}
      </Button>
    ),
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  showLocationSelection = () => {
    this.setState({
      isShowLocationSelection: true,
      locationModalStateMessage: defaultState.locationModalStateMessage,
    });
  };

  hideLocationSelection = () => {
    this.setState({
      isShowLocationSelection: false,
    });
  };

  showAccountSelection = (accounts) => {
    this.setState({
      isShowAccountSelection: true,
      googleAccounts: accounts,
    });
  };

  hideAccountselection = () => {
    this.setState({
      isShowAccountSelection: false,
    });
  };

  renderGoogleSection = () => {
    const {
      google: { isLogin: isGoogleLogin },
      user: { googleAccountId, googleAccountName },
    } = this.props;
    const { signIn, signOut, selectAccount, selectLocation } = this.googleButtonsMap;
    let children;
    let headerComponent;
    if (!isGoogleLogin) {
      children = [signIn].map((item) => (
        <Col key={item.key} sm="2">
          {item}
        </Col>
      ));
      headerComponent = 'Google';
    } else if (!googleAccountId) {
      children = [selectAccount, signOut].map((item) => (
        <Col key={item.key} sm={{ size: 'auto' }}>
          {item}
        </Col>
      ));
      headerComponent = t('googleChooseAdministrator');
    } else {
      children = [selectAccount, selectLocation, signOut].map((item) => (
        <Col key={item.key} sm={{ size: 'auto' }}>
          {item}
        </Col>
      ));
      headerComponent = (
        <span>
          {t('currentGoogleAdministrator')}
          <Badge color="primary">{`${googleAccountName}`}</Badge>
        </span>
      );
    }
    return (
      <div>
        <CardHeader>{headerComponent}</CardHeader>
        <CardBody>
          <Row>{children}</Row>
        </CardBody>
      </div>
    );
  };

  renderGoogleCalendarAuthSection = () => {
    const { byId, selectedIds } = this.props.clients;
    const clients = selectedIds.map((id) => byId[id]).filter(({ features }) => features.calendar);
    const buttons = clients.map(({ id, companyName }) => (
      <Col key={id} className="buttonsColumn" sm="2">
        <Button outline color="primary">
          <a href={`${AUTH_URL}/api/googlecalendar?clientID=${id}`}>{`${companyName}`}</a>
        </Button>
      </Col>
    ));
    return (
      <div>
        <CardHeader>{t('googleCalendarAuthorization')}</CardHeader>
        <CardBody>
          <Row>{buttons}</Row>
        </CardBody>
      </div>
    );
  };

  renderDataStudioSection = () => {
    const { byId, selectedIds } = this.props.clients;
    const clients = selectedIds.map((id) => byId[id]);
    const buttons = clients.map(({ id, companyName }) => (
      <Col key={id} className="buttonsColumn" sm="2">
        <Button outline color="primary" onClick={this.handleDataStudioClick(id)}>
          {companyName}
        </Button>
      </Col>
    ));
    return (
      <div>
        <CardHeader>Datastudio</CardHeader>
        <CardBody>
          <Row>{buttons}</Row>
        </CardBody>
      </div>
    );
  };

  renderFacebookSection = () => {
    const { byId, selectedIds } = this.props.clients;
    const clients = selectedIds.map((id) => (
      <Col key={id} className="buttonsColumn" sm={{ size: 'auto' }}>
        <Button outline color="primary">
          <a target="_blank" rel="noopener noreferrer" href={`${SERVER_URL}reviews/${id}/facebook/connect`}>
            {t('authorizeCompanyName', { name: byId[id].companyName })}
          </a>
        </Button>
      </Col>
    ));
    return (
      <div>
        <CardHeader>Facebook</CardHeader>
        <CardBody>
          <Row>{clients}</Row>
        </CardBody>
      </div>
    );
  };

  render() {
    return (
      <div className="animated fadeIn">
        <Row style={{ marginTop: '1.5rem' }}>
          <Col>
            <Card>
              {this.renderGoogleSection()}
              {this.renderGoogleCalendarAuthSection()}
              {this.renderDataStudioSection()}
              {this.renderFacebookSection()}
              {this.state.isShowLocationSelection && (
                <SelectionModal
                  isOpen={this.state.isShowLocationSelection}
                  title={t('chooseAuthorizedMerchant')}
                  hideModal={this.hideLocationSelection}
                  stateMessage={this.state.locationModalStateMessage}
                >
                  <LocationSelector
                    fetchLocations={this.props.getGoogleLocations}
                    selectGoogleLocation={this.props.setGoogleLocation}
                    showMessage={(msg) => this.setState({ locationModalStateMessage: msg })}
                  ></LocationSelector>
                </SelectionModal>
              )}
              {this.state.isShowAccountSelection && (
                <SelectionModal
                  isOpen={this.state.isShowAccountSelection}
                  title={t('chooseAdministratorIdentity')}
                  hideModal={this.hideAccountselection}
                >
                  {this.state.googleAccounts.map((account) => (
                    <div className="list-item" key={account.name}>
                      <button
                        onClick={async () => {
                          await this.props.setGoogleAccount(account);
                          this.showLocationSelection();
                          this.hideAccountselection();
                        }}
                      >
                        {account.accountName}
                      </button>
                    </div>
                  ))}
                </SelectionModal>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
