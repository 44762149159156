import { AUTH_LOGOUT, updateFBScopes } from 'reducers/auth';
import { timeAgo } from 'helpers';
import { reviewManagerPlatformTransfer } from 'helpers/reviewHelpers';
import { demoMiddleWare } from 'demo';
import {
  fetchFBCommentsRepliedApi,
  replyFBCommentApi,
  fetchTokenScopesApi,
  replyGoogleCommentApi,
} from '../lib/reviews';
import { Platform } from '../utils/appContant';
import { addAlert } from './ui';
import { t } from 'i18n/config';

const LOADING = 'LOADING';
const SET_FB_POST = 'SET_FB_POST';
const UPDATE_FB_POST = 'UPDATE_FB_POST';
const UPDATE_GOOGLE_REVIEW = 'REVIEW/UPDATE_GOOGLE_REVIEW';
const SET_TOTAL_REVIEWS = 'REVIEW/SET_TOTAL_REVIEWS';

const defaultState = {
  isLoading: false,
  fbPosts: {
    byId: {},
  },
  googleReviews: {
    byId: {},
  },
  totalReviews: {
    fb: [],
    google: [],
  },
};

export const setReviews = ({ reviews }) => ({
  type: SET_TOTAL_REVIEWS,
  payload: {
    [Platform.FACEBOOK]: reviews.filter((review) => review.type === reviewManagerPlatformTransfer(Platform.FACEBOOK)),
    [Platform.GOOGLE]: reviews.filter((review) => review.type === reviewManagerPlatformTransfer(Platform.GOOGLE)),
  },
});

const setFBPosts = ({ fbPostId, reply }) => ({
  type: SET_FB_POST,
  payload: {
    [fbPostId]: {
      fbPostId,
      reply,
    },
  },
});

const updateFBPosts = ({ fbPostId, newReply }) => ({
  type: UPDATE_FB_POST,
  payload: {
    fbPostId,
    newReply,
  },
});

const updateGoogleReview = ({ googleReviewId, reply }) => ({
  type: UPDATE_GOOGLE_REVIEW,
  payload: {
    googleReviewId,
    reply,
  },
});

export const fetchCommentsReplied =
  ({ platform, fbPostId, googleReviewId, reply: reviewReply }) =>
  async (dispatch, getState) => {
    if (platform === Platform.FACEBOOK) {
      const { fbFanPageToken } = getState().auth.user;
      const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
      const reply = await demoMiddleWare(
        clients,
        {
          fbFanPageToken,
          fbPostId,
        },
        fetchFBCommentsRepliedApi
      );
      if (reply != null && reply.length > 0) {
        dispatch(setFBPosts({ fbPostId, reply }));
      }
    } else if (platform === Platform.GOOGLE) {
      dispatch(
        updateGoogleReview({
          googleReviewId,
          reply: reviewReply,
        })
      );
    }
  };

export const replyReview =
  ({ platform, reply, fbPostId, googleReviewId, googleAccountId, googleLocationId }) =>
  async (dispatch, getState) => {
    try {
      if (platform === Platform.FACEBOOK) {
        const { fbFanPageToken } = getState().auth.user;
        await replyFBCommentApi({ reply, fbPostId, fbFanPageToken });
        dispatch(
          updateFBPosts({
            fbPostId,
            newReply: reply,
          })
        );
      } else if (platform === Platform.GOOGLE) {
        const response = await replyGoogleCommentApi({
          googleAccountAccessToken: getState().auth.user.googleAccountAccessToken,
          googleAccountId,
          googleLocationId,
          googleReviewId,
          comment: reply,
        });
        dispatch(
          updateGoogleReview({
            googleReviewId,
            reply: response.comment,
          })
        );
      }
    } catch (error) {
      dispatch(addAlert({ message: t('pleaseRefreshPage') }));
    }
  };

export const fetchTokenScopes = () => async (dispatch, getState) => {
  const { fbFanPageToken } = getState().auth.user;
  try {
    const { scopes } = await fetchTokenScopesApi(fbFanPageToken);
    dispatch(updateFBScopes(scopes));
  } catch (error) {
    console.error('fetchTokenScopesApi error:', error);
  }
};

export const currentAndLastMonthDigit = ({ mixReviews, customers, firstDay, firstDayLastMonth }) => {
  const currentMonthReviewNumber = mixReviews.filter(
    (review) => new Date(review.date).getTime() >= new Date(firstDay).getTime()
  ).length;
  const currentSMSNumber = customers
    .filter((customer) => !!customer.phone)
    .filter((customer) => new Date(customer.createdAt).getTime() >= new Date(firstDay).getTime()).length;
  const lastMonthReviewNumber = mixReviews.filter(
    (review) =>
      new Date(review.date).getTime() >= new Date(firstDayLastMonth).getTime() &&
      new Date(review.date).getTime() < new Date(firstDay).getTime()
  ).length;

  const lastMonthSMSNumber = customers
    .filter((customer) => !!customer.phone)
    .filter(
      (customer) =>
        new Date(customer.createdAt).getTime() >= new Date(firstDayLastMonth).getTime() &&
        new Date(customer.createdAt).getTime() < new Date(firstDay).getTime()
    ).length;
  return {
    currentMonthReviewNumber,
    lastMonthReviewNumber,
    lastMonthSMSNumber,
    currentSMSNumber,
  };
};

export const reportReviewDataHandler = (reportResponse) => async (dispatch, getState) => {
  const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);
  const {
    customers,
    googleReview,
    fbReview,
    apReview,
    tripAdvisorReview, // TODO: remove customers from api
  } = reportResponse;
  const mixReviews = [...googleReview, ...fbReview, ...apReview, ...tripAdvisorReview].filter(
    (review) => !Number.isNaN(Number(review.rating))
  );

  const totalStars = mixReviews
    .map((review) => parseFloat(review.rating))
    .reduce((acc, rating) => (acc * 100 + rating * 100) / 100, 0);
  const averageStars = mixReviews.length ? parseFloat(totalStars / mixReviews.length).toFixed(1) : 0;
  const totalReviewNumber = mixReviews.length;
  const today = new Date();
  const yr = today.getFullYear();
  const m = today.getMonth();
  const firstDay = new Date(yr, m, 1);
  const firstDayLastMonth = new Date(yr, m - 1, 1);

  const { currentMonthReviewNumber, lastMonthReviewNumber, lastMonthSMSNumber, currentSMSNumber } =
    await demoMiddleWare(
      clients,
      {
        mixReviews,
        customers,
        firstDay,
        firstDayLastMonth,
      },
      currentAndLastMonthDigit
    );

  const mixedReviews30days = mixReviews
    .filter((review) => new Date(review.date).getTime() >= new Date().getTime() - 86400000 * 30)
    .map((review) => ({
      ...review,
      author_name: review.customerName,
      relative_time_description: timeAgo(new Date(review.createdAt).getTime()),
      text: review.reviewText,
    }))
    .sort((x, y) => +new Date(y.createdAt) - +new Date(x.createdAt));

  return {
    mixedReviews30days,
    averageStars,
    totalReviewNumber,
    currentMonthReviewNumber,
    lastMonthReviewNumber,
    lastMonthSMSNumber,
    currentSMSNumber,
  };
};

export const mappingCustomersWithSocialPlatform =
  ({ clientId, commentTime, platform }) =>
  (dispatch, getState) => {
    const {
      review: { totalReviews },
      customer: customers,
    } = getState();
    const targetReview = totalReviews[platform].find(
      (review) =>
        review.clientId === clientId &&
        review.createdAt < commentTime + 10 * 60 * 1000 &&
        review.createdAt > commentTime - 30 * 60 * 1000
    );
    if (targetReview == null) {
      return null;
    }
    const targetCustomer = customers.byId[targetReview.userId];
    return targetCustomer || null;
  };

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_FB_POST:
      return {
        ...state,
        fbPosts: {
          byId: {
            ...state.fbPosts.byId,
            ...action.payload,
          },
        },
      };
    case UPDATE_FB_POST: {
      const targetPost = state.fbPosts.byId[action.payload.fbPostId];
      let combineReply;
      if (targetPost.reply.length) {
        combineReply = `${targetPost.reply}/${action.payload.newReply}`;
      } else {
        combineReply = `${t('reply')}${action.payload.newReply}`;
      }
      return {
        ...state,
        fbPosts: {
          ...state.fbPosts,
          byId: {
            ...state.fbPosts.byId,
            [targetPost.fbPostId]: {
              fbPostId: targetPost.fbPostId,
              reply: combineReply,
            },
          },
        },
      };
    }
    case UPDATE_GOOGLE_REVIEW: {
      const combineReply = `${t('reply')}${action.payload.reply}`;
      return {
        ...state,
        googleReviews: {
          ...state.googleReviews,
          byId: {
            ...state.googleReviews.byId,
            [action.payload.googleReviewId]: {
              googleReviewId: action.payload.googleReviewId,
              reply: combineReply,
            },
          },
        },
      };
    }
    case SET_TOTAL_REVIEWS: {
      return {
        ...state,
        totalReviews: {
          ...action.payload,
        },
      };
    }
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
