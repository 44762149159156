import React from 'react';
import styled from 'styled-components';

const Italic = styled.i`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

function App({ disabled, className, onClick }) {
  return <Italic className={className} isDisabled={disabled} onClick={onClick}></Italic>;
}

export default App;
