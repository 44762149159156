import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DatePicker from 'components/MaterialDesign/InlineDatePicker';
import ColumnSetting from 'components/Table/ColumnSetting';
import FilterWrapper from './FilterWrapper';
import { t } from 'i18n/config';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
`;

const ToolSection = ({
  selectedDate,
  headers,
  onDateChange,
  columnSettable,
  handleColumnSettingUpdate,
  customFilters,
}) => {
  const handleDateChange = useCallback((date) => {
    headers.forEach((column) => {
      const { setFilter } = column;
      setFilter(undefined);
    });
    onDateChange(date);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {customFilters ? (
        <Filters>{customFilters}</Filters>
      ) : (
        <Filters>
          {selectedDate && (
            <FilterWrapper
              title={t('date')}
              select={<DatePicker defaultDate={selectedDate} handleChange={handleDateChange} />}
            />
          )}
          {headers.map((column) => {
            if (!column.canFilter) {
              return null;
            }
            if (!column.filterable) {
              return null;
            }
            return <FilterWrapper key={column.id} title={column.render('Header')} select={column.render('Filter')} />;
          })}
        </Filters>
      )}
      {columnSettable && <ColumnSetting columns={headers} updateColumnSetting={handleColumnSettingUpdate} />}
    </Container>
  );
};

ToolSection.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      canFilter: PropTypes.bool,
      filterable: PropTypes.bool,
    })
  ),
  onDateChange: PropTypes.func,
  filterOptionsByAccessor: PropTypes.shape(),
  selectedDate: PropTypes.instanceOf(Date),
};

export default React.memo(ToolSection);
