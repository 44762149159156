import { set } from 'date-fns';
import { phoneFilter } from 'lib/vendors/teetchScalingParser/utils';
import XLSX from 'xlsx';

function parseTWDateToAD(twdateString) {
  const intres = parseInt(twdateString, 0);
  if (!Number.isNaN(intres)) {
    const y = parseInt(intres / 10000, 10) + 1911;
    const m = parseInt((intres % 10000) / 100, 10);
    const d = parseInt(intres % 100, 10);
    const res = new Date(`${y}/${m}/${d}`);
    return res;
  }
  return undefined;
}

export default function Cooper() {
  this.parse = (xls, rABS) => {
    const wb = XLSX.read(xls, { type: rABS ? 'binary' : 'array' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 'A' });
    const res = data
      .filter((d, i) => i !== 0)
      .filter((d) => d.K != null && d.K !== '')
      .map((d) => {
        let phone = `${d.K}`;
        if (phone[0] !== '0') {
          phone = `0${phone}`;
        }
        return {
          hisID: d.A,
          name: d.B,
          phone,
          doctor: d.Q,
          birth: parseTWDateToAD(d.F),
          lastVisit: parseTWDateToAD(d.V),
          lastTeethScaling: d.W && `${d.W}`.length > 0 ? parseTWDateToAD(d.W) : parseTWDateToAD(d.V),
          // d.W洗牙日 d.V就診日
        };
      })
      .filter(phoneFilter);

    return res;
  };

  this.bindCampaign = (list, campaign, options) => {
    const { delaySeconds } = campaign;
    return list.map((l) => {
      let estimatedDeliveryTime = new Date(l.lastTeethScaling.getTime() + delaySeconds * 1000);
      if (options && options.setDateValues) {
        estimatedDeliveryTime = set(estimatedDeliveryTime, options.setDateValues);
      }
      return {
        ...l,
        estimatedDeliveryTime,
      };
    });
  };
}
