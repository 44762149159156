import React, { useState, useEffect, memo } from 'react';
import { format } from 'date-fns';
import Popover from '@material-ui/core/Popover';
import Input from 'components/Dashboard_v2/Input';
import 'react-datepicker/dist/react-datepicker.css';
import Wrapper from 'components/Dashboard_v2/DatePicker/index';
import Button from 'components/Dashboard_v2/Button';
import styled from 'styled-components';
import { t } from 'i18n/config';

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  > button:first-child {
    margin-right: 16px;
  }
  padding: 24px 16px;
`;

const DatePicker = ({
  label,
  value,
  yearRange = { startYear: 1900, endYear: 2099 },
  minDate,
  maxDate,
  onChange,
  ...restProps
}) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [draftDate, setDraftDate] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    setSelectedDate(draftDate);
    onChange(format(draftDate, 'yyyy/MM/dd'));
    handleClose();
  };

  useEffect(() => {
    setSelectedDate(value ? new Date(value) : '');
  }, [value]);

  return (
    <React.Fragment>
      <Input
        label={label}
        prefix={<i className="ri-calendar-todo-fill" />}
        value={selectedDate ? format(selectedDate, 'yyyy/MM/dd') : ''}
        onClick={handleOpen}
        {...restProps}
      />
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
            marginLeft: '-40px',
          }}
        >
          <Wrapper
            yearRange={yearRange}
            selectedDate={selectedDate}
            onChange={(date) => setDraftDate(date)}
            maxDate={maxDate}
            minDate={minDate}
          />
          <ActionButtons>
            <Button color="secondary" variant="filled" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button color="primary" variant="filled" onClick={handleSave}>
              {t('save')}
            </Button>
          </ActionButtons>
        </Popover>
      )}
    </React.Fragment>
  );
};

export default memo(DatePicker);
