import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import addHours from 'date-fns/addHours';
import setHours from 'date-fns/setHours';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import ThreeDayIcon from 'components/icons/icons-ic-finance-3-day-line.svg';
import theme from 'theme';
import { t } from 'i18n/config';

const RowContainer = styled.div`
  cursor: pointer;
  height: 64px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  &:hover {
    background-color: ${theme.list.hover};
  }
`;

const RowContainerWithTopBorder = styled(RowContainer)`
  border-top: ${theme.boxBorder.default};
`;

const Icon = styled.i`
  font-size: 24px;
  margin: 0 8px;
  color: ${theme.colors.default};
`;

const IconSvg = styled.img`
  margin: 0 8px;
`;

export const name = 'DefaultDateTimeSelect';

const DefaultDateTimeSelect = ({ onSelectDate, onSelectCustom }) => {
  const handleClickDate = (date) => () => {
    onSelectDate(date);
  };
  const handleSelectCustomDateTime = () => {
    onSelectCustom();
  };

  return (
    <React.Fragment>
      <RowContainer onClick={handleClickDate(addHours(new Date(), 24))}>
        <Icon className="ri-24-hours-line" />
        {t('sendAfter24Hours')}
      </RowContainer>
      <RowContainer onClick={handleClickDate(addHours(new Date(), 24 * 3))}>
        <IconSvg src={ThreeDayIcon} alt="three-day" />
        {t('sendAfter3Days')}
      </RowContainer>
      <RowContainer onClick={handleClickDate(setHours(startOfTomorrow(), 8))}>
        <Icon className="ri-sun-line" />
        {t('sendTomorrowAt8Am')}
      </RowContainer>
      <RowContainerWithTopBorder onClick={handleSelectCustomDateTime}>
        <Icon className="ri-calendar-event-line" />
        {t('chooseDateAndTime')}
      </RowContainerWithTopBorder>
    </React.Fragment>
  );
};

DefaultDateTimeSelect.propTypes = {
  onSelectDate: PropTypes.func,
  onSelectCustom: PropTypes.func,
};

export default DefaultDateTimeSelect;
