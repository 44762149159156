import React from 'react';
import PropTypes from 'prop-types';
import { Button, Badge, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { t } from 'i18n/config';

const SelectionModal = ({ isOpen, hideModal, title, stateMessage = { error: undefined, message: '' }, children }) => (
  <Modal className="selection-modal" isOpen={isOpen} toggle={hideModal}>
    <ModalHeader toggle={hideModal}>{title}</ModalHeader>
    <ModalBody>
      <div className="list-group">{children}</div>
    </ModalBody>
    <ModalFooter>
      {stateMessage.error != null && (
        <Badge color="danger" pill>
          {stateMessage.error.message}
        </Badge>
      )}
      {stateMessage.error == null && (
        <Badge color="success" pill>
          {stateMessage.message}
        </Badge>
      )}
      <Button color="primary" onClick={hideModal}>
        {t('confirm')}
      </Button>
    </ModalFooter>
  </Modal>
);

SelectionModal.propTypes = {
  isOpen: PropTypes.bool,
  hideModal: PropTypes.func,
  title: PropTypes.string,
  stateMessage: PropTypes.shape({
    error: PropTypes.object,
    message: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default SelectionModal;
