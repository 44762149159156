import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dashboard_v2/Dialog';
import FormControl from 'components/Dashboard_v2/FormControl';
import Text from 'components/Dashboard_v2/Text';
import Radio from 'components/Dashboard_v2/Radio';
import { t } from 'i18n/config';

const RunAtModal = ({ runAtSelectable = false, onConfirm, onClose }) => {
  const [selected, setSelected] = useState(runAtSelectable);

  const handleConfirm = () => {
    onConfirm(selected);
    onClose();
  };
  const handleRadioChange = (e) => {
    setSelected(e);
  };

  return (
    <Dialog
      open={true}
      title={t('trackingTime')}
      description={
        <React.Fragment>
          <Text marginBottom={40} font="Body/14px_Regular" color="SHADES_400">
            {t('canActivateTrackingBySpecifiedDateOrClickNow')}
          </Text>
          <FormControl margin="none">
            <Radio
              label={t('addToTrackingTime')}
              name="intersectionType"
              value={runAtSelectable}
              onChange={handleRadioChange}
              items={[
                {
                  label: t('specifiedDate'),
                  value: true,
                },
                {
                  label: t('now'),
                  value: false,
                },
              ]}
            />
          </FormControl>
        </React.Fragment>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
    />
  );
};

const propTypes = {
  runAtSelectable: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

RunAtModal.propTypes = propTypes;

export default memo(RunAtModal);
