import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { appendCustomTagApi } from 'lib/appointmentService';
import { More, TagTitle, Tag, Container } from '../styledComponents';
import EditTagPopover from './EditTagPopover';
import { t } from 'i18n/config';

const defaultValue = [];
function TagCell({
  value: initialValue = defaultValue,
  row: {
    index,
    values: { phone },
    original,
  },
  column: { id },
  updateMyData,
  state: { clientID },
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState((initialValue || []).map((e, i) => ({ id: i, name: e })));

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    updateMyData(
      index,
      id,
      tags.map((tag) => tag.name)
    );
    setAnchorEl(null);
  }, [id, index, tags, updateMyData]);

  const handleChange = useCallback(() => {
    appendCustomTagApi({ clientId: clientID, phone, tagsToAppend: tags })
      .then((res) => {
        console.log('res = ', res);
      })
      .catch((err) => {
        console.error('err = ', err);
      });
  }, [clientID, phone, tags]);

  const tagNumber = initialValue.length;
  return (
    <React.Fragment>
      <Container onClick={handleClick}>
        {tagNumber === 0 && (
          <Tag customcolor="default" width={65}>
            <TagTitle>{t('addTag')}</TagTitle>
          </Tag>
        )}
        {tagNumber > 0 && (
          <Tag>
            <TagTitle>{initialValue[0]}</TagTitle>
          </Tag>
        )}
        {tagNumber > 1 && <More> {tagNumber - 1}+ </More>}
      </Container>
      {Boolean(anchorEl) && (
        <EditTagPopover
          key={`${original.id}-edittag`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          tags={tags}
          setTags={setTags}
          handleClose={handleClose}
          handleChange={handleChange}
        />
      )}
    </React.Fragment>
  );
}

TagCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.shape({
      phone: PropTypes.string,
    }),
    original: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  column: PropTypes.shape({
    id: PropTypes.string,
  }),
  updateMyData: PropTypes.func,
  state: PropTypes.shape({
    clientID: PropTypes.string,
  }),
};

export default TagCell;
