import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/MaterialDesign/Popover';
import History from './History';

export default function HistoryPopover({ handleClose, anchorEl, open, appointmentID, clientID }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      handleClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <History clientID={clientID} appointmentID={appointmentID} />
    </Popover>
  );
}

HistoryPopover.propTypes = {
  handleClose: PropTypes.func,
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  appointmentID: PropTypes.number,
  clientID: PropTypes.string,
};
