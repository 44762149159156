import { FB_URL, FB_ACCESS_TOKEN, GMB_API_VERSION, SERVER_URL } from 'helpers/envHelpers';
import { queryStringConstructor } from '../helpers';
import { t } from 'i18n/config';

export const reshandler = (res) => {
  if (res.status >= 400) {
    throw res.json();
  }
  return res.json();
};

const get = (url) => fetch(url).then(reshandler);

export const fetchFBCommentsRepliedApi = ({ fbFanPageToken, fbPostId }) => {
  const fbCommentsUrl = `${FB_URL}${fbPostId}/comments?access_token=${fbFanPageToken}`;
  return fetch(fbCommentsUrl)
    .then(reshandler)
    .then((resJson) => {
      const normalizedComments = {};
      resJson.data.forEach((comments) => {
        if (normalizedComments[comments.from.id]) {
          normalizedComments[comments.from.id].message = `${normalizedComments[comments.from.id].message}/${
            comments.message
          }`;
        } else {
          normalizedComments[comments.from.id] = {
            id: comments.from.id,
            name: comments.from.name,
            message: comments.message,
          };
        }
      });
      const replied = Object.keys(normalizedComments)
        .map((id) => `${normalizedComments[id].name}${t('replyFrom')}${normalizedComments[id].message}`)
        .reduce((sum, temp) => `${sum}\n${temp}`, '');
      return replied;
    });
};

export const replyFBCommentApi = ({ fbPostId, fbFanPageToken, reply }) => {
  if (fbPostId == null) {
    return new Promise((resolve) => resolve());
  }
  const replyCommentUrl = `
  ${FB_URL}${fbPostId}/comments?message=${reply}&access_token=${fbFanPageToken}`;
  return fetch(replyCommentUrl, {
    method: 'POST',
    body: JSON.stringify({}),
  }).then(reshandler);
};

export const fetchTokenScopesApi = (fbFanPageToken) => {
  const debugTokenUrl = `${FB_URL}debug_token?${queryStringConstructor({
    input_token: fbFanPageToken,
    access_token: FB_ACCESS_TOKEN,
  })}`;

  return get(debugTokenUrl).then(({ data }) => data);
};

export const replyGoogleCommentApi = ({
  googleAccountAccessToken,
  googleAccountId,
  googleLocationId,
  googleReviewId,
  comment,
}) => {
  const replyCommentUrl = `${GMB_API_VERSION}/accounts/${googleAccountId}/locations/${googleLocationId}/reviews/${googleReviewId}/reply`;
  return fetch(replyCommentUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${googleAccountAccessToken}`,
    },
    body: JSON.stringify({
      comment,
    }),
  }).then(reshandler);
};

const feedbackAnalyticsResponseHandler = (clientId) => (res) => {
  res.clientId = clientId;
  return res;
};

// TODO: use new service instead
export const feedbackAnalyticsApi = ({ clientId, startTimeString, endTimeString }, headers) => {
  const feedbackAnalyticsUrl = `${SERVER_URL}monthlyReport/metrics/${clientId}/userFeedback?startTime=${startTimeString}&endTime=${endTimeString}`;
  return fetch(feedbackAnalyticsUrl, {
    headers,
  })
    .then(reshandler)
    .then(feedbackAnalyticsResponseHandler(clientId));
};
