import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 4px;
`;

const Info = styled.div`
  padding: 6px;
`;

const TitleSection = styled.div`
  font-size: 12px;
  line-height: 1.58;
  letter-spacing: 0.3px;
  color: ${(props) => props.theme.colors.textSecondary};
`;

const CountSection = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

function StatusComponent({ icon, title, count }) {
  return (
    <Container>
      {icon}
      <Info>
        <TitleSection>{title}</TitleSection>
        <CountSection> {count}</CountSection>
      </Info>
    </Container>
  );
}

StatusComponent.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  count: PropTypes.number,
};

export default StatusComponent;
