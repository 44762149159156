import download from 'lib/download';
import { getToken, SERVER_URL, SURVEY_URL, getDateFormat, REDIS_BOMB_URL } from '../helpers';
import { responseHandler } from './responseHandler';
import { t } from 'i18n/config';

const emailCollectUrl = `${SERVER_URL}api/v1/emailCollect`;
const bulkUploadUrl = `${SERVER_URL}api/nsq`;

export const getTodos = () => fetch(SERVER_URL).then((res) => res.json());

export const createCustomerApi = (name, email, phone, note, country) =>
  fetch(emailCollectUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      note,
      country,
    }),
  }).then(responseHandler);

// TODO: change to new customer api
export const createCustomerApiV1 = ({ clientId, customer }) =>
  fetch(`${SERVER_URL}emailCollect/v1`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      _cli: clientId,
      ...customer,
    }),
  }).then(responseHandler);

export const bulkUploadApi = (body) =>
  fetch(bulkUploadUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.alleypin+json;version=1',
    },
    body: JSON.stringify(body),
  }).then(responseHandler);

export const getMonthlyReportIframeUrlPath = ({ clientIds, startDate, endDate }, demo) =>
  `${SERVER_URL}monthlyReport/${clientIds}?startTime=${getDateFormat(startDate)}&endTime=${getDateFormat(
    endDate
  )}&demo=${demo}`;

export const fetchReportDataApi = ({ clientId, startTimeStamp, endTimeStamp }, headers) => {
  const reportUrl = `${SERVER_URL}monthlyReport/api/${clientId}?startTime=${startTimeStamp}&endTime=${endTimeStamp}`;
  return fetch(reportUrl, {
    headers,
  }).then(responseHandler);
};

export const downloadReviewXlsx = ({ clientId, startTime, endTime, title }, headers) => {
  const startTimeStamp = new Date(startTime).getTime();
  const endTimeStamp = new Date(endTime).getTime();
  const reportUrl = `${SERVER_URL}monthlyReport/reviews/${clientId}?startTime=${startTimeStamp}&endTime=${endTimeStamp}&action=download`;
  return fetch(reportUrl, {
    headers,
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      download(blob, t('titleEvaluationListStartTimeEndTime', { title, startTime, endTime }));
    });
};

export const fetchReportCSVApi = ({ clientId, startTimeString, endTimeString, title }, headers) => {
  const reportUrl = `${SERVER_URL}monthlyReport/csv/${clientId}?startTime=${startTimeString}&endTime=${endTimeString}&action=download`;
  return fetch(reportUrl, {
    headers,
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      download(blob, t('titleCustomerListStartTimeEndTime', { title, startTimeString, endTimeString }));
    });
};

export const fetchSurveyCSVApi = ({ startTime, endTime, clientId }) => {
  const surveyUrl = `${SURVEY_URL}api/csv?clientId=${clientId}&startTime=${startTime}&endTime=${endTime}`;
  return fetch(surveyUrl).then(responseHandler);
};

export const fetchFeedbackCSVApi = ({ clientId, startTimeString, endTimeString, title }, headers) => {
  const reportUrl = `${SERVER_URL}monthlyReport/csv/${clientId}/userFeedback?startTime=${startTimeString}&endTime=${endTimeString}&action=download`;
  return fetch(reportUrl, {
    headers,
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      download(blob, t('titleCustomerFeedbackStartTimeEndTime', { title, startTimeString, endTimeString }));
    });
};

export const fetchCustomersInQueApi = ({ clientId, page = 1, pageSize = 10 }) => {
  const url = `${REDIS_BOMB_URL}clients/${clientId}/customers?page=${page}&pageSize=${pageSize}`;
  return fetch(url).then(responseHandler);
};
