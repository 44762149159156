import { Platform } from 'utils/appContant';
import { t } from 'i18n/config';

export const reviewManagerPlatformTransfer = (platform) => {
  switch (platform) {
    case Platform.FACEBOOK:
      return 'Facebook';
    case Platform.GOOGLE:
      return 'Google';
    case Platform.ALLEYPIN:
      return 'Alleypin';
    case Platform.ALLEYPIN_FEEDBACK:
      return 'AlleypinFeedback';
    case Platform.TRIPADVISOR:
      return 'TripAdvisor';
    default:
      return null;
  }
};

export const FEEDBACK_LABEL = {
  PUSH: 'push',
  WAITING: 'waiting',
  DOCTOR: 'doctor',
  SERVICE: 'service',
  ENVIRONMENT: 'environment',
};

export const feedbackLabelMap = {
  [FEEDBACK_LABEL.PUSH]: t('tooSalesy'),
  [FEEDBACK_LABEL.WAITING]: t('waitingTime'),
  [FEEDBACK_LABEL.DOCTOR]: t('treatmentService'),
  [FEEDBACK_LABEL.SERVICE]: t('frontDeskService'),
  [FEEDBACK_LABEL.ENVIRONMENT]: t('environmentIssues'),
};

export const feedbackLabelDescriptionMap = {
  [FEEDBACK_LABEL.PUSH]: '',
  [FEEDBACK_LABEL.WAITING]: t('longWaitForAppointment'),
  [FEEDBACK_LABEL.DOCTOR]: t('feedbackFromDoctorOrCounselor'),
  [FEEDBACK_LABEL.SERVICE]: t('serviceAttitudeAndQualityOfFrontDeskStaff'),
  [FEEDBACK_LABEL.ENVIRONMENT]: t('environmentHygieneLayoutComfort'),
};

export const enen = () => true;
