import { createSelector } from 'reselect';

export const serialized = (appointment) => appointment;

const appointmentsSelector = (state) => state.westernAppointments;

export const getAppointments = createSelector(appointmentsSelector, (appointments) =>
  Object.keys(appointments).reduce((sum, temp) => {
    const appointInClient = appointments[temp];
    const appointList = appointInClient.Ids.map((id) => ({
      ...appointInClient.byId[id],
    })).map(serialized);
    sum[temp] = {
      sendingStatus: appointments[temp].sendingStatus,
      list: appointList,
      loading: appointments[temp].loading,
    };
    return sum;
  }, {})
);
