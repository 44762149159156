import {
  CSV_ATTRIBUTE,
  NAME_PRIORITY,
  NAME_OPTIONS,
  PHONE_PRIORITY,
  PHONE_OPTIONS,
  isNeedCreateObject,
  isPriorityHigher,
  EMAIL_PRIORITY,
  EMAIL_OPTIONS,
  NOTE_PRIORITY,
  NOTE_OPTIONS,
  SERVICE_PROVIDER_NAME_OPTIONS,
  SERVICE_PROVIDER_NAME_PRIORITY,
} from '../helpers';

export default class CsvAttribute {
  constructor(attribute) {
    switch (attribute) {
      case CSV_ATTRIBUTE.NAME:
        this.priority = NAME_PRIORITY;
        this.options = NAME_OPTIONS;
        break;
      case CSV_ATTRIBUTE.PHONE:
        this.priority = PHONE_PRIORITY;
        this.options = PHONE_OPTIONS;
        break;
      case CSV_ATTRIBUTE.EMAIL:
        this.priority = EMAIL_PRIORITY;
        this.options = EMAIL_OPTIONS;
        break;
      case CSV_ATTRIBUTE.NOTE:
        this.priority = NOTE_PRIORITY;
        this.options = NOTE_OPTIONS;
        break;
      case CSV_ATTRIBUTE.SERVICE_PROVIDER_NAME:
        this.priority = SERVICE_PROVIDER_NAME_PRIORITY;
        this.options = SERVICE_PROVIDER_NAME_OPTIONS;
        break;
      default:
        this.priority = {};
        this.options = {};
        break;
    }
    this.optionArray = Object.keys(this.options).map((title) => this.options[title]);
  }

  getOptionArray() {
    return this.optionArray;
  }

  getPriority() {
    return this.priority;
  }

  getOptions() {
    return this.options;
  }

  getObject(currentObj, newTitle, index) {
    if (
      isNeedCreateObject(this.optionArray, currentObj, newTitle) ||
      isPriorityHigher(this.priority, newTitle, currentObj.title)
    ) {
      return {
        title: newTitle,
        index,
      };
    }
    return currentObj;
  }
}
