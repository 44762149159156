import React, { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { useSelector } from 'react-redux';
import useAlert from 'hooks/useAlert';
import { dashboard_v2 } from 'theme';
import { enablePatientTracking, disablePatientTracking } from 'lib/messageCenterService';
import Dialog from 'components/Dashboard_v2/Dialog';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  text-align: right;
`;

const Icon = styled.i`
  color: ${colors.SHADES_400};
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
`;

const ActionMenu = styled.div`
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  a,
  div {
    color: ${colors.SHADES_900};
    display: block;
    padding: 12px 24px;
    &:hover {
      background-color: ${colors.SHADES_50};
      text-decoration: none;
    }
  }
`;

function ActionCell({ row, updateMyData }) {
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { original, index } = row;
  const { setAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const isExpired = moment(new Date()).isAfter(original.endTime);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openConfirmDialog = useCallback(() => {
    setConfirmDialogOpen(true);
    handleClose();
  }, [handleClose]);

  const handleStatusChange = useCallback(
    async (status) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        if (status === 'enabled') {
          await enablePatientTracking({ clientId, patientTrackingId: original.id });
        }
        if (status === 'disabled') {
          await disablePatientTracking({ clientId, patientTrackingId: original.id });
        }
        updateMyData(index, 'status', status);
        setAlert({
          type: 'success',
          title: t('successTitle', { status: status === 'enabled' ? t('enable') : t('disable') }),
        });
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: t('updateStatusFailed', { error: error || error.message }) });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    },
    [clientId, original.id, setAlert, index, updateMyData, isLoading]
  );

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <Icon className="ri-more-2-fill" onClick={handleClick} />
      {open && (
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
          <ActionMenu>
            <Link to={`/message_center/patient_tracking/${original.id}`}>
              {original.status === 'draft' ? t('edit') : t('view')}
            </Link>
            {['draft', 'disabled'].includes(original.status) && !isExpired && (
              <div onClick={openConfirmDialog}>{t('enable')}</div>
            )}
            {original.status === 'enabled' && !isExpired && <div onClick={openConfirmDialog}>{t('disable')}</div>}
          </ActionMenu>
        </Popover>
      )}
      {confirmDialogOpen && (
        <Dialog
          open={true}
          title={
            original.status === 'enabled'
              ? t('confirmDisable', { title: original.title })
              : t('confirmEnable', { title: original.title })
          }
          description={original.status === 'enabled' ? t('disableMessage') : t('enableMessage')}
          confirmText={t('confirm')}
          cancelText={t('cancel')}
          onConfirm={() => handleStatusChange(original.status === 'enabled' ? 'disabled' : 'enabled')}
          onCancel={() => setConfirmDialogOpen(false)}
          isLoading={isLoading}
        />
      )}
    </Wrapper>
  );
}

export default memo(ActionCell);
