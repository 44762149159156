import React, { useState, useMemo, useCallback, memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { get } from 'lodash-es';
import { Checkbox } from '@material-ui/core';
import { getGroupingUsers } from 'lib/messageCenterService';
import Dialog from 'components/Dashboard_v2/Dialog';
import Block from 'components/Dashboard_v2/Block';
import FormControl from 'components/Dashboard_v2/FormControl';
import Select from 'components/Dashboard_v2/Select';
import Radio from 'components/Dashboard_v2/Radio';
import Switch from 'components/Dashboard_v2/Switch';
import Text from 'components/Dashboard_v2/Text';
import DatePicker from 'components/Dashboard_v2/DatePicker/Input';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import PredictChartBlock, {
  defaultChartData,
  getChartSetting,
} from 'feature/MemberCenter/GroupForm/components/PredictChartBlock';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const FilterContainer = styled.div`
  ${({ toAllMembers }) =>
    toAllMembers &&
    `
      pointer-events: none;
      opacity: 0.5;
  `};
`;

const FilterTypeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid ${colors.SHADES_300};
  padding: 16px;
  border-radius: 8px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 30px 0;
  max-height: 64vh;
  overflow: auto;
  > div {
    flex: 1;
  }
`;

const ModalSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0px 8px 0;
  > div {
    > div:first-child {
      font-size: 16px;
      font-weight: 500;
      color: ${colors.SHADES_900};
    }
    > div:last-child {
      font-size: 14px;
      color: ${colors.SHADES_500};
    }
  }
`;

const TitleSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.SHADES_900};
  }
  > div:last-child {
    font-size: 14px;
    color: ${colors.SHADES_500};
    margin-right: 80px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    padding: 0;
    margin-right: 8px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-right: 6px;
`;

const DeleteIcon = styled.i`
  margin-top: 36px;
  margin-left: 12px;
  font-size: 18px;
  color: ${colors.SHADES_400};
  cursor: pointer;
`;

const DISPLAY_DATE_FORMAT = 'YYYY/MM/DD';
const DATA_DATE_FORMAT = 'YYYY-MM-DD';
const FILTER_OPTIONS = [
  { id: 'groups', name: t('groups') },
  { id: 'appointmentTimes', name: t('appointmentTime') },
];
const defaultAppointmentTime = {
  startTime: moment(new Date()).format(DATA_DATE_FORMAT),
  endTime: moment(new Date()).format(DATA_DATE_FORMAT),
};

const TargetModal = ({ clientId, groupList, data, onConfirm, onClose }) => {
  const timeoutRef = useRef();
  const [toAllMembers, setToAllMembers] = useState(data.toAllMembers);
  const [chartSetting, setChartSetting] = useState(defaultChartData);
  const [isChartRendering, setIsChartRendering] = useState(false);
  const [users, setUsers] = useState(data.users || []);
  const [group, setGroup] = useState(get(data, 'groupMessageFilters.groups[0]'));
  const [excludedGroup, setExcludedGroup] = useState(get(data, 'groupMessageFilters.excludedGroups[0]'));
  const [allowExclusive, setAllowExclusive] = useState(!!get(data, 'groupMessageFilters.excludedGroups[0]'));
  const [isAvoidDuplicated, setIsAvoidDuplicated] = useState(!!data.isAvoidDuplicated);
  const [appointmentTime, setAppointmentTime] = useState(
    get(data, 'groupMessageFilters.appointmentTimes[0]') || defaultAppointmentTime
  );
  const [filterTypes, setFilterTypes] = useState(
    Object.keys(data.groupMessageFilters).reduce((types, key) => {
      if (
        (key === 'groups' && data.groupMessageFilters[key].length > 0) ||
        (key === 'appointmentTimes' && data.groupMessageFilters[key].length > 0)
      ) {
        const typeData = FILTER_OPTIONS.find((option) => option.id === key);
        types.push(typeData);
      }
      return types;
    }, [])
  );

  const selectedFilterTypes = useMemo(() => filterTypes.map((type) => type.id), [filterTypes]);

  const isDisabled = useMemo(() => {
    if (!toAllMembers) {
      const isEmptyFilterTypes = selectedFilterTypes.length === 0;
      const isEmptyGroup = selectedFilterTypes.includes('groups') && !group;
      const isEmptyExcludeGroup = selectedFilterTypes.includes('groups') && allowExclusive && !excludedGroup;
      const isEmptyAppointmentTime = selectedFilterTypes.includes('appointmentTimes') && !appointmentTime;
      return isEmptyFilterTypes || isEmptyGroup || isEmptyExcludeGroup || isEmptyAppointmentTime;
    }
  }, [allowExclusive, appointmentTime, selectedFilterTypes, toAllMembers, excludedGroup, group]);

  const filterTypeOptions = useMemo(() => {
    const filterTypeIds = filterTypes.map((type) => type.id);
    return FILTER_OPTIONS.map((option) => ({
      ...option,
      disabled: filterTypeIds.includes(option.id),
    }));
  }, [filterTypes]);

  const groupOptions = useMemo(
    () => groupList.filter((listItem) => listItem.id !== get(excludedGroup, 'id')),
    [groupList, excludedGroup]
  );

  const excludedGroupOptions = useMemo(
    () => groupList.filter((listItem) => listItem.id !== get(group, 'id')),
    [groupList, group]
  );

  const handleRadioChange = (value) => setToAllMembers(value);

  const handleDateChange = (type, date) => {
    const formattedTime = moment(date).format(DATA_DATE_FORMAT);
    if (type === 'startTime' && moment(date).isAfter(moment(appointmentTime.endTime))) {
      setAppointmentTime({ startTime: formattedTime, endTime: formattedTime });
    } else {
      setAppointmentTime((prev) => ({ ...prev, [type]: formattedTime }));
    }
  };

  const handleAddFilter = useCallback(() => {
    const restOption = filterTypeOptions.filter((option) => !option.disabled)[0];
    setFilterTypes((prev) => [...prev, restOption]);
  }, [filterTypeOptions]);

  const handleFilterChange = (option, index) => {
    const cloneFilterTypes = [...filterTypes];
    cloneFilterTypes[index] = option;
    setFilterTypes(cloneFilterTypes);
  };

  const handleFilterRemove = (index) => {
    if (filterTypes[index].id === 'groups') {
      setAllowExclusive(false);
    }
    const cloneFilterTypes = [...filterTypes];
    cloneFilterTypes.splice(index, 1);
    setFilterTypes(() => cloneFilterTypes);
  };

  const handleConfirm = () => {
    const hasGroup = selectedFilterTypes.includes('groups') && group;
    const hasExcludedGroup = selectedFilterTypes.includes('groups') && allowExclusive && excludedGroup;
    const hasAppointmentTime = selectedFilterTypes.includes('appointmentTimes');
    const targetData = {
      toAllMembers,
      isAvoidDuplicated,
      excludedUserIDs: [],
      groupMessageFilters: {
        groups: hasGroup ? [group] : [],
        excludedGroups: hasExcludedGroup ? [excludedGroup] : [],
        appointmentTimes: hasAppointmentTime ? [appointmentTime] : [],
      },
    };

    onConfirm({ targetData, users });
    onClose();
  };

  useEffect(() => {
    if ((allowExclusive && !excludedGroup) || (!toAllMembers && !selectedFilterTypes.length)) return;

    const fetchGroupingUsers = async () => {
      setIsChartRendering(true);
      try {
        const payload = { toAllMembers, groupIDs: [], excludedGroupIDs: [] };
        if (selectedFilterTypes.includes('groups')) {
          payload.groupIDs = group ? [group.id] : [];
          payload.excludedGroupIDs = allowExclusive && excludedGroup ? [excludedGroup.id] : [];
        }
        if (selectedFilterTypes.includes('appointmentTimes')) {
          Object.assign(payload, {
            ...(appointmentTime && {
              startTime: appointmentTime.startTime,
              endTime: appointmentTime.endTime,
            }),
          });
        }
        const { activeLineMembers, inactiveLineMembers, totalMembers, users } = await getGroupingUsers({
          clientId,
          payload,
        });
        setChartSetting(() => getChartSetting({ activeLineMembers, inactiveLineMembers, totalMembers }));
        setUsers(() => users);
      } catch (e) {
        const errRes = await e;
        console.error(errRes);
      } finally {
        timeoutRef.current = setTimeout(() => setIsChartRendering(false), 1000);
      }
    };
    fetchGroupingUsers();
  }, [clientId, toAllMembers, selectedFilterTypes, group, excludedGroup, allowExclusive, appointmentTime]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Dialog
      open={true}
      width={1140}
      zIndex={1100}
      title={t('setSendingConditions')}
      description={
        <Container>
          <div>
            <Block title={t('selectRecipients')} minWidth={500}>
              <FormControl>
                <Radio
                  name="toAllMembers"
                  value={toAllMembers}
                  onChange={handleRadioChange}
                  items={[
                    {
                      label: t('allMembers'),
                      value: true,
                    },
                    {
                      label: t('select'),
                      value: false,
                      content: (
                        <FilterContainer toAllMembers={toAllMembers}>
                          {filterTypes.map((type, index) => (
                            <React.Fragment key={type.id}>
                              <FilterTypeWrapper>
                                <div>
                                  <Select
                                    label={t('category')}
                                    options={filterTypeOptions}
                                    selectedOption={type}
                                    setSelectedOption={(option) => handleFilterChange(option, index)}
                                    fullWidth
                                  />
                                  {type.id === 'groups' && (
                                    <React.Fragment>
                                      <Select
                                        label={t('groups')}
                                        options={groupOptions}
                                        selectedOption={group}
                                        setSelectedOption={(option) => setGroup(option)}
                                        allowSearch
                                        fullWidth
                                      />
                                      <ModalSwitchWrapper>
                                        <div>
                                          <div>{t('excludeFollowingGroups')}</div>
                                          <div>{t('openingExclusion')}</div>
                                        </div>
                                        <Switch
                                          checked={allowExclusive}
                                          onChange={(e) => setAllowExclusive(e.target.checked)}
                                        />
                                      </ModalSwitchWrapper>
                                      <Select
                                        label={t('excludeGroups')}
                                        options={excludedGroupOptions}
                                        selectedOption={excludedGroup}
                                        setSelectedOption={(option) => setExcludedGroup(option)}
                                        allowSearch
                                        fullWidth
                                        disabled={!allowExclusive}
                                      />
                                    </React.Fragment>
                                  )}
                                  {type.id === 'appointmentTimes' && (
                                    <React.Fragment>
                                      <DateWrapper>
                                        <DatePicker
                                          value={moment(get(appointmentTime, 'startTime'), [DISPLAY_DATE_FORMAT])}
                                          onChange={(date) => handleDateChange('startTime', date)}
                                        />
                                        <Text marginLeft={8} marginRight={8}>
                                          ~
                                        </Text>
                                        <DatePicker
                                          value={moment(get(appointmentTime, 'endTime'), [DISPLAY_DATE_FORMAT])}
                                          onChange={(date) => handleDateChange('endTime', date)}
                                          minDate={
                                            get(appointmentTime, 'startTime')
                                              ? moment(appointmentTime.startTime, [DISPLAY_DATE_FORMAT]).toDate()
                                              : new Date()
                                          }
                                        />
                                      </DateWrapper>
                                    </React.Fragment>
                                  )}
                                </div>
                                <DeleteIcon className="ri-delete-bin-line" onClick={() => handleFilterRemove(index)} />
                              </FilterTypeWrapper>
                            </React.Fragment>
                          ))}

                          {filterTypes.length < FILTER_OPTIONS.length && (
                            <Text
                              onClick={handleAddFilter}
                              font="Body/14px_Medium"
                              color="PRIMARY_500"
                              marginTop={16}
                              marginLeft={8}
                              cursor="pointer"
                            >
                              <i className="ri-add-line" />
                              <Text font="Body/14px_Medium" color="PRIMARY_500" marginLeft={8}>
                                {t('addGroups')}
                              </Text>
                            </Text>
                          )}
                        </FilterContainer>
                      ),
                    },
                  ]}
                  direction="column"
                  fullWidth
                  required
                />
              </FormControl>
            </Block>
            <Block title={t('sendingMethod')} minWidth={435}>
              <CheckboxWrapper>
                <Checkbox checked={true} disabled />
                LINE
              </CheckboxWrapper>
              <TitleSwitchWrapper>
                <div>
                  <div>
                    {t('mergeSending')}
                    <InfoPopover
                      popoverContent={
                        <>
                          <div>{t('note1')}</div>
                          <div>{t('note2')}</div>
                        </>
                      }
                      style={{ marginLeft: 8, color: colors.SHADES_400 }}
                      type="TOP_CENTER"
                    />
                  </div>
                  <Switch checked={isAvoidDuplicated} onChange={(e) => setIsAvoidDuplicated(e.target.checked)} />
                </div>
                <div>{t('ifMultipleFamilyMembers')}</div>
              </TitleSwitchWrapper>
            </Block>
          </div>
          <PredictChartBlock isChartRendering={isChartRendering} chartSetting={chartSetting} />
        </Container>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={isDisabled}
    />
  );
};

const propTypes = {
  clientId: PropTypes.string,
  groupList: PropTypes.array,
  data: PropTypes.shape({
    toAllMembers: PropTypes.bool,
    groupMessageFilters: PropTypes.shape({
      groups: PropTypes.array,
      excludedGroups: PropTypes.array,
      appointmentTimes: PropTypes.array,
    }),
    chartData: PropTypes.shape({
      activeLineMembers: PropTypes.number,
      inactiveLineMembers: PropTypes.number,
      totalMembers: PropTypes.number,
    }),
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

TargetModal.propTypes = propTypes;

export default memo(TargetModal);
