import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Confirm } from 'components/MaterialDesign/Buttons';
import { Loading } from 'components/MaterialDesign/Loading';
import { bulkRemoveUserNotifications } from 'lib/campaignService';
import { t } from 'i18n/config';

const Container = styled.div`
  height: 127px;
  display: flex;
  align-items: center;
  padding: 16px 18px;
`;

const Description = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
  margin: 0 40px 0 0;
  white-space: nowrap;
`;

const Number = styled.span`
  font-weight: bold;
  margin: 0 4px;
  font-size: ${(props) => props.fontSize || 'inherit'};
  color: ${(props) => props.color || 'inherit'};
`;

const RemoveButton = styled(Confirm)`
  background-color: ${(props) => props.theme.colors.error};
  border: solid 1px ${(props) => (props.disabled ? '#fff' : props.theme.colors.error)};
  &:hover {
    background-color: ${(props) => props.theme.colors.error};
    opacity: 0.9;
  }
  &:focus {
    border: 0px;
    outline: none;
  }
  &:hover.Mui-disabled {
    background-color: ${(props) => props.theme.colors.error};
    opacity: 0.9;
  }
`;

function SelectedToolSection({ clientID, selectedRowIds, selectedFlatRows, batchRemoveRows, openSnackbar }) {
  const [loading, setloading] = useState(false);
  const selectedNumber = selectedFlatRows.length;
  const originals = selectedFlatRows.map((d) => d.original);

  const handleRemove = useCallback(async () => {
    try {
      setloading(true);
      await bulkRemoveUserNotifications({ clientID, originals });
      if (batchRemoveRows) {
        batchRemoveRows(selectedRowIds);
      }
      openSnackbar({
        message: t('deleteSelectedSchedules', { selectedNumber }),
        status: 'success',
      });
    } catch (error) {
      openSnackbar({ message: t('tryAgainLater'), status: 'error' });
    }
    setloading(false);
  }, [originals]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Description>
        {t('selected')} <Number>{selectedNumber}</Number> {t('items')}
      </Description>
      <RemoveButton disabled={loading} onClick={handleRemove} height={40} width={110}>
        {loading && <Loading size={20} />}
        {!loading && t('batchDelete')}
      </RemoveButton>
    </Container>
  );
}

export default React.memo(SelectedToolSection);
