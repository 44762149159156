import styled from 'styled-components';
import { CircularProgress, Button } from '@material-ui/core';

export const SentMessageContainer = styled.div`
  margin: 18px 16px 6px 16px;
`;

export const SentMessageContent = styled.div`
  padding: 6px 8px;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
`;

export const SentMessageTime = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.58;
  letter-spacing: 0.3px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.textSecondary};
`;

export const Loading = styled(CircularProgress)`
  margin: 16px auto;
`;

export const EmptyInfo = styled.div`
  margin: 16px auto;
  color: ${(props) => props.theme.colors.default};
`;

export const EmptyEditedTextInfo = styled.button`
  &:focus {
    border: 0px;
    outline: none;
  }
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: inherit;
  background: unset;
  border: 0px;
  color: ${(props) => props.theme.colors.SHADES_500};
`;

export const PageRouteButton = styled(Button)`
  &:focus {
    border: 0px;
    outline: none;
  }
`;
