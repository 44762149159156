import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { t } from 'i18n/config';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '&> *': {
      marginBottom: '1rem',
      marginTop: '2rem',
    },
  },
}));

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function isValidNumber(input) {
  const digitRegex = /^[0-9]+$/;
  return digitRegex.test(input);
}

function areAllEmailValid(emailInput) {
  const emailList = emailInput.split(',').map((element) => {
    return element.trim();
  });
  return emailList.every(isValidEmail);
}

function isValidTaiwanPhone(phoneNumber) {
  const phoneRegex = /^09\d{8}$/;
  return phoneRegex.test(phoneNumber);
}

function areAllPhoneValidate(phoneInput) {
  const phoneList = phoneInput.split(',').map((e) => {
    return e.trim();
  });
  return phoneList.every(isValidTaiwanPhone);
}

function calLengthWithoutBreak(text) {
  const textWithoutLineBreaks = text.replace(/\\n/g, '_');
  const length = textWithoutLineBreaks.length;
  return length;
}
function convertSlashN(text) {
  const textWithSlashN = text.replace(/\n/g, '\\n');
  return textWithSlashN;
}

function handleSmsContent2BackEnd(text) {
  const textWithoutSlahN = text.replace(/\\n/g, '\n');
  return textWithoutSlahN;
}

export function SmsSetting({ keyToRetrieve, dispatch }) {
  const smsContentWithSlashN = keyToRetrieve.smsContent
    ? convertSlashN(keyToRetrieve.smsContent)
    : t('review_settings.default_content');
  const [smsContentFront, setSmsContentFront] = useState(smsContentWithSlashN);
  const textLength = calLengthWithoutBreak(smsContentFront);

  useEffect(() => {
    return dispatch({ type: 'change_smsContentInput', content: handleSmsContent2BackEnd(smsContentFront) });
  }, [smsContentFront, dispatch]);

  const classes = useStyles();
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        id="outlined-full-width"
        label={t('review_settings.content')}
        helperText={<div style={{ whiteSpace: 'pre-wrap' }}>{t('review_settings.content_hint', { textLength })}</div>}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={smsContentFront}
        onChange={(e) => {
          setSmsContentFront(e.target.value);
        }}
      />
    </form>
  );
}

export function ReviewReceiving({ phoneInput, emailInput, state, validationState, setValidationState }) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        error={!validationState.email}
        name="email"
        id="outlined-full-width"
        label={t('review_settings.email')}
        helperText={!validationState.email ? t('review_settings.email_error') : t('review_settings.email_hint')}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={state.feedbackEmail}
        onChange={(e) => {
          setValidationState({ ...validationState, email: areAllEmailValid(e.target.value) });
          emailInput(e);
        }}
      />
      <TextField
        error={!validationState.taiwanPhone}
        name="phone"
        id="outlined-full-width"
        label={t('review_settings.phone')}
        helperText={!validationState.taiwanPhone ? t('review_settings.phone_error') : t('review_settings.phone_hint')}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={state.cellPhoneNumber}
        onChange={(e) => {
          setValidationState({ ...validationState, taiwanPhone: areAllPhoneValidate(e.target.value) });
          phoneInput(e);
        }}
      />
    </form>
  );
}

export default function ReviewCommonSetting({ state, delayTimeInput, daysInput, validationState, setValidationState }) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        error={!validationState.delayTime}
        id="outlined-full-width"
        label={t('review_settings.delay_seconds')}
        helperText={
          !validationState.delayTime ? t('review_settings.integer_error') : t('review_settings.delay_seconds_hint')
        }
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={state.uploadDelayTime}
        onChange={(e) => {
          delayTimeInput(e);
          setValidationState({ ...validationState, delayTime: isValidNumber(e.target.value) });
        }}
      />
      <TextField
        error={!validationState.expireDays}
        id="outlined-full-width"
        label={t('review_settings.non_repeat_days')}
        helperText={
          !validationState.expireDays ? t('review_settings.integer_error') : t('review_settings.non_repeat_days_hint')
        }
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={state.customerExpireDay}
        onChange={(e) => {
          daysInput(e);
          setValidationState({ ...validationState, expireDays: isValidNumber(e.target.value) });
        }}
      />
    </form>
  );
}
