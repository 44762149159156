export * from './authHelpers';
export * from './customerHelpers';
export * from './envHelpers';
export * from './dateHelpers';
export * from './constants';

export const test = () => {};

export const queryStringConstructor = (queryStringObj) =>
  Object.keys(queryStringObj).reduce((sum, temp, index) => {
    if (index === 0) {
      return `${temp}=${queryStringObj[temp]}`;
    }
    return `${sum}&${temp}=${queryStringObj[temp]}`;
  }, '');

export const normalizeById = (ary) => {
  const byId = {};
  ary.forEach((item) => {
    byId[item.id] = item;
  });
  return byId;
};

export const normalizeIds = (ary) => {
  const idsAry = ary.map((item) => item.id);
  return idsAry;
};

// convert Hex to RGBA
export const convertHex = (hexx = '#fff', opacity) => {
  const hex = hexx.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity / 100})`;
  return result;
};

export const arrayChunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

export const isOpenApiVendor = () => {
  const vendor = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user.vendor : null;
  return [
    'baihui',
    'hygeai',
    'iisi',
    'esis',
    'hugeia',
    'googlecalendar',
    'sc-dr',
    'pinmedpro',
    'cyt',
    'youngmen',
    'tongxin',
  ].includes(vendor);
};
