import React, { useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { uploadImageApi } from 'lib/lineService';
import Text from 'components/Dashboard_v2/Text';
import Italic from 'components/Dashboard_v2/Italic';
import UploadArea, { UPLOAD_STATUS } from 'components/Dashboard_v2/UploadArea';
import Input from 'components/Dashboard_v2/Input';
import { Loading } from 'components/MaterialDesign/Loading';
import { ROLE } from 'lib/openaiService';
import { dashboard_v2 } from 'theme';
import Tooltip from 'components/Dashboard_v2/Tooltip';
import HintInfo from 'components/Dashboard_v2/HintInfo';
import HintImage from 'components/Dashboard_v2/HintInfo/openaiImageHint.png';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const PreviewImageWrapper = styled.div`
  position: relative;
  padding: 8px;
  width: 180px;
  border: 1px solid ${colors.SHADES_300};
  i {
    position: absolute;
    top: -2px;
    right: 2px;
    font-size: 24px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
  img {
    width: 100%;
  }
`;

const Block = styled.div`
  ${({ showTitle }) =>
    showTitle &&
    `
      margin-top: 24px;
      padding: 24px;
      border: 1px solid ${colors.SHADES_300};
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
  `}
`;

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  i {
    font-size: 20px;
    margin-left: 24px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
`;

const ImageEditor = ({
  data = {},
  clientId,
  handleChange,
  handleMessageMove,
  handleMessageDelete,
  handleOpenAI,
  allowMoveUp,
  allowMoveDown,
  disabled,
  showTitle = true,
  type,
}) => {
  const ref = useRef();
  const [prompt, setPrompt] = useState('');
  const [status, setStatus] = useState(data.previewImageUrl ? UPLOAD_STATUS.DONE : UPLOAD_STATUS.INITIAL);
  const [openaiLoading, setOpenaiLoading] = useState(false);

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size / (1024 * 1024) > 1) return alert(t('fileSizeMustBeLessThan1MB'));
    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) return alert(t('fileFormatMustBeJPGorPNG'));

    try {
      setStatus(UPLOAD_STATUS.UPLOADING);
      const data = new FormData();
      data.append('file', file);
      const { url } = await uploadImageApi({ clientId, data });
      handleChange(url);
    } catch (error) {
      const err = await error;
      alert(err.message);
    } finally {
      setStatus(UPLOAD_STATUS.DONE);
    }
  };

  const handleFileClear = () => {
    ref.current.value = '';
    handleChange(null);
    setStatus(UPLOAD_STATUS.INITIAL);
  };

  const ClickMagic = async () => {
    try {
      setOpenaiLoading(true);
      // const url = await createImageGenerations({ prompt });
      await handleOpenAI({
        role: ROLE.IMAGE_BOT,
        content: prompt,
        args: [],
        callback: (url) => {
          handleChange(url);
        },
      });
      setStatus(UPLOAD_STATUS.DONE);
    } catch (error) {
      const err = await error;
      console.error(err);
      setStatus(UPLOAD_STATUS.INITIAL);
    } finally {
      setOpenaiLoading(false);
    }
  };

  const handelPromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handelRemovePrompt = () => {
    setPrompt('');
  };

  const openAIEnabled = handleOpenAI != null;

  return (
    <Block showTitle={showTitle}>
      {showTitle && (
        <BlockTitle>
          <Text font="Heading/Large/Medium">{t('image')}</Text>
          {!disabled && (
            <div>
              {allowMoveUp && <i className="ri-arrow-up-s-line" onClick={() => handleMessageMove('up')} />}
              {allowMoveDown && <i className="ri-arrow-down-s-line" onClick={() => handleMessageMove('down')} />}
              <i className="ri-delete-bin-line" onClick={handleMessageDelete} />
            </div>
          )}
        </BlockTitle>
      )}
      {!disabled && (
        <UploadArea
          innerRef={ref}
          status={status}
          type={type}
          hint={t('fileFormatSupport')}
          accept="image/png, image/jpg, image/jpeg"
          onFileSelect={handleFileSelect}
        />
      )}
      {!disabled && status != UPLOAD_STATUS.DONE && (
        <Input
          onChange={handelPromptChange}
          onInputClear={handelRemovePrompt}
          showClearButton={!!prompt}
          value={prompt}
          suffixDisabled={!openAIEnabled || !prompt}
          suffix={
            openaiLoading ? (
              <Loading size={18} customcolor={dashboard_v2.colors.SHADES_400}></Loading>
            ) : (
              <Tooltip
                popoverContent={
                  <HintInfo
                    image={HintImage}
                    title={t('autoGenerateImage')}
                    description={t('autoGenerateImageDescription')}
                  ></HintInfo>
                }
                type={'CENTER_RIGHT'}
                popoverStyle={{ margin: 18 }}
              >
                <Italic className="ri-sparkling-2-line" onClick={ClickMagic} disabled={!openAIEnabled || !prompt} />
              </Tooltip>
            )
          }
          placeholder={t('describeImage')}
          decoration
          disabled={!openAIEnabled || openaiLoading}
        />
      )}
      {data.previewImageUrl && (
        <PreviewImageWrapper>
          {!disabled && <i className="ri-close-circle-fill" onClick={handleFileClear} />}
          <img src={data.previewImageUrl} />
        </PreviewImageWrapper>
      )}
    </Block>
  );
};

const propTypes = {
  clientId: PropTypes.string,
  data: PropTypes.shape({
    type: PropTypes.string,
    originalContentUrl: PropTypes.string,
    previewImageUrl: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleMessageMove: PropTypes.func,
  handleMessageDelete: PropTypes.func,
  allowMoveUp: PropTypes.bool,
  allowMoveDown: PropTypes.bool,
  showTitle: PropTypes.bool,
};

ImageEditor.propTypes = propTypes;

export default memo(ImageEditor);
