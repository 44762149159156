import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FilterableTable from 'components/Table/FilterableTable';
import {
  headerColumns,
  tableStyles,
  TabTitles,
  TabMap,
} from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/userNotificationTable/headerColumns';
import Tabs from 'components/Table/HorizontalTabs';
import Tab, { a11yProps } from 'components/Table/HorizontalTabs/HorizontalTab';
import TabPanel from 'components/Table/HorizontalTabs/TabPanel';
import SelectedToolSection from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/userNotificationTable/Section/SelectedToolSection';
import {
  useCampaign,
  useNotifications,
} from 'components/Message/campaignTable/Columns/TargetUsers/userNotifications/hooks';
import { set } from 'date-fns';

const Container = styled.div`
  margin: 30px;
  padding-bottom: 30px;
`;

const defaultTab = 0;
function TargetUsersPage(props) {
  const { clientID, campaignID, onRemove } = props;
  const [loading, setLoading] = useState(false);
  const campaign = useCampaign({ campaignID, clientID });
  const [data, setData] = useState([]);
  const [date, setDate] = useState(null);
  const [tab, setTab] = useState(defaultTab);
  const [notifications, listNotifications] = useNotifications();

  useEffect(() => {
    if (campaign) {
      const nextDate = new Date(campaign.nextDeliveryDate || Date.now());
      const selectedDate = date || nextDate;
      setDate(selectedDate);
    }
  }, [campaign]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (date) {
      listNotifications({
        clientID,
        campaignID,
        status: TabMap[tab].query,
        startTime: set(date, { hours: 0, minutes: 0, seconds: 0 }).getTime(),
        endTime: set(date, { hours: 23, minutes: 59, seconds: 59 }).getTime(),
      });
    }
  }, [tab, date]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (notifications) {
      setData(notifications);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [notifications]);

  const handleDataRemoved = useCallback((res, removed) => {
    onRemove(removed.length);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChanged = useCallback(
    (d) => {
      setLoading(true);
      setDate(d);
    },
    [clientID, tab] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangeStatusTab = useCallback((event, newtab) => {
    setTab(newtab);
  }, []);

  return (
    <Container>
      <Tabs value={tab} onChange={handleChangeStatusTab} aria-label="tabs">
        {TabTitles.map((status) => (
          <Tab key={status} label={status} {...a11yProps(status)} />
        ))}
      </Tabs>
      {TabTitles.map((status, statusIndex) => (
        <TabPanel key={status} value={tab} index={statusIndex}>
          {date && (
            <FilterableTable
              tableStyles={tableStyles}
              tableHeaderColumns={headerColumns(statusIndex)}
              clientID={clientID}
              data={data}
              onDateChange={handleDateChanged}
              defaultDate={date.getTime()}
              loading={loading}
              onDataRemoved={handleDataRemoved}
              shouldRowsRemoved
              selectedToolSection={<SelectedToolSection />}
            />
          )}
        </TabPanel>
      ))}
    </Container>
  );
}

TargetUsersPage.propTypes = {
  clientID: PropTypes.string,
  campaignID: PropTypes.string,
  onRemove: PropTypes.func,
};

export default TargetUsersPage;
