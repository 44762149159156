import setDate from 'date-fns/set';
import { t } from 'i18n/config';
// appointments here is a remote data array, which contain appointment with date: yyyy/MM/dd only
// and without time format.
export const checkAppointmentsValidToSend = (appointments, sendDate) => {
  appointments.forEach(({ date }) => {
    const appointmentDate = setDate(new Date(date), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });
    if (sendDate > appointmentDate) {
      throw new Error(t('appointmentReminderTimeCannotBeLaterThanAppointmentDate'));
    }
  });
};
export const test = true;
