import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

const Tag = styled.div`
  width: ${(props) => props.width || 60}px;
  background-color: ${(props) => props.theme.colors[props.customcolor] || props.theme.colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 24px;
  margin-right: 8px;
`;

const More = styled(Tag)`
  width: 32px;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  color: ${(props) => props.theme.colors.primary};
`;

const TagTitle = styled.div`
  line-height: 1.43;
  letter-spacing: 0.07px;
  padding: 4px 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const EditContainer = styled.div`
  height: 330px;
  width: 264px;
  display: flex;
  flex-direction: column;
`;

const EditHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const EditHeaderTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
`;
const EditSection = styled.div`
  margin: 16px 8px 0 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.customcolor};
  flex: 1;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: 0;
  padding: 8px;
  font-size: 13px;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

const TextRow = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  display: flex;
  padding: 8px;
  justify-content: ${(props) => props.justifyContent || 'flex-end'}};
  align-items: center;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.09px;
`;

const Alert = styled.i`
  color: ${(props) => props.theme.colors.error};
  font-size: 13px;
  margin-right: 8px;
`;

const BreakLine = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.border};
`;

export {
  Container,
  Tag,
  More,
  TagTitle,
  EditContainer,
  EditHeader,
  EditHeaderTitle,
  EditSection,
  TextArea,
  TextRow,
  Alert,
  BackHeader,
  BreakLine,
};
