import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import styledTheme from 'theme';
import { t } from 'i18n/config';

const useStyles = (props) =>
  makeStyles(() => ({
    root: {
      display: 'flex',
      width: props.width || 160,
      height: props.height || 'inherit',
    },
    button: {
      width: '100%',
      display: 'flex',
      justifyContent: props.justifyContent || 'space-between',
      textTransform: 'none',
      borderRadius: 8,
      border: `solid 1px ${props.borderColor || styledTheme.colors.borderSecondary}`,
      color: props.color || styledTheme.colors.textPrimary,
      backgroundColor: props.backgroundColor,
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: props.backgroundColor || 'unset',
      },
      fontWeight: 'normal',
    },
    buttonTitle: {
      marginLeft: 4,
    },
    menuList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    paper: {
      border: `solid 1px ${props.paperBorderColor || styledTheme.colors.borderSecondary}`,
      marginTop: 8,
      width: props.width || 160,
      borderRadius: 8,
      boxShadow: '0 2px 4px 0 rgba(41, 39, 95, 0.2)',
    },
    menuItem: {
      fontSize: 13,
      lineHeight: 1.53,
      letterSpacing: 0.09,
      color: styledTheme.colors.textPrimary,
      '&:hover': {
        backgroundColor: styledTheme.list.hover,
      },
    },
    circularProgress: {
      color: props.circularProgressColor || 'rgba(0, 0, 0, 0.87)',
    },
    icon: {
      color: styledTheme.colors.textSecondary,
    },
  }))();

export default function MenuListComposition({
  name = t('pleaseSelect'),
  items = [],
  disablePortal = false,
  onClickItem,
  loading,
  disabled,
  styles,
}) {
  const classes = useStyles({ ...styles });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={loading || disabled}
      >
        {loading ? (
          <CircularProgress className={classes.circularProgress} size={23} />
        ) : (
          <React.Fragment>
            <span className={classes.buttonTitle}>{name}</span>
            <ArrowDropDownIcon className={classes.icon} />
          </React.Fragment>
        )}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal={disablePortal}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  className={classes.menuList}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {items.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.menuItem,
                      }}
                      key={item.id}
                      onClick={() => {
                        onClickItem(item);
                        handleClose(item);
                      }}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

MenuListComposition.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  disablePortal: PropTypes.bool,
  onClickItem: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  styles: PropTypes.shape(),
};
