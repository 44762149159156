import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import connectData from 'components/HOCs/connectData';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ManualVerifyButton from 'components/Passcode/ManualVerifyButton';
import { VendorCategory } from 'utils/vendors';
import { agreement } from './agreement';

import Table from './table';
import { t } from 'i18n/config';

const propTypes = {
  clients: PropTypes.shape({
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.object,
  }),
};

const defaultProps = {};

@connectData
class Passcode extends PureComponent {
  getColumns = (client) => {
    const columns = [
      { label: t('mobileNumber'), name: 'phone' },
      { label: t('verificationCode'), name: 'passcode' },
      { label: t('expirationTime'), name: 'expirationTime' },
    ];
    if (VendorCategory[client.vendor] === 'western') {
      return [
        ...columns,
        {
          label: t('directBind'),
          name: 'lineID',
          options: {
            customBodyRender: (value, tableMeta) => {
              const { rowData } = tableMeta;
              const [phone, passcode, expirationTime, lineID] = rowData;
              return (
                <ManualVerifyButton
                  clientID={client.id}
                  phone={phone}
                  passcode={passcode}
                  expirationTime={expirationTime}
                  lineID={lineID}
                />
              );
            },
          },
        },
      ];
    }
    return columns;
  };

  isMultipleSelectedStores = () => this.props.clients.selectedIds.length > 1;

  render() {
    const { clients } = this.props;

    return (
      <React.Fragment>
        {clients.selectedIds.map((id) => (
          <Table
            key={id}
            clientId={id}
            title={this.isMultipleSelectedStores() ? clients.byId[id].companyName : ''}
            columns={this.getColumns(clients.byId[id])}
          />
        ))}
        <Tooltip title={agreement} arrow>
          <Button>{t('serviceTermsStatement')}</Button>
        </Tooltip>
      </React.Fragment>
    );
  }
}

Passcode.propTypes = propTypes;
Passcode.defaultProps = defaultProps;

export default Passcode;
