import matchSorter from 'match-sorter';
import { t } from 'i18n/config';

export const actionValues = {
  Initial: 'initial',
  Pending: 'pending',
  Deleted: 'deleted',
  SmsToggleBlock: 'smsToggleBlock',
};

export const filterValues = {
  [actionValues.Initial]: 'initial',
  [actionValues.Pending]: 'initial',
  [actionValues.Deleted]: 'deleted',
  [actionValues.SmsToggleBlock]: 'smsToggleBlock',
};

export const filterOptions = {
  sendingAction: [
    filterValues[actionValues.Initial],
    filterValues[actionValues.Deleted],
    filterValues[actionValues.SmsToggleBlock],
  ],
};

const titleMap = {
  [actionValues.Initial]: t('immediateOrSchedule'),
  [actionValues.Pending]: t('immediateOrSchedule'), // display on user
  [actionValues.Deleted]: t('sendDelete'),
  [actionValues.SmsToggleBlock]: t('unboundOrBlocked'),
};

const pnpTitleMap = {
  [actionValues.Initial]: t('immediateOrSchedule'),
  [actionValues.Pending]: t('immediateOrSchedule'),
  [actionValues.Deleted]: t('sendDelete'),
  [actionValues.SmsToggleBlock]: t('blocked'),
};

export const getTitle = (status) => titleMap[status];

export const getPnpTitle = (status) => pnpTitleMap[status];

export function sendingActionFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [(row) => filterValues[row.values.sendingAction.type]],
  });
}
