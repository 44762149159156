import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import styled from 'styled-components';

import { getTags, getPatientTrackings } from 'reducers/members';
import { fetchMembers } from 'lib/memberService';
import useAlert from 'hooks/useAlert';

import Popover from '@material-ui/core/Popover';
import Input from 'components/Dashboard_v2/Input';
import Button from 'components/Dashboard_v2/Button';
import FilterableTable from 'components/Table/FilterableTable';
import MemberProfile from 'feature/MemberCenter/MemberProfile';
import SelectedToolSection from './table/SelectedToolSection';
import CustomFilter from './table/CustomFilter';
import { useColumns, tableStyles } from './table/columns';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const IconButton = styled(Button)`
  margin-left: 16px;
  i {
    margin-right: 8px;
  }
`;

const MemberMenu = styled.div`
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    color: ${colors.SHADES_500};
    font-size: 16px;
    i {
      margin-right: 12px;
    }
    &:hover {
      color: ${colors.PRIMARY_500};
    }
  }
`;

function updateUrlQueries(method, key, value) {
  if (!method || !key) return;

  const url = new URL(window.location);

  switch (method) {
    case 'set':
      url.searchParams.set(key, value);
      break;
    case 'delete':
      url.searchParams.delete(key);
      break;
    default:
      break;
  }

  window.history.replaceState(null, '', url.toString());
}

const AllMembers = () => {
  const dispatch = useDispatch();
  const selectedIds = useSelector((state) => state.clients.selectedIds);
  const { renderAlert } = useAlert({ unmountClear: true });
  const [members, setMembers] = useState([]);
  const [pageObj, setPageObj] = useState({});
  const [memberMenuAnchorEl, setMemberMenuAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchQueries, setSearchQueries] = useState({});
  const [lastPayload, setLastPayload] = useState(null);
  const clientId = selectedIds[0];

  const tableHeaderColumns = useColumns();

  const openMemberMenu = useCallback((event) => {
    setMemberMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMemberMenu = useCallback(() => {
    setMemberMenuAnchorEl(null);
  }, []);

  const handleFilterUpdate = useCallback((filter) => {
    setCurrentPage(1);
    setSearchQueries((prev) => ({ ...prev, filter }));
  }, []);

  const handleInputOnChange = useCallback((e) => {
    setSearchKeyword(e.target.value);
  }, []);

  const handleInputEnter = (e) => {
    if (e.keyCode === 13) {
      handleInputSubmit();
    }
  };

  const handleInputSubmit = useCallback(() => {
    setCurrentPage(1);
    setSearchQueries((prev) => ({ ...prev, searchKeyword }));
    updateUrlQueries('set', 'search', searchKeyword);
  }, [searchKeyword]);

  const handleInputClear = useCallback(() => {
    setSearchKeyword('');
    if (searchQueries.searchKeyword) {
      setCurrentPage(1);
      setSearchQueries((prev) => ({ ...prev, searchKeyword: '' }));
      updateUrlQueries('delete', 'search');
    }
  }, [searchQueries.searchKeyword]);

  const getMembers = async (forceFetch = false) => {
    const payload = {
      clientId,
      page: currentPage,
      size: currentPageSize,
      searchQueries,
    };

    if (!forceFetch && (isLoading || isEmpty(searchQueries) || lastPayload === JSON.stringify(payload))) return;

    try {
      setIsLoading(true);
      const { users, total } = await fetchMembers({ clientId, payload });
      setMembers(users || []);
      setPageObj({ total, currentPage, currentPageSize, setCurrentPage, setCurrentPageSize });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setLastPayload(JSON.stringify(payload));
    }
  };

  const isMemberMenuOpen = Boolean(memberMenuAnchorEl);

  useEffect(() => {
    dispatch(getTags({ clientId }));
    dispatch(getPatientTrackings({ clientId }));
  }, [dispatch, clientId]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get('search');
    if (searchParam) {
      setSearchKeyword(searchParam);
      setCurrentPage(1);
      setSearchQueries((prev) => ({ ...prev, searchKeyword: searchParam }));
    }
  }, []);

  useEffect(() => {
    getMembers();
  }, [currentPage, currentPageSize, searchQueries]); // eslint-disable-line

  return (
    <React.Fragment>
      {renderAlert()}
      <ToolBar>
        <Input
          onChange={handleInputOnChange}
          onKeyDown={handleInputEnter}
          onInputClear={handleInputClear}
          showClearButton={!!searchKeyword}
          value={searchKeyword}
          suffix={<i className="ri-search-line" onClick={handleInputSubmit} />}
          placeholder={t('searchBy')}
          width={400}
          decoration
        />
        <div>
          {/* @TODO: hidden filter for later phase */}
          <IconButton color="secondary" variant="outline" hidden>
            <i className="ri-filter-line" />
            {t('filter')}
          </IconButton>
          <IconButton color="primary" variant="filled" onClick={openMemberMenu}>
            <i className="ri-add-line" />
            {t('memberAction')}
          </IconButton>
          {isMemberMenuOpen && (
            <Popover
              open={isMemberMenuOpen}
              anchorEl={memberMenuAnchorEl}
              onClose={closeMemberMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{
                marginTop: '8px',
              }}
            >
              <MemberMenu>
                <Link to="/members/create">
                  <i className="ri-user-add-line" />
                  {t('addMember')}
                </Link>
                <Link to="/members/import">
                  <i className="ri-upload-line" />
                  {t('importList')}
                </Link>
                <Link to="/members/merge">
                  <i className="ri-merge-cells-horizontal" />
                  {t('mergeMember.title')}
                </Link>
              </MemberMenu>
            </Popover>
          )}
        </div>
      </ToolBar>
      {tableHeaderColumns && (
        <FilterableTable
          tableStyles={tableStyles}
          tableHeaderColumns={tableHeaderColumns}
          selectedToolSection={
            <SelectedToolSection
              dataCount={members.length}
              searchQueries={searchQueries}
              getMembers={getMembers}
              allUserCount={pageObj.total}
            />
          }
          loading={isLoading}
          data={members}
          columnSettable
          showToolSection
          customFilters={<CustomFilter onFilterUpdate={handleFilterUpdate} />}
          pageObj={pageObj}
        />
      )}
      <MemberProfile />
    </React.Fragment>
  );
};

export default AllMembers;
