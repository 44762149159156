export const agreement = `
ㄧ、服務使用責任與義務
(一)您於使用本網站服務時，除應遵守中華民國法令、國際網際網路規定與慣例及本條款規範外，並不得有下列行為，如您違反規定，致本公司或關係企業、受僱/任人、代理人及其他履行輔助人受有損害或支出費用（包括但不限於訴訟費及律師費）者，應負損害賠償責任。如有涉犯刑事犯罪者，本公司並得移請主管機關或司法單位處理。
1. 未經同意複製、轉載、轉售他人資訊。

2. 未經本公司許可，利用本網站服務所提供之資源，包括但不限於圖文、網頁、程式碼等，從事任何商業交易行為，或招攬廣告商或贊助人。

3. 冒用他人名義註冊為網路會員或擅自使用他人驗證碼。

4. 傳輸或散佈電腦病毒或為其他任何可能破壞、毀損或干擾本公司本服務之行為。

5. 其他違反本條款之行為。

(二)您應妥善保管使用者驗證碼，勿將該組驗證碼洩露予第三人知悉、出借或轉讓第三人使用，並為該組驗證碼登入本服務系統後所進行之一切活動負責，包括查詢、檢索、閱覽、更改個人資料、參與各項活動、取得消費資訊、購買商品或服務及其他涉及個人權益之類似行為等。如有外洩或遭到盜用等，應即通知本公司，惟本公司不對損害負擔任何責任。

(三)本網站所提供登入之驗證碼皆應視為機密，接收前述簡訊驗證碼之手機號碼申裝人應予以嚴格保密，如未盡保密義務所生之損害概由接收前述簡訊驗證碼之手機號碼申裝人及使用本服務之操作方自行負責，本公司不對損害負擔任何責任。

二、免責聲明

(一)本公司就網站服務不負任何明示或默示之擔保責任，且不保證網站服務之穩定、安全、無誤及不中斷，亦不保證本網站資訊內容之正確、完整及適當得宜。但本公司願對您的申訴進行妥適瞭解，並適度反應予提供資訊或產品之廠商。

(二)本網站如有無法使用、失誤刪除、備份儲存失敗等情形，致生有直接、間接、衍生、或特別損害等，本公司均不負任何賠償責任。
三、本條款修訂權利

本公司有權隨時修正本條款，修正之內容自本網站公告時起生效，不另行個別通知，您不得因此而要求任何補償或賠償。
`;
export const text = true;
