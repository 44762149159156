import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/MaterialDesign/Modal';
import { Confirm } from 'components/MaterialDesign/Buttons';
import TargetUserPage from './index';
import { t } from 'i18n/config';

const Container = styled.div`
  height: 100vh;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const BackButton = styled(Confirm)`
  right: 30px;
  position: absolute;
`;

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px;
`;

function UsersModal(props) {
  const { visible, onClose, clientID, campaignID, onRemove } = props;
  return (
    <Modal open={visible} onClose={onClose} styles={{ maxWidth: '100%', minWidth: '100%', maxHeight: '100%' }}>
      <Container>
        <ActionContainer>
          <BackButton onClick={onClose} startIcon={<i className="ri-arrow-left-s-line" />} height={40} width={110}>
            {t('goBack')}
          </BackButton>
          <Title>{t('numberOfRecipients')}</Title>
        </ActionContainer>
        <TargetUserPage clientID={clientID} campaignID={campaignID} onRemove={onRemove} />
      </Container>
    </Modal>
  );
}

UsersModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  clientID: PropTypes.string,
  campaignID: PropTypes.string,
  onRemove: PropTypes.func,
};

export default UsersModal;
