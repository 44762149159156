import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import theme from 'theme';

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.07px;
  text-align: center;
  color: $body;
  padding: 10px 0;
  border-bottom: 1px solid #ebe8e8;
`;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  backDrop: {
    backgroundColor: theme.colors.body38,
  },
  papper: {
    position: 'absolute',
    width: (props) => props.width || 485,
    maxWidth: (props) => props.maxWidth || 600,
    minWidth: (props) => props.minWidth || '33vw',
    backgroundColor: 'white',
    borderRadius: (props) => props.borderRadius || '4px',
    '@media screen and (max-width: 647px)': {
      maxWidth: '80%',
    },
    boxShadow: 'none',
    margin: 0,
    maxHeight: (props) => props.maxHeight || 'calc(100% - 64px)',
  },
}));

export default function ModalBase({ onClose, open, title, children, styles }) {
  const classes = useStyles({ ...styles });

  return (
    <Dialog
      classes={{
        root: classes.root,
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.papper,
        },
      }}
    >
      <div>
        {title && <Title>{title}</Title>}
        {children}
      </div>
    </Dialog>
  );
}

ModalBase.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.shape(),
};
