import XLSX from 'xlsx';
import { set } from 'date-fns';
import { phoneFilter } from 'lib/vendors/teetchScalingParser/utils';
import format from 'date-fns/format';

export default function Leyan() {
  this.parse = (xls, rABS) => {
    const wb = XLSX.read(xls, {
      type: rABS ? 'binary' : 'array',
      cellDates: true,
    });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 'A', raw: true });
    const res = data
      .filter((d, i) => i !== 0)
      .filter((d) => d.D != null && d.D !== '')
      .map((d) => {
        let phone = `${d.D}`;
        if (phone[0] !== '0') {
          phone = `0${phone}`;
        }
        return {
          hisID: '',
          name: d.B,
          phone,
          doctor: '',
          birth: d.C,
          lastVisit: d.I ? new Date(format(d.I, 'yyyy/MM/dd')) : new Date(),
          lastTeethScaling: d.I ? new Date(format(d.I, 'yyyy/MM/dd')) : new Date(),
        };
      })
      .filter(phoneFilter);

    return res;
  };

  this.bindCampaign = (list, campaign, options) => {
    const { delaySeconds } = campaign;
    return list.map((l) => {
      let estimatedDeliveryTime = new Date(l.lastTeethScaling.getTime() + delaySeconds * 1000);
      if (options && options.setDateValues) {
        estimatedDeliveryTime = set(estimatedDeliveryTime, options.setDateValues);
      }
      return {
        ...l,
        estimatedDeliveryTime,
      };
    });
  };
}
