import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { t } from 'i18n/config';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
`;
function areAllValidationTrue(obj) {
  return Object.values(obj).every((value) => value === true);
}

export default function SaveButton({ handleClick, validationState }) {
  return (
    <ButtonContainer>
      <Button
        disabled={!areAllValidationTrue(validationState)}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        {t('review_settings.save')}
      </Button>
    </ButtonContainer>
  );
}
