import { AUTH_LOGOUT } from 'reducers/auth';
import { fetchAppointmentsApi } from 'lib/westernAppointmentService';
import { demoMiddleWare } from 'demo';
import { normalizeIds } from 'helpers';

const SET_APPOINTMENTS = 'WESTERN/APPOINTMENTS/SET_APPOINTMENTS';
const CLEAR_APPOINTMENTSIDS = 'WESTERN/APPOINTMENTS/CLEAR_APPOINTMENTSIDS';

const normalizeById = (ary) => {
  const byId = {};
  ary.forEach(({ id, ...rest }) => {
    byId[id] = {
      id,
      ...rest,
    };
  });
  return byId;
};

const defaultState = {};

const setAppointments = (appointmentsByClientId) => ({
  type: SET_APPOINTMENTS,
  payload: appointmentsByClientId,
});

const clearAppointmentsIds = ({ loading }) => ({
  type: CLEAR_APPOINTMENTSIDS,
  payload: {
    loading,
  },
});

export const clearAppointments = () => async (dispatch) => {
  dispatch(clearAppointmentsIds({ loading: true }));
};

export const fetchTotalAppointmentsInQue =
  ({ startTime: start, sendingStatus }) =>
  async (dispatch, getState) => {
    const { selectedIds } = getState().clients;
    dispatch(clearAppointmentsIds({ loading: true }));
    const clients = getState().clients.selectedIds.map((id) => getState().clients.byId[id]);

    const promises = selectedIds.map((id) =>
      demoMiddleWare(
        clients,
        {
          clientId: id,
          startTime: start.startOf('day').unix() * 1000,
          endTime: start.endOf('day').unix() * 1000,
          sendingStatus,
        },
        fetchAppointmentsApi
      )
    );
    Promise.all(promises).then((appointmentsArray) => {
      const appointmentsByClientId = {};
      selectedIds.forEach((clientId, i) => {
        appointmentsByClientId[clientId] = {
          sendingStatus,
          byId: normalizeById(appointmentsArray[i]),
          Ids: normalizeIds(appointmentsArray[i]),
          loading: false,
        };
      });
      dispatch(setAppointments(appointmentsByClientId));
    });
  };

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return defaultState;
    case SET_APPOINTMENTS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_APPOINTMENTSIDS: {
      return Object.keys(state).reduce((sum, temp) => {
        const res = sum;
        res[temp] = {
          ...state[temp],
          Ids: [],
          loading: action.payload.loading,
        };
        return sum;
      }, {});
    }
    default:
      return state;
  }
};
