import React, { useState, useEffect, useMemo, useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getGroupMessages } from 'lib/messageCenterService';
import { dashboard_v2 } from 'theme';
import connectData from 'components/HOCs/connectData';
import SideBar from '../Sidebar';
import DoctorSvg from 'components/Dashboard_v2/Svg/DoctorSvg';
import Input from 'components/Dashboard_v2/Input';
import Button from 'components/Dashboard_v2/Button';
import FilterableTable from 'components/Table/FilterableTable';
import tableHeaderColumns, { tableStyles } from './table/columns';
import useAlert from 'hooks/useAlert';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  margin: 0 -30px;
`;

const Main = styled.div`
  padding: 30px 30px 30px 86px;
`;

const Header = styled.div`
  padding: 5px 30px 0 30px;
  margin: -30px -30px 30px;
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SectionTitle = styled.h5`
  margin-top: 40px;
  font-size: 28px;
`;

const SectionDescription = styled.p`
  margin: 24px 0;
  font-size: 14px;
  color: ${colors.SHADES_400};
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
`;

const StatusFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const StatusFilter = styled.div`
  padding: 12px 24px;
  font-size: 16px;
  color: ${({ isSelected }) => (isSelected ? colors.PRIMARY_500 : colors.SHADES_500)};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${colors.PRIMARY_500};
  }
`;

const Content = styled.div``;

const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
`;

const STATUS_FILTER_MAP = [
  { id: 0, name: t('all'), value: 'all' },
  { id: 1, name: t('draft'), value: 'draft' },
  { id: 2, name: t('scheduled'), value: 'pending' },
  { id: 3, name: t('sent'), value: 'done' },
];

const groupListData = (list) =>
  list.reduce(
    (group, data) => {
      if (!group[data.status]) {
        group[data.status] = [data];
      } else {
        group[data.status].push(data);
      }
      group['all'].push(data);
      return group;
    },
    { all: [], draft: [], pending: [], done: [] }
  );

const GroupMessageList = () => {
  const { renderAlert, setAlert } = useAlert({ unmountClear: true });
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const [isInit, setIsInit] = useState(false);
  const [listData, setListData] = useState(null);
  const [statusFilter, setStatusFilter] = useState(STATUS_FILTER_MAP[0]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageObj, updatePageObject] = useReducer((prev, next) => ({ ...prev, ...next }), {
    total: 0,
    currentPage: 1,
    currentPageSize: 20,
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
  };

  const handleInputClear = () => {
    setSearchKeyword('');
  };

  const filteredList = useMemo(() => {
    return listData && listData[statusFilter.value].filter((data) => data.title.includes(searchKeyword));
  }, [listData, statusFilter, searchKeyword]);

  const getGroupMessageList = useCallback(async () => {
    try {
      const { groupMessages, page, size, totalElements } = await getGroupMessages({
        clientId,
        size: pageObj.currentPageSize,
        page: pageObj.currentPage,
      });
      updatePageObject({ currentPage: page, currentPageSize: size, total: totalElements });
      setListData(groupListData(groupMessages));
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: t('loadListFailed', { error: error || e.message }) });
      console.error(errRes);
    } finally {
      setIsInit(true);
    }
  }, [clientId, setAlert, pageObj]);

  useEffect(() => {
    getGroupMessageList();
  }, [pageObj.currentPage, pageObj.currentPageSize]); // eslint-disable-line

  return (
    <Container className="animated fadeIn">
      {renderAlert()}
      <SideBar />
      <Main>
        <Header>
          <SectionTitle>{t('groupMessage')}</SectionTitle>
        </Header>
        <Content>
          {isInit && (
            <div>
              <ToolBar>
                <Input
                  value={searchKeyword}
                  onChange={handleInputChange}
                  onInputClear={handleInputClear}
                  showClearButton={!!searchKeyword}
                  prefix={<i className="ri-search-line" />}
                  placeholder={t('searchGroupMessage')}
                />
                <Link to="/message_center/group_message/create">
                  <IconButton color="primary" variant="filled">
                    <i className="ri-add-line" />
                    {t('create')}
                  </IconButton>
                </Link>
              </ToolBar>

              {listData && listData.all.length ? (
                <React.Fragment>
                  <StatusFilterWrapper>
                    {STATUS_FILTER_MAP.map((status) => (
                      <StatusFilter
                        onClick={() => setStatusFilter(status)}
                        isSelected={statusFilter.value === status.value}
                        key={status.id}
                      >
                        {status.name}
                      </StatusFilter>
                    ))}
                  </StatusFilterWrapper>
                  <FilterableTable
                    tableStyles={tableStyles}
                    tableHeaderColumns={tableHeaderColumns}
                    data={filteredList}
                    shouldRowsRemoved={true}
                    pageObj={{
                      total: pageObj.total,
                      currentPage: pageObj.currentPage,
                      currentPageSize: pageObj.currentPageSize,
                      setCurrentPage: (page) => updatePageObject({ currentPage: page }),
                      setCurrentPageSize: (size) => updatePageObject({ currentPageSize: size }),
                    }}
                  />
                </React.Fragment>
              ) : (
                <EmptyBlock>
                  <DoctorSvg />
                  <SectionDescription>{t('noReadyGroupMessage')}</SectionDescription>
                  <Link to="/message_center/group_message/create">
                    <IconButton variant="ghost" color="primary">
                      <i className="ri-add-line"></i>
                      {t('createGroupMessage')}
                    </IconButton>
                  </Link>
                </EmptyBlock>
              )}
            </div>
          )}
        </Content>
      </Main>
    </Container>
  );
};

export default connectData(GroupMessageList);
