import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';
import Snackbar from 'components/MaterialDesign/Snackbar';
import { Styles, TableWrap } from './styles';
import Table from './table';

const makeFilterOptionsByAccessor = (data, columns) => {
  const columnFilterabled = columns[0].columns
    .filter(({ filterable }) => filterable)
    .reduce((s, t) => {
      s[t.accessor] = new Set();
      return s;
    }, {});
  const keys = Object.keys(columnFilterabled);
  data.forEach((d) => {
    keys.forEach((k) => {
      columnFilterabled[k] = columnFilterabled[k].add(d[k]);
    });
  });
  Object.keys(columnFilterabled).forEach((k) => {
    columnFilterabled[k] = Array.from(columnFilterabled[k]);
  });

  return columnFilterabled;
};

function FilterableTable({
  clientID,
  tableStyles,
  tableHeaderColumns,
  data: originalData,
  loading,
  onDateChange: onDateChangeParent,
  onDataRemoved,
  defaultDate,
  selectedToolSection,
  shouldRowsRemoved,
  onClickRow,
  showToolSection,
  columnSettable,
  customFilters,
  pageObj,
}) {
  const [date, setDate] = React.useState(defaultDate ? new Date(defaultDate) : defaultDate);
  const [data, setData] = React.useState(originalData);
  const [isLoading, setLoading] = useState(loading);
  const [openSnackStatus, setOpenSnackbar] = useState({
    open: false,
    message: '',
    status: '',
  });
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [columnSetting, setColumnSetting] = useState();

  const headerColumns = React.useMemo(() => {
    const data = tableHeaderColumns();
    if (columnSetting) {
      data[0].columns = data[0].columns
        .filter((column) => {
          const headName = column.headDataName || column.Header;
          return columnSetting.includes(headName);
        })
        .sort((a, b) => {
          const headNameA = a.headDataName || a.Header;
          const headNameB = b.headDataName || b.Header;
          return columnSetting.indexOf(headNameA) - columnSetting.indexOf(headNameB);
        });
    }
    return data;
  }, [tableHeaderColumns, columnSetting]);

  const handleColumnSettingUpdate = useCallback((setting) => {
    setColumnSetting(setting.map((setting) => setting.name));
  }, []);

  const updateMyData = useCallback((rowIndex, columnId, value, values) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return values
            ? { ...old[rowIndex], ...values }
            : {
                ...old[rowIndex],
                [columnId]: value,
              };
        }
        return row;
      })
    );
  }, []);

  const removeRow = useCallback((rowIndex) => {
    setSkipPageReset(true);
    setData((old) => {
      const res = old.filter((row, index) => {
        if (index === rowIndex) {
          return false;
        }
        return true;
      });
      const removed = old.filter((row, index) => {
        if (index === rowIndex) {
          return true;
        }
        return false;
      });
      if (onDataRemoved) {
        onDataRemoved(res, removed);
      }
      return res;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const batchUpdateMyData = useCallback((rowByIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (rowByIndex[index]) {
          return {
            ...old[index],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }, []);

  const batchRemoveRows = useCallback((rowByIndex) => {
    setSkipPageReset(true);
    setData((old) => {
      const remained = old.filter((row, index) => {
        if (rowByIndex[index]) {
          return false;
        }
        return true;
      });
      const removed = old.filter((row, index) => {
        if (rowByIndex[index]) {
          return true;
        }
        return false;
      });
      if (onDataRemoved) {
        onDataRemoved(remained, removed);
      }
      return remained;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setData(originalData);
  }, [date, originalData]);

  useEffect(() => {
    setSkipPageReset(false);
  }, [data, date]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const filterOptionsByAccessor = React.useMemo(
    () => makeFilterOptionsByAccessor(data, headerColumns),
    [data, headerColumns]
  );

  const onDateChange = useCallback((d) => {
    const newDate = new Date(d);
    setDate(new Date(d));
    onDateChangeParent(newDate);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openSnackbar = useCallback(({ message, status, autoHideDuration }) => {
    setOpenSnackbar({
      open: true,
      message,
      status,
      autoHideDuration,
    });
  }, []);

  const handleClose = useCallback(() => {
    setOpenSnackbar({ open: false });
  }, []);

  const hiddenColumns = React.useMemo(
    () => headerColumns[0].columns.filter((column) => column.hidden).map((column) => column.accessor),
    [headerColumns]
  );

  return (
    <React.Fragment>
      <Styles>
        <TableWrap>
          <Table
            styles={tableStyles}
            clientID={clientID}
            columns={headerColumns}
            hiddenColumns={hiddenColumns}
            data={data}
            filterOptionsByAccessor={filterOptionsByAccessor}
            selectedDate={date}
            selectedToolSection={selectedToolSection}
            onDateChange={onDateChange}
            updateMyData={updateMyData}
            openSnackbar={openSnackbar}
            batchUpdateMyData={batchUpdateMyData}
            removeRow={shouldRowsRemoved ? removeRow : undefined}
            batchRemoveRows={shouldRowsRemoved ? batchRemoveRows : undefined}
            skipPageReset={skipPageReset}
            loading={isLoading}
            onClickRow={onClickRow}
            showToolSection={showToolSection}
            columnSettable={columnSettable}
            handleColumnSettingUpdate={handleColumnSettingUpdate}
            customFilters={customFilters}
            pageObj={pageObj}
          />
        </TableWrap>
      </Styles>
      <Snackbar
        open={openSnackStatus.open}
        message={openSnackStatus.message}
        status={openSnackStatus.status}
        autoHideDuration={openSnackStatus.autoHideDuration}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
}

FilterableTable.defaultProps = {
  tableStyles: {},
};

FilterableTable.propTypes = {
  clientID: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  tableHeaderColumns: PropTypes.func,
  loading: PropTypes.bool,
  onDateChange: PropTypes.func,
  onDataRemoved: PropTypes.func,
  onClickRow: PropTypes.func,
  defaultDate: PropTypes.number,
  selectedToolSection: PropTypes.shape(),
  tableStyles: PropTypes.shape(),
  shouldRowsRemoved: PropTypes.bool,
  showToolSection: PropTypes.bool,
  columnSettable: PropTypes.bool,
  customFilters: PropTypes.element,
};

export default React.memo(FilterableTable, areEqual);
