import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import Popover from 'components/MaterialDesign/Popover';
import Button from 'components/Dashboard_v2/Button';
import { dashboard_v2 } from 'theme';
import { Loading } from 'components/MaterialDesign/Loading';
import { ROLE } from 'lib/openaiService';
import Tooltip from 'components/Dashboard_v2/Tooltip';
import HintInfo from 'components/Dashboard_v2/HintInfo';
import HintImage from 'components/Dashboard_v2/HintInfo/openaiReminderHint.png';
import {
  EditContainer as Container,
  EditHeader as Header,
  EditSection,
  EditHeaderTitle as Title,
  TextArea,
  Alert,
  TextRow,
  BreakLine,
} from '../styledComponents';
import { t } from 'i18n/config';

export default function EditReminderPopover({
  open,
  anchorEl,
  handleClose,
  editedReminder,
  setEditedReminder,
  handleOpenAI,
  openSnackbar,
  maxLength,
}) {
  const [openaiLoading, setOpenaiLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDisplayTextExceedToast = useCallback(
    useAsyncDebounce(() => {
      openSnackbar({ message: t('exceededWordLimit'), status: 'error' });
    }, 200),
    []
  );

  const onTextChange = useCallback(
    (event) => {
      const v = event.target.value;
      setEditedReminder(v);
      if (event.target.value.length > maxLength) {
        onDisplayTextExceedToast();
      }
    },
    [maxLength, setEditedReminder, onDisplayTextExceedToast]
  );

  const handleClickOpenAI = useCallback(async () => {
    setOpenaiLoading(true);
    await handleOpenAI({
      role: ROLE.PATIENT_REMINDER_BOT,
      content: editedReminder,
      args: [50],
      callback: (msg) => {
        onTextChange({ target: { value: msg } });
      },
    });
    setOpenaiLoading(false);
  }, [onTextChange, editedReminder, handleOpenAI]);

  const isexceedText = editedReminder.length > maxLength;

  const openAIEnabled = handleOpenAI != null;

  return (
    <Popover open={open} anchorEl={anchorEl} handleClose={handleClose}>
      <Container>
        <Header>
          <Title>{t('patientReminder')}</Title>
        </Header>
        <BreakLine />
        <EditSection customcolor={dashboard_v2.colors.SHADES_400}>
          <TextArea value={editedReminder} onChange={onTextChange} />
        </EditSection>
        <TextRow>
          {isexceedText && <Alert className="ri-alert-fill" />} {editedReminder.length} / {maxLength}
        </TextRow>
        <TextRow justifyContent={'flex-start'}>
          <Button
            variant="outline"
            color="secondary"
            size="small"
            fontSize={14}
            onClick={handleClickOpenAI}
            disabled={!openAIEnabled || openaiLoading}
          >
            {openaiLoading ? (
              <Loading size={18} customcolor={dashboard_v2.colors.SHADES_400}></Loading>
            ) : (
              <Tooltip
                popoverContent={
                  <HintInfo
                    image={HintImage}
                    title={t('autoGenerateMessage')}
                    description={t('messageWithSecondThirdReminder')}
                  ></HintInfo>
                }
                type={'CENTER_LEFT'}
                popoverStyle={{ margin: -12 }}
              >
                <i className="ri-sparkling-2-fill"></i>
              </Tooltip>
            )}
          </Button>
        </TextRow>
      </Container>
    </Popover>
  );
}

EditReminderPopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  editedReminder: PropTypes.string,
  setEditedReminder: PropTypes.func,
  openSnackbar: PropTypes.func,
  maxLength: PropTypes.number,
  clientID: PropTypes.string,
};
