import Tag from 'components/Dashboard_v2/Tag';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const getTagSettings = ({ recyclable, isEnabled, isExpired }) => ({
  icon: isEnabled && recyclable ? 'ri-repeat-2-fill' : null,
  color: !isEnabled || isExpired ? colors.SHADES_300 : colors.SHADES_500,
  dotColor: (() => {
    if (isEnabled && (isExpired || recyclable)) return null;
    return isEnabled ? colors.SYSTEM_SUCCESS_500 : colors.SYSTEM_ERROR_500;
  })(),
  iconColor: isExpired ? colors.SHADES_300 : colors.PRIMARY_400,
  borderColor: colors.SHADES_300,
  borderRadius: '4px',
  selectedBorderColor: colors.SHADES_500,
  selectedBackgroundColor: colors.SHADES_100,
});

export default function PatientTrackingTag({ setting = {}, ...restProps }) {
  return <Tag settings={getTagSettings(setting)} {...restProps} />;
}
