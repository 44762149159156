import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  border: none;
  border-radius: 8px;
  letter-spacing: 0.02em;
  transition: 0.3s;
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '4px 12px;';
      default:
        return '8px 16px;';
    }
  }};

  ${({ variant, color, disabled }) => {
    if (variant === 'filled') {
      const setting = {};
      if (color === 'primary') {
        setting.color = colors.SHADES_000;
        setting.bgColor = colors.PRIMARY_500;
        setting.hoverColor = colors.SHADES_000;
        setting.hoverBgColor = colors.PRIMARY_400;
      }
      if (color === 'secondary') {
        setting.color = colors.SHADES_500;
        setting.bgColor = colors.SHADES_100;
        setting.hoverColor = colors.SHADES_600;
        setting.hoverBgColor = colors.SHADES_200;
      }
      if (color === 'error') {
        setting.color = colors.SHADES_000;
        setting.bgColor = colors.SYSTEM_ERROR_500;
        setting.hoverColor = colors.SHADES_000;
        setting.hoverBgColor = colors.SYSTEM_ERROR_400;
      }
      return `
        color: ${setting.color};
        background-color: ${setting.bgColor};
        &:hover {
          color: ${setting.hoverColor};
          background-color: ${setting.hoverBgColor};
        }
        ${
          disabled &&
          `
            &,
            &:hover {
              cursor: not-allowed;
              color: ${colors.SHADES_300};
              background-color: ${colors.SHADES_100};
            }
          `
        }
      `;
    }

    if (variant === 'outline') {
      const setting = {};
      if (color === 'primary') {
        setting.color = colors.PRIMARY_400;
        setting.borderColor = colors.PRIMARY_400;
        setting.hoverColor = colors.PRIMARY_500;
        setting.hoverBgColor = colors.PRIMARY_100;
        setting.hoverBorderColor = colors.PRIMARY_500;
      }
      if (color === 'secondary') {
        setting.color = colors.SHADES_500;
        setting.borderColor = colors.SHADES_400;
        setting.hoverColor = colors.SHADES_500;
        setting.hoverBgColor = colors.SHADES_50;
        setting.hoverBorderColor = colors.SHADES_500;
      }
      if (color === 'error') {
        setting.color = colors.SYSTEM_ERROR_400;
        setting.borderColor = colors.SYSTEM_ERROR_400;
        setting.hoverColor = colors.SYSTEM_ERROR_500;
        setting.hoverBgColor = colors.SYSTEM_ERROR_100;
        setting.hoverBorderColor = colors.SYSTEM_ERROR_500;
      }
      return `
        color: ${setting.color};
        border: 1px solid ${setting.borderColor};
        background-color: ${colors.SHADES_000};
        transition: 0.3s;
        &:hover {
          color: ${setting.hoverColor};
          background-color: ${setting.hoverBgColor};
          border-color: ${setting.hoverBorderColor};
        }
        ${
          disabled &&
          `
            &,
            &:hover {
              cursor: not-allowed;
              color: ${colors.SHADES_300};
              border-color:  ${colors.SHADES_200};
              background-color: ${colors.SHADES_000};
            }
          `
        }
      `;
    }

    if (variant === 'ghost') {
      const setting = {};
      if (color === 'primary') {
        setting.color = colors.PRIMARY_500;
        setting.hoverColor = colors.PRIMARY_600;
      }
      if (color === 'secondary') {
        setting.color = colors.SHADES_400;
        setting.hoverColor = colors.SHADES_500;
      }
      if (color === 'error') {
        setting.color = colors.SYSTEM_ERROR_500;
        setting.hoverColor = colors.SYSTEM_ERROR_600;
      }
      return `
        color: ${setting.color};
        border: none;
        background-color: transparent;
        &:hover {
          color: ${setting.hoverColor};
        }
        ${
          disabled &&
          `
            &,
            &:hover {
              cursor: not-allowed;
              color: ${colors.SHADES_300};
            }
          `
        }
      `;
    }
  }}
`;

const Button = ({
  fullWidth,
  color = 'primary',
  variant = 'filled',
  disabled,
  onClick = () => {},
  children,
  ...restProps
}) => {
  return (
    <StyledButton
      color={color}
      variant={variant}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

const propTypes = {
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.propTypes = propTypes;

export default memo(Button);
