import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  width: 64px;
  height: 12px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 4px;
  border: 0px;
`;

function LoadingCell() {
  return <Box />;
}

export default LoadingCell;
