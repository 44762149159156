import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import { ConditionLabel } from 'components/Dashboard_v2/ConditionSelector';
import ActionCell from './ActionCell';
import { t } from 'i18n/config';

const DATE_FORMAT = 'YYYY/MM/DD[\n]h:mm:ss a';

const InfoHead = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 8px;
  }
`;

const DateLabel = styled.div`
  white-space: pre-line;
`;

const ConditionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 12px;
`;

export const columns = [
  {
    Header: t('segmentName'),
    accessor: 'name',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: () => (
      <InfoHead>
        <InfoPopover popoverContent={t('segmentDescription')} />
        <span>{t('estimatedNumbers')}</span>
      </InfoHead>
    ),
    accessor: 'usersCount',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: t('updateTime'),
    accessor: 'updatedAt',
    Cell: ({ value: date }) => <DateLabel>{moment(date).format(DATE_FORMAT)}</DateLabel>,
    minWidth: 140,
    maxWidth: 140,
  },
  {
    Header: t('tags'),
    accessor: 'conditions',
    Cell: ({ value: conditions }) => (
      <ConditionWrapper>
        {conditions.map((condition) => (
          <ConditionLabel type={condition.type} name={condition.name} key={condition.id} />
        ))}
      </ConditionWrapper>
    ),
  },
  {
    Header: '',
    accessor: 'action',
    Cell: ActionCell,
    disableSortBy: true,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
