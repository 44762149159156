import { getToken, MARKETING_URL, ENV_STAGE } from 'helpers';

import { responseHandler } from './responseHandler';

export const createMessageApi = ({ messages, lineIDs, clientId }) =>
  fetch(`${MARKETING_URL}/v1/groupMessages/clients/${clientId}/lineusers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'AP-stage': ENV_STAGE,
    },
    body: JSON.stringify({
      messages,
      lineIDs,
    }),
  }).then(responseHandler);

export const fetchMessageListApi = ({ clientID }) =>
  fetch(`${MARKETING_URL}/v1/groupMessages/clients/${clientID}`, {
    headers: {
      'AP-stage': ENV_STAGE,
    },
  })
    .then(responseHandler)
    .then((res) => res.messages);

export const createGroupMessageApi = ({ messages, lineIDs, phones, clientId, title, type, deliveryTime }) =>
  fetch(`${MARKETING_URL}/v1/groupMessages/clients/${clientId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'AP-stage': ENV_STAGE,
    },
    body: JSON.stringify({
      messages,
      lineIDs,
      phones,
      title,
      type,
      deliveryTime,
    }),
  }).then(responseHandler);

export const fetchLineUsersByTagsApi = ({ clientId, tags }) => {
  const queryStrings = tags.reduce((sum, temp) => `${sum}[]tagIDs=${temp}&`, '');
  const url = `${MARKETING_URL}/v1/tag-management/clients/${clientId}/tags/grouping?${queryStrings}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
    },
  }).then(responseHandler);
};

export const fetchTagListInClientApi = ({ clientId }) => {
  const url = `${MARKETING_URL}/v1/tag-management/clients/${clientId}/tags`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
    },
  }).then(responseHandler);
};

export const uploadImageApi = ({ clientId, data }) => {
  const url = `${MARKETING_URL}/v1/groupMessages/clients/${clientId}/images`;
  console.log(data);
  return fetch(url, {
    method: 'POST',
    headers: {
      'AP-stage': ENV_STAGE,
    },
    body: data,
  }).then(responseHandler);
};

export const getConsumption = ({ clientId }) => {
  const url = `${MARKETING_URL}/v1/groupMessages/clients/${clientId}/quota/consumption`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
    },
  })
    .then(responseHandler)
    .catch((err) => {
      throw new Error(err.error);
    });
};

export const fetchPnpConsumptionApi = ({ startTime, endTime, clientId }) => {
  const url = `${MARKETING_URL}/v1/groupMessages/clients/${clientId}/pnp/consumption?startTime=${startTime}&endTime=${endTime}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .catch((err) => {
      throw new Error(err.error);
    });
};

export const fetchPnpAppointmentDeliverySuccessListApi = ({ startTime, endTime, clientId }) => {
  const url = `${MARKETING_URL}/v1/groupMessages/clients/${clientId}/pnp?startTime=${startTime}&endTime=${endTime}&type=appointments`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .catch((err) => {
      throw new Error(err.error);
    });
};

export const manualVerifyUser = ({ clientID, lineID, phone, passcode }) => {
  const url = `${MARKETING_URL}/v2/clients/${clientID}/lineUsers/${lineID}/manualPassVerify`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'AP-stage': ENV_STAGE,
    },
    body: JSON.stringify({
      phone,
      passcode,
    }),
  })
    .then(responseHandler)
    .catch(async (err) => {
      const error = await err;
      throw new Error(error.error);
    });
};
