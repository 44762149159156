import { AUTH_LOGOUT } from './auth';
import nanoid from 'nanoid';

export const REFRESH_PAGE = 'REFRESH_PAGE';
const ADD_ALERT = 'UI/ADD_ALERT';
const REMOVE_ALERT = 'UI/REMOVE_ALERT';
const defaultState = {
  isRefresh: false,
  alerts: [],
  removedAlerts: [],
};

export const doneRefreshPage = () => ({
  type: REFRESH_PAGE,
  payload: false,
});

export const refreshPage = (isRefresh) => ({
  type: REFRESH_PAGE,
  payload: isRefresh,
});

export const addAlert = ({ id, type, message }) => ({
  type: ADD_ALERT,
  payload: {
    id: id || nanoid(),
    type,
    message,
  },
});

export const removeAlert = (id) => ({
  type: REMOVE_ALERT,
  payload: id,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case REFRESH_PAGE:
      return {
        ...state,
        isRefresh: action.payload,
      };
    case AUTH_LOGOUT:
      return defaultState;
    case ADD_ALERT: {
      const isDuplicate =
        state.alerts.some((alert) => alert.message === action.payload.message) ||
        state.removedAlerts.includes(action.payload.message);
      return isDuplicate
        ? state
        : {
            ...state,
            alerts: [...state.alerts, action.payload],
          };
    }
    case REMOVE_ALERT: {
      const removeAlert = state.alerts.find((alert) => alert.id === action.payload);
      let newRemovedAlerts = [...state.removedAlerts];
      if (removeAlert.type === 'announcement') {
        newRemovedAlerts = [...state.removedAlerts, removeAlert.message];
      }
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload),
        removedAlerts: newRemovedAlerts,
      };
    }

    default:
      return state;
  }
};
