import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dashboard_v2 } from 'theme';
import Text from 'components/Dashboard_v2/Text';
import { getUserPatientTrackings } from 'lib/memberService';
import FilterableTable from 'components/Table/FilterableTable';
import EmptyIcon from 'components/icons/EmptyIcon';
import PatientTrackingDetail from './PatientTrackingDetail';
import { getFormattedDate } from '../';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: relative;
  tr {
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 24px;
  i {
    font-size: 20px;
    color: ${colors.SHADES_400};
  }
`;

const StatusDot = styled.div`
  margin-right: 12px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;

const Block = styled.div`
  ${({ flex }) => flex && `display: flex;`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ column }) => column && `flex-direction: column;`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${colors.SHADES_500};
  background-color: ${colors.SHADES_100};
  border-radius: 4px;
  white-space: nowrap;
`;

const StatusLabel = (status) => {
  const settingMap = {
    deleted: { text: t('removed'), dotColor: colors.SYSTEM_ERROR_500 },
    disabled: { text: t('disabled'), dotColor: colors.SYSTEM_ERROR_300 },
    inProcess: { text: t('inProgress'), dotColor: colors.SYSTEM_INFO_400 },
    done: { text: t('ended'), dotColor: colors.SHADES_300 },
  };
  const setting = settingMap[status] || { text: status, dotColor: null };
  return (
    <StatusWrapper>
      <StatusDot color={setting.dotColor} />
      {setting.text}
    </StatusWrapper>
  );
};

const DateTimeLabel = (timeStamp) => {
  return (
    <Block flex column>
      <Text font="Heading/X-Small/Regular" color="SHADES_900" block>
        {getFormattedDate(timeStamp, 'date')}
      </Text>
      <Text font="Heading/X-Small/Regular" color="SHADES_400">
        {getFormattedDate(timeStamp, 'time')}
      </Text>
    </Block>
  );
};

const patientTrackingListColumns = () => [
  {
    Header: 'PatientTrackingList',
    columns: [
      {
        Header: t('patientTracking'),
        accessor: 'title',
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ row, value: status }) => {
          const expiredAt = row.original.expiredAt;
          switch (status) {
            case 'deleted':
              return StatusLabel('deleted');
            case 'disabled':
              return StatusLabel('disabled');
            default:
              if (Date.now() > new Date(expiredAt)) {
                return StatusLabel('done');
              }
              return StatusLabel('inProcess');
          }
        },
      },
      {
        Header: t('specifiedSendingTime'),
        accessor: 'runAt',
        Cell: ({ value }) => DateTimeLabel(value),
      },
      {
        Header: t('creationTime'),
        accessor: 'createdAt',
        Cell: ({ value }) => DateTimeLabel(value),
      },
    ],
  },
];

const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};

const PatientTrackingList = ({ userId }) => {
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const [isLoading, setIsLoading] = useState(true);
  const [patientTrackings, setPatientTrackings] = useState([]);
  const [selectedPatientTracking, setSelectedPatientTracking] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getUserPatientTrackings({ clientId, userId })
      .then((data) => {
        setPatientTrackings(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId, userId]);

  return (
    !isLoading && (
      <Wrapper>
        <Title>
          <Text font="Heading/H6/Medium">{t('patientTracking')}</Text>
          <Link to="/message_center/patient_tracking" target="_blank">
            <i className="ri-arrow-right-up-line" color={colors.SHADES_400} />
          </Link>
        </Title>
        {patientTrackings.length ? (
          <FilterableTable
            tableStyles={tableStyles}
            tableHeaderColumns={patientTrackingListColumns}
            onClickRow={(data) => setSelectedPatientTracking({ id: data.id, title: data.title })}
            data={patientTrackings}
          />
        ) : (
          <Block flex column alignItems="center" justifyContent="center" margin="100px 0 0 0">
            <EmptyIcon />
            <Text font="Body/12px_Regular" color="SHADES_400" marginTop={40}>
              {t('noPatientTrackingAdded')}
            </Text>
          </Block>
        )}
        {selectedPatientTracking && (
          <PatientTrackingDetail
            id={selectedPatientTracking.id}
            title={selectedPatientTracking.title}
            userId={userId}
            clientId={clientId}
            handleClose={() => setSelectedPatientTracking(null)}
          />
        )}
      </Wrapper>
    )
  );
};

export default memo(PatientTrackingList);
