import React from 'react';
import Tag from 'components/Dashboard_v2/Tag';
import ActionCell from './ActionCell';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

export const columns = [
  {
    Header: t('tagName'),
    accessor: 'name',
    Cell: ({ value: tagName }) => (
      <Tag
        name={tagName}
        settings={{
          icon: 'ri-time-line',
          borderColor: colors.SYSTEM_SUCCESS_600,
          color: colors.SYSTEM_SUCCESS_600,
          selectedBackgroundColor: colors.SYSTEM_SUCCESS_100,
        }}
      />
    ),
  },
  {
    Header: '',
    accessor: 'action',
    Cell: ActionCell,
    disableSortBy: true,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
