import React from 'react';
import PropTypes from 'prop-types';
import 'components/Alerts/FixedCornerAlert.css';

const FixedCornerAlert = ({ message, onRemoveAlert }) => (
  <div className="general-alert">
    <div className="general-alert-message" dangerouslySetInnerHTML={{ __html: message }} />
    <div className="general-alert-button" onClick={onRemoveAlert} role="button">
      ×
    </div>
  </div>
);

FixedCornerAlert.propTypes = {
  message: PropTypes.string.isRequired,
  onRemoveAlert: PropTypes.func,
};

export default FixedCornerAlert;
