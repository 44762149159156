import React from 'react';

export default function DoctorSvg() {
  return (
    <svg width="62" height="144" viewBox="0 0 62 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.8908 142.519H42.8784C42.7432 142.519 42.6335 142.628 42.6335 142.763V143.156C42.6335 143.291 42.7432 143.4 42.8784 143.4H57.8908C58.026 143.4 58.1356 143.291 58.1356 143.156V142.763C58.1356 142.628 58.026 142.519 57.8908 142.519Z"
        fill="#88BED7"
      />
      <path
        d="M56.17 137.653H50.7631C50.7631 137.653 50.1159 139.043 46.5813 139.405C42.635 139.81 42.9155 142.519 42.9155 142.519H57.7046C57.7046 142.519 58.4175 140.468 56.17 137.653Z"
        fill="#40465C"
      />
      <path
        d="M32.2377 137.653H26.8327C26.8327 137.653 26.1854 139.131 22.6509 139.496C18.7243 139.9 18.985 142.519 18.985 142.519H33.7742C33.7742 142.519 34.4852 140.468 32.2377 137.653Z"
        fill="#40465C"
      />
      <path
        d="M34.0513 142.519H18.8588C18.7236 142.519 18.614 142.628 18.614 142.763V143.156C18.614 143.291 18.7236 143.4 18.8588 143.4H34.0513C34.1865 143.4 34.2961 143.291 34.2961 143.156V142.763C34.2961 142.628 34.1865 142.519 34.0513 142.519Z"
        fill="#88BED7"
      />
      <path
        d="M51.4833 61.1401C51.6165 65.4917 52.168 99.1655 56.0506 108.623C59.9857 118.208 57.7315 137.702 57.7315 137.702C52.3397 138.554 50.1494 137.608 50.1494 137.608C50.1494 137.608 42.3786 105.264 36.9108 82.6213C36.3592 91.4126 35.7852 104.488 36.711 110.869C38.1865 121.019 34.9766 137.738 34.9766 137.738C30.322 138.46 24.9846 137.738 24.9846 137.738C24.9846 137.738 23.9358 121.641 23.328 103.279C23.0466 94.7482 21.3937 63.398 21.4641 61.3193L51.4833 61.1401Z"
        fill="#88BED7"
      />
      <path
        d="M41.6453 19.0414C42.8581 19.0414 54.3604 21.333 54.3501 26.3422C54.3388 31.8287 48.5126 49.8935 48.9911 53.2057C49.4695 56.518 52.5922 60.8977 51.1551 61.5271C47.2829 63.225 34.3557 64.5729 21.4352 61.2776C21.4352 61.2776 22.7831 58.2346 20.4605 45.0167C19.1923 37.8041 18.8968 24.0046 19.9775 22.5675C21.0581 21.1304 27.5653 19.6445 29.9498 19.2027C32.3343 18.7609 41.6453 19.0414 41.6453 19.0414Z"
        fill="#88BED7"
      />
      <path
        d="M41.2646 19.001C40.9767 20.6613 37.7939 58.317 41.9006 77.0534C41.9006 77.0534 48.5607 77.9624 55.3681 75.4034C55.3681 75.4034 51.5024 51.853 51.2614 47.9301C51.0203 44.0072 54.0286 23.8094 52.2079 22.6969C46.1594 19.001 41.2646 19.001 41.2646 19.001Z"
        fill="white"
      />
      <path
        d="M29.5127 19.2588C29.5127 19.2588 30.4254 55.0263 30.628 76.8704C30.628 76.8704 24.0618 78.5138 18.396 75.9549C18.396 75.9549 20.622 49.5143 20.6539 46.1468C20.6858 42.7792 18.9401 22.8534 20.8527 21.8393C24.9679 19.6565 29.5127 19.2588 29.5127 19.2588Z"
        fill="white"
      />
      <path
        d="M59.7468 52.5288C64.5759 46.3537 58.9673 23.2375 50.811 22.2226C49.949 22.1156 48.1348 22.7685 48.0129 28.1866C47.9116 32.6986 49.8027 41.3032 50.7332 45.2468C43.8733 46.0244 33.4986 48.6819 31.9499 48.8235C30.2051 48.983 31.2679 54.6741 33.0427 54.8908C35.8943 55.2388 54.0107 56.749 59.7468 52.5288Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3816 38.1554C49.7383 40.4435 50.2159 43.2505 50.7011 45.3423L50.7583 45.5888L50.5082 45.6271C47.0767 46.1529 42.2324 46.9156 38.2675 47.6939C36.2839 48.0833 34.5271 48.4754 33.2789 48.8422C32.653 49.0262 32.1662 49.2007 31.8449 49.361C31.6828 49.4418 31.5771 49.5122 31.5173 49.5687C31.4865 49.5977 31.4768 49.6142 31.4744 49.6193C31.4753 49.6161 31.4768 49.6083 31.4751 49.5962L31.0106 49.6612C30.9845 49.4745 31.0908 49.3263 31.1951 49.2278C31.3041 49.1248 31.4574 49.0301 31.6356 48.9412C31.9948 48.7621 32.5137 48.5783 33.1467 48.3923C34.4163 48.0191 36.1905 47.6237 38.1772 47.2337C42.0574 46.472 46.7735 45.7259 50.1878 45.2017C49.7181 43.1224 49.2614 40.4293 48.9182 38.2276C48.7396 37.0819 48.5915 36.0676 48.488 35.3398C48.4363 34.9759 48.3957 34.6835 48.3681 34.4821C48.3542 34.3813 48.3436 34.3033 48.3365 34.2505C48.3329 34.2241 48.3302 34.2039 48.3284 34.1904L48.3257 34.1701L48.3257 34.1699C48.3257 34.1698 48.3257 34.1698 48.5581 34.1388C48.7906 34.1078 48.7906 34.1079 48.7906 34.1079L48.7906 34.1081L48.7933 34.128C48.7951 34.1414 48.7978 34.1614 48.8013 34.1877C48.8084 34.2402 48.819 34.3179 48.8327 34.4183C48.8603 34.6191 48.9008 34.9107 48.9524 35.2738C49.0556 36.0001 49.2034 37.0122 49.3816 38.1554ZM31.4737 49.621C31.4736 49.621 31.4738 49.6205 31.4744 49.6193C31.474 49.6204 31.4737 49.621 31.4737 49.621Z"
        fill="#E4EFFD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.301 43.5325L21.9787 53.6408L21.5989 53.3656L28.9212 43.2573L29.301 43.5325Z"
        fill="black"
      />
      <path
        d="M32.253 50.0479C31.4022 49.2975 26.3039 48.1061 25.757 48.4617C25.2102 48.8172 20.8633 49.7862 20.8633 49.7862C20.8633 49.7862 20.9815 50.8312 22.7122 50.5995C24.7233 50.3293 25.1623 50.7402 25.1623 50.7402C25.1623 50.7402 24.9156 51.8517 25.5544 52.2532C26.1932 52.6547 25.3828 53.2738 26.2786 54.0646C26.8039 54.527 26.8414 55.1199 27.513 55.2259C28.1847 55.3319 31.8421 53.5027 32.479 52.2241C33.0175 51.1379 32.7539 50.4953 32.253 50.0479Z"
        fill="#E18C32"
      />
      <path
        d="M4.2424 48.864C2.82972 47.6155 0.203203 40.7322 4.63169 40.7397C5.61569 40.7397 8.04147 41.0858 10.7984 41.7199C11.7627 35.9219 13.7307 26.8389 16.9059 24.1664C18.6394 22.7068 21.1693 21.6703 22.0305 21.3813C27.4833 19.554 26.0106 57.206 17.5372 55.7296C14.6987 55.2305 6.85578 51.1716 4.2424 48.864Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1466 42.1979C20.3132 44.2556 20.4139 45.9223 20.2097 47.1973L19.7466 47.1231C19.9402 45.9142 19.8467 44.3066 19.6791 42.2358C19.6541 41.9263 19.6274 41.607 19.5999 41.2777C19.4444 39.4139 19.2623 37.2313 19.1931 34.717L19.662 34.7041C19.7307 37.2042 19.9117 39.3729 20.0672 41.2366C20.0947 41.5666 20.1214 41.887 20.1466 42.1979Z"
        fill="#E4EFFD"
      />
      <path
        d="M14.1588 61.1161H30.0191C30.1514 61.116 30.2813 61.081 30.3957 61.0146C30.51 60.9482 30.6048 60.8527 30.6704 60.7379C30.736 60.623 30.7701 60.4929 30.7693 60.3607C30.7684 60.2284 30.7326 60.0987 30.6654 59.9848L18.1314 38.6809C18.011 38.4767 17.8395 38.3074 17.6337 38.1898C17.4279 38.0721 17.1949 38.0103 16.9579 38.0103H1.0975C0.965169 38.0101 0.835158 38.045 0.720654 38.1114C0.606151 38.1777 0.511227 38.2732 0.445509 38.388C0.379791 38.5029 0.345617 38.6331 0.34645 38.7654C0.347284 38.8977 0.383097 39.0275 0.450257 39.1415L12.9853 60.4444C13.1053 60.649 13.2768 60.8186 13.4827 60.9364C13.6885 61.0542 13.9216 61.1161 14.1588 61.1161Z"
        fill="#6562CE"
      />
      <path
        d="M11.879 48.6551L12.924 50.4656C12.9627 50.5303 13.016 50.585 13.0797 50.6254C13.1434 50.6658 13.2157 50.6907 13.2907 50.6982H14.7428C14.8179 50.7056 14.8901 50.7306 14.9538 50.771C15.0175 50.8114 15.0709 50.8661 15.1096 50.9308L15.9444 52.3773C15.9832 52.442 16.0365 52.4967 16.1002 52.5371C16.1639 52.5775 16.2362 52.6025 16.3112 52.6099H18.1198C18.2483 52.6099 18.2924 52.5058 18.2136 52.3773L17.3778 50.9308C17.3037 50.8023 17.3478 50.6982 17.4763 50.6982H18.9237C19.0522 50.6982 19.0963 50.5941 19.0175 50.4656L17.9725 48.6551C17.9338 48.5904 17.8804 48.5357 17.8167 48.4953C17.753 48.4549 17.6808 48.43 17.6057 48.4225H16.1639C16.0889 48.4151 16.0166 48.3901 15.9529 48.3497C15.8893 48.3093 15.8359 48.2546 15.7972 48.1899L14.9623 46.7434C14.9235 46.6788 14.8702 46.6242 14.8065 46.584C14.7428 46.5437 14.6705 46.519 14.5955 46.5117H12.7851C12.6566 46.5117 12.6125 46.6158 12.6866 46.7434L13.5234 48.1899C13.5975 48.3184 13.5534 48.4225 13.4249 48.4225H11.9765C11.848 48.4225 11.8049 48.5257 11.879 48.6551Z"
        fill="#8281D8"
      />
      <path
        d="M5.58403 46.1027C5.44333 46.7087 5.38986 46.9882 4.89176 47.1627C4.35708 47.5529 3.3909 47.6496 2.17145 47.6824C1.22684 47.7087 1.42101 46.0136 1.42101 46.0136C1.09833 45.7829 0.995145 45.0756 1.18557 44.4518C1.18557 44.4518 0.556142 43.8833 0.879765 42.8787C0.879765 42.8787 0.373224 42.2521 0.569275 41.5054C0.679963 41.087 1.95288 41.0364 2.86466 41.0204C3.4969 40.9801 4.00625 40.966 4.27171 40.9529C4.55313 40.9388 4.89645 41.5213 4.85611 42.344C5.03359 42.5464 5.14201 42.8 5.16567 43.0682C5.22383 43.7755 5.55308 44.8504 5.42738 45.5634C5.37391 45.8054 5.6103 45.993 5.58403 46.1027Z"
        fill="#E18C32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6261 41.2108C4.52809 41.0995 4.40578 41.0375 4.26884 41.0464C3.87998 41.0718 2.49892 41.2389 1.6454 41.3423C1.43344 41.3679 1.25401 41.3896 1.13039 41.4043C1.07894 41.4104 1.0323 41.3736 1.02621 41.3221C1.02012 41.2707 1.05689 41.224 1.10834 41.218C1.22978 41.2036 1.40684 41.1821 1.61661 41.1568C2.4691 41.0535 3.86178 40.8849 4.25663 40.8592L4.25663 40.8592C4.46489 40.8456 4.64018 40.943 4.76688 41.0868C4.89276 41.2298 4.97526 41.4226 5.0044 41.6181C5.03351 41.8133 5.0107 42.0207 4.9137 42.1898C4.81535 42.3613 4.6451 42.4852 4.39965 42.5161C3.28399 42.787 2.14316 42.9412 0.995582 42.9761C0.943799 42.9777 0.900545 42.937 0.89897 42.8852C0.897395 42.8334 0.938097 42.7901 0.989879 42.7886C2.12625 42.754 3.25594 42.6012 4.36064 42.3325L4.3661 42.3311L4.37168 42.3305C4.56309 42.3076 4.68222 42.2163 4.75096 42.0965C4.82181 41.9729 4.84344 41.8107 4.81885 41.6458C4.79429 41.481 4.72494 41.323 4.6261 41.2108Z"
        fill="#D97802"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90145 44.0427C4.97899 44.032 5.0435 43.9891 5.09366 43.9337C5.14655 43.8753 5.18761 43.7988 5.2178 43.715C5.2782 43.5474 5.30124 43.3337 5.27571 43.1259C5.25021 42.9184 5.1749 42.7067 5.02791 42.5518C4.87771 42.3937 4.66076 42.3037 4.37425 42.3303C4.32267 42.3351 4.28474 42.3808 4.28953 42.4324C4.29432 42.484 4.34002 42.5219 4.3916 42.5171C4.62524 42.4954 4.7842 42.5676 4.89186 42.681C5.0027 42.7978 5.06716 42.9669 5.0895 43.1488C5.11181 43.3303 5.09077 43.5142 5.0413 43.6514C5.01655 43.7201 4.98623 43.7728 4.95457 43.8078C4.92324 43.8424 4.89488 43.8554 4.87121 43.8574L4.85652 43.8586L4.84346 43.8641C4.84347 43.8641 4.84335 43.8642 4.84331 43.8642C4.84326 43.8642 4.84324 43.8642 4.84314 43.8642C4.8431 43.8642 4.84312 43.8642 4.84307 43.8643C4.8425 43.8645 4.84128 43.865 4.83936 43.8657C4.83553 43.8671 4.82892 43.8694 4.81908 43.8727C4.7994 43.8791 4.76694 43.8891 4.71815 43.9019C4.62052 43.9276 4.45816 43.9649 4.20293 44.0096C3.69236 44.0991 2.81232 44.2182 1.33909 44.3348C1.28745 44.3389 1.24889 44.3841 1.25298 44.4357C1.25707 44.4874 1.30225 44.5259 1.3539 44.5218C2.83081 44.4049 3.71709 44.2852 4.23532 44.1944C4.49449 44.149 4.66213 44.1106 4.76592 44.0833C4.81784 44.0697 4.85393 44.0587 4.87763 44.0509C4.8877 44.0476 4.89555 44.0449 4.90145 44.0427Z"
        fill="#D97802"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25667 45.586C5.33398 45.5708 5.39503 45.5213 5.44069 45.4623C5.48828 45.4007 5.5249 45.3219 5.55193 45.2362C5.60605 45.0646 5.62713 44.8484 5.60821 44.6401C5.58941 44.4331 5.52993 44.2219 5.41168 44.0686C5.28892 43.9096 5.10535 43.8177 4.86323 43.8565C4.81207 43.8648 4.77727 43.9129 4.78548 43.964C4.7937 44.0152 4.84182 44.05 4.89297 44.0418C5.06265 44.0145 5.17949 44.0748 5.26315 44.1833C5.35133 44.2975 5.4043 44.4691 5.42137 44.6571C5.43834 44.8439 5.41873 45.0349 5.37301 45.1798C5.35011 45.2524 5.32202 45.3091 5.29229 45.3475C5.26244 45.3861 5.23661 45.3994 5.21796 45.4024L5.20896 45.4038L5.20054 45.4069L5.20052 45.4069L5.20047 45.4069L5.20041 45.407L5.20033 45.407L5.19892 45.4075C5.1984 45.4077 5.19776 45.4079 5.197 45.4082C5.19569 45.4086 5.19403 45.4092 5.19199 45.4099C5.18552 45.4121 5.17531 45.4154 5.16111 45.4198C5.13271 45.4287 5.08836 45.4417 5.02609 45.458C4.90153 45.4906 4.70535 45.5363 4.42175 45.5874C3.85448 45.6897 2.93797 45.8139 1.54605 45.8995C1.49434 45.9027 1.45501 45.9472 1.45819 45.9989C1.46137 46.0506 1.50587 46.09 1.55758 46.0868C2.95538 46.0008 3.87931 45.8759 4.45504 45.772C4.74294 45.7201 4.9439 45.6734 5.07361 45.6395C5.13847 45.6225 5.18553 45.6087 5.21676 45.599C5.23238 45.5941 5.24404 45.5903 5.25199 45.5876C5.25338 45.5872 5.25467 45.5867 5.25583 45.5863C5.25612 45.5862 5.2564 45.5861 5.25667 45.586Z"
        fill="#D97802"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52664 45.776C4.52662 45.776 4.52665 45.776 4.52664 45.776V45.776ZM4.52664 45.776L4.52691 45.7759L4.52844 45.7753C4.52987 45.7748 4.53214 45.7739 4.53518 45.7728C4.54128 45.7706 4.55048 45.7673 4.56238 45.7633C4.58622 45.7553 4.62075 45.7443 4.66275 45.7328C4.74728 45.7097 4.85961 45.6854 4.97478 45.6784C5.09118 45.6713 5.20207 45.6824 5.28924 45.7226C5.37186 45.7608 5.43784 45.8269 5.4685 45.9456C5.53115 46.1881 5.5059 46.3789 5.40797 46.5366C5.3079 46.6978 5.12378 46.8379 4.84205 46.9591C4.27662 47.2024 3.36373 47.351 2.11236 47.4722C2.06079 47.4772 2.02304 47.523 2.02803 47.5746C2.03302 47.6262 2.07887 47.6639 2.13044 47.6589C3.37895 47.5381 4.32011 47.3879 4.9162 47.1315C5.21524 47.0028 5.43875 46.8428 5.56736 46.6356C5.69811 46.425 5.72258 46.1791 5.65015 45.8986C5.60483 45.7232 5.4995 45.6131 5.3679 45.5523C5.24084 45.4936 5.09458 45.4831 4.96334 45.4911C4.83087 45.4992 4.70505 45.5268 4.61335 45.5518C4.56723 45.5644 4.52912 45.5765 4.50235 45.5856C4.48895 45.5901 4.47835 45.5939 4.47097 45.5966C4.46728 45.5979 4.4644 45.599 4.46236 45.5997L4.45995 45.6006L4.45924 45.6009L4.45901 45.601C4.45898 45.601 4.45886 45.6011 4.49277 45.6885L4.45886 45.6011C4.41056 45.6198 4.38658 45.6741 4.40531 45.7224C4.42403 45.7707 4.47834 45.7947 4.52664 45.776Z"
        fill="#D97802"
      />
      <path
        d="M28.1918 35.3501H21.2785C21.0702 35.3501 20.9014 35.5189 20.9014 35.7272V39.53C20.9014 39.7383 21.0702 39.9071 21.2785 39.9071H28.1918C28.4001 39.9071 28.5689 39.7383 28.5689 39.53V35.7272C28.5689 35.5189 28.4001 35.3501 28.1918 35.3501Z"
        fill="#E4EFFD"
      />
      <path d="M25.194 33.707H24.2766V35.8214H25.194V33.707Z" fill="#85C3D3" />
      <path d="M23.4942 36.2402H21.832V38.2345H23.4942V36.2402Z" fill="white" />
      <path d="M27.5846 36.2402H24.021V36.7018H27.5846V36.2402Z" fill="white" />
      <path d="M27.5846 37.0073H24.021V37.4688H27.5846V37.0073Z" fill="white" />
      <path d="M27.5846 37.7739H24.021V38.2354H27.5846V37.7739Z" fill="white" />
      <path d="M27.585 38.5547H21.832V39.0162H27.585V38.5547Z" fill="white" />
      <path
        d="M42.0752 37.7232C41.8541 37.7229 41.6348 37.6842 41.427 37.6087L41.5865 37.1679C41.9166 37.2869 42.2788 37.2818 42.6055 37.1536C42.9322 37.0253 43.2011 36.7827 43.3622 36.4709L45.7261 31.7122C45.9814 31.1925 46.1224 30.6241 46.1395 30.0453C46.1567 29.4665 46.0496 28.8907 45.8255 28.3568C45.5885 27.7923 45.2359 27.2837 44.7904 26.8637C44.345 26.4438 43.8164 26.1218 43.2389 25.9186C42.6614 25.7153 42.0477 25.6353 41.4374 25.6836C40.8271 25.732 40.2337 25.9077 39.6954 26.1993C39.1856 26.4758 38.741 26.8583 38.3915 27.3211C38.042 27.7839 37.7957 28.3162 37.6692 28.8821L36.5323 34.0733C36.4622 34.4176 36.5201 34.7756 36.695 35.0804C36.8699 35.3852 37.1499 35.6157 37.4825 35.7289L37.3324 36.1735C36.8909 36.0234 36.5194 35.7172 36.2876 35.3125C36.0559 34.9079 35.9797 34.4325 36.0736 33.9757L37.2114 28.7808C37.3557 28.1513 37.6313 27.5594 38.0202 27.0439C38.4092 26.5284 38.9027 26.1009 39.4684 25.7894C40.0644 25.4651 40.7218 25.2693 41.3981 25.2149C42.0744 25.1604 42.7547 25.2484 43.3949 25.4732C44.0351 25.698 44.621 26.0546 45.1148 26.52C45.6086 26.9853 45.9993 27.5491 46.2617 28.1748C46.5075 28.7718 46.6246 29.4139 46.6052 30.0592C46.5858 30.7045 46.4304 31.3384 46.1491 31.9195L43.7824 36.682C43.6211 36.9957 43.3764 37.2589 43.0752 37.4426C42.774 37.6263 42.428 37.7234 42.0752 37.7232Z"
        fill="#6562CE"
      />
      <path
        d="M37.924 36.4874C37.8511 36.4877 37.7787 36.4753 37.7101 36.4509L37.3733 36.3298C37.2107 36.2725 37.0776 36.1529 37.003 35.9975C36.9284 35.842 36.9186 35.6633 36.9756 35.5006V35.4931C37.0339 35.3321 37.1535 35.2006 37.3083 35.1273C37.4631 35.0541 37.6405 35.045 37.802 35.102L38.1378 35.2201C38.2824 35.2715 38.4042 35.3722 38.4817 35.5045C38.5593 35.6369 38.5876 35.7924 38.5617 35.9436C38.5358 36.0948 38.4573 36.232 38.3401 36.3309C38.2229 36.4299 38.0745 36.4844 37.9211 36.4846L37.924 36.4874ZM37.4146 35.6629C37.4011 35.7083 37.4053 35.7572 37.4264 35.7996C37.4474 35.8421 37.4838 35.875 37.5281 35.8918L37.8649 36.01C37.9074 36.0152 37.9504 36.0057 37.9868 35.9831C38.0232 35.9605 38.0508 35.9261 38.0649 35.8857C38.079 35.8452 38.0789 35.8012 38.0645 35.7608C38.0502 35.7205 38.0224 35.6862 37.9859 35.6638L37.6501 35.5456C37.6042 35.53 37.5542 35.5327 37.5103 35.5531C37.4667 35.5744 37.4331 35.6118 37.4165 35.6573L37.4146 35.6629Z"
        fill="#6562CE"
      />
      <path
        d="M41.2461 37.9611C41.172 37.961 41.0985 37.9487 41.0285 37.9245L40.6917 37.8054C40.53 37.7475 40.3974 37.6286 40.3224 37.4741C40.2473 37.3196 40.2358 37.1419 40.2902 36.979V36.9715C40.3486 36.8087 40.4688 36.6756 40.6248 36.601C40.7807 36.5263 40.9598 36.5162 41.1232 36.5728L41.46 36.6919C41.6226 36.7501 41.7558 36.8701 41.8306 37.0259C41.9054 37.1817 41.9158 37.3606 41.8596 37.524V37.5324C41.8007 37.6924 41.682 37.8233 41.5284 37.8973C41.4402 37.9392 41.3438 37.9609 41.2461 37.9611ZM40.7358 37.1253C40.7206 37.1714 40.7238 37.2215 40.7448 37.2652C40.7658 37.309 40.8029 37.3429 40.8484 37.3598L41.1851 37.479C41.2312 37.4948 41.2817 37.4921 41.3258 37.4714C41.3696 37.4499 41.4032 37.4123 41.4196 37.3664V37.3617C41.4336 37.3159 41.4295 37.2665 41.4083 37.2236C41.387 37.1807 41.3501 37.1475 41.3052 37.1309L40.9684 37.0127C40.9226 36.9981 40.8729 37.0016 40.8296 37.0226C40.7863 37.0435 40.7528 37.0803 40.7358 37.1253Z"
        fill="#6562CE"
      />
      <path
        d="M45.886 32.4006L45.417 32.3837C45.417 32.2448 45.5014 28.9664 42.5344 27.9233C39.5196 26.8624 37.5741 29.4832 37.4934 29.5958L37.1125 29.321C37.1351 29.29 39.3413 26.3023 42.6901 27.4805C45.9798 28.6381 45.886 32.363 45.886 32.4006Z"
        fill="#6562CE"
      />
      <path
        d="M26.8197 28.1916C26.7658 28.1914 26.7136 28.1726 26.6719 28.1384C26.6302 28.1043 26.6016 28.0568 26.5908 28.004C26.3876 26.9637 26.2787 25.9073 26.2653 24.8475C26.2212 21.8889 26.9632 20.1573 27.5926 19.2295C28.4153 18.0101 29.6085 17.2672 31.1375 17.0092C31.1971 17.0027 31.257 17.0192 31.3048 17.0554C31.3527 17.0916 31.385 17.1446 31.395 17.2038C31.4051 17.2629 31.3922 17.3237 31.359 17.3736C31.3257 17.4236 31.2747 17.459 31.2163 17.4726C29.8177 17.708 28.7295 18.3872 27.981 19.4922C25.9173 22.538 27.0373 27.8511 27.0486 27.9045C27.0556 27.9348 27.0565 27.9662 27.0513 27.9968C27.0461 28.0274 27.0348 28.0567 27.0181 28.0829C27.0014 28.1091 26.9797 28.1317 26.9541 28.1494C26.9286 28.1671 26.8998 28.1795 26.8694 28.186C26.8531 28.1896 26.8364 28.1915 26.8197 28.1916Z"
        fill="#6562CE"
      />
      <path
        d="M27.5304 31.9432C28.651 31.9432 29.5594 31.0348 29.5594 29.9142C29.5594 28.7937 28.651 27.8853 27.5304 27.8853C26.4099 27.8853 25.5015 28.7937 25.5015 29.9142C25.5015 31.0348 26.4099 31.9432 27.5304 31.9432Z"
        fill="white"
      />
      <path
        d="M27.5098 32.1392C27.0714 32.1392 26.6429 32.0092 26.2783 31.7657C25.9138 31.5221 25.6297 31.1759 25.4619 30.7709C25.2942 30.3659 25.2503 29.9202 25.3358 29.4902C25.4213 29.0602 25.6324 28.6653 25.9424 28.3553C26.2524 28.0453 26.6474 27.8342 27.0774 27.7486C27.5074 27.6631 27.953 27.707 28.3581 27.8748C28.7631 28.0425 29.1093 28.3267 29.3529 28.6912C29.5964 29.0557 29.7264 29.4842 29.7264 29.9226C29.7257 30.5103 29.4919 31.0736 29.0764 31.4892C28.6608 31.9047 28.0975 32.1385 27.5098 32.1392ZM27.5098 28.0813C27.1456 28.0813 26.7896 28.1893 26.4868 28.3916C26.184 28.5939 25.948 28.8815 25.8086 29.218C25.6692 29.5544 25.6328 29.9247 25.7038 30.2819C25.7749 30.6391 25.9502 30.9672 26.2078 31.2247C26.4653 31.4822 26.7934 31.6576 27.1506 31.7286C27.5078 31.7997 27.878 31.7632 28.2145 31.6238C28.551 31.4845 28.8385 31.2484 29.0409 30.9456C29.2432 30.6428 29.3512 30.2868 29.3512 29.9226C29.3505 29.4345 29.1562 28.9666 28.811 28.6214C28.4659 28.2763 27.998 28.082 27.5098 28.0813Z"
        fill="#EFAF00"
      />
      <path
        d="M27.5042 31.2589C28.2487 31.2589 28.8522 30.6554 28.8522 29.911C28.8522 29.1665 28.2487 28.563 27.5042 28.563C26.7598 28.563 26.1562 29.1665 26.1562 29.911C26.1562 30.6554 26.7598 31.2589 27.5042 31.2589Z"
        fill="#EFAF00"
      />
      <path
        d="M43.4008 25.8882C43.3706 25.888 43.3407 25.8823 43.3126 25.8713C43.284 25.8597 43.258 25.8427 43.236 25.821C43.214 25.7994 43.1965 25.7736 43.1845 25.7452C43.1724 25.7168 43.1661 25.6863 43.1659 25.6555C43.1657 25.6246 43.1716 25.594 43.1832 25.5655C43.201 25.5223 44.9289 21.1989 43.4177 18.6915C42.808 17.6775 41.7292 17.0875 40.2227 16.9355C40.1605 16.9293 40.1034 16.8986 40.0638 16.8503C40.0242 16.8019 40.0054 16.7398 40.0117 16.6776C40.0179 16.6154 40.0486 16.5582 40.0969 16.5186C40.1453 16.479 40.2074 16.4603 40.2696 16.4665C41.9347 16.6344 43.1297 17.3004 43.8201 18.4486C44.5105 19.5967 44.6841 21.1689 44.337 23.1388C44.1818 24.026 43.9411 24.8962 43.6184 25.7371C43.6015 25.7812 43.5717 25.8192 43.5329 25.8462C43.4941 25.8731 43.4481 25.8877 43.4008 25.8882Z"
        fill="#6562CE"
      />
      <path
        d="M39.2172 11.1411C39.2172 11.1411 38.3833 15.0499 41.265 19.0009C41.265 19.0009 36.2858 21.5487 29.5132 19.2589C29.5132 19.2589 33.5036 17.8847 31.7982 11.3034L39.2172 11.1411Z"
        fill="#E18C32"
      />
      <path
        d="M39.0558 11.144C39.0605 11.4039 39.0605 11.6206 39.0558 11.7772C39.0558 11.7772 38.7622 14.7424 37.2351 16.2029C36.0485 17.337 32.3714 18.4955 31.1772 17.9186C32.1563 15.8554 32.3762 13.5129 31.7982 11.3035L39.0558 11.144Z"
        fill="#D97802"
      />
      <path
        d="M39.1539 11.7301L39.1426 11.3971L39.0938 9.9122C39.0938 9.9122 37.5311 9.97224 38.0873 7.62151C38.6952 5.05504 37.6764 3.85435 35.0274 4.87306C33.111 5.60942 31.2884 6.19663 30.0145 5.16291C29.7803 4.96997 29.5775 4.7418 29.4133 4.48659C28.7003 3.40503 29.0146 3.30841 30.1637 2.59831C31.6036 1.71093 33.1917 -0.10793 36.759 0.645315C39.6088 1.24754 39.7551 3.64235 39.7551 3.64235C39.7551 3.64235 41.4643 3.25963 41.8479 5.04941C42.3348 7.29601 39.1539 11.7301 39.1539 11.7301Z"
        fill="#40465C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8038 3.86262C39.8039 3.86257 39.8039 3.86252 39.673 3.72813C39.5421 3.59373 39.5422 3.59367 39.5422 3.59361L39.5424 3.59348L39.5427 3.59314L39.5437 3.59222L39.5466 3.58946C39.549 3.58721 39.5522 3.58416 39.5563 3.58038C39.5645 3.57283 39.5761 3.56235 39.591 3.54954C39.6206 3.52393 39.6632 3.48883 39.7171 3.44898C39.8245 3.36964 39.9791 3.26958 40.1671 3.18876C40.5442 3.02667 41.0729 2.93655 41.6104 3.26939L41.4128 3.58839C41.0141 3.34152 40.6235 3.40102 40.3153 3.53348C40.1607 3.59992 40.0313 3.68337 39.9402 3.75072C39.8948 3.78421 39.8597 3.81325 39.8363 3.83345C39.8246 3.84353 39.8159 3.85137 39.8105 3.85641C39.8077 3.85893 39.8058 3.86075 39.8047 3.86179L39.8037 3.86276L39.8037 3.8627L39.8038 3.86262ZM39.8036 3.8628C39.8036 3.86284 39.8036 3.86282 39.8037 3.86276L39.8036 3.8628Z"
        fill="#40465C"
      />
      <path
        d="M39.0088 11.9135C38.612 12.5607 37.4403 14.4087 36.8569 14.6282C35.099 15.2923 32.2849 15.872 31.3468 15.388C30.7052 15.0578 30.6696 15.0371 29.9163 11.77C29.4961 9.94453 29.7287 6.62762 30.0195 5.16334C31.2934 6.19518 33.115 5.60984 35.0324 4.87348C37.6814 3.85477 38.7001 5.05546 38.0923 7.62194C37.612 9.65092 38.7105 9.88355 39.02 9.90982C39.0782 9.84415 39.792 9.06558 40.4562 9.64435C40.9205 10.0505 39.3202 11.6142 39.0088 11.9135Z"
        fill="#E18C32"
      />
      <path
        d="M30.8003 9.51902C31.0697 9.51902 31.2881 9.30063 31.2881 9.03124C31.2881 8.76185 31.0697 8.54346 30.8003 8.54346C30.5309 8.54346 30.3125 8.76185 30.3125 9.03124C30.3125 9.30063 30.5309 9.51902 30.8003 9.51902Z"
        fill="#40465C"
      />
      <path
        d="M34.8374 9.51902C35.1068 9.51902 35.3252 9.30063 35.3252 9.03124C35.3252 8.76185 35.1068 8.54346 34.8374 8.54346C34.568 8.54346 34.3496 8.76185 34.3496 9.03124C34.3496 9.30063 34.568 9.51902 34.8374 9.51902Z"
        fill="#40465C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7175 8.37452C32.8386 8.40377 32.9131 8.52566 32.8838 8.64677L32.2729 11.1767H33.1158C33.2403 11.1767 33.3414 11.2777 33.3414 11.4023C33.3414 11.5269 33.2403 11.6279 33.1158 11.6279H31.9863C31.9172 11.6279 31.8519 11.5962 31.8091 11.5419C31.7663 11.4875 31.7508 11.4166 31.767 11.3494L32.4453 8.54086C32.4745 8.41975 32.5964 8.34527 32.7175 8.37452Z"
        fill="#40465C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7215 12.0522C34.8348 12.104 34.8847 12.2379 34.8329 12.3512C34.4973 13.0852 33.9416 13.3706 33.4714 13.4649C33.2401 13.5113 33.0312 13.5116 32.8805 13.5005C32.8048 13.4949 32.7428 13.4864 32.6988 13.4791C32.6768 13.4755 32.6591 13.4721 32.6465 13.4695C32.6401 13.4682 32.635 13.4671 32.6312 13.4662L32.6265 13.4651L32.6249 13.4647L32.6242 13.4646L32.624 13.4645C32.6238 13.4645 32.6237 13.4645 32.6766 13.2451L32.6237 13.4645C32.5026 13.4353 32.4281 13.3134 32.4573 13.1923C32.4864 13.0714 32.6078 12.9969 32.7287 13.0256L32.7297 13.0259C32.7311 13.0262 32.7336 13.0267 32.7373 13.0275C32.7447 13.029 32.7567 13.0314 32.7728 13.034C32.805 13.0394 32.8532 13.0461 32.9135 13.0505C33.0349 13.0594 33.2012 13.0589 33.3826 13.0225C33.7379 12.9513 34.1577 12.7429 34.4225 12.1636C34.4743 12.0503 34.6082 12.0004 34.7215 12.0522Z"
        fill="#40465C"
      />
    </svg>
  );
}
