import { SET_SELECTED_STORE } from 'reducers/clients';
import { getReportInsightsApi } from 'lib/gapi';
import { feedbackAnalyticsApi } from 'lib/reviews';
import { refreshPage } from 'reducers/ui';
import { getDateFormat } from 'helpers/dateHelpers';
import CustomError from 'models/error';
import { demoMiddleWare } from 'demo';
import { t } from 'i18n/config';

const SET_ANALYTICS = 'ANALYTICS/SET_ANALYTICS';
const CLEAR_ANALYTICS = 'ANALYTICS/CLEAR_ANALYTICS';

const defaultState = {
  ok: true,
  google: [],
  feedbackAnalytics: [],
  meta: {
    startTime: null,
    endTime: null,
  },
};

const setAnalytics = ({ locationMetrics, feedbackAnalytics, startTime, endTime }) => ({
  type: SET_ANALYTICS,
  locationMetrics,
  feedbackAnalytics,
  meta: {
    startTime,
    endTime,
  },
});

const clearAnalytics = () => ({
  type: CLEAR_ANALYTICS,
});

export const fetchReportInsights =
  ({ startTime, endTime }) =>
  async (dispatch, getState) => {
    dispatch(refreshPage(true));
    const unAuthClient = [];
    let accessToken = null;
    let googleAccountId = null;
    const locationNames = [];
    getState().clients.selectedIds.forEach((selectedId) => {
      const {
        googleAccountAccessToken,
        googleAccountId: accountId,
        googleLocationId: locationId,
        companyName,
        id: clientId,
      } = getState().clients.byId[selectedId];
      if (accountId == null || locationId == null) {
        unAuthClient.push({
          metricValues: [],
          locationId,
          companyName,
          clientId,
          isUnAuth: true,
        });
        return;
      }
      if (googleAccountAccessToken != null) {
        accessToken = googleAccountAccessToken;
        googleAccountId = accountId;
      }
      locationNames.push(`accounts/${accountId}/locations/${locationId}`);
    });

    if (accessToken == null || googleAccountId == null) {
      throw CustomError({
        error: new Error(t('pleaseGoToPlatformAuthorizationForGoogleAuthorization')),
        redirect: '/platforms',
      });
    }

    if (startTime !== getState().analytics.meta.startTime || endTime !== getState().analytics.meta.endTime) {
      dispatch(clearAnalytics());
      const { selectedIds } = getState().clients;
      const clients = selectedIds.map((id) => getState().clients.byId[id]);
      const googleReportInsightResult = await demoMiddleWare(
        clients,
        {
          accessToken,
          accountId: googleAccountId,
          locationNames,
          clients,
          startTime,
          endTime,
        },
        getReportInsightsApi
      );

      let feedbackAanalyticsArray = [];
      const feedbackAnalyticsPromises = [];
      clients.forEach((client) => {
        feedbackAnalyticsPromises.push(
          demoMiddleWare(
            [client],
            {
              clientId: client.id,
              startTimeString: getDateFormat(startTime),
              endTimeString: getDateFormat(endTime),
            },
            feedbackAnalyticsApi
          )
        );
      });

      feedbackAanalyticsArray = await Promise.all(feedbackAnalyticsPromises).then((feedbackResultArray) =>
        feedbackResultArray.map((feedbackResult = { feedbackAnalytics: {} }) => ({
          clientId: feedbackResult.clientId,
          labels: feedbackResult.feedbackAnalytics,
        }))
      );

      const locationMetricsWithoutSort = googleReportInsightResult.concat(unAuthClient);
      dispatch(
        setAnalytics({
          locationMetrics: selectedIds.map((id) => locationMetricsWithoutSort.find(({ clientId }) => clientId === id)),
          feedbackAnalytics: feedbackAanalyticsArray,
          startTime,
          endTime,
        })
      );
    }

    dispatch(refreshPage(false));
  };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ANALYTICS:
      return {
        ...state,
        google: action.locationMetrics,
        feedbackAnalytics: action.feedbackAnalytics,
        meta: action.meta,
      };
    case CLEAR_ANALYTICS:
      return {
        ...defaultState,
        feedbackAnalytics: [],
        meta: state.meta,
      };
    case SET_SELECTED_STORE:
      return defaultState;
    default:
      return state;
  }
};
