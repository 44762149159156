import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const NameContainer = styled.div``;

const BookinNumber = styled.span`
  color: ${(props) => props.theme.western.colors.primary};
  font-weight: bold;
`;

const BookingInfo = styled.span`
  color: ${(props) => props.theme.western.colors.textPrimary};
  letter-spacing: 0.08px;
  font-size: 15px;
`;

export function LoadingCell() {
  return <Box />;
}

function NameCell({ value: initialValue, row }) {
  const {
    original: { bookingNumber, period, bookingTime },
  } = row;
  return (
    <NameContainer>
      {`${initialValue} `}{' '}
      <BookingInfo>
        {`(${period}${bookingTime}`} <BookinNumber>{bookingNumber}</BookinNumber>
        {')'}
      </BookingInfo>{' '}
    </NameContainer>
  );
}

NameCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bookingNumber: PropTypes.number,
      period: PropTypes.string,
    }),
  }),
};

export default NameCell;
