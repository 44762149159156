import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { dashboard_v2 } from 'theme';
import connectData from 'components/HOCs/connectData';
import SideBar from '../Sidebar';
import Tags from './Tags';
import AgeTags from './AgeTags';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  margin: 0 -30px;
`;

const Main = styled.div`
  padding: 30px 30px 30px 86px;
`;

const Header = styled.div`
  padding: 5px 30px 0 30px;
  margin: -30px -30px 30px;
  background-color: #fff;
`;

const SectionTitle = styled.h5`
  margin: 40px 0 24px 0;
  font-size: 28px;
`;

const SectionDescription = styled.p`
  margin: 24px 0;
  font-size: 14px;
  color: ${colors.SHADES_400};
`;

const Content = styled.div``;

const StyledTab = withStyles(() => ({
  root: {
    fontSize: '16px',
    color: (props) => (props.selected ? colors.PRIMARY_500 : colors.SHADES_500),
  },
}))(Tab);

const tabItems = [
  { type: 'tag', name: t('tag'), component: <Tags /> },
  { type: 'ageTag', name: t('ageTag'), component: <AgeTags /> },
];

const TagList = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const onTabIndexChange = (e, index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabType = urlParams.get('tagListType');
    const index = tabItems.findIndex((item) => item.type === tabType);
    if (index !== -1) {
      setTabIndex(index);
    }
  }, []);

  return (
    <Container className="animated fadeIn">
      <SideBar />
      <Main>
        <Header>
          <Header>
            <SectionTitle>{t('tag')}</SectionTitle>
            <SectionDescription>{t('tagFunctionDescription')}</SectionDescription>
          </Header>
          <Tabs
            value={tabIndex}
            onChange={onTabIndexChange}
            TabIndicatorProps={{ style: { background: colors.PRIMARY_500 } }}
          >
            {tabItems.map((item, index) => (
              <StyledTab label={item.name} key={index} />
            ))}
          </Tabs>
        </Header>
        <Content>
          {tabItems.map((item, index) => (
            <div value={index} hidden={index !== tabIndex} key={index}>
              {item.component}
            </div>
          ))}
        </Content>
      </Main>
    </Container>
  );
};

export default connectData(TagList);
