import { t } from 'i18n/config';

export function parseRuleTimeToRule(delaySeconds) {
  const absSeconds = Math.abs(delaySeconds);
  let value;
  let unit;
  switch (true) {
    case absSeconds / 86400 > 1:
      value = `${parseInt(absSeconds / 86400, 10)}`;
      unit = t('days');
      break;
    case absSeconds / 3600 > 1:
      value = `${parseInt(absSeconds / 3600, 10)}`;
      unit = t('hours');
      break;
    case absSeconds / 60 > 1:
      value = `${parseInt(absSeconds / 60, 10)}`;
      unit = t('minutes');
      break;
    default:
      value = `${parseInt(absSeconds, 10)}`;
      unit = t('seconds');
      break;
  }
  if (delaySeconds > 0) {
    return {
      rule: t('afterEventStarts'),
      value,
      unit,
    };
  }
  return {
    rule: t('beforeEventStarts'),
    value,
    unit,
  };
}
export const test = true;
