import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 250px;
  margin: 8px;
`;
const Image = styled.img`
  width: 100%;
`;
const Title = styled.div`
  font-weight: bold;
  margin-top: 8px;
  font-size: 22px;
`;
const Description = styled.div`
  font-size: 16px;
  margin-top: 8px;
`;

function HintInfo({ image, title, description }) {
  return (
    <Container>
      <Image src={image} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}

HintInfo.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default HintInfo;
