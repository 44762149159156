import React from 'react';
import Tag from 'components/Dashboard_v2/Tag';
import ActionCell from './ActionCell';
import PatientTrackingCell from 'components/Dashboard_v2/PatientTrackingSelectorCell';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import { t } from 'i18n/config';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
const { colors } = dashboard_v2;

const Wrapper = styled.div`
  i {
    font-size: 18px;
    color: ${colors.SHADES_400};
  }
`;

const TIME_UNIT = {
  day: t('days'),
  week: t('weeks'),
  month: t('months'),
  season: t('quarters'),
  year: t('years'),
};

export const columns = [
  {
    Header: t('tagName'),
    accessor: 'name',
    Cell: ({ value: tagName }) => <Tag name={tagName} />,
  },
  {
    Header: t('members'),
    accessor: 'usersCount',
  },
  {
    Header: t('validityPeriod'),
    accessor: 'expiable',
    Cell: ({ row, value: expiable }) =>
      expiable ? `${row.original.timeValue} ${TIME_UNIT[row.original.timeUnit]}` : t('permanent'),
  },

  {
    Header: () => (
      <Wrapper>
        <InfoPopover popoverContent={t('trackingTag.info')} style={{ marginRight: 8 }} />
        {t('patientTracking')}
      </Wrapper>
    ),
    headDataName: t('patientTracking'),
    accessor: 'patientTrackings',
    Cell: (params) => <PatientTrackingCell {...params} isTagList />,
    minWidth: 200,
    settable: true,
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'action',
    Cell: ActionCell,
    disableSortBy: true,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
