import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/MaterialDesign/Modal';
import { Confirm } from 'components/MaterialDesign/Buttons';
import Menu from 'components/MaterialDesign/menu';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Loading } from 'components/MaterialDesign/Loading';
import { format, set, parse } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import theme from 'theme';
import { t } from 'i18n/config';

const useStyles = makeStyles(() => ({
  input: {
    height: 40,
    '&.Mui-focused $notchedOutline': {
      border: `solid 1px ${theme.colors.SHADES_300}`,
      borderWidth: 1,
    },
    '&:hover  $notchedOutline': {
      border: `solid 1px ${theme.colors.SHADES_300}`,
      borderWidth: 1,
    },
  },
  notchedOutline: {
    borderRadius: 8,
    borderColor: theme.colors.SHADES_300,
  },
}));

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  align-self: flex-start;
  margin: 16px 0;
`;

const CloseIcon = styled.i`
  font-size: 30px;
  cursor: pointer;
  align-self: flex-end;
  color: #6f788b;
`;
const Button = styled(Confirm)`
  margin: 30px 0;
  font-size: 18px;
  height: 45px;
`;

const Header3 = styled.div`
  align-self: flex-start;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  align-items: center;
`;

const TextFieldWrap = styled(TextField)`
  margin: 0 8px;
`;
const AMPMSelect = [
  { id: 1, name: t('am'), value: 'AM' },
  { id: 2, name: t('pm'), value: 'PM' },
];

const timeBoundary = {
  hours: {
    max: 12,
    min: 1,
  },
  minutes: { max: 59, min: 0 },
};

function SettingModal(props) {
  const { visible, onClose, onConfirm, defaultSendTime, loading, onSetDateValues } = props;
  const classes = useStyles();
  const [ampm, setAMPM] = useState(format(set(new Date(), defaultSendTime), 'a', { locale: zhTW }));
  const [hours, setHours] = useState(defaultSendTime.hours);
  const [minutes, setMinutes] = useState(defaultSendTime.minutes);

  useEffect(() => {
    const tempDate = parse(`${ampm} ${hours}:${minutes}`, 'a hh:mm', new Date(), { locale: zhTW });
    onSetDateValues({
      hours: parseInt(format(tempDate, 'HH'), 10),
      minutes: parseInt(format(tempDate, 'mm'), 10),
    });
  }, [ampm, hours, minutes]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelectAMPM = useCallback(({ name }) => {
    setAMPM(name);
  });

  const handleHoursChange = useCallback((e) => {
    let value = parseInt(e.target.value, 10);
    value = Number.isNaN(value) ? 0 : value;
    if (value > timeBoundary.hours.max) value = timeBoundary.hours.max;
    if (value < timeBoundary.hours.min) value = timeBoundary.hours.min;
    setHours(value);
  }, []);

  const handleMinutesChange = useCallback((e) => {
    let value = parseInt(e.target.value, 10);
    value = Number.isNaN(value) ? 0 : value;

    if (value > timeBoundary.minutes.max) value = timeBoundary.minutes.max;
    if (value < timeBoundary.minutes.min) value = timeBoundary.minutes.min;
    setMinutes(value);
  }, []);

  const handleConfirm = useCallback(() => {
    const tempDate = parse(`${ampm} ${hours}:${minutes}`, 'a hh:mm', new Date(), { locale: zhTW });
    onConfirm({
      setDateValues: {
        hours: parseInt(format(tempDate, 'HH'), 10),
        minutes: parseInt(format(tempDate, 'mm'), 10),
      },
    });
  }, [ampm, hours, minutes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={visible} onClose={onClose} styles={{ maxWidth: '500px', minWidth: '500px', borderRadius: '16px' }}>
      <Container>
        <CloseIcon className="ri-close-line" onClick={onClose} />
        <Title>{t('setSendTime')}</Title>
        <Header3>{t('sendTime')}</Header3>
        <SelectContainer>
          <Menu
            items={AMPMSelect}
            disablePortal
            name={ampm}
            onClickItem={onSelectAMPM}
            styles={{
              color: theme.colors.SHADES_500,
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.SHADES_300,
              paperBorderColor: theme.colors.SHADES_000,
              justifyContent: 'center',
              width: 100,
              height: 40,
            }}
          />
          <TextFieldWrap
            variant="outlined"
            type="number"
            InputProps={{
              classes: {
                root: classes.input,
                notchedOutline: classes.notchedOutline,
              },
              inputProps: {
                min: timeBoundary.hours.min,
                max: timeBoundary.hours.max,
              },
            }}
            value={hours}
            onChange={handleHoursChange}
          />
          {t('hour')}
          <TextFieldWrap
            variant="outlined"
            type="number"
            InputProps={{
              classes: {
                root: classes.input,
                notchedOutline: classes.notchedOutline,
              },
              inputProps: {
                min: timeBoundary.minutes.min,
                max: timeBoundary.minutes.max,
              },
            }}
            value={minutes}
            onChange={handleMinutesChange}
          />
          {t('minute')}
        </SelectContainer>
        <Button width={100} disabled={loading} onClick={handleConfirm}>
          {!loading && t('startRunning')}
          {loading && <Loading size={20} />}
        </Button>
      </Container>
    </Modal>
  );
}

SettingModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  vendor: PropTypes.string,
  campaign: PropTypes.shape({
    id: PropTypes.string,
  }),
  defaultSendTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
  }),
  onSetDateValues: PropTypes.func,
};

export default SettingModal;
