import React from 'react';
import styled from 'styled-components';
import connectData from 'components/HOCs/connectData';
import { t } from 'i18n/config';
import { useSelector } from 'react-redux';
import { fetchMembers } from 'lib/memberService';
import { dashboard_v2 } from 'theme';
import Input from 'components/Dashboard_v2/Input';
import { getMergeStateByType } from 'feature/MemberCenter/MemberMerge';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 64px;
  width: 100%;
`;

const OptionWrapper = styled.div`
  position: absolute;
  top: 100%;
  border-radius: 8px;
  min-width: 100%;
  background-color: #fff;
  max-height: 310px;
  overflow-y: auto;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
    0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  z-index: 100;
  ${({ type }) => (type === 'A' ? 'left: 16px;' : 'right: 16px;')};
`;

const Title = styled.div`
  padding: 8px 16px;
  color: ${colors.SHADES_500};
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  font-size: 16px;
  color: ${colors.SHADES_500};
  cursor: pointer;
  ${({ isSelected }) => isSelected && `background-color: ${colors.PRIMARY_100} !important`};
  ${({ isDisabled }) =>
    isDisabled &&
    `
      color: ${colors.SHADES_300} !important;
      background-color: ${colors.SHADES_000} !important;
      cursor: not-allowed;
    `};
  &:hover {
    background-color: ${colors.SHADES_50};
  }
`;

const OptionContent = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
`;

function SearchInput({ type, state, updateState, updateMergeState, disabledId }) {
  const selectedIds = useSelector((state) => state.clients.selectedIds);
  const clientId = selectedIds[0];

  const getMembers = async ({ keyword }) => {
    if (state.loading) return;

    const payload = {
      clientId,
      page: 1,
      size: 100,
      searchQueries: {
        searchKeyword: keyword,
      },
    };

    try {
      updateState({ loading: true });
      const { users } = await fetchMembers({ clientId, payload });
      const filteredUsers = users.filter((user) => user.idType !== 'lineID');
      updateState({ list: filteredUsers });
      if (!disabledId) {
        updateMergeState(getMergeStateByType(type));
      }
    } catch (e) {
      console.error(e);
    } finally {
      updateState({ loading: false });
    }
  };

  return (
    <Wrapper>
      <Input
        onChange={(e) => updateState({ keyword: e.target.value })}
        onKeyDown={(e) => e.keyCode === 13 && getMembers({ keyword: state.keyword })}
        onInputClear={() => updateState({ keyword: '', list: [], selectedMember: null })}
        onFocus={() => updateState({ showList: true })}
        onBlur={() => setTimeout(() => updateState({ showList: null }), 200)}
        showClearButton={!!state.keyword}
        value={state.keyword}
        prefix={<i className="ri-search-line" />}
        placeholder={t('mergeMember.searchPlaceholder')}
        width={400}
      />
      {state.showList && (
        <OptionWrapper type={type}>
          <Title>
            {t('member')}({state.list.length})
          </Title>
          {state.list.map((member) => {
            const { name, hisName, lineName, birthday, idNum, phone, tags } = member;
            const tagNames = tags
              .map((tag) => tag.name)
              .splice(0, 3)
              .join('/');
            return (
              <Option
                onClick={() => disabledId !== member.id && updateState({ selectedMember: member })}
                isSelected={state.selectedMember?.id === member.id}
                isDisabled={disabledId === member.id}
                key={member.id}
              >
                <OptionContent>
                  <span>{name || hisName || lineName || '-'}</span>
                  <span>|</span>
                  <span>{birthday || '-'}</span>
                  <span>|</span>
                  <span>{idNum}</span>
                </OptionContent>
                <OptionContent>
                  <span>{phone || '-'}</span>
                  <span>|</span>
                  <span>{tagNames || '-'}</span>
                </OptionContent>
              </Option>
            );
          })}
        </OptionWrapper>
      )}
    </Wrapper>
  );
}

const propTypes = {};
SearchInput.propTypes = propTypes;

export default connectData(SearchInput);
