import { t } from 'i18n/config';

// TODO: logging service (maybe one day we'll have)
const ErrorObj = async ({ error, redirect = '/' }) => ({
  error: await error,
  redirect,
});

export default ErrorObj;

export const errorHandler = async (errorPromise, history) => {
  const error = await errorPromise;
  if (error instanceof Error) {
    console.error(error);
    alert(t('pleaseRefreshAndInformSystemAdmin'));
    history.push('/');
  } else if (typeof error === 'object') {
    // customError
    alert(error.error.message);
    history.push(error.redirect || '/');
  } else {
    console.error(error);
    alert(t('pleaseRefreshAndInformSystemAdmin'));
    history.push('/');
  }
};
