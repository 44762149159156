import { getToken, FB_URL, CLIENT_URL, SERVER_URL, APCENTERGO_URL, ENV_STAGE, MARKETING_URL } from '../helpers';
import { responseHandler } from './responseHandler';

export const test = () => true;

export const fetchFBMessageUnreadNumber = ({ fbFanPageToken, fbId }) => {
  const fields = `unread_message_count&access_token=${fbFanPageToken}`;
  const fbPublicPageAccessUrl = `${FB_URL}${fbId}?fields=${fields}`;
  return fetch(fbPublicPageAccessUrl)
    .then(responseHandler)
    .then((resJson) => resJson.unread_message_count);
};

export const fetchClientsByIds = async (clientIdsString) => {
  const clients = await Promise.all([
    fetch(`${CLIENT_URL}?id=${clientIdsString}`).then(responseHandler),
    fetch(`${APCENTERGO_URL}/v1/clients?id=${clientIdsString}`, {
      method: 'GET',
      headers: {
        'AP-stage': ENV_STAGE,
      },
    }).then(responseHandler),
  ])
    .then((clientArr) => {
      const cli = clientArr[0];
      const cliInClock = clientArr[1];
      return cli.map((c) => {
        const {
          features: {
            appointNotification = false,
            review = false,
            calendar = false,
            message = false,
            chat = false,
            passcode = false,
            enablePNPNotify = false,
            enableSMSNotify = false,
            userManagement = false,
            openAI = false,
          },
          host,
          vendor,
        } = cliInClock.find((cc) => cc.id === c.id) || { features: {} };
        return {
          ...c,
          host,
          features: {
            ...c.features,
            appointNotification,
            review,
            calendar,
            message,
            chat,
            passcode,
            enablePNPNotify,
            enableSMSNotify,
            userManagement,
            openAI,
          },
          vendor,
        };
      });
    })
    .catch((err) => {
      console.log('err = ', err);
    });
  return clients;
};

export const fetchClientById = async (clientId) => {
  try {
    const clients = await fetchClientsByIds(clientId);
    if (clients == null) {
      return null;
    }
    return clients[0];
  } catch (error) {
    return null;
  }
};

export const updateClientGoogleTokenApi = ({ clientId, googleAccountAccessToken }) =>
  fetch(`${CLIENT_URL}/${clientId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      googleAccountAccessToken,
    }),
  })
    .then(responseHandler)
    .then((res) => res.body);

export const updateClientAccountApi = ({ client }) =>
  fetch(`${CLIENT_URL}/${client.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      googleAccountId: client.googleAccountId,
      googleAccountName: client.googleAccountName,
    }),
  })
    .then(responseHandler)
    .then((res) => res.body);

export const updateBlackListApi = ({ clientId, blackList }) =>
  fetch(`${SERVER_URL}v1/clients`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'ap-client-id': clientId,
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      blackList,
    }),
  })
    .then(responseHandler)
    .then((res) => res.body);

export const updateClientLocationApi = ({
  clientId,
  googleAccountId,
  googleAccountName,
  googleLocationId,
  googleLocationName,
}) =>
  fetch(`${CLIENT_URL}/${clientId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      googleAccountId,
      googleAccountName,
      googleLocationId,
      googleLocationName,
    }),
  })
    .then(responseHandler)
    .then((res) => res.body);

export const fetchPasscodeByClient = ({ clientId }) => {
  const url = `${APCENTERGO_URL}/v1/clients/${clientId}/validationCodes`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'AP-stage': ENV_STAGE,
    },
  }).then(responseHandler);
};

export const fetchDataStudioLink = ({ clientId }) => {
  const url = `${MARKETING_URL}/v2/clients/${clientId}/datastudio/report`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'AP-stage': ENV_STAGE,
    },
  }).then(responseHandler);
};
