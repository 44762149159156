import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { statusKeyMap } from 'components/Message/campaignTable/Columns/Status/StatusCell';
import UsersModal from './userNotifications/Modal';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div`
  height: 100%;
`;

const UsersContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: ${(props) => props.theme.colors.PRIMARY_500};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.32px;
  &:hover {
    font-weight: bold;
  }
`;

export function LoadingCell() {
  return <Box />;
}

function TargetUsersCell(props) {
  const {
    value: initialValue,
    row,
    column: { id: columnID },
    initialState: { clientID },
    updateMyData,
  } = props;
  const [visible, setVisible] = useState(false);
  const { index } = row;
  const handleClose = useCallback((e) => {
    setVisible(false);
    e.stopPropagation();
  }, []);

  const handleClick = useCallback((e) => {
    setVisible(true);
    e.stopPropagation();
  }, []);

  const { original } = row;
  const handleRemove = useCallback(
    (removedNumber) => {
      updateMyData(index, columnID, initialValue - removedNumber);
      updateMyData(index, 'status', statusKeyMap.PENDING);
    },
    [initialValue] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <UsersContainer onClick={handleClick}>{`${initialValue} `}</UsersContainer>
      {visible && (
        <UsersModal
          clientID={clientID}
          campaignID={original.id}
          visible={visible}
          onRemove={handleRemove}
          onClose={handleClose}
        />
      )}
    </Container>
  );
}

TargetUsersCell.propTypes = {
  initialState: PropTypes.shape({
    clientID: PropTypes.string,
  }),
  column: PropTypes.shape({
    id: PropTypes.string,
  }),
  value: PropTypes.number,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
    }),
    index: PropTypes.number,
  }),
  updateMyData: PropTypes.func,
};

export default TargetUsersCell;
