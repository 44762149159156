import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Confirm } from 'components/MaterialDesign/Buttons';
import { sendDraftMessageApi } from 'lib/appointmentService';
import { Loading } from 'components/MaterialDesign/Loading';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const Container = styled.div`
  height: 127px;
  display: flex;
  align-items: flex-end;
  padding: 16px 18px;
`;

const Description = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${dashboard_v2.colors.SHADES_800};
  margin: 0 40px 0 0;
`;

const Number = styled.span`
  font-weight: bold;
  margin: 0 4px;
`;

const SendIcon = styled.i`
  font-size: 24px;
`;

function SelectedToolSection({ clientID, selectedRowIds, selectedFlatRows, batchUpdateMyData, openSnackbar }) {
  const [loading, setLoading] = useState(false);
  const selectedNumber = selectedFlatRows.length;
  const onClick = useCallback(async () => {
    const ids = selectedFlatRows.map((d) => d.original.id);
    setLoading(true);
    await sendDraftMessageApi({
      clientID,
      ids,
    })
      .then(() => {
        setLoading(false);
        batchUpdateMyData(selectedRowIds, 'message', { draft: '' });
        openSnackbar({ message: t('messageSent'), status: 'success' });
      })
      .catch(() => {
        setLoading(false);
        openSnackbar({ message: t('sendFailedTryAgain'), status: 'error' });
      });
  }, [selectedRowIds, selectedFlatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Description>
        <div>
          {t('selectedAll')} <Number>{selectedNumber}</Number> {t('items')}
        </div>
      </Description>
      <Confirm
        width={93}
        height={36}
        endIcon={loading ? null : <SendIcon className="ri-mail-send-line" />}
        onClick={onClick}
      >
        {loading ? <Loading size={20} /> : t('send')}
      </Confirm>
    </Container>
  );
}

SelectedToolSection.propTypes = {
  selectedRowIds: PropTypes.objectOf(PropTypes.bool),
  selectedFlatRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  clientID: PropTypes.string,
  batchUpdateMyData: PropTypes.func,
  openSnackbar: PropTypes.func,
};

export default React.memo(SelectedToolSection);
