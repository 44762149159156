import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import { handleLogout } from 'reducers/auth';
import { refreshPage } from 'reducers/ui';
import { setSelectedStores } from 'reducers/clients';
import SidebarFooter from './../SidebarFooter';
import SidebarForm from './../SidebarForm';
import SidebarHeader from './../SidebarHeader';
import SidebarMinimizer from './../SidebarMinimizer';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import { filterForMainStore } from 'components/Sidebar/pathRule';

const mapStateToProps = (state) => ({
  nav: state.nav,
  selectedStoreIds: state.clients.selectedIds,
});

const mapDispatchToProps = {
  handleLogout,
  refreshPage,
  setSelectedStores,
};

@connect(mapStateToProps, mapDispatchToProps)
export default class Sidebar extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    handleLogout: PropTypes.func,
    nav: PropTypes.shape({
      items: PropTypes.array,
    }),
    refreshPage: PropTypes.func,
    setSelectedStores: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.toggleRef = React.createRef();
    this.navItems = this.props.nav.items;
  }

  componentDidUpdate(prevProps) {
    if (
      this.toggleRef.current &&
      JSON.stringify(prevProps.selectedStoreIds) !== JSON.stringify(this.props.selectedStoreIds)
    ) {
      this.handleDropdownToggle();
    }
    if (this.props.selectedStoreIds.length > 1) {
      this.navItems = this.props.nav.items.filter(filterForMainStore());
    } else {
      this.navItems = this.props.nav.items;
    }
  }

  handleDropdownToggle = (e) => {
    if (e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle('open');
    } else {
      this.toggleRef.current.parentElement.classList.toggle('open');
    }
  };

  activeRoute = (routeName) =>
    // return this.props.location.pathname.indexOf(routeName) > -1
    // ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';

  handleClickTab = (event, item) => {
    if (item.url === '/login') {
      console.log('click login');
      this.props.handleLogout();
    }

    if (item.enabled === false) {
      event.preventDefault();
    }

    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  };

  // todo Sidebar nav secondLevel
  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ?
  // "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  render() {
    // badge addon to NavItem
    const badge = (badgex) => {
      if (badgex) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badgex.variant}>
            {badgex.text}
          </Badge>
        );
      }
      return null;
    };

    // simple wrapper for nav-title item
    const wrapper = (item) =>
      item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
        : item.name;

    // nav list section title
    const title = (titlex, key) => {
      const classes = classNames('nav-title', titlex.class);
      return (
        <li key={key} className={classes}>
          {wrapper(titlex)}{' '}
        </li>
      );
    };

    // nav list divider
    const divider = (dividerx, key) => {
      const classes = classNames('divider', dividerx.class);
      return <li key={key} className={classes} />;
    };

    const isExternal = (url) => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : '';
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink href={url} className={classes.link} hidden={item.hidden} active>
              <i className={classes.icon} />
              {item.name}
              {item.popover && <InfoPopover popoverContent={item.popover} style={{ marginLeft: 4 }} type="TOP_RIGHT" />}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink
              to={url}
              className={classes.link}
              activeClassName="active"
              onClick={(event) => this.handleClickTab(event, item)}
              hidden={item.hidden}
            >
              <i className={classes.icon} />
              {item.name}
              {item.popover && <InfoPopover popoverContent={item.popover} style={{ marginLeft: 4 }} type="TOP_RIGHT" />}
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
          !item.icon ? 'fa fa-circle' : item.icon,
          item.label.variant ? `text-${item.label.variant}` : '',
          item.label.class ? item.label.class : ''
        ),
      };
      return navLink(item, key, classes);
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames(
          'nav-link',
          item.variant ? `nav-link-${item.variant}` : '',
          item.enabled === false ? 'nohover' : ''
        ),
        icon: classNames(item.icon),
      };
      return navLink(item, key, classes);
    };
    /* eslint no-use-before-define: 0 */
    // nav type
    const navType = (item, idx) => {
      if (item.title) {
        return title(item, idx);
      } else if (item.divider) {
        return divider(item, idx);
      } else if (item.label) {
        return navLabel(item, idx);
      } else if (item.children) {
        return navDropdown(item, idx);
      } else if (item.button) {
        return navButton(item, idx);
      }
      return navItem(item, idx);
    };

    // // nav type
    // const navType = (item, idx) =>
    //   item.title ? title(item, idx) :
    //   item.divider ? divider(item, idx) :
    //   item.label ? navLabel(item, idx) :
    //   item.children ? navDropdown(item, idx)
    //                 : navItem(item, idx) ;

    // nav list
    const navList = (items) => items.map((item, index) => navType(item, index));

    const navButton = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames('nav-link', 'button'),
        icon: classNames(item.icon),
      };
      return (
        <NavItem key={key} className={classes.item}>
          <a
            className="nav-link nav-dropdown-toggle"
            href="#"
            onClick={() => {
              this.props.refreshPage(true);
              this.props.setSelectedStores(item.clientIds);
            }}
          >
            <i className={item.icon} />
            {item.name}
          </a>
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => (
      <li key={key} className={this.activeRoute(item.url)}>
        <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleDropdownToggle} ref={this.toggleRef}>
          <i className={item.icon} />
          {item.name}
        </a>
        <ul className="nav-dropdown-items">{navList(item.children)}</ul>
      </li>
    );

    // sidebar-nav root
    return (
      <div className="sidebar">
        <SidebarHeader />
        <SidebarForm />
        <nav className="sidebar-nav">
          <Nav>{navList(this.navItems)}</Nav>
        </nav>
        <SidebarFooter />
        <SidebarMinimizer />
      </div>
    );
  }
}
