import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import theme from 'theme';

const customTheme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: theme.colors.primary,
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    color: theme.colors.textSecondary,
  },
}));

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const classes = useStyles();
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <ThemeProvider theme={customTheme}>
      <Checkbox
        className={classes.root}
        ref={resolvedRef}
        inputProps={{ 'aria-label': 'selection checkbox' }}
        {...rest}
      />
    </ThemeProvider>
  );
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
};

export default IndeterminateCheckbox;

export const RenderCheckboxHeader = ({ getToggleAllRowsSelectedProps }) => (
  <div>
    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
  </div>
);

RenderCheckboxHeader.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func,
};

export const RenderCheckboxCell = ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />;

export const RenderLoadingCell = () => <IndeterminateCheckbox disabled />;

RenderCheckboxCell.propTypes = {
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
  }),
};
