import { responseHandler } from './responseHandler';
import { getToken, MARKETING_URL, ENV_STAGE } from '../helpers';

const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  'AP-stage': ENV_STAGE,
});

export const ROLE = {
  CLINIC_REPLY_BOT: 'CLINIC_REPLY_BOT',
  CLINIC_MESSAGE_BOT: 'CLINIC_MESSAGE_BOT',
  PATIENT_REMINDER_BOT: 'PATIENT_REMINDER_BOT',
  IMAGE_BOT: 'IMAGE_BOT',
};

export const generateContent = async ({ clientID, role, content, args, callback }) => {
  try {
    const { message } = await fetch(`${MARKETING_URL}/v2/clients/${clientID}/openai/generateContent`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        inputRole: role,
        inputContent: content,
        inputArguments: args,
        inputClient: clientID,
      }),
    }).then(responseHandler);
    if (role == ROLE.IMAGE_BOT && callback != null) {
      callback(message);
      return message;
    }
    if (callback) {
      let i = 0;
      let msg = '';
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          msg += message[i];
          callback(msg);
          i++;
          if (i >= message.length) {
            resolve();
            clearInterval(interval);
          }
        }, 50);
      });
    }
  } catch (error) {
    const err = await error;
    console.log('error', JSON.stringify(err));
    return JSON.stringify(err);
  }
};
