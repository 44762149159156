import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bulkRemoveUserNotifications } from 'lib/campaignService';
import { Loading } from 'components/MaterialDesign/Loading';
import theme from 'theme';
import { t } from 'i18n/config';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
`;

const DeletedIcon = styled.i`
  color: ${(props) => props.theme.colors.default};
`;

export function LoadingCell() {
  return <Box />;
}

function ActionCell({ removeRow, openSnackbar, row, initialState: { clientID } }) {
  const { original: rawOriginal, index } = row;
  const [loading, setloading] = useState(false);

  const handleDelete = useCallback(async () => {
    setloading(true);
    try {
      await bulkRemoveUserNotifications({ clientID, originals: [rawOriginal] });
      removeRow(index);
      openSnackbar({ message: t('deleted'), status: 'success' });
    } catch (error) {
      openSnackbar({ message: t('tryAgainLater'), status: 'error' });
    }
    setloading(false);
  }, [row, clientID]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container>
      {loading && <Loading customcolor={theme.colors.default} size={26} />}
      {!loading && <DeletedIcon className="ri-delete-bin-line" onClick={handleDelete} />}
    </Container>
  );
}

ActionCell.propTypes = {
  initialState: PropTypes.shape({
    clientID: PropTypes.string,
  }),
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({}),
    index: PropTypes.number,
  }),
  removeRow: PropTypes.func,
  openSnackbar: PropTypes.func,
};

export default ActionCell;
