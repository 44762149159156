import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PatientTrackingTemplateForm from './PatientTrackingTemplateForm';
import GroupMessageTemplateForm from './GroupMessageTemplateForm';

const TemplateForm = ({ mode, type, match }) => {
  switch (type) {
    case 'groupMessage':
      return <GroupMessageTemplateForm mode={mode} type={type} match={match} />;
    case 'patientTracking':
      return <PatientTrackingTemplateForm mode={mode} type={type} match={match} />;
    default:
      return null;
  }
};

const propTypes = {
  mode: PropTypes.string,
  type: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

TemplateForm.propTypes = propTypes;

export default memo(TemplateForm);
