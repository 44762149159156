import { getToken, MARKETING_URL, ENV_STAGE } from 'helpers';
const baseURL = MARKETING_URL + '/v2/clients';

export async function patchSetting(payload) {
  const { clientId } = payload;
  const { keyToUpdate } = payload;
  const { feedbackEmail, cellPhoneNumber, customerExpireDay, uploadDelayTime, smsContent, customerAgeRange } =
    keyToUpdate;
  try {
    const response = await fetch(`${baseURL}/${clientId}/setting`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'AP-stage': ENV_STAGE,
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        feedbackEmail,
        cellPhoneNumber,
        customerExpireDay,
        uploadDelayTime,
        smsContent,
        customerAgeRange,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();

    return response.ok ? { success: true, data } : { success: false, error: 'Request failed' };
  } catch (error) {
    console.error('patch setting failed:', error);
    return { success: false, error: 'Request failed' };
  }
}
