import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';
const { colors } = dashboard_v2;
const Icon = styled.i`
  font-size: ${({ size }) => `${size}px`};
  line-height: 1;
  color: ${({ color }) => color || colors.SHADES_400} !important;
`;

export default function LinkIcon({ tooltip, size = 18 }) {
  return (
    <Tooltip title={t('bound')} placement="top" {...(tooltip ? {} : { open: false })}>
      <Icon className="ri-link" color={colors.SYSTEM_SUCCESS_600} size={size} />
    </Tooltip>
  );
}
