import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/MaterialDesign/Popover';
import { useSelector } from 'react-redux';
import { updateDraftApi, sendDraftMessageApi } from 'lib/appointmentService';
import { generateContent } from 'lib/openaiService';
import EditMessageComponent from './EditMessageComponent';
import ROUTE from '../route';
import MessageHistoryComponent from './MessageHistoryComponent';
import { t } from 'i18n/config';

export default function MessagePopover({
  handleClose,
  value,
  anchorEl,
  open,
  handleChange,
  appointmentID,
  clientID,
  openSnackbar,
}) {
  const [draftValue, setDraftValue] = useState(value);
  const [route, dispatchRoute] = useState(ROUTE.MAIN);
  const {
    features: { openAI: openAIEnabled },
  } = useSelector((state) => state.clients.byId[state.clients.selectedIds[0]]);

  const goToHistory = useCallback(() => {
    dispatchRoute(ROUTE.HISTORY);
  }, []);
  const goToMain = useCallback(() => {
    dispatchRoute(ROUTE.MAIN);
  }, []);

  const onSaveDraft = useCallback(async () => {
    await updateDraftApi({ clientID, id: appointmentID, draft: draftValue })
      .then(() => {
        handleChange(draftValue);
      })
      .catch((err) => {
        console.log('err = ', err);
      });
  }, [appointmentID, clientID, handleChange, draftValue]);

  const handleOpenAI = async ({ role, content, args, callback }) => {
    await generateContent({
      clientID,
      role,
      content,
      args,
      callback: callback,
    });
  };

  const onSend = useCallback(async () => {
    await updateDraftApi({ clientID, id: appointmentID, draft: draftValue });
    await sendDraftMessageApi({ clientID, ids: [appointmentID] })
      .then(() => {
        handleChange('');
        openSnackbar({ message: t('messageSent'), status: 'success' });
      })
      .catch(() => {
        openSnackbar({ message: t('sendingFailedTryAgain'), status: 'error' });
      });
  }, [appointmentID, clientID, handleChange, openSnackbar, draftValue]);

  const onClose = useCallback(() => {
    onSaveDraft();
    handleClose();
  }, [onSaveDraft, handleClose]);

  return (
    <Popover open={open} anchorEl={anchorEl} handleClose={onClose}>
      {route === ROUTE.MAIN && (
        <EditMessageComponent
          draftValue={draftValue}
          setDraftValue={setDraftValue}
          onSend={onSend}
          handleOpenAI={openAIEnabled ? handleOpenAI : undefined}
          goToHistory={goToHistory}
          openSnackbar={openSnackbar}
        />
      )}
      {route === ROUTE.HISTORY && (
        <MessageHistoryComponent goToMain={goToMain} clientID={clientID} appointmentID={appointmentID} />
      )}
    </Popover>
  );
}

MessagePopover.propTypes = {
  handleClose: PropTypes.func,
  value: PropTypes.string,
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  handleChange: PropTypes.func,
  appointmentID: PropTypes.string,
  clientID: PropTypes.string,
  openSnackbar: PropTypes.func,
};
