import React, { useState, useMemo, useRef, memo } from 'react';
import styled from 'styled-components';
import Text from 'components/Dashboard_v2/Text';
import Checkbox from 'components/Dashboard_v2/Checkbox';
import Tag from 'components/Dashboard_v2/Tag';
import Popover from '@material-ui/core/Popover';
import PatientTrackingTag from 'components/Dashboard_v2/PatientTrackingTag';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: relative;
`;

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 100px;
  cursor: pointer;
  overflow: hidden;
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  ${({ hasSelectedItem }) =>
    hasSelectedItem
      ? `
          background-color: ${colors.PRIMARY_100};
          border: 1px solid ${colors.PRIMARY_400};
          * {
            color: ${colors.PRIMARY_400};
          }
        `
      : `
          background-color: ${colors.SHADES_000};
          border: 1px solid ${colors.SHADES_300};
          * {
            color: ${colors.SHADES_500};
          }
      `};
`;

const FilterContent = styled.div`
  width: 360px;
  padding: 12px 16px;
  background-color: ${colors.SHADES_000};
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_400};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  i.ri-close-fill {
    margin-top: 2px;
    margin-left: 8px;
    font-size: 14px;
    color: ${colors.SHADES_400};
    cursor: pointer;
    &:hover {
      color: ${colors.PRIMARY_400};
    }
  }
  > div {
    width: 100%;
  }
  input {
    border: none;
    font-size: 14px;
    width: 120px;
  }
  &:hover {
    border: 1px solid ${colors.PRIMARY_400};
  }
`;

const SelectedCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  margin: 12px 0;
`;

const ItemWrapper = styled.div`
  max-height: 230px;
  overflow-y: auto;
`;

const SelectedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`;

const DefaultItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  i {
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
`;

const ItemBlock = React.memo(({ itemType, data, onRemove }) => {
  switch (itemType) {
    case 'tag':
      return <Tag name={data.name} onClickRemove={onRemove} />;
    case 'patientTracking':
      return (
        <PatientTrackingTag
          name={data.name}
          setting={{
            isEnabled: data.status === 'enabled',
            recyclable: data.recyclable,
          }}
          onClickRemove={onRemove}
        />
      );
    default:
      return (
        <DefaultItem key={data.id}>
          {data.name}
          {onRemove && <i className="ri-close-circle-fill" onClick={onRemove} />}
        </DefaultItem>
      );
  }
});

const Filter = ({ label, iconClass, itemType, items, onSelected }) => {
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [keyword, setKeyword] = useState('');

  const { selectedItemIds, selectedItemNames } = useMemo(
    () =>
      selectedItems.reduce(
        (obj, item) => {
          obj.selectedItemIds.push(item.id);
          obj.selectedItemNames.push(item.name);
          return obj;
        },
        { selectedItemIds: [], selectedItemNames: [] }
      ),
    [selectedItems]
  );

  const filteredItems = useMemo(() => {
    if (!keyword) {
      return items;
    }
    return items.filter((item) => item.name?.toLowerCase()?.includes(keyword.toLowerCase()));
  }, [items, keyword]);

  const handleSelect = (isChecked, item) => {
    if (isChecked) {
      setSelectedItems((prev) => [...prev, item]);
    } else {
      const selectedItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id === item.id);
      if (selectedItemIndex !== -1) {
        setSelectedItems((prev) => {
          const newArray = [...prev];
          newArray.splice(selectedItemIndex, 1);
          return newArray;
        });
      }
    }
  };

  const onClose = () => {
    onSelected(selectedItemIds);
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <FilterButton hasSelectedItem={!!selectedItems.length} onClick={(e) => setAnchorEl(e.currentTarget)}>
        {iconClass && <i className={iconClass} />}
        <Text font="Heading/Small/Regular">{selectedItems.length ? selectedItemNames.join(', ') : label}</Text>
        <i className="ri-arrow-down-s-line" />
      </FilterButton>
      {!!anchorEl && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
          }}
        >
          <FilterContent>
            <InputContainer onClick={() => inputRef.current.focus()}>
              <SelectedItemWrapper>
                {selectedItems?.map((selectedItem) => (
                  <ItemBlock
                    data={selectedItem}
                    itemType={itemType}
                    onRemove={() => handleSelect(false, selectedItem)}
                    key={selectedItem.id}
                  />
                ))}
                <input
                  ref={inputRef}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder={selectedItems.length ? '' : t('searchKeywords')}
                  type="text"
                />
              </SelectedItemWrapper>
              <i
                className="ri-close-fill"
                onClick={() => {
                  setKeyword('');
                  setSelectedItems([]);
                }}
              />
            </InputContainer>
            <SelectedCount>
              {label} ({selectedItems.length}/{items.length})
            </SelectedCount>
            <ItemWrapper>
              {filteredItems.map((item) => (
                <Checkbox
                  onChange={(e) => handleSelect(e, item)}
                  checked={selectedItemIds.includes(item.id)}
                  fontSize={14}
                  marginBottom={16}
                  key={item.id}
                >
                  <ItemBlock data={item} itemType={itemType} />
                </Checkbox>
              ))}
            </ItemWrapper>
          </FilterContent>
        </Popover>
      )}
    </Wrapper>
  );
};

export default memo(Filter);
