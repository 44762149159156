import { AUTH_TYPE, OPERATION_TYPE, VERSION } from 'helpers/constants';

export const isLogin = () => !!JSON.parse(localStorage.getItem('auth'));
export const getToken = () => (localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).token : null);
export const getInfo = () => (localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user : null);
export const removeAuth = () => localStorage.removeItem('auth');
export default localStorage.getItem('auth');

export const isOperationType = (client) => client.operationType === OPERATION_TYPE.OPERATION;
export const isAuthRoleBrand = (client) => client.authRole === AUTH_TYPE.BRAND;

export const isProfessionalVersion = (client) => client.features.version === VERSION.PROFESSIONAL;
