import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Confirm } from 'components/MaterialDesign/Buttons';
import FilterableTable from 'components/Table/FilterableTable';
import theme from 'theme';
import { headerColumns, tableStyles } from 'components/Message/campaign/importUsers/importUsersTable/headerColumns';
import Footer from 'components/Message/campaign/importUsers/Footer';
import { bulkUploadUsers } from 'lib/campaignService';
import TeethScalingList, { strategies } from 'lib/vendors/teetchScalingParser';
import UploadModal from './UploadModal';
import SettingModal from './SettingModal';
import { t } from 'i18n/config';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f1f4f9;
  padding: 30px;
  margin-top: 130px;
  flex: 1;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-bottom: 120px;
`;

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
`;

const defaultSendTime = { hours: 11, minutes: 0 };
function ImportUsers(props) {
  const { clientID, campaign, vendor, onUploadSuccess } = props;
  const [uploaderVisible, setUploaderVisible] = useState(false);
  const [uploading, setuploading] = useState(false);
  const [data, setData] = useState([]);
  const [settingVisible, setSettingVisible] = useState(false);
  const [sendTime, setSendTime] = useState();

  const handleImport = useCallback(() => {
    setUploaderVisible(true);
  }, []);
  const handleUploaderClose = useCallback(() => {
    setUploaderVisible(false);
  }, []);

  const handleImported = useCallback(
    (res) => {
      // calculate estimated delivery time based on vendor with customized logic
      const ts = new TeethScalingList();
      const strategy = strategies[vendor];
      ts.setStrategy(strategy);
      const bindedData = ts.bindCampaign(res, campaign, { setDateValues: defaultSendTime });
      setData(bindedData);
    },
    [campaign, vendor]
  );

  const handleUpload = useCallback(async (d) => {
    if (d.length === 0) {
      alert('data is empty');
      return;
    }
    setuploading(true);
    try {
      await bulkUploadUsers({ clientID, campaignID: campaign.id, users: d });
      onUploadSuccess();
    } catch (error) {
      alert(error.message);
    }
    setuploading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDataChange = useCallback((res) => {
    console.log('data change', res);
  }, []);

  const handleSettingClose = useCallback(() => {
    setSettingVisible(false);
  }, []);

  const handleSettingConfirm = useCallback(
    async (settings) => {
      const { setDateValues } = settings;
      setSendTime(setDateValues);
      const ts = new TeethScalingList();
      const strategy = strategies[vendor];
      ts.setStrategy(strategy);
      const bindedData = ts.bindCampaign(data, campaign, { setDateValues });
      setData(bindedData);
      await handleUpload(bindedData);
      setSettingVisible(false);
    },
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSettingSetDateValues = useCallback(
    (setDateValues) => {
      setSendTime(setDateValues);
      const ts = new TeethScalingList();
      const strategy = strategies[vendor];
      ts.setStrategy(strategy);
      setData((d) => ts.bindCampaign(d, campaign, { setDateValues }));
    },
    [vendor] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onNext = useCallback(async () => {
    if (data.length === 0) {
      return;
    }
    if (!sendTime) {
      setSettingVisible(true);
    }
  }, [sendTime, data]);

  return (
    <Container>
      <ActionContainer>
        <Description>
          <span role="img" aria-label="star">
            ⭐
          </span>{' '}
          {t('importDentalCleaningListFromHis')}
        </Description>
        <Confirm
          onClick={handleImport}
          startIcon={<i className="ri-add-line" />}
          customizedcolor={data.length === 0 ? theme.colors.primary : theme.colors.default}
          height={40}
          width={110}
        >
          {data.length === 0 ? t('importList') : t('reimport')}
        </Confirm>
      </ActionContainer>
      <TableContainer>
        <FilterableTable
          tableStyles={tableStyles}
          tableHeaderColumns={headerColumns(vendor)}
          clientID={clientID}
          data={data}
          loading={false}
          onDateChange={handleDataChange}
          shouldRowsRemoved
        />
      </TableContainer>
      <Footer sendTime={sendTime} data={data} loading={uploading} onClick={onNext} />

      {uploaderVisible && (
        <UploadModal
          visible={uploaderVisible}
          onClose={handleUploaderClose}
          onUploaded={handleImported}
          vendor={vendor}
        />
      )}
      {settingVisible && (
        <SettingModal
          visible={settingVisible}
          campaign={campaign}
          onClose={handleSettingClose}
          onConfirm={handleSettingConfirm}
          onSetDateValues={handleSettingSetDateValues}
          vendor={vendor}
          loading={uploading}
          defaultSendTime={defaultSendTime}
        />
      )}
    </Container>
  );
}

ImportUsers.propTypes = {
  clientID: PropTypes.string,
  campaign: PropTypes.shape({
    id: PropTypes.string,
  }),
  vendor: PropTypes.string,
  onClose: PropTypes.func,
  onUploadSuccess: PropTypes.func,
};

export default ImportUsers;
