import React from 'react';
import styled from 'styled-components';
import Radio from 'components/Dashboard_v2/Radio';
import { getMergeStateByType } from 'feature/MemberCenter/MemberMerge';
import { t } from 'i18n/config';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import { dashboard_v2 } from 'theme';
const { colors } = dashboard_v2;

const Item = styled.div`
  padding: 0 48px !important;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 44px;
  i {
    cursor: pointer;
    font-size: 20px;
    color: ${colors.SHADES_400};
  }
`;

export default function CompareItems({ type, selectedMember, mergeState, updateMergeState, updateState }) {
  const {
    id,
    name,
    hisName,
    lineName,
    lineBinding,
    lineMessage,
    phone,
    birthday,
    idType,
    twID,
    hisID,
    memberID,
    pdID,
    tags = [],
    patientTrackings = [],
  } = selectedMember || {};

  const displayName = name || hisName || lineName;

  const tagsText = () => {
    const text = tags.map((tag) => tag.name).join('/') || '/';
    if (text.length > 20) {
      const lineBreakText = tags.map((tag) => tag.name).join('\n');
      return <InfoPopover popoverContent={lineBreakText} label={`${text.slice(0, 20)} ...`} type="TOP_LEFT" />;
    } else {
      return text;
    }
  };

  const patientTrackingsText = () => {
    const text = patientTrackings.map((tracking) => tracking.title).join('/') || '/';
    if (text.length > 20) {
      const lineBreakText = patientTrackings.map((tracking) => tracking.title).join('\n');
      return <InfoPopover popoverContent={lineBreakText} label={`${text.slice(0, 20)} ...`} type="TOP_LEFT" />;
    } else {
      return text;
    }
  };

  return id ? (
    <>
      <Item>
        <Radio
          name="username"
          gap={32}
          value={mergeState.preserved}
          onChange={() => updateMergeState(getMergeStateByType(type))}
          items={[
            {
              label:
                (
                  <NameWrapper>
                    <span>{displayName}</span>
                    <i
                      className="c1 ri-delete-bin-line"
                      onClick={(e) => {
                        e.stopPropagation();
                        updateState({ keyword: '', list: [], selectedMember: null });
                      }}
                    />
                  </NameWrapper>
                ) || '/',
              value: type,
            },
          ]}
          checked={mergeState.preserved === type}
          itemAlign="center"
          single
          fullWidth
        />
      </Item>
      <Item>
        <Radio
          name="twID"
          gap={32}
          value={mergeState.twID}
          onChange={() => updateMergeState({ twID: type })}
          items={[{ label: twID || '/', value: type }]}
          checked={mergeState.twID === type}
          disabled={idType === 'twID'}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="lineBinding"
          gap={32}
          value={mergeState.preserved}
          items={[{ label: lineBinding === 'binding' ? t('bound') : t('unbound'), value: type }]}
          checked={mergeState.preserved === type}
          itemAlign="center"
          single
          disabled
        />
      </Item>
      <Item>
        <Radio
          name="lineBinding"
          gap={32}
          value={mergeState.preserved}
          items={[
            { label: lineMessage === 'active' ? t('lineMessageSendable') : t('lineMessageNotSendable'), value: type },
          ]}
          checked={mergeState.preserved === type}
          itemAlign="center"
          single
          disabled
        />
      </Item>
      <Item>
        <Radio
          name="phone"
          gap={32}
          value={mergeState.phone}
          onChange={() => updateMergeState({ phone: type })}
          items={[{ label: phone || '/', value: type }]}
          checked={mergeState.phone === type}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="birthday"
          gap={32}
          value={mergeState.birthday}
          onChange={() => updateMergeState({ birthday: type })}
          items={[{ label: birthday || '/', value: type }]}
          checked={mergeState.birthday === type}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="hisID"
          gap={32}
          value={mergeState.hisID}
          onChange={() => updateMergeState({ hisID: type })}
          items={[{ label: hisID || '/', value: type }]}
          checked={mergeState.hisID === type}
          disabled={idType === 'hisID'}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="memberID"
          gap={32}
          value={mergeState.memberID}
          onChange={() => updateMergeState({ memberID: type })}
          items={[{ label: memberID || '/', value: type }]}
          checked={mergeState.memberID === type}
          disabled={idType === 'memberID'}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="pdID"
          gap={32}
          value={mergeState.pdID}
          onChange={() => updateMergeState({ pdID: type })}
          items={[{ label: pdID || '/', value: type }]}
          checked={mergeState.pdID === type}
          disabled={idType === 'pdID'}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="tags"
          gap={32}
          value={mergeState.tags}
          onChange={() => updateMergeState({ tags: type })}
          items={[{ label: tagsText(), value: type }]}
          checked={mergeState.tags === type}
          itemAlign="center"
          single
        />
      </Item>
      <Item>
        <Radio
          name="patientTrackings"
          gap={32}
          value={mergeState.patientTrackings}
          onChange={() => updateMergeState({ patientTrackings: type })}
          items={[{ label: patientTrackingsText(), value: type }]}
          checked={mergeState.patientTrackings === type}
          itemAlign="center"
          single
        />
      </Item>
    </>
  ) : null;
}
