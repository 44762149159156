import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from 'components/Dashboard_v2/Dialog';
import Select from 'components/Dashboard_v2/Select';
import Input from 'components/Dashboard_v2/Input';
import Text from 'components/Dashboard_v2/Text';
import { FlowIcon } from './EditBlocks';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.SHADES_800};
  margin-bottom: 24px;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 24px;
  > span {
    margin: 0 4px 8px 8px;
  }
  ${({ timeUnit }) =>
    timeUnit &&
    `
      align-items: center;
      > div {
        margin-right: 8px;
      }
    `}
`;

const Hint = styled.div`
  display: flex;
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${colors.SHADES_100};
  i {
    color: ${colors.SHADES_600};
    margin-top: -3px;
    margin-right: 12px;
  }
`;

const TIME_OPTIONS = {
  TIME_UNIT: [
    { id: 0, name: t('hours'), value: 'hour' },
    { id: 1, name: t('days'), value: 'day' },
    { id: 2, name: t('weeks'), value: 'week' },
    { id: 3, name: t('months'), value: 'month' },
    { id: 4, name: t('quarters'), value: 'season' },
    { id: 5, name: t('years'), value: 'year' },
  ],
  // HOURS: 00, 01 ... 23
  HOURS: Array.from(Array(24)).map((n, i) => ({ id: i, name: `${i}`.padStart(2, '0'), value: i })),
  // MINUTES: 00, 05, ... 55
  MINUTES: Array.from(Array(12)).map((n, i) => ({ id: i, name: `${i * 5}`.padStart(2, '0'), value: i * 5 })),
};

export const getDisplayTime = (settings) => {
  const { timeUnit, timeValue } = settings;
  if (!timeUnit) return '';
  const timeUnitText = TIME_OPTIONS.TIME_UNIT.find((unit) => timeUnit === unit.value).name;
  return t('delayForTime', {
    timeValue,
    timeUnitText,
  });
};

const getSettingValues = (timeSetting) => ({
  timeUnit: timeSetting.timeUnit.value,
  timeValue: parseInt(timeSetting.timeValue, 10),
  hour: timeSetting.hour.value,
  minute: timeSetting.minute.value,
});

const getSecondes = (timeSetting) => {
  const formatToDays = {
    day: 1,
    week: 7,
    month: 30,
    season: 90,
    year: 365,
  };
  const { timeUnit, timeValue, hour, minute } = getSettingValues(timeSetting);
  let seconds = 0;
  if (timeUnit === 'hour') {
    seconds = timeValue * 3600;
  } else {
    const daysSeconds = timeValue * formatToDays[timeUnit] * 86400;
    const timeSeconds = hour * 3600 + minute * 60;
    seconds = daysSeconds + timeSeconds;
  }
  return seconds;
};

const getSettings = (data) => {
  if (data.timeValue !== 0 && !Number(data.timeValue)) {
    return defaultTimeSetting;
  } else {
    const { timeUnit, timeValue, hour, minute } = data;
    return {
      timeUnit: TIME_OPTIONS.TIME_UNIT.find((option) => option.value === timeUnit),
      timeValue,
      hour: TIME_OPTIONS.HOURS.find((option) => option.value === hour),
      minute: TIME_OPTIONS.MINUTES.find((option) => option.value === minute),
    };
  }
};

const defaultTimeSetting = {
  timeUnit: TIME_OPTIONS.TIME_UNIT[0],
  timeValue: 1,
  hour: TIME_OPTIONS.HOURS[0],
  minute: TIME_OPTIONS.MINUTES[0],
};

const TimeModal = ({ data, recyclable, onConfirm, onClose }) => {
  const [timeSetting, setTimeSetting] = useState(getSettings(data));

  const handleChange = (key, value) => {
    setTimeSetting((prev) => ({ ...prev, [key]: value }));
  };

  const handleConfirm = () => {
    onConfirm({ ...getSettingValues(timeSetting), delaySeconds: getSecondes(timeSetting) });
    onClose();
  };

  return (
    <Dialog
      open={true}
      title={<FlowIcon type="time" />}
      description={
        <div>
          <ModalTitle>{t('timeSetting')}</ModalTitle>
          <TimeWrapper timeUnit>
            <Input
              label={t('delayForUnitNumber', { unit: timeSetting.timeUnit.name })}
              value={timeSetting.timeValue}
              onInputChange={(e) => handleChange('timeValue', e.target.value)}
              width={120}
              type="number"
              min={!recyclable && timeSetting.timeUnit.value === 'hour' ? 0 : 1}
            />
            <Select
              label={t('timeFormat')}
              options={TIME_OPTIONS.TIME_UNIT}
              selectedOption={timeSetting.timeUnit}
              setSelectedOption={(option) => handleChange('timeUnit', option)}
              width={120}
            />
          </TimeWrapper>
          {timeSetting.timeUnit.value !== 'hour' && (
            <TimeWrapper>
              <Select
                label={t('sendingTime')}
                options={TIME_OPTIONS.HOURS}
                selectedOption={timeSetting.hour}
                setSelectedOption={(option) => handleChange('hour', option)}
                width={80}
              />
              <span>:</span>
              <Select
                options={TIME_OPTIONS.MINUTES}
                selectedOption={timeSetting.minute}
                setSelectedOption={(option) => handleChange('minute', option)}
                width={80}
              />
            </TimeWrapper>
          )}
          <Hint>
            <i className="ri-information-line" />
            <Text font="Body/12px_Regular" color="SHADES_600">
              {t('whenSendingLargeAmount')}
            </Text>
          </Hint>
        </div>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={recyclable && !Number(timeSetting.timeValue)}
    />
  );
};

const propTypes = {
  data: PropTypes.shape({
    timeUnit: PropTypes.string,
    timeValue: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    delaySeconds: PropTypes.number,
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

TimeModal.propTypes = propTypes;

export default memo(TimeModal);
