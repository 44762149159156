import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTable, usePagination } from 'react-table';
import nanoid from 'nanoid';
import TablePagination from '@material-ui/core/TablePagination';

function Table({ columns, data }) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  const handleChangeRowsPerPage = useCallback((event) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = useCallback((event, newPage) => {
    gotoPage(newPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={nanoid()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()} key={nanoid()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, pageIndex) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} key={pageIndex}>
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()} key={nanoid()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      {rows.length > pageSize && (
        <TablePagination
          component="div"
          count={rows.length}
          page={pageIndex}
          onChangePage={handleChangePage}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
