import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

const Note = styled.div`
  color: ${(props) => (props.isEmpty ? props.theme.colors.disabled : props.theme.colors.textPrimary)};
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const Reminder = styled.div`
  padding: 3px 4px;
  color: ${(props) => (props.isEmpty ? props.theme.colors.disabled : props.theme.colors.textPrimary)};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  max-height: 80px;
  word-break: break-all;
  white-space: pre-line;
}
`;

const ReminderSection = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  margin-top: 4px;
`;

const PencilIcon = styled.i`
  font-size: 18px;
`;

const Footer = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
`;

export { Container, Note, Reminder, ReminderSection, PencilIcon, Footer };
