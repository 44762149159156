import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import theme from 'theme';
import { Loading as LoadingCircular } from 'components/MaterialDesign/Loading';
import { updateProgressStateApi } from 'lib/appointmentService';
import { progressValues } from './progress';
import { Container, Time, Button } from './styledComponents';
import Reducer, { setProgress, updateRemote } from './reducer';
import LoadingCell from './LoadingCell';
import { t } from 'i18n/config';

const defaultValue = {};
function ProgressCell({ value: initialValue = defaultValue, row, column: { id }, updateMyData }) {
  const { index } = row;
  const { time, status } = initialValue;
  const [state, dispatch] = useReducer(Reducer, { loading: false, progress: status });
  useEffect(() => {
    dispatch(setProgress(status));
  }, [status]);

  const setInProgress = useCallback(async () => {
    const inprogress = progressValues.InProgress;
    dispatch(updateRemote());
    await updateProgressStateApi({ id: row.original.id, progress: inprogress })
      .then((res) => {
        console.log('res = ', res);
        updateMyData(index, id, { status: inprogress });
        dispatch(setProgress(inprogress));
      })
      .catch((err) => {
        console.log('err in progress cell', err.message);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setDone = useCallback(async () => {
    const now = moment(new Date()).format('YYYY/MM/DD HH:mm');
    const done = progressValues.Done;
    dispatch(updateRemote());
    await updateProgressStateApi({ id: row.original.id, progress: done })
      .then((res) => {
        console.log('res = ', res);
        updateMyData(index, id, { status: done, time: now });
        dispatch(setProgress(done));
      })
      .catch((err) => {
        console.log('err in progress cell', err.message);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {state.progress === progressValues.Done && (
        <React.Fragment>
          <Button width={70} height={30} customizedcolor={theme.colors.success} onClick={setInProgress}>
            {state.loading ? <LoadingCircular size={20} /> : t('processingComplete')}
          </Button>
          <Time>{time}</Time>
        </React.Fragment>
      )}
      {state.progress === progressValues.InProgress && (
        <Button width={56} height={30} customizedcolor={theme.colors.secondary} onClick={setDone}>
          {state.loading ? <LoadingCircular size={20} /> : t('pending')}
        </Button>
      )}
    </Container>
  );
}

ProgressCell.propTypes = {
  value: PropTypes.shape({
    time: PropTypes.string,
    status: PropTypes.string,
  }),
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  column: PropTypes.shape({
    id: PropTypes.string,
    setFilter: PropTypes.func,
    filterValue: PropTypes.string,
  }),
  updateMyData: PropTypes.func,
};

export default ProgressCell;

export const Loading = LoadingCell;
