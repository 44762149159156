import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import { getTags } from 'reducers/members';
import { fetchTag, createTag, updateTag } from 'lib/memberService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import FormControl from 'components/Dashboard_v2/FormControl';
import Input from 'components/Dashboard_v2/Input';
import Radio from 'components/Dashboard_v2/Radio';
import Text from 'components/Dashboard_v2/Text';
import Select from 'components/Dashboard_v2/Select';
import { t } from 'i18n/config';
import Tooltip from 'components/Dashboard_v2/Tooltip';
import PatientTrackingSelectorInput from 'components/Dashboard_v2/PatientTrackingSelectorInput';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 16px;
  margin-left: 28px;
  > span {
    margin: 0 4px 8px 8px;
  }
  > div {
    margin-right: 8px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const initialFormState = {
  name: '',
  expiable: false,
  timeValue: 0,
};

const InputField = {
  NAME: 'name',
  EXPIRE_SECONDS: 'expireSeconds',
  TIME_VALUE: 'timeValue',
};

const TIME_UNIT_OPTIONS = [
  { id: 1, name: t('days'), value: 'day', seconds: 86400 }, // 1d
  { id: 2, name: t('weeks'), value: 'week', seconds: 604800 }, // 7d
  { id: 3, name: t('months'), value: 'month', seconds: 2592000 }, // 30d
  { id: 4, name: t('quarters'), value: 'season', seconds: 7776000 }, // 90d
  { id: 5, name: t('years'), value: 'year', seconds: 31536000 }, // 365d
];

const MODE_NAME_MAP = {
  create: {
    title: t('add'),
    description: t('tagNameRequired'),
    saveButton: t('saveAndAdd'),
  },
  edit: {
    title: t('edit'),
    description: t('tagNameRequired'),
    saveButton: t('updateAndClose'),
  },
};

const TagForm = ({ mode, match }) => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { renderAlert, setAlert } = useAlert();
  const tagId = match.params.id;
  const [formData, setFormData] = useState(null);
  const [timeUnit, setTimeUnit] = useState(null);
  const [errInput, setErrInput] = useState({});
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPatientTrackings, setSelectedPatientTrackings] = useState([]);

  const checkValidation = useCallback(() => {
    const errInput = {};
    if (formData.name === '') {
      errInput[InputField.NAME] = t('confirmTagFormat');
    }

    if (formData.expiable) {
      const value = parseInt(formData.timeValue, 10);
      if (!value) {
        errInput[InputField.TIME_VALUE] = t('enterValidDays');
      } else if (value % 1 !== 0) {
        errInput[InputField.TIME_VALUE] = t('enterInteger');
      }
    }

    setErrInput(errInput);
    return errInput;
  }, [formData]);

  const handleSubmit = async () => {
    if (isLoading) return;

    const errMsg = checkValidation();

    if (Object.keys(errMsg).length === 0) {
      setIsLoading(true);
      try {
        const tagData = {
          ...formData,
          timeValue: parseInt(formData.timeValue, 10),
          timeUnit: timeUnit.value,
          expireSeconds: timeUnit.seconds * formData.timeValue,
          patientTrackingIds: selectedPatientTrackings.map((tracking) => tracking.id),
        };
        if (mode === 'create') {
          await createTag({ clientId, tag: tagData });
        }
        if (mode === 'edit') {
          await updateTag({ clientId, tagId, tag: tagData });
        }
        setAlert({ type: 'success', title: t('tagActionSuccess', { title: MODE_NAME_MAP[mode].title }) });
        setRedirectToList(true);
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('tagActionFailed', { title: MODE_NAME_MAP[mode].title }) });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(errInput).length !== 0) {
      checkValidation(formData);
    }
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getTags({ clientId }));
    if (mode === 'create') {
      setFormData(initialFormState);
      setTimeUnit(TIME_UNIT_OPTIONS[0]);
    }
    if (mode === 'edit') {
      const getTagData = async () => {
        const { name, expiable, timeValue, timeUnit, patientTrackings = [] } = await fetchTag({ clientId, tagId });
        setFormData({ name, expiable, timeValue });
        setTimeUnit(TIME_UNIT_OPTIONS.find((option) => option.value === timeUnit) || TIME_UNIT_OPTIONS[0]);
        setSelectedPatientTrackings(patientTrackings);
      };
      getTagData();
    }
  }, [mode, dispatch, clientId, tagId]);

  return redirectToList ? (
    <Redirect push to="/members/tags" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/members/tags">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/members/tags">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button color="primary" variant="filled" onClick={handleSubmit}>
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
          </Button>
        </FormActions>
      </Header>
      {formData && (
        <Main>
          <Block title={MODE_NAME_MAP[mode].title} description={MODE_NAME_MAP[mode].description}>
            <FormControl>
              <Input
                required
                name={InputField.NAME}
                label={t('tagName')}
                helperText={errInput[InputField.NAME]}
                value={formData.name}
                onInputChange={(e) => setFormData({ ...formData, name: e.target.value })}
                error={!!errInput[InputField.NAME]}
              />
            </FormControl>
          </Block>
          <Block title={t('validityPeriod')} description={mode === 'create' && t('tagCreationNotice')}>
            <FormControl>
              <Radio
                value={formData.expiable}
                onChange={(value) => setFormData({ ...formData, expiable: value })}
                items={[
                  {
                    label: t('permanent'),
                    value: false,
                    content: (
                      <Text font="Heading/X-Small/Regular" color="SHADES_400" marginLeft={28}>
                        {t('permanentTagDescription')}
                      </Text>
                    ),
                  },
                  {
                    label: t('validDays'),
                    value: true,
                    content: (
                      <React.Fragment>
                        <Text font="Heading/X-Small/Regular" color="SHADES_400" marginLeft={28}>
                          {t('tagRemovalTimeSetting')}
                        </Text>
                        <TimeWrapper>
                          <Input
                            name={InputField.TIME_VALUE}
                            value={formData.timeValue || 0}
                            onInputChange={(e) => setFormData({ ...formData, timeValue: e.target.value })}
                            error={!!errInput[InputField.TIME_VALUE]}
                            helperText={errInput[InputField.TIME_VALUE]}
                            disabled={mode === 'edit'}
                            width={100}
                            type="number"
                            min="1"
                          />
                          <Select
                            options={TIME_UNIT_OPTIONS}
                            selectedOption={timeUnit}
                            setSelectedOption={(option) => setTimeUnit(option)}
                            disabled={mode === 'edit'}
                            width={72}
                          />
                        </TimeWrapper>
                      </React.Fragment>
                    ),
                  },
                ]}
                disabled={mode === 'edit'}
                direction="column"
                required
              />
            </FormControl>
          </Block>
          <Block
            title={
              <FlexWrapper>
                <div>{t('trackingTag.title')}</div>
                <Tooltip popoverContent={t('trackingTag.info')}>
                  <i className="ri-information-line" style={{ color: colors.SHADES_400 }} />
                </Tooltip>
              </FlexWrapper>
            }
          >
            <FormControl>
              <PatientTrackingSelectorInput
                selectedPatientTrackings={selectedPatientTrackings}
                setSelectedPatientTrackings={setSelectedPatientTrackings}
              />
            </FormControl>
          </Block>
        </Main>
      )}
    </Container>
  );
};

const propTypes = {};

TagForm.propTypes = propTypes;

export default connectData(TagForm);
