import LastTeethScalingCell from 'components/Message/campaign/importUsers/importUsersTable/Columns/LastTeethScaling/LastTeethScalingCell';
import EstimatedDeliveryTimeStampCell from 'components/Message/campaign/importUsers/importUsersTable/Columns/EstimatedDeliveryTimeStamp/EstimatedDeliveryTimeStampCell';
import theme from 'theme';
import { t } from 'i18n/config';

export const columns = [
  {
    Header: t('medicalRecordNumber'),
    accessor: 'hisID',
    filterable: false,
    maxWidth: 100,
    minWidth: 100,
  },
  {
    Header: t('patientName'),
    accessor: 'name',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('mobileNumber'),
    accessor: 'phone',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('seeingDoctor'),
    accessor: 'doctor',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('lastDentalCleaningVisitDate'),
    accessor: 'lastTeethScaling',
    Cell: LastTeethScalingCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('estimatedDeliveryDate'),
    accessor: 'estimatedDeliveryTime',
    Cell: EstimatedDeliveryTimeStampCell,
    filterable: false,
    maxWidth: 120,
  },
];

export const leyanColumns = [
  {
    Header: t('patientName'),
    accessor: 'name',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('mobileNumber'),
    accessor: 'phone',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('lastDentalCleaningVisitDate'),
    accessor: 'lastTeethScaling',
    Cell: LastTeethScalingCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('estimatedDeliveryDate'),
    accessor: 'estimatedDeliveryTime',
    Cell: EstimatedDeliveryTimeStampCell,
    filterable: false,
    maxWidth: 120,
  },
];

const columnsMap = {
  DrCooper: columns,
  vintech: columns,
  FIADigital: columns,
  yousing: columns,
  leyan: leyanColumns,
};

export const headerColumns = (vendor) => () =>
  [
    {
      Header: t('uploadList'),
      columns: columnsMap[vendor],
    },
  ];

export const tableStyles = {
  rowHeight: '100px',
  rowHover: theme.list.hover,
  fontsize: '16px',
};
