import React, { useState, useCallback, useReducer } from 'react';
import styled from 'styled-components';
import Loading from 'components/Dashboard_v2/Loading';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import Alert from 'components/Dashboard_v2/Alert';
import Dialog from 'components/Dashboard_v2/Dialog';
import SearchInput from './components/SearchInput';
import CompareItems from './components/CompareItems';
import SearchSvg from 'components/icons/SearchSvg';
import { updateMember, mergeUsers } from 'lib/memberService';
import { updateUserPatientTrackings } from 'lib/messageCenterService';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 250px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const IconButton = styled(Button)`
  display: flex;
  gap: 8px;
  font-size: 16px;
  line-height: 1.3;
  i {
    font-size: 14px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CompareList = styled.div`
  display: flex;
  margin: 24px 0;
  border: 1px solid ${colors.SHADES_200};
  border-right-color: transparent;
  overflow: auto;
  > div {
  }
`;

const CompareListTitles = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.SHADES_200};
  > div {
    display: flex;
    align-items: center;
    padding: 16px;
    height: 56px;
    background-color: ${colors.SHADES_100};
    border-bottom: 1px solid transparent;
    color: ${colors.SHADES_500};
    white-space: nowrap;
    &:first-child {
      height: 64px;
    }
  }
`;

const CompareListItemWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const CompareListItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${colors.SHADES_200};
  > div:not(:first-child) {
    display: flex;
    align-items: center;
    padding: 16px;
    height: 56px;
    margin: 0 16px;
    border-bottom: 1px solid ${colors.SHADES_200};
  }
  > div:last-child {
    border-bottom: 1px solid transparent;
  }
  ${LoadingWrapper} {
    height: 100% !important;
  }
`;

const EmptyBlock = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 0 16px;
  height: calc(100% - 64px);
  width: calc(100% - 32px);
  bottom: 0;
  color: ${colors.SHADES_500};
  border-top: 1px solid ${colors.SHADES_200};
  background-color: ${colors.SHADES_000};
`;

export const getMergeStateByType = (type) => {
  return {
    preserved: type,
    phone: type,
    birthday: type,
    idType: type,
    twID: type,
    hisID: type,
    memberID: type,
    pdID: type,
    tags: type,
    patientTrackings: type,
  };
};
function MemberMerge() {
  const selectedIds = useSelector((state) => state.clients.selectedIds);
  const clientId = selectedIds[0];
  const { renderAlert, setAlert } = useAlert();
  const [redirectToList, setRedirectToList] = useState(false);
  const [mergeState, updateMergeState] = useReducer((prev, next) => ({ ...prev, ...next }), {
    loading: false,
    showConfirmModal: false,
    ...getMergeStateByType(''),
  });

  const [listStateA, updateListStateA] = useReducer((prev, next) => ({ ...prev, ...next }), {
    loading: false,
    keyword: '',
    selectedMember: null,
    showList: false,
    list: [],
  });

  const [listStateB, updateListStateB] = useReducer((prev, next) => ({ ...prev, ...next }), {
    loading: false,
    keyword: '',
    selectedMember: null,
    showOptions: false,
    list: [],
  });

  const handleSubmit = useCallback(async () => {
    if (mergeState.loading) return;
    updateMergeState({ loading: true });
    try {
      const data = { A: listStateA.selectedMember, B: listStateB.selectedMember };
      const preservedUser = mergeState.preserved === 'A' ? listStateA.selectedMember : listStateB.selectedMember;
      const removedUser = mergeState.preserved === 'A' ? listStateB.selectedMember : listStateA.selectedMember;
      const selectedTags = data[mergeState.tags].tags;
      const selectedPatientTrackings = data[mergeState.patientTrackings].patientTrackings;
      // 1. add selectedTags to preservedUser
      await updateMember({ clientId, memberId: preservedUser.id, member: { tags: selectedTags } });
      // 2. remove tags from removedUser
      await updateMember({ clientId, memberId: removedUser.id, member: { tags: [] } });
      // 3. add selectedPatientTrackings to preservedUser
      await updateUserPatientTrackings({
        clientId,
        payload: {
          userIds: [preservedUser.id],
          patientTrackingIds: selectedPatientTrackings.map((data) => data.id),
          runTimesByPatientTrackingId: selectedPatientTrackings.reduce((acc, patientTracking) => {
            const { id, runAt } = patientTracking;
            acc[id] = runAt;
            return acc;
          }, {}),
        },
      });
      // 4. remove patientTrackings from removedUser
      await updateUserPatientTrackings({
        clientId,
        payload: { userIds: [removedUser.id], patientTrackingIds: [], runTimesByPatientTrackingId: {} },
      });
      // 5. merge member data
      const payload = {
        preservedUserID: preservedUser.id,
        removedUserID: removedUser.id,
        latestPatientData: {
          id: preservedUser.id,
          birthday: data[mergeState.birthday].birthday,
          phone: data[mergeState.phone].phone,
          phoneCountryCode: data[mergeState.phone].phoneCountryCode,
          twID: data[mergeState.twID].twID,
          hisID: data[mergeState.hisID].hisID,
          memberID: data[mergeState.memberID].memberID,
          pdID: data[mergeState.pdID].pdID,
        },
      };
      await mergeUsers({ clientId, payload });
      setAlert({ type: 'success', title: t('mergeMember.success') });
      setRedirectToList(true);
    } catch (e) {
      setAlert({ type: 'error', title: e.error || t('mergeMember.failed') });
    }
  }, [listStateA.selectedMember, listStateB.selectedMember, mergeState, clientId, setAlert]);

  return redirectToList ? (
    <Redirect push to="/members" />
  ) : (
    <Wrapper>
      {renderAlert()}
      <Header>
        <Link to="/members">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/members">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button
            color="primary"
            variant="filled"
            onClick={() => updateMergeState({ showConfirmModal: true })}
            disabled={mergeState.loading || !listStateA.selectedMember || !listStateB.selectedMember}
          >
            {mergeState.loading === 'merge' ? (
              <CircularProgress style={{ color: '#fff' }} size={20} />
            ) : (
              t('mergeMember.confirm')
            )}
          </Button>
        </FormActions>
      </Header>
      <ContentWrapper>
        <Block title={t('mergeMember.title')} maxWidth={1088}>
          <Alert title={t('mergeMember.alertTitle')} description={t('mergeMember.alertDescription')} type="warning" />
          <CompareList>
            <CompareListTitles>
              <div>{t('search')}</div>
              <div>{t('name')}</div>
              <div>{t('idOrResidentNumber')}</div>
              <div>{t('bindingStatus')}</div>
              <div>{t('sendingChannel')}</div>
              <div>{t('mobile')}</div>
              <div>{t('birthDate')}</div>
              <div>{t('medicalRecordNumber')}</div>
              <div>{t('memberNumber')}</div>
              <div>{t('passportNumber')}</div>
              <div>{t('tag')}</div>
              <div>{t('tracking')}</div>
            </CompareListTitles>
            <CompareListItemWrapper>
              {!listStateA.selectedMember && !listStateB.selectedMember && (
                <EmptyBlock>
                  <SearchSvg />
                  <div>{t('mergeMember.emptyHint')}</div>
                </EmptyBlock>
              )}
              <CompareListItems>
                <SearchInput
                  type="A"
                  state={listStateA}
                  updateState={updateListStateA}
                  updateMergeState={updateMergeState}
                  disabledId={listStateB.selectedMember?.id}
                />
                {listStateA.loading ? (
                  <LoadingWrapper>
                    <Loading size={160} />
                  </LoadingWrapper>
                ) : (
                  <CompareItems
                    type="A"
                    isLoading={listStateA.loading}
                    selectedMember={listStateA.selectedMember}
                    mergeState={mergeState}
                    updateMergeState={updateMergeState}
                    updateState={updateListStateA}
                  />
                )}
              </CompareListItems>
              <CompareListItems>
                <SearchInput
                  type="B"
                  state={listStateB}
                  updateState={updateListStateB}
                  updateMergeState={updateMergeState}
                  disabledId={listStateA.selectedMember?.id}
                />
                {listStateB.loading ? (
                  <LoadingWrapper>
                    <Loading size={160} />
                  </LoadingWrapper>
                ) : (
                  <CompareItems
                    type="B"
                    selectedMember={listStateB.selectedMember}
                    mergeState={mergeState}
                    updateMergeState={updateMergeState}
                    updateState={updateListStateB}
                  />
                )}
              </CompareListItems>
            </CompareListItemWrapper>
          </CompareList>
        </Block>
      </ContentWrapper>
      {/* Confirm Modal */}
      <Dialog
        open={mergeState.showConfirmModal}
        title={t('mergeMember.confirmModalTitle')}
        description={t('mergeMember.confirmModalDescription')}
        onCancel={() => updateMergeState({ showConfirmModal: false })}
        onConfirm={handleSubmit}
        isLoading={mergeState.loading}
        cancelText={t('cancel')}
        confirmText={t('mergeMember.confirm')}
        confirmColorType="error"
        width={400}
      />
    </Wrapper>
  );
}

export default connectData(MemberMerge);
