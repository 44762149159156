import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import format from 'date-fns/format';

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div``;

export function LoadingCell() {
  return <Box />;
}

function EventTimeCell({ value }) {
  const date = format(new Date(value), 'yyyy/MM/dd');
  return <Container>{date} </Container>;
}

EventTimeCell.propTypes = {
  value: PropTypes.string,
};

export default EventTimeCell;
