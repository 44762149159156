import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { Tr, Td } from './styles';

function TableRow({ row, styles, onClick }) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(row.original);
    }
  }, [row, onClick]);

  return (
    <Tr {...row.getRowProps({})} hover={styles.rowHover} onClick={handleClick}>
      {row.cells.map((cell) => {
        const { column } = cell;
        return (
          <Td
            {...cell.getCellProps()}
            style={{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
            }}
            {...styles}
            key={nanoid()}
          >
            {cell.render('Cell')}
          </Td>
        );
      })}
    </Tr>
  );
}

TableRow.propTypes = {
  row: PropTypes.shape({
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        render: PropTypes.func,
      })
    ),
    getRowProps: PropTypes.func,
    original: PropTypes.shape({}),
  }),
  selectedRowIds: PropTypes.shape({}),
  styles: PropTypes.shape({ rowHeight: PropTypes.string, rowHover: PropTypes.string }),
  onClick: PropTypes.func,
};

export default React.memo(TableRow);
