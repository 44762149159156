export const SERVER_URL = process.env.AP_SERVICE_URL;
export const NSQ_URL = process.env.AP_NSQ_URL;
export const FB_URL = process.env.AP_FB_URL;
export const SURVEY_URL = process.env.AP_SURVEY_URL;
export const FB_ACCESS_TOKEN = process.env.AP_FB_ACCESS_TOKEN;
export const CLIENT_URL = process.env.AP_CLIENT_URL;
export const GOOGLE_MY_BUSINESS_CLIENT_ID = process.env.AP_GOOGLE_MY_BUSINESS_CLIENT_ID;
export const GOOGLE_MY_BUSINESS_API_KEY = process.env.AP_GOOGLE_MY_BUSINESS_API_KEY;
export const GOOGLE_ANNOUNCEMENT_SHEET_ID = process.env.AP_GOOGLE_ANNOUNCEMENT_SHEET_ID;
export const GOOGLE_SHEET_API_KEY = process.env.AP_GOOGLE_SHEET_API_KEY;
export const GMB_API_VERSION = process.env.AP_GMB_API_VERSION;
// https://developers.google.com/my-business/content/businessinformation/change-log
// Endpoints for all business information, attributes, categories, chains and locations search are accessible at https://mybusinessbusinessinformation.googleapis.com/v1/ instead of https://mybusiness.googleapis.com/v4/
export const GMB_BI_API = process.env.AP_GMB_BI_API;
export const GAPI_OAUTH2_VERSION = process.env.AP_GAPI_OAUTH2_VERSION;
export const GOOGLE_API_SCOPES = process.env.AP_GOOGLE_API_SCOPES;
export const REDIS_BOMB_URL = process.env.AP_REDIS_BOMB_URL;
export const MARKETING_URL = process.env.AP_MARKETING_URL;
export const APPOINT_URL = process.env.AP_APPOINT_URL;
export const APCENTERGO_URL = process.env.AP_APCENTERGO_URL;
export const AUTH_URL = process.env.AP_AUTH_URL;
export const ENV_STAGE = process.env.AP_STAGE;
export const SENTRY_DSN = process.env.AP_SENTRY_DSN;
