import React from 'react';
import PropTypes from 'prop-types';
import './Ratings.css';

const Ratings = ({ value }) => {
  let ratingElementCount = parseFloat(value);
  const ratingElementArray = [];
  while (ratingElementCount > 0) {
    if (ratingElementCount <= 0.5) {
      ratingElementArray.push(<i className="star fa fa-star-half-o" />);
    } else {
      ratingElementArray.push(<i className="star fa fa-star" />);
    }
    ratingElementCount -= 1;
  }

  return <div className="review-rating">{ratingElementArray}</div>;
};

Ratings.propTypes = {
  value: PropTypes.string,
};

export default Ratings;
