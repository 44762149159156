import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchMessageHistory } from 'lib/appointmentService';
import {
  SentMessageContainer,
  SentMessageContent,
  SentMessageTime,
  Loading,
  EmptyInfo,
  PageRouteButton,
} from './styledComponents';
import { EditContainer as Container, BackHeader as Header, Alert } from '../../styledComponents';
import { t } from 'i18n/config';

const defaultState = {
  list: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        loading: true,
      };
    case 'fetchDone':
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    default:
      return state;
  }
};

function MessageHistoryComponent({ goToMain, clientID, appointmentID }) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    async function fetchData() {
      return fetchMessageHistory({ clientID, id: appointmentID });
    }

    dispatch({ type: 'fetching' });
    fetchData()
      .then((res) => {
        console.log('res = ', res);
        dispatch({ type: 'fetchDone', payload: res });
      })
      .catch((err) => {
        console.log('err = ', err);
        dispatch({ type: 'fetchDone', payload: [] });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Header>
        <PageRouteButton color="primary" onClick={goToMain}>
          {t('backToEdit')}
        </PageRouteButton>
      </Header>
      {state.loading && <Loading size={23} />}
      {!state.loading && state.list.length === 0 && (
        <EmptyInfo>
          {t('noSendingHistory')}
          <span role="img" aria-label="cry">
            🥺
          </span>
        </EmptyInfo>
      )}
      {state.list.map(({ content, sentType, time, failed }, index) => (
        <SentMessageContainer key={index}>
          <SentMessageContent>{content}</SentMessageContent>
          {failed ? (
            <SentMessageTime>
              <Alert className="ri-alert-fill" /> {time} {t('sendingFailed')}
            </SentMessageTime>
          ) : (
            <SentMessageTime>
              {time}
              {t('from')}
              {sentType}
              {t('sentOut')}
            </SentMessageTime>
          )}
        </SentMessageContainer>
      ))}
    </Container>
  );
}

MessageHistoryComponent.propTypes = {
  goToMain: PropTypes.func,
  clientID: PropTypes.string,
  appointmentID: PropTypes.string,
};

export default MessageHistoryComponent;
