import { findNumbers } from 'libphonenumber-js/mobile';

export const isValidPhoneNumber = (rawPhone = '') => {
  const detectArr = findNumbers(rawPhone, '', { v2: true });
  if (detectArr == null || detectArr.length === 0) {
    const returnPhone = rawPhone.replace(/-|x/g, '').match('[9][0-9]{8}');
    if (returnPhone == null) {
      return false;
    }
    return true;
  }
  return true;
};

export const maskPhone = (phone) => `${phone.substring(0, 3)}XXX${phone.substring(6)}`;

export const text = true;
