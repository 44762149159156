import React, { Component } from 'react';
import packageInfo from '../../../package.json';

const { version } = packageInfo;

class SidebarMinimizer extends Component {
  sidebarMinimize() {
    document.body.classList.toggle('sidebar-minimized');
  }

  brandMinimize() {
    document.body.classList.toggle('brand-minimized');
  }

  render() {
    return (
      <button
        className="sidebar-minimizer"
        type="button"
        onClick={() => {
          this.sidebarMinimize();
          this.brandMinimize();
        }}
      >
        <span className="dashboard-version">{`v ${version}`}</span>
      </button>
    );
  }
}

export default SidebarMinimizer;
