import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/MaterialDesign/Modal';
import DefaultDateTimeSelect, {
  name as DefaultDateTimeSelectName,
} from 'components/WesternAppointments/table/Columns/Action/Scheduled/DefaultDateTimeSelect';
import CustomDateTimeSelect, {
  name as CustomDateTimeSelectName,
} from 'components/WesternAppointments/table/Columns/Action/Scheduled/DateTimeSelect';
import BatchConfirm from 'components/WesternAppointments/table/Columns/Action/Scheduled/BatchConfirm';
import { t } from 'i18n/config';

const modalAttribute = {
  [DefaultDateTimeSelectName]: {
    title: t('scheduleSendTime'),
    modalWidth: 400,
  },
  [CustomDateTimeSelectName]: {
    title: t('chooseDateAndTime'),
    modalWidth: 550,
  },
  batchConfirm: {
    title: t('multiSelectConfirm'),
    modalWidth: 400,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'goCustomDateSelect': {
      return {
        ...state,
        visible: CustomDateTimeSelectName,
        lastVisible: state.visible,
        ...modalAttribute[CustomDateTimeSelectName],
      };
    }
    case 'goDefaultDateSelect': {
      return {
        ...state,
        visible: DefaultDateTimeSelectName,
        lastVisible: state.visible,
        ...modalAttribute[DefaultDateTimeSelectName],
      };
    }
    case 'goBatchConfirm': {
      return {
        ...state,
        visible: 'batchConfirm',
        ...modalAttribute.batchConfirm,
        lastVisible: state.visible,
        scheduledDate: action.payload,
      };
    }
    case 'goBack':
      return {
        ...state,
        ...modalAttribute[state.lastVisible],
        visible: state.lastVisible,
      };
    default:
      return state;
  }
};

const ScheduledModal = ({
  visible,
  onClose,
  onScheduled,
  rows = [],
  shouldConfirmAgain,
  scheduledDate,
  contentFormat,
}) => {
  const visiblePage = scheduledDate && shouldConfirmAgain ? 'batchConfirm' : DefaultDateTimeSelectName;
  const initialState = {
    visible: visiblePage,
    ...modalAttribute[visiblePage],
    scheduledDate,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSelectDefault = (date) => {
    if (shouldConfirmAgain) {
      dispatch({ type: 'goBatchConfirm', payload: date });
      return;
    }
    onScheduled(date, contentFormat);
    onClose();
  };

  const handleSelectCustom = () => {
    dispatch({ type: 'goCustomDateSelect' });
  };

  const handleCustomCancel = () => {
    dispatch({ type: 'goDefaultDateSelect' });
  };

  const handleCustomConfirm = (date) => {
    if (shouldConfirmAgain) {
      dispatch({ type: 'goBatchConfirm', payload: date });
      return;
    }
    onScheduled(date, contentFormat);
    onClose();
  };

  const handleBatchConfirmCancel = () => {
    if (state.lastVisible) {
      dispatch({ type: 'goBack' });
      return;
    }
    onClose();
  };

  const handleBatchConfirm = () => {
    onScheduled(state.scheduledDate, contentFormat);
    onClose();
  };

  return (
    <React.Fragment>
      <Modal title={state.title} open={visible} onClose={onClose} styles={{ width: state.modalWidth }}>
        {state.visible === DefaultDateTimeSelectName && (
          <DefaultDateTimeSelect onSelectDate={handleSelectDefault} onSelectCustom={handleSelectCustom} />
        )}
        {state.visible === CustomDateTimeSelectName && (
          <CustomDateTimeSelect onConfirm={handleCustomConfirm} onCancel={handleCustomCancel} />
        )}
        {shouldConfirmAgain && state.visible === 'batchConfirm' && (
          <BatchConfirm count={rows.length} onCancel={handleBatchConfirmCancel} onConfirm={handleBatchConfirm} />
        )}
      </Modal>
    </React.Fragment>
  );
};

ScheduledModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onScheduled: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  shouldConfirmAgain: PropTypes.bool,
  scheduledDate: PropTypes.instanceOf(Date),
  contentFormat: PropTypes.string,
};

export default ScheduledModal;
