import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import Muitable from 'components/Table/muitable';
import { fetchPasscodeByClient } from 'lib/clientService';
import reducer, { defaultState } from './reducer';
import ReloadButton from './ReloadButton';

const Table = ({ clientId, title, columns }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const loadPasscodes = useCallback(async () => {
    dispatch({ type: 'fetching' });
    await fetchPasscodeByClient({ clientId }).then((res) => {
      dispatch({ type: 'fetchDone', payload: res });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadPasscodes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCustomtoolBar = useCallback(
    () => <ReloadButton loading={state.loading} onClick={loadPasscodes} />,
    [loadPasscodes, state.loading]
  );

  return (
    <React.Fragment>
      <Muitable
        title={title}
        selectEnabled={false}
        data={state.data}
        columns={columns}
        onSelectionChange={() => {}}
        extraOptions={{
          customToolbar: renderCustomtoolBar,
          filter: false,
        }}
      />
    </React.Fragment>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  clientId: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
export default React.memo(Table);
