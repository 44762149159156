import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { CircularProgress } from '@material-ui/core';
import { t } from 'i18n/config';

const ReloadButton = ({ onClick, loading }) => {
  if (loading) {
    return (
      <Tooltip title={t('loading')}>
        <IconButton>
          <CircularProgress size={20} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <React.Fragment>
      <Tooltip title={t('reload')}>
        <IconButton onClick={onClick}>
          <AutorenewIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

ReloadButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default React.memo(ReloadButton);
