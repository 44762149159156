import { AUTH_LOGOUT } from 'reducers/auth';

const SET_ALERT = 'ALERT/SET_ALERT';
const CLEAR_ALERT = 'ALERT/CLEAR_ALERT';

const defaultState = {
  data: null,
};

export const setAlert = (data) => ({
  type: SET_ALERT,
  payload: data,
});

export const clearAlert = () => ({
  type: CLEAR_ALERT,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAR_ALERT:
      return {
        ...state,
        data: null,
      };
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
