import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import Tag from 'components/Dashboard_v2/Tag';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import PatientTrackingTag from 'components/Dashboard_v2/PatientTrackingTag';
import TagCell from 'feature/MemberCenter/MemberList/AllMembers/table/TagCell';
import PatientTrackingCell from 'components/Dashboard_v2/PatientTrackingSelectorCell';
import { getFormattedDate } from '../';
import { ProfileMemberEditForm } from 'feature/MemberCenter/MemberForm';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Card = styled.div`
  ${({ width }) => width && `width: ${width}px`};
  ${({ height }) => height && `height: ${height}px`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  ${({ padding }) => padding && `padding: ${padding}`};
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_300};
  border-radius: 8px;
  margin-bottom: 24px;
`;

const AddButton = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  background: ${colors.SHADES_100};
  border-radius: 100px;
  color: ${colors.SHADES_500};
  cursor: pointer;
  i {
    color: ${colors.SYSTEM_INFO_400};
  }
`;

const Block = styled.div`
  ${({ flex }) => flex && `display: flex;`}
  ${({ column }) => column && `flex-direction: column;`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
  ${({ pointer }) => pointer && `cursor: pointer;`}
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 4px;
  i {
    margin-right: 8px;
    color: ${colors.SHADES_400};
  }
`;

const InfoTitle = ({ title, popoverContent }) => {
  return (
    <InfoTitleWrapper>
      <InfoPopover popoverContent={popoverContent} />
      <Text font="Heading/Small/Regular" color="SHADES_400">
        {title}
      </Text>
    </InfoTitleWrapper>
  );
};

const OverView = () => {
  const profile = useSelector((state) => state.member.profile);
  const { row, updateMyData } = profile || {};
  const [isEditing, setIsEditing] = useState(false);

  if (!profile) return null;

  const { index, original } = row;
  const {
    id,
    phone,
    name,
    lineName,
    hisName,
    birthday,
    gender,
    twID,
    hisID,
    memberID,
    pdID,
    createdAt,
    updatedAt,
    tags,
    patientTrackings,
    idType,
  } = original;

  const handleClose = ({ memberData }) => {
    if (memberData) {
      updateMyData(index, null, null, memberData);
    }
    setIsEditing(false);
  };

  return (
    <div>
      <Card padding="28px">
        <Block>
          <Text font="Heading/Small/Regular" color="SHADES_400" marginBottom={16} block>
            {t('tags')}
          </Text>
          <TagCell row={row} value={tags} updateMyData={updateMyData} ellipsis={false}>
            <Block flex flexWrap gap={8} pointer>
              {tags.map((tag) => (
                <Tag key={tag.id} name={tag.name} />
              ))}
              <AddButton>
                <i className="ri-add-fill" />
                {tags.length > 0 ? '' : t('addTags')}
              </AddButton>
            </Block>
          </TagCell>
        </Block>
        <Block>
          <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={28} marginBottom={16} block>
            {t('patientTracking')}
          </Text>
          <PatientTrackingCell row={row} value={patientTrackings} updateMyData={updateMyData} ellipsis={false}>
            <Block flex flexWrap gap={16} pointer>
              {patientTrackings.map((patientTracking) => (
                <PatientTrackingTag
                  name={`${patientTracking.title} ${getFormattedDate(patientTracking.runAt, 'patientTrackingLabel')}`}
                  setting={{
                    isExpired: Date.now() > new Date(patientTracking.expiredAt),
                    isEnabled: patientTracking.status === 'enabled',
                    recyclable: patientTracking.recyclable,
                  }}
                  key={patientTracking.id}
                />
              ))}
              <AddButton>
                <i className="ri-add-fill" />
                {tags.length > 0 ? '' : t('addPatientTracking')}
              </AddButton>
            </Block>
          </PatientTrackingCell>
        </Block>
      </Card>
      {isEditing ? (
        <Card padding="28px" minHeight={500}>
          <ProfileMemberEditForm mode="edit" id={id} onClose={handleClose} isProfileEdit />
        </Card>
      ) : (
        <Card padding="28px">
          <Block flex alignItem="center" justifyContent="space-between">
            <Text font="Heading/H6/Medium">{t('memberInformation')}</Text>
            <Button color="secondary" onClick={() => setIsEditing(true)}>
              {t('edit')}
            </Button>
          </Block>
          {name && (
            <Block>
              <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={4} block>
                {t('customName')}
              </Text>
              <Text font="Body/16px_Regular" block>
                {name}
              </Text>
            </Block>
          )}
          <Block flex gap={48}>
            {lineName && (
              <Block>
                <InfoTitle title="LINE" popoverContent={t('lineName')} />
                <Text font="Body/16px_Regular" block>
                  {lineName}
                </Text>
              </Block>
            )}
            {hisName && (
              <Block>
                <InfoTitle title="HIS" popoverContent={t('hisName')} />
                <Text font="Body/16px_Regular" block>
                  {hisName}
                </Text>
              </Block>
            )}
          </Block>
          {gender && (
            <Block>
              <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={4} block>
                {t('gender')}
              </Text>
              <Text font="Body/16px_Regular" block>
                {gender === 'M' ? t('male') : t('female')}
              </Text>
            </Block>
          )}
          <Block>
            <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={4} block>
              {t('birthday')}
            </Text>
            <Text font="Body/16px_Regular" block>
              {birthday}
            </Text>
          </Block>
          <Block flex column gap={4}>
            {idType !== 'lineID' && (
              <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={4} block>
                {t('identifier')}
              </Text>
            )}
            {twID && <Text font="Body/16px_Regular">{t('idNumber', { twID })}</Text>}
            {hisID && <Text font="Body/16px_Regular">{t('medicalRecordId', { hisID })}</Text>}
            {memberID && <Text font="Body/16px_Regular">{t('memberId', { memberID })}</Text>}
            {pdID && <Text font="Body/16px_Regular">{t('passportId', { pdID })}</Text>}
          </Block>
          <Block>
            <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={4} block>
              {t('phone')}
            </Text>
            <Text font="Body/16px_Regular" block>
              {phone}
            </Text>
          </Block>
          <Block flex gap={48}>
            <Block>
              <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={8} block>
                {t('recentUpdate')}
              </Text>
              {getFormattedDate(updatedAt)}
            </Block>
            <Block>
              <Text font="Heading/Small/Regular" color="SHADES_400" marginTop={24} marginBottom={8} block>
                {t('addDate')}
              </Text>
              {getFormattedDate(createdAt)}
            </Block>
          </Block>
        </Card>
      )}
    </div>
  );
};

export default memo(OverView);
