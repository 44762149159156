import React, { memo, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'components/Dashboard_v2/Button';
import Dialog from 'components/Dashboard_v2/Dialog';
import TagSelectorModal from '../components/TagSelectorModal';
import PropTypes from 'prop-types';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

const SelectedInfo = styled.div`
  font-size: 16px;
  margin-right: 24px;
  color: ${dashboard_v2.colors.SHADES_800};
  span {
    font-weight: bold;
    margin: 0 4px;
  }
`;

const IconButton = styled(Button)`
  font-size: 16px;
  margin-right: 16px;
  line-height: 1.3;
  i {
    font-size: 14px;
    margin-right: 8px;
  }
`;

function SelectedToolSection({ selectedFlatRows, dataCount, searchQueries, getMembers, allUserCount }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [tagSelectorOpen, setTagSelectorOpen] = useState(false);

  const selectedNumberCount = selectedFlatRows.length;
  const selectedMemberIds = useMemo(() => selectedFlatRows.map((row) => row.original.id), [selectedFlatRows]);
  const isSelectedAll = useMemo(() => dataCount === selectedMemberIds.length, [dataCount, selectedMemberIds]);
  const openTagSelector = useCallback(() => setTagSelectorOpen(true), []);
  const openDeleteDialog = useCallback(() => setDeleteDialogOpen(true), []);
  const closeDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);
  const handleDelete = useCallback(() => {
    // @TODO: delete api
    closeDeleteDialog();
  }, [closeDeleteDialog]);

  const handleExport = useCallback(() => {}, []);

  return (
    <Wrapper>
      <SelectedInfo>
        {t('selectedAll')} <span>{isSelectedAll ? allUserCount : selectedNumberCount}</span> {t('items')}
      </SelectedInfo>

      <IconButton color="secondary" variant="outline" onClick={openTagSelector}>
        <i className="ri-price-tag-3-line" />
        {t('addTag')}
      </IconButton>

      <TagSelectorModal
        open={tagSelectorOpen}
        getMembers={getMembers}
        onClose={() => setTagSelectorOpen(false)}
        isSelectedAll={isSelectedAll}
        searchQueries={searchQueries}
        selectedMemberIds={selectedMemberIds}
      />

      {/* @TODO: hidden export for later phase */}
      <IconButton color="secondary" variant="outline" onClick={handleExport} hidden>
        <i className="ri-user-shared-line" />
        {t('exportList')}
      </IconButton>

      {/* @TODO: hidden delete for later phase */}
      <IconButton color="error" variant="filled" onClick={openDeleteDialog} hidden>
        <i className="ri-delete-bin-6-line" />
        {t('delete')}
      </IconButton>

      {/* @TODO: hidden delete for later phase */}
      <Dialog
        open={deleteDialogOpen}
        title={t('confirmDelete')}
        description={t('deleteMultipleItems', { count: selectedNumberCount })}
        confirmText={t('delete')}
        cancelText={t('cancel')}
        onConfirm={handleDelete}
        onCancel={closeDeleteDialog}
        confirmColorType="error"
      />
    </Wrapper>
  );
}

SelectedToolSection.propTypes = {
  selectedFlatRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  dataCount: PropTypes.number,
};

export default memo(SelectedToolSection);
