/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import connectData from 'components/HOCs/connectData';
import 'components/Customers/Customers.css';
import { fetchTotalAppointmentsInQue } from 'reducers/appointments';
import { getAppointments } from 'selectors/appointmentSelectors';
import { doneRefreshPage } from 'reducers/ui';
import FilterableTable from 'components/Table/FilterableTable';
import { TitleContainer, Title, Reload } from 'components/Table/styles';
import Tabs from 'components/Table/HorizontalTabs';
import Tab from 'components/Table/HorizontalTabs/HorizontalTab';
import TabPanel from 'components/Table/HorizontalTabs/TabPanel';
import SelectedToolSection from 'components/Table/ToolSection/SelectedToolSection';
import './Appointments.css';
import './Appointments.scss';
import tableHeaderColumns, { tableStyles } from './table/Columns/columns';
import { t } from 'i18n/config';

const defaultAppointInfo = {
  list: [],
  loading: true,
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  appointments: getAppointments(state),
  user: state.auth.user,
});

const mapDispatchToProps = {
  fetchTotalAppointmentsInQue,
  doneRefreshPage,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Appointments extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    user: PropTypes.shape({
      operationType: PropTypes.string,
    }),
    appointments: PropTypes.shape({
      id: PropTypes.string,
    }),
    fetchTotalAppointmentsInQue: PropTypes.func,
    appendCustomTag: PropTypes.func,
    updateReminder: PropTypes.func,
    doneRefreshPage: PropTypes.func,
  };

  static emptyCustomers = [];

  constructor(props) {
    super(props);
    const startDate = moment(new Date());
    this.state = {
      startDate,
      tabIndex: 0,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchData = async () => {
    try {
      await this.props.fetchTotalAppointmentsInQue({ startTime: this.state.startDate });
    } catch (error) {
      const res = await error;
      console.log('fail to fetch customers in queue', res);
    }
  };

  onDateChange = (date) => {
    this.setState({ startDate: moment(date) }, () => {
      this.onFetchData();
    });
  };

  handleChangeTab = (event, newtab) => {
    this.onFetchData();
    this.setState({
      tabIndex: newtab,
    });
  };

  handleReload = () => {
    this.onFetchData();
  };

  render() {
    console.log('appointment render');
    const { byId, selectedIds } = this.props.clients;
    const { appointments } = this.props;
    const { tabIndex, startDate } = this.state;
    const isMultiClietns = selectedIds.length > 1;
    return (
      <div className="animated fadeIn appointment-page">
        <TitleContainer>
          <Title>{t('appointmentCenter')}</Title>
          <Reload className="ri-refresh-line" onClick={this.handleReload} />
        </TitleContainer>
        {isMultiClietns && (
          <Tabs value={tabIndex} onChange={this.handleChangeTab} aria-label="tabs">
            {selectedIds.map((id, index) => {
              const client = byId[id];
              return <Tab label={client.companyName} {...a11yProps(index)} key={id} />;
            })}
          </Tabs>
        )}
        {selectedIds.map((id, index) => {
          const appointmentsInClient = appointments[id] || defaultAppointInfo;
          return (
            <TabPanel value={tabIndex} index={index} key={`${id}`}>
              <FilterableTable
                tableStyles={tableStyles}
                defaultDate={startDate.toDate().getTime()}
                clientID={id}
                tableHeaderColumns={tableHeaderColumns}
                data={appointmentsInClient.list}
                loading={appointmentsInClient.loading}
                onDateChange={this.onDateChange}
                selectedToolSection={<SelectedToolSection />}
              />
            </TabPanel>
          );
        })}
      </div>
    );
  }
}
