import i18next from 'i18next';

const languages = ['tw', 'en', 'jp'];

const storedLang = localStorage.getItem('CRM_Dashboard_lang');
const defaultLang = languages.includes(storedLang) ? storedLang : 'tw';

i18next.init({
  fallbackLng: defaultLang,
  resources: {
    tw: {
      translations: require('./locales/tw.json'),
    },
    en: {
      translations: require('./locales/en.json'),
    },
    jp: {
      translations: require('./locales/jp.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
});

i18next.languages = languages;

const t = i18next.t;
const currentLanguage = i18next.language;

const changeLanguage = (lang) => {
  i18next.changeLanguage(lang);
  localStorage.setItem('CRM_Dashboard_lang', lang);
  window.location.reload();
};

export { t, changeLanguage, currentLanguage };

export default i18next;
