/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import connectData from 'components/HOCs/connectData';
import { fetchTotalAppointmentsInQue, clearAppointments } from 'reducers/westernAppointments';
import { doneRefreshPage } from 'reducers/ui';
import {
  TabTitles,
  headerColumnsMap,
  shouldRowRemovedMap,
  tableStyles,
} from 'components/WesternAppointments/table/headerColumns';
import FilterableTable from 'components/Table/FilterableTable';
import { TitleContainer, Title, Reload } from 'components/Table/styles';
import Tabs from 'components/Table/HorizontalTabs';
import Tab, { a11yProps } from 'components/Table/HorizontalTabs/HorizontalTab';
import TabPanel from 'components/Table/HorizontalTabs/TabPanel';
import { getAppointments } from 'selectors/westernAppointmentSelectors';
import SelectedToolSection from 'components/WesternAppointments/table/Section/SelectedToolSection';
import { t } from 'i18n/config';

const defaultAppointInfo = {
  list: [],
  loading: true,
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  appointments: getAppointments(state),
  user: state.auth.user,
  features: state.auth.features,
});

const mapDispatchToProps = {
  fetchTotalAppointmentsInQue,
  clearAppointments,
  doneRefreshPage,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Appointments extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    user: PropTypes.shape({
      operationType: PropTypes.string,
    }),
    appointments: PropTypes.shape({
      id: PropTypes.string,
    }),
    fetchTotalAppointmentsInQue: PropTypes.func,
    clearAppointments: PropTypes.func,
    appendCustomTag: PropTypes.func,
    updateReminder: PropTypes.func,
    doneRefreshPage: PropTypes.func,
  };

  static emptyCustomers = [];

  constructor(props) {
    super(props);
    const startDate = moment(new Date());
    this.state = {
      startDate,
      clientTabIndex: 0,
      statusTabIndex: 0,
    };
  }

  componentDidMount() {
    this.onFetchData(this.state.statusTabIndex);
  }

  componentWillUnmount() {
    this.props.clearAppointments();
  }

  isLoading = () => Object.values(this.props.appointments).some((item) => item.loading);

  onFetchData = async (statusTabIndex) => {
    try {
      const { startDate } = this.state;
      await this.props.fetchTotalAppointmentsInQue({
        startTime: startDate,
        sendingStatus: statusTabIndex,
      });
    } catch (error) {
      const res = await error;
      console.log('fail to fetch customers in queue', res);
    }
  };

  onDateChange = (date) => {
    if (this.isLoading()) return;
    this.setState({ startDate: moment(date) }, () => {
      this.onFetchData(this.state.statusTabIndex);
    });
  };

  handleChangeClientTab = (event, newtab) => {
    if (this.isLoading()) return;
    this.onFetchData(this.state.statusTabIndex);
    this.setState({
      clientTabIndex: newtab,
    });
  };

  handleChangeStatusTab = (event, newtab) => {
    if (this.isLoading()) return;
    this.onFetchData(newtab);
    this.setState({
      statusTabIndex: newtab,
    });
  };

  handleReload = () => {
    this.onFetchData(this.state.statusTabIndex);
  };

  render() {
    const { byId, selectedIds } = this.props.clients;
    const { appointments, features } = this.props;
    const { clientTabIndex, startDate, statusTabIndex } = this.state;
    const isMultiClietns = selectedIds.length > 1;
    const isPnpEnabled = features?.enablePNPNotify;
    const isSmsEnabled = features?.enableSMSNotify;
    const headerColumns = headerColumnsMap(isPnpEnabled);
    if (isPnpEnabled || isSmsEnabled) {
      // 如果有開啟 PNP 或 SMS, 未綁定的用戶也能夠發送
      Object.keys(appointments).forEach((key) => {
        appointments[key].list.forEach((item) => {
          // binding = 0: 未綁定, 1: 已綁定
          if (['0', '1'].includes(item.binding)) {
            // deleted = 0: 未刪除, 1: USER 刪除, 2: HIS 刪除
            const isDeleted = ['1', '2'].includes(item.deleted);
            item.sendingAction = isDeleted
              ? {
                  type: 'deleted',
                  title: t('notificationDelete'),
                  disabled: false,
                }
              : {
                  type: 'initial',
                  title: t('immediateOrSchedule'),
                  disabled: false,
                };
          }
        });
      });
    }

    return (
      <div className="animated fadeIn appointment-page">
        <TitleContainer>
          <Title>{t('registrationCenter')}</Title>
          <Reload className="ri-refresh-line" onClick={this.handleReload} />
        </TitleContainer>
        {isMultiClietns && (
          <Tabs value={clientTabIndex} onChange={this.handleChangeClientTab} aria-label="tabs">
            {selectedIds.map((id, index) => {
              const client = byId[id];
              return <Tab key={client.companyName} label={client.companyName} {...a11yProps(index)} />;
            })}
          </Tabs>
        )}
        {selectedIds.map((id, index) => {
          const appointmentsInClient = appointments[id] || defaultAppointInfo;
          return (
            <TabPanel key={id} value={clientTabIndex} index={index}>
              <Tabs value={statusTabIndex} onChange={this.handleChangeStatusTab} aria-label="tabs">
                {TabTitles.map((status) => (
                  <Tab key={status} label={status} {...a11yProps(index)} />
                ))}
              </Tabs>
              {TabTitles.map((status, statusIndex) => (
                <TabPanel key={status} value={statusTabIndex} index={statusIndex}>
                  <FilterableTable
                    key={`${id}`}
                    tableStyles={tableStyles}
                    status={status}
                    defaultDate={startDate.toDate().getTime()}
                    tableHeaderColumns={headerColumns[statusIndex]}
                    clientID={id}
                    data={appointmentsInClient.list}
                    loading={appointmentsInClient.loading}
                    onDateChange={this.onDateChange}
                    selectedToolSection={<SelectedToolSection />}
                    shouldRowsRemoved={shouldRowRemovedMap[status]}
                  />
                </TabPanel>
              ))}
            </TabPanel>
          );
        })}
      </div>
    );
  }
}
