import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import DatePicker from 'components/MaterialDesign/DatePicker';
import { Cancel, Confirm } from 'components/MaterialDesign/Buttons';
import { TextField } from '@material-ui/core';
import theme from 'theme';
import { t } from 'i18n/config';

export const name = 'CustomDateTimeSelect';

const Container = styled.div`
  margin: 16px;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextFieldWrap = styled(TextField)`
  margin-top: 16px;
  margin-left: 60px;
  width: 150px;
`;

const DateTextField = styled(TextFieldWrap)`
  pointer-events: none;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
`;

const MCancel = styled(Cancel)`
  margin-right: 8px;
`;

const Icon = styled.i`
  font-size: 20px;
  color: ${theme.colors.default};
`;

function DateTimeSelect({ onCancel, onConfirm }) {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('07:30');
  const handleDateChange = (d) => {
    setDate(d);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };
  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm(new Date(`${format(date, 'yyyy/MM/dd')} ${time}`));
  };
  return (
    <Container>
      <DateContainer>
        <DatePicker defaultDate={format(date, 'yyyy/MM/dd')} handleChange={handleDateChange} />
        <div>
          <TextFieldWrap
            id="time"
            type="time"
            variant="outlined"
            defaultValue={time}
            InputLabelProps={{
              shrink: true,
              endAdornment: <Icon className="ri-alarm-line" />,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={handleTimeChange}
          />
          <DateTextField
            variant="outlined"
            value={format(date, 'yyyy/MM/dd')}
            InputProps={{
              endAdornment: <Icon className="ri-calendar-event-line" />,
            }}
          />
        </div>
      </DateContainer>
      <ActionContainer>
        <MCancel onClick={handleCancel} height={42} width={80}>
          {t('cancel')}
        </MCancel>
        <Confirm onClick={handleConfirm} height={42} width={133} borderradius={4}>
          {t('scheduleSendTime')}
        </Confirm>
      </ActionContainer>
    </Container>
  );
}

DateTimeSelect.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DateTimeSelect;
