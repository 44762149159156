import React, { useState, useEffect, useCallback, memo } from 'react';
import styled from 'styled-components';
import nanoid from 'nanoid';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Popover from '@material-ui/core/Popover';
import Button from 'components/Dashboard_v2/Button';
import { dashboard_v2 } from 'theme';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from '../../utils/arrayMove';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: colors.PRIMARY_300,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: colors.PRIMARY_300,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 100,
    backgroundColor: colors.SHADES_300,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const IconButton = styled(Button)`
  white-space: nowrap;
  margin-left: 16px;
  i {
    margin-right: 8px;
  }
`;

const DragIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 7px;
  font-size: 20px;
  color: ${colors.SHADES_400};
  cursor: move;
  i:first-child {
    margin-right: -14px;
  }
`;

const DragItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
  font-size: 16px;
  padding: 10px;
  line-height: 1;
  > div {
    display: flex;
    align-items: center;
    color: ${({ show }) => (show ? colors.SHADES_900 : colors.SHADES_400)};
  }
  &.dragging {
    background-color: ${colors.SHADES_50};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Content = styled.div`
  width: 320px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
`;

const Hint = styled.div`
  padding: 16px 24px;
  font-size: 12px;
  color: ${colors.SHADES_400};
`;

const ActionButtons = styled.div`
  text-align: right;
  border-top: solid 1px ${colors.SHADES_300};
  padding: 4px 8px;
`;

const DragHandle = sortableHandle(() => (
  <DragIcon>
    <i className="ri-more-2-fill"></i>
    <i className="ri-more-2-fill"></i>
  </DragIcon>
));

const SortableItem = sortableElement(({ setting, onSwitch, idx }) => (
  <DragItem show={setting.show}>
    <div>
      <DragHandle />
      <span>{setting.name}</span>
    </div>
    <StyledSwitch checked={setting.show} onChange={(e) => onSwitch(e, idx)} />
  </DragItem>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const ColumnSetting = ({ columns, updateColumnSetting }) => {
  const [currentSetting, setCurrentSetting] = useState({});
  const [draftSetting, setDraftSetting] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setDraftSetting(currentSetting);
    setAnchorEl(null);
  }, [currentSetting]);

  const onSwitch = (e, index) => {
    const newSettings = [...draftSetting];
    newSettings[index].show = e.target.checked;
    setDraftSetting(newSettings);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setDraftSetting((prev) => arrayMoveImmutable(prev, oldIndex, newIndex));
  };

  const handleSave = () => {
    setCurrentSetting(draftSetting);
    updateColumnSetting(draftSetting.filter((setting) => setting.show));
    localStorage.setItem(`${window.location.href}[table_setting]`, JSON.stringify(draftSetting));
    setAnchorEl(null);
  };

  useEffect(() => {
    let defaultSetting = columns.map((column) => ({
      name: column.headDataName || column.Header,
      show: !column.defaultHide,
      settable: column.settable,
    }));
    try {
      const storageSetting = JSON.parse(localStorage.getItem(`${window.location.href}[table_setting]`));
      const storageSettingKeys = storageSetting && storageSetting.map((setting) => setting.name);
      const shouldApplyStorageSetting =
        storageSettingKeys && defaultSetting.every((setting) => storageSettingKeys.includes(setting.name));
      if (shouldApplyStorageSetting) {
        defaultSetting = storageSetting;
      }
    } catch (e) {
      console.error(e);
    }
    setCurrentSetting(defaultSetting);
    setDraftSetting(defaultSetting);
    updateColumnSetting(defaultSetting.filter((setting) => setting.show));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <IconButton color="secondary" variant="outline" onClick={handleOpen}>
        <i className="ri-layout-column-line" />
        {t('column')}
      </IconButton>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            marginTop: '8px',
          }}
        >
          <Content>
            <Hint>{t('editColumnsOrderAndVisibility')}</Hint>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle helperClass="dragging">
              {draftSetting.map((setting, index) =>
                setting.settable ? (
                  <SortableItem onSwitch={onSwitch} setting={setting} key={nanoid()} index={index} idx={index} />
                ) : null
              )}
            </SortableContainer>
            <ActionButtons>
              <Button variant="ghost" color="primary" onClick={handleSave}>
                {t('save')}
              </Button>
            </ActionButtons>
          </Content>
        </Popover>
      )}
    </Wrapper>
  );
};

const propTypes = {};

ColumnSetting.propTypes = propTypes;

export default memo(ColumnSetting);
