/* eslint-disable no-param-reassign */
import moment from 'moment';
import { createSelector } from 'reselect';
import { getAppointmentLight } from 'components/Table/Columns/Status/status';
import { progressValues } from 'components/Table/Columns/Progress/progress';

export const serialized = (appointment) => {
  const {
    id,
    appointmentTime,
    lineStatus,
    responseStatus,
    name,
    customTags,
    phone,
    doctor,
    note,
    reminder,
    processState = {},
    draft = '',
    deleted,
    rejectedBy = '',
  } = appointment;
  return {
    id,
    appointmentTime: moment(appointmentTime).format('HH:mm'),
    status: getAppointmentLight({
      responseStatus,
      lineStatus,
      deleted,
      rejectedBy,
    }),
    patient: responseStatus === -1 && rejectedBy !== 'patient' ? `[C]${name}` : name,
    customTags,
    phone,
    doctor,
    noteReminder: {
      note,
      reminder,
    },
    message: {
      draft,
    },
    progress: {
      time: processState.updatedAt ? moment(processState.updatedAt).format('YYYY/MM/DD HH:mm') : '',
      status: processState.status || progressValues.InProgress,
    },
  };
};

const appointmentsSelector = (state) => state.appointments;

export const getAppointments = createSelector(appointmentsSelector, (appointments) =>
  Object.keys(appointments).reduce((sum, temp) => {
    const appointInClient = appointments[temp];
    const appointList = appointInClient.Ids.map((id) => ({
      ...appointInClient.byId[id],
      ...appointInClient.byPhone[appointInClient.byId[id].phone],
    })).map(serialized);
    sum[temp] = {
      list: appointList,
      loading: appointments[temp].loading,
    };
    return sum;
  }, {})
);
