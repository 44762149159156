import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { fetchGroups } from 'lib/memberService';
import { dashboard_v2 } from 'theme';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';
import SideBar from '../Sidebar';
import DoctorSvg from 'components/Dashboard_v2/Svg/DoctorSvg';
import Input from 'components/Dashboard_v2/Input';
import Button from 'components/Dashboard_v2/Button';
import FilterableTable from 'components/Table/FilterableTable';
import tableHeaderColumns, { tableStyles } from './table/columns';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  margin: 0 -30px;
`;

const Main = styled.div`
  padding: 30px 30px 30px 86px;
`;

const Header = styled.div`
  padding: 5px 30px 0 30px;
  margin: -30px -30px 30px;
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SectionTitle = styled.h5`
  margin-top: 40px;
  font-size: 28px;
`;

const SectionDescription = styled.p`
  margin: 24px 0;
  font-size: 14px;
  color: ${colors.SHADES_400};
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
`;

const Content = styled.div``;

const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
`;

const GroupList = () => {
  const selectedIds = useSelector((state) => state.clients.selectedIds);
  const clientId = selectedIds[0];
  const { renderAlert, setAlert } = useAlert({ unmountClear: true });
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
  };

  const handleInputClear = () => {
    setSearchKeyword('');
  };

  useEffect(() => {
    if (searchKeyword) {
      const list = groupList.filter((group) => group.name.includes(searchKeyword));
      setFilteredList(list);
    } else {
      setFilteredList(groupList);
    }
  }, [groupList, searchKeyword]);

  useEffect(() => {
    setFilteredList(groupList);
  }, [groupList]);

  useEffect(() => {
    if (isInit || isLoading) return;
    const getGroupList = async () => {
      try {
        const { groups } = await fetchGroups({ clientId });
        setGroupList(
          (groups || []).map((group) => {
            const filters = group.filters.reduce(
              (obj, filter) => {
                obj[filter.type] = [...obj[filter.type], filter];
                return obj;
              },
              { tag: [], age_range: [], gender: [] }
            );
            return {
              ...group,
              conditions: [...group.tags, ...filters.tag, ...filters.age_range, ...filters.gender],
            };
          })
        );
      } catch (e) {
        setAlert({ type: 'error', title: t('loadingGroupDataFailed') });
        console.error(`[Member Group List Page]: fetch list data error ${e.message}`);
      } finally {
        setIsInit(true);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    getGroupList();
  }, [isInit, setAlert, clientId, isLoading, setIsLoading]);

  return (
    <Container className="animated fadeIn">
      {renderAlert()}
      <SideBar />
      <Main>
        <Header>
          <SectionTitle>{t('segment')}</SectionTitle>
          <SectionDescription>{t('createSegmentDescription')}</SectionDescription>
        </Header>
        <Content>
          {isInit && (
            <React.Fragment>
              <EmptyBlock hidden={groupList.length}>
                <DoctorSvg />
                <SectionDescription>{t('noSegmentAdded')}</SectionDescription>
                <Link to="/members/groups/create">
                  <IconButton variant="ghost" color="primary">
                    <i className="ri-add-line"></i>
                    {t('addSegment')}
                  </IconButton>
                </Link>
              </EmptyBlock>
              <div hidden={!groupList.length}>
                <ToolBar>
                  <Input
                    value={searchKeyword}
                    onChange={handleInputChange}
                    onInputClear={handleInputClear}
                    showClearButton={!!searchKeyword}
                    prefix={<i className="ri-search-line" />}
                    placeholder={t('searchGroup')}
                  />
                  <Link to="/members/groups/create">
                    <IconButton color="primary" variant="filled">
                      <i className="ri-add-line" />
                      {t('addSegment')}
                    </IconButton>
                  </Link>
                </ToolBar>
                <FilterableTable
                  tableStyles={tableStyles}
                  tableHeaderColumns={tableHeaderColumns}
                  data={filteredList}
                  shouldRowsRemoved={true}
                />
              </div>
            </React.Fragment>
          )}
        </Content>
      </Main>
    </Container>
  );
};

export default connectData(GroupList);
