/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { areEqual } from 'react-window';
import nanoid from 'nanoid';
import DefaultColumnFilter from './ToolSection/DefaultColumnFilter';
import ToolSection from './ToolSection/ToolSection';
import filterTypes from './filterTypes';
import TableRow from './tableRow';
import LoadingCell from './LoadingCell';
import Pagination from './Pagination';
import { ScrollableWrap, Footer, EmptyTr, EmptyTd, TableComp, Thead, Tbody, Tr, Th, Td } from './styles';
import { t } from 'i18n/config';

function Table({
  styles,
  clientID,
  columns: rawcolumns,
  data,
  updateMyData,
  batchUpdateMyData,
  removeRow,
  batchRemoveRows,
  openSnackbar,
  filterOptionsByAccessor,
  onDateChange,
  selectedDate,
  skipPageReset,
  loading,
  hiddenColumns,
  selectedToolSection,
  onClickRow,
  showToolSection,
  columnSettable,
  handleColumnSettingUpdate,
  customFilters,
  pageObj,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      Loading: LoadingCell,
    }),
    [filterOptionsByAccessor] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    columns,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds, filters },
  } = useTable(
    {
      columns: rawcolumns,
      data,
      defaultColumn,
      filterTypes,
      updateMyData,
      removeRow,
      openSnackbar,
      filterOptionsByAccessor,
      selectedDate,
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      initialState: { clientID, hiddenColumns },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const renderTbody = useCallback(() => {
    if (loading) {
      return Array(10)
        .fill(0)
        .map((r, i) => (
          <Tr key={i}>
            {headerGroups[1].headers.map((column, columnIndex) => (
              <Td
                {...column.getHeaderProps()}
                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                key={columnIndex}
              >
                {column.render('Loading')}
              </Td>
            ))}
          </Tr>
        ));
    }
    if (rows.length === 0) {
      return (
        <EmptyTr>
          <EmptyTd colSpan={headerGroups[1].headers.length}>
            {t('noDataAvailable')}
            <span role="img" aria-label="emoji cry">
              😪{' '}
            </span>
            {t('empty')}
          </EmptyTd>
        </EmptyTr>
      );
    }
    return rows.map((row) => {
      prepareRow(row);
      return <TableRow styles={styles} key={row.id} row={row} selectedRowIds={selectedRowIds} onClick={onClickRow} />;
    });
  }, [loading, rows, headerGroups, prepareRow, selectedRowIds, onClickRow, styles]);

  const renderSection = () => {
    if (selectedFlatRows.length > 0) {
      return (
        <ScrollableWrap>
          {React.cloneElement(selectedToolSection, {
            selectedRowIds: selectedRowIds,
            selectedFlatRows: selectedFlatRows,
            batchUpdateMyData: batchUpdateMyData,
            batchRemoveRows: batchRemoveRows,
            openSnackbar: openSnackbar,
            clientID: clientID,
            filters: filters,
          })}
        </ScrollableWrap>
      );
    }
    if (selectedDate != null || showToolSection) {
      return (
        <ScrollableWrap>
          <ToolSection
            selectedDate={selectedDate}
            headers={columns[0].columns}
            filterOptionsByAccessor={filterOptionsByAccessor}
            onDateChange={onDateChange}
            columnSettable={columnSettable}
            handleColumnSettingUpdate={handleColumnSettingUpdate}
            customFilters={customFilters}
          />
        </ScrollableWrap>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {renderSection()}
      <ScrollableWrap>
        <TableComp {...getTableProps()}>
          <Thead>
            {
              <Tr {...headerGroups[1].getHeaderGroupProps()}>
                {headerGroups[1].headers.map((column) => (
                  <Th style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }} key={nanoid()}>
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                      style={{ ...(!column.disableSortBy && { cursor: 'pointer' }) }}
                    >
                      {column.render('Header')}
                      <span className="sortIcon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="ri-arrow-up-s-fill"></i>
                          ) : (
                            <i className="ri-arrow-down-s-fill"></i>
                          )
                        ) : null}
                      </span>
                    </div>
                  </Th>
                ))}
              </Tr>
            }
          </Thead>
          <Tbody {...getTableBodyProps()}>{renderTbody()}</Tbody>
        </TableComp>
      </ScrollableWrap>
      {pageObj && (
        <Footer>
          <Pagination pageObj={pageObj} />
        </Footer>
      )}
    </React.Fragment>
  );
}

Table.defaultProps = {
  hiddenColumns: [],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
          accessor: PropTypes.string,
        })
      ),
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  updateMyData: PropTypes.func,
  batchUpdateMyData: PropTypes.func,
  removeRow: PropTypes.func,
  batchRemoveRows: PropTypes.func,
  filterOptionsByAccessor: PropTypes.shape({}),
  onDateChange: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  skipPageReset: PropTypes.bool,
  loading: PropTypes.bool,
  clientID: PropTypes.string,
  openSnackbar: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  selectedToolSection: PropTypes.shape(),
  styles: PropTypes.shape(),
  onClickRow: PropTypes.func,
  showToolSection: PropTypes.bool,
};

export default React.memo(Table, areEqual);
