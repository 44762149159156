import NameCell from 'components/Message/campaignTable/Columns/Name/NameCell';
import TargetUsersCell from 'components/Message/campaignTable/Columns/TargetUsers/TargetUsersCell';
import SendTimeRuleCell from 'components/Message/campaignTable/Columns/SendTimeRule/SendTimeRuleCell';
import UpdatedAtCell from 'components/Message/campaignTable/Columns/UpdatedAt/UpdatedAtCell';
import StatusCell from 'components/Message/campaignTable/Columns/Status/StatusCell';
import ActionCell from 'components/Message/campaignTable/Columns/Action/ActionCell';
import theme from 'theme';
import { t } from 'i18n/config';

const RegularReminderColumns = [
  {
    Header: t('campaignName'),
    Cell: NameCell,
    accessor: 'name',
    filterable: false,
    maxWidth: 100,
    minWidth: 100,
  },
  {
    Header: t('numberOfRecipients'),
    Cell: TargetUsersCell,
    accessor: 'number',
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('sendTime'),
    accessor: '',
    Cell: SendTimeRuleCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('updateTime'),
    accessor: 'updatedAt',
    Cell: UpdatedAtCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: t('status'),
    accessor: 'status',
    Cell: StatusCell,
    filterable: false,
    maxWidth: 120,
  },
  {
    Header: ' ',
    accessor: '',
    Cell: ActionCell,
    filterable: false,
    maxWidth: 120,
  },
];

const BroadCastMessageColumns = [
  {
    Header: t('patientName'),
    accessor: 'name',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    Header: t('clinic'),
    accessor: 'room',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
];

export const TabTitles = [t('regularReminder')];

export const shouldRowRemovedMap = {
  [TabTitles[0]]: true,
  [TabTitles[1]]: false,
  // [TabTitles[2]]: true
};

export const headerColumnsMap = {
  0: () => [
    {
      Header: TabTitles[0],
      columns: RegularReminderColumns,
    },
  ],
  1: () => [
    {
      Header: TabTitles[1],
      columns: BroadCastMessageColumns,
    },
  ],
  // 2: () => [{
  //   Header: TabTitles[2],
  //   columns: unsendcolumns
  // }]
};

export const tableStyles = {
  rowHeight: '100px',
  fontsize: '16px',
  rowHover: theme.list.hover,
};
