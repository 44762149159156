import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import todoReducer from 'reducers/todo';
import messageReducer from 'reducers/messages';
import authReducer from 'reducers/auth';
import memberReducer from 'reducers/members';
import alertReducer from 'reducers/alert';
import customersReducer from 'reducers/customers';
import reviewsReducer from 'reducers/reviews';
import clientsReducer from 'reducers/clients';
import appointmentsReducer from 'reducers/appointments';
import westernAppointmentsReducer from 'reducers/westernAppointments';
import lineReducer from 'reducers/line';
import navReducer from 'reducers/nav';
import uiReducer from 'reducers/ui';
import analyticsReducer from 'reducers/analytics';

const reducer = combineReducers({
  todo: todoReducer,
  message: messageReducer,
  auth: authReducer,
  member: memberReducer,
  alert: alertReducer,
  customer: customersReducer,
  review: reviewsReducer,
  clients: clientsReducer,
  analytics: analyticsReducer,
  appointments: appointmentsReducer,
  westernAppointments: westernAppointmentsReducer,
  line: lineReducer,
  nav: navReducer,
  ui: uiReducer,
});

export default createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
