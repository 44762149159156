import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { attributeMap } from '../../../helpers/constants';

const propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  setType: PropTypes.func,
  clickable: PropTypes.bool,
};

const DropdownComp = ({ isOpen, toggle, title, values, setType, clickable = true }) => {
  const renderdiv = (e) => (
    <div>
      <div
        key={e}
        onClick={() => {
          setType(e);
          toggle();
        }}
      >
        {attributeMap[e]}
      </div>
    </div>
  );
  return (
    <Dropdown className="mr-1 line-drop" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle tag="span" onClick={toggle} data-toggle="dropdown" aria-expanded={isOpen}>
        {title}
      </DropdownToggle>
      {clickable && (
        <DropdownMenu>
          {values.map((e) => renderdiv(e))}
          <div />
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

DropdownComp.propTypes = propTypes;

export default DropdownComp;
