import React, { memo, useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import nanoid from 'nanoid';
import Popover from '@material-ui/core/Popover';
import DatePicker from 'components/Dashboard_v2/DatePicker';
import Select from 'components/Dashboard_v2/Select';
import Button from 'components/Dashboard_v2/Button';
import PatientTrackingTag from 'components/Dashboard_v2/PatientTrackingTag';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;
const TIME_OPTIONS = {
  // HOURS: 00, 01 ... 23
  HOURS: Array.from(Array(24)).map((n, i) => ({ id: i, name: `${i}`.padStart(2, '0'), value: i })),
  // MINUTES: 00, 05, ... 55
  MINUTES: Array.from(Array(12)).map((n, i) => ({ id: i, name: `${i * 5}`.padStart(2, '0'), value: i * 5 })),
};

const getHourOption = (date) =>
  date
    ? TIME_OPTIONS.HOURS.find((hour) => hour.name === moment(date).add(5, 'minute').format('HH'))
    : TIME_OPTIONS.HOURS[0];

const getMinuteOption = (date) => {
  const option =
    date &&
    TIME_OPTIONS.MINUTES.find(
      (minute) => minute.name === `${parseInt(moment(date).add(5, 'minute').format('mm') / 5) * 5}`.padStart(2, '0')
    );
  return option || TIME_OPTIONS.MINUTES[0];
};

const TimeWrapper = styled.div`
  display: flex;
  margin: 0 24px 0;
  > span {
    margin: 8px 4px 0px 4px;
  }
`;

const ActivateTimeTitle = styled.div`
  margin: 0 0 8px 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.SHADES_400};
`;

const PopoverWrapper = styled.div`
  max-width: 360px;
  overflow: auto;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  padding: 8px 16px;
  i.ri-add-circle-line {
    color: ${colors.SHADES_400};
  }
`;

const StyledHr = styled.hr`
  margin: 0;
`;

const SelectedCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  padding: 8px 16px 0 16px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: ${({ selectedWrapper }) => (selectedWrapper ? '0' : '16px')};
  font-size: 12px;
  input {
    flex: 1;
    font-size: 16px;
    border: none;
    min-width: ${({ hasTag }) => (hasTag ? '100px' : '255px')};
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.SHADES_400};
    }
    :-ms-input-placeholder {
      color: ${colors.SHADES_400};
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  > button:first-child {
    margin-right: 16px;
  }
  padding: 24px 16px;
`;

const HelperText = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 24px;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: 0.2px;
  color: ${({ error }) => (error ? colors.SYSTEM_ERROR_500 : colors.SHADES_400)};
  margin-top: 8px;
  margin-bottom: 0;
  margin-right: 16px;
  img {
    margin: 0 7px 0 7px;
  }
`;

const Divider = styled.div`
  margin-top: 24px;
  background-color: ${colors.SHADES_100};
  height: 2px;
  width: 100%;
`;

const getSettings = () => {
  const startTime = new Date();
  return {
    startDate: moment(startTime).format('YYYY/MM/DD'),
    startHour: getHourOption(startTime),
    startMinute: getMinuteOption(startTime),
  };
};

const PatientTrackingSelectorPopover = ({ memberPatientTrackings, onClose, isLoading, isTagList, children }) => {
  const patientTrackings = useSelector((state) =>
    isTagList ? state.member.taggablePatientTrackings : state.member.patientTrackings
  );
  const inputRef = useRef();
  const [showTimer, setShowTimer] = useState({ show: false, id: undefined, date: getSettings() });
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [selectedData, setSelectedData] = useState(memberPatientTrackings);
  const [hint, setHint] = useState('');

  const open = Boolean(anchorEl);

  const patientTrackingItems = useMemo(
    () =>
      patientTrackings
        .filter((item) => item.status === 'enabled' && Date.now() < new Date(item.endTime))
        .sort((a, b) => (a.status === 'enabled' ? -1 : b.status === 'enabled' ? 1 : 0))
        .map((item) => ({ ...item, name: item.title })),
    [patientTrackings]
  );

  const filteredData = useMemo(() => {
    if (!keyword) {
      return patientTrackingItems;
    }
    return patientTrackingItems.filter((patientTracking) => patientTracking.title.includes(keyword));
  }, [patientTrackingItems, keyword]);

  const handleClose = () => {
    const isChanged = JSON.stringify(memberPatientTrackings) !== JSON.stringify(selectedData);
    if (isChanged) {
      onClose(selectedData);
    }
    setAnchorEl(null);
  };

  const openPopover = useCallback(
    (e) => {
      if (isLoading) return;
      setAnchorEl(e.currentTarget);
    },
    [isLoading]
  );

  const getSelectedIndex = (patientTracking) => selectedData.findIndex((data) => data.title === patientTracking.title);

  const removePatientTracking = (index) => {
    setSelectedData((prev) => {
      const newArray = [...prev];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  const handleSelect = (patientTracking) => {
    const index = getSelectedIndex(patientTracking);
    if (index === -1) {
      if (patientTracking.runAtSelectable) {
        setShowTimer((prev) => ({ ...prev, show: true, id: patientTracking.id }));
      } else {
        const data = patientTracking;
        data.runAt = new Date().toISOString();
        setSelectedData((prev) => [...prev, JSON.parse(JSON.stringify(data))]);
      }
    } else {
      removePatientTracking(index);
    }
  };

  const handleRemove = (e, patientTracking) => {
    e.stopPropagation();
    const index = getSelectedIndex(patientTracking);
    if (index !== -1) {
      removePatientTracking(index);
    }
  };

  const setDateTimeHint = (date) => {
    const { startDate, startHour, startMinute } = date;
    const startTime = new Date(`${startDate} ${startHour.value}:${startMinute.value}`);
    const now = new Date();
    if (startTime < now) {
      setHint(t('pastTimeNote'));
    } else {
      setHint('');
    }
    return true;
  };

  const handleDateTimeSelectChange = (key, value) => {
    const newDate = { ...showTimer.date, [key]: value };
    setDateTimeHint(newDate);
    setShowTimer((prev) => {
      return { ...prev, date: { ...prev.date, [key]: value } };
    });
  };

  const handleCancelDateTimeSelect = () => {
    setShowTimer((prev) => ({ ...prev, show: false, id: undefined, date: getSettings() }));
    setHint('');
  };

  const handleConfirmDateTimeSelect = () => {
    setDateTimeHint(showTimer.date);
    const data = patientTrackingItems.find((patientTracking) => patientTracking.id === showTimer.id);
    const { startDate, startHour, startMinute } = showTimer.date;
    data.runAt = new Date(`${startDate} ${startHour.value}:${startMinute.value}`).toISOString();
    setSelectedData((prev) => [...prev, JSON.parse(JSON.stringify(data))]);
    handleCancelDateTimeSelect();
  };

  useEffect(() => {
    setSelectedData(memberPatientTrackings);
  }, [memberPatientTrackings]);

  return (
    <React.Fragment>
      <div onClick={openPopover}>{children}</div>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
            maxHeight: '650px',
          }}
        >
          <PopoverWrapper hidden={!showTimer.show}>
            <DatePicker
              selectedDate={new Date()}
              yearRange={{ startYear: new Date().getFullYear() - 1, endYear: new Date().getFullYear() + 15 }}
              onChange={(date) => handleDateTimeSelectChange('startDate', moment(date).format('YYYY/MM/DD'))}
            />
            <ActivateTimeTitle>{t('patientTrackingActivationTime')}</ActivateTimeTitle>
            <TimeWrapper key={showTimer.id}>
              <Select
                options={TIME_OPTIONS.HOURS}
                selectedOption={showTimer.date.startHour}
                setSelectedOption={(option) => handleDateTimeSelectChange('startHour', option)}
                width={80}
                direction="top"
              />
              <span>:</span>
              <Select
                options={TIME_OPTIONS.MINUTES}
                selectedOption={showTimer.date.startMinute}
                setSelectedOption={(option) => handleDateTimeSelectChange('startMinute', option)}
                width={80}
                direction="top"
              />
            </TimeWrapper>
            {!!hint && <HelperText>{hint}</HelperText>}
            <Divider />
            <ActionButtons>
              <Button color="secondary" variant="filled" onClick={handleCancelDateTimeSelect}>
                {t('cancel')}
              </Button>
              <Button color="primary" variant="filled" onClick={handleConfirmDateTimeSelect}>
                {t('save')}
              </Button>
            </ActionButtons>
          </PopoverWrapper>
          <PopoverWrapper hidden={showTimer.show}>
            <InputContainer>
              <TagsWrapper selectedWrapper onClick={() => inputRef.current.focus()} hasTag={selectedData.length > 0}>
                {selectedData.map((data) => (
                  <PatientTrackingTag
                    name={`${data.title} ${
                      !isTagList && data.runAt ? moment(new Date(data.runAt)).format('YYYY/MM/DD HH:mm') : ''
                    }`}
                    onClick={(e) => e.stopPropagation()}
                    onClickRemove={(e) => handleRemove(e, data)}
                    setting={{
                      isExpired: moment(new Date()).isAfter(data.expiredAt),
                      isEnabled: data.status === 'enabled',
                      recyclable: data.recyclable,
                    }}
                    key={nanoid()}
                  />
                ))}
                <input
                  ref={inputRef}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder={selectedData.length ? '' : t('searchKeywordPlaceholder')}
                  type="text"
                />
              </TagsWrapper>
            </InputContainer>
            <StyledHr />
            <SelectedCount>
              {t('patientTrackingPrefix', {
                info: `${filteredData.length}/${patientTrackingItems.length}`,
              })}
            </SelectedCount>
            <TagsWrapper>
              {filteredData.map((data) => (
                <PatientTrackingTag
                  name={data.title}
                  onClick={() => handleSelect(data)}
                  isSelected={getSelectedIndex(data) !== -1}
                  setting={{
                    recyclable: data.recyclable,
                    isEnabled: data.status === 'enabled',
                  }}
                  key={nanoid()}
                />
              ))}
            </TagsWrapper>
          </PopoverWrapper>
        </Popover>
      )}
    </React.Fragment>
  );
};

const propTypes = {
  onClose: PropTypes.func,
  memberPatientTrackings: PropTypes.array,
};

PatientTrackingSelectorPopover.propTypes = propTypes;

export default memo(PatientTrackingSelectorPopover);
