import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ActionComponent from 'components/WesternAppointments/table/Columns/Action/ActionComponent';
import { western } from 'theme';
import { t } from 'i18n/config';

const Container = styled.div`
  height: 127px;
  display: flex;
  align-items: center;
  padding: 16px 18px;
`;

const Description = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.colors.textPrimary};
  margin: 0 40px 0 0;
  white-space: nowrap;
`;

const Number = styled.span`
  font-weight: bold;
  margin: 0 4px;
  font-size: ${(props) => props.fontSize || 'inherit'};
  color: ${(props) => props.color || 'inherit'};
`;

function SelectedToolSection({ clientID, selectedRowIds, selectedFlatRows, batchRemoveRows, openSnackbar, filters }) {
  const { value: type } = filters.find(({ id }) => id === 'sendingAction');
  const selectedNumber = selectedFlatRows.length;
  const originals = selectedFlatRows.map((d) => d.original);
  const disabled = originals.filter((d) => d.sendingAction.disabled);
  const enabled = originals.filter((d) => !d.sendingAction.disabled);

  const handleSuccess = (msg) => {
    if (batchRemoveRows) {
      batchRemoveRows(selectedRowIds);
    }
    openSnackbar({ message: msg, status: 'success' });
  };

  const handleFail = (msg) => {
    openSnackbar({ message: msg, status: 'error' });
  };

  const increaseUnsend = useCallback(() => {
    // not implement
  }, []);

  return (
    <Container>
      <Description>
        {t('selected')} <Number>{selectedNumber}</Number> {t('itemsWithComma')}
        {disabled.length !== 0 && (
          <React.Fragment>
            {t('has')} <Number>{disabled.length}</Number>
            {t('itemsNotBound')}
          </React.Fragment>
        )}
        {t('canSend')}
        <Number fontSize="20px" color={western.common.primary}>
          {enabled.length}
        </Number>
        {t('people')}
      </Description>
      <ActionComponent
        clientID={clientID}
        type={type}
        disabled={enabled.length === 0}
        appointments={enabled}
        shouldConfirmAgain
        onSuccess={handleSuccess}
        increaseUnsend={increaseUnsend}
        onFail={handleFail}
      />
    </Container>
  );
}

SelectedToolSection.propTypes = {
  selectedRowIds: PropTypes.objectOf(PropTypes.bool),
  selectedFlatRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  clientID: PropTypes.string,
  batchUpdateMyData: PropTypes.func,
  batchRemoveRows: PropTypes.func,
  openSnackbar: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

export default React.memo(SelectedToolSection);
