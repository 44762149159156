import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// const { colors } = dashboard_v2;

const StyledDiv = styled.div`
  ${({ margin }) => {
    if (margin === 'none') return `margin-bottom: 0`;
    return `margin-bottom: 24px`;
  }};
  ${({ flex }) =>
    flex &&
    `
      display: flex;
      align-items: center;
  `};
`;

const FormControl = ({ children, margin, flex }) => (
  <StyledDiv margin={margin} flex={flex}>
    {children}
  </StyledDiv>
);

const propTypes = {
  children: PropTypes.node,
  margin: PropTypes.string,
};

FormControl.propTypes = propTypes;

export default memo(FormControl);
