import matchSorter from 'match-sorter';
import { sendingActionFilterFn } from 'components/WesternAppointments/table/Columns/Action/action';
import { progressFilterFn } from './Columns/Progress/progress';

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
export default {
  fuzzyText: fuzzyTextFilterFn,
  progressFilter: progressFilterFn,
  sendingActionFilter: sendingActionFilterFn,
  text: (rowsx, id, filterValue) =>
    rowsx.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    }),
};
