import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, CardGroup, Card, CardBody, Button, Input, InputGroup } from 'reactstrap';
import { handleLogin } from '../../reducers/auth';
import { isLogin } from '../../helpers';

class Login extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    handleLogin: PropTypes.func,
  };

  constructor(props) {
    super(props);
    console.log(isLogin());
    console.log(localStorage.getItem('auth'));
    if (isLogin()) {
      this.props.history.push('/');
    }
    this.state = {
      username: '',
      password: '',
    };
  }

  login = (username, password) => this.props.handleLogin(username, password);

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // this.setState({ isLoading: true });

    try {
      const res = await this.login(this.state.username, this.state.password);
      console.log(res);
      this.props.history.push('/');
    } catch (e) {
      const res = await e;
      alert(res.error);
    }
  };

  render() {
    const { username, password } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <InputGroup className="mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-user" />
                        </span>
                      </div>
                      <Input
                        name="username"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-lock" />
                        </span>
                      </div>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" onClick={this.handleSubmit}>
                          Login
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect((state) => ({ auth: state.auth }), { handleLogin })(Login);
