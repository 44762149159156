import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInfo } from 'helpers/authHelpers';
import { fetchTagListInClient } from 'reducers/line';
import { fetchFBUnreadNumber, handleLogout, fetchGoogleAccountStatus } from '../../reducers/auth';
import { fetchTokenScopes } from '../../reducers/reviews';
import { fetchClient } from '../../reducers/clients';
import { OPERATION_TYPE } from '../../helpers';

export default function connectData(WrappedComponent) {
  const mapStateToProps = (state) => ({
    clients: state.clients,
    account: state.auth.user,
  });

  const mapDispatchToProps = {
    handleLogout,
    fetchClient,
    fetchFBUnreadNumber,
    fetchTokenScopes,
    fetchGoogleAccountStatus,
    fetchTagListInClient,
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class ConnectData extends React.Component {
    static propTypes = {
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      clients: PropTypes.shape({
        selectedIds: PropTypes.array,
      }),
      fetchClient: PropTypes.func,
      fetchFBUnreadNumber: PropTypes.func,
      fetchTokenScopes: PropTypes.func,
      match: PropTypes.shape({
        path: PropTypes.string,
      }),
      account: PropTypes.shape({
        operationType: PropTypes.string,
      }),
      handleLogout: PropTypes.func,
      fetchGoogleAccountStatus: PropTypes.func,
      fetchTagListInClient: PropTypes.func,
    };

    state = {
      doneChecking: false,
    };

    componentDidMount() {
      if (getInfo() == null || getInfo().id == null) {
        this.props.handleLogout();
        this.props.history.push('/login');
      } else {
        this.fetchData();
      }
    }

    doneChecking = () => {
      this.setState({
        doneChecking: true,
      });
    };

    fetchData = async () => {
      try {
        Promise.all([this.props.fetchClient()])
          .catch((error) => {
            alert(error.message);
            this.props.handleLogout();
            this.props.history.push('/login');
            this.doneChecking();
          })
          .then(() => {
            this.props.fetchGoogleAccountStatus();
            this.props.fetchTagListInClient();
            this.props.fetchFBUnreadNumber();
            this.props.fetchTokenScopes();
            this.doneChecking();
          });
      } catch (error) {
        alert(error.message);
        this.props.handleLogout();
        this.props.history.push('/login');
        this.doneChecking();
      }
    };

    render() {
      if (
        this.props.account.operationType === OPERATION_TYPE.OPERATION &&
        /response|report/.test(this.props.match.path)
      ) {
        return null;
      }
      if (this.props.clients.selectedIds.length > 0 && this.state.doneChecking) {
        return <WrappedComponent {...this.props} />;
      }

      return null;
    }
  }
  return ConnectData;
}
