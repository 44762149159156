import React, { memo } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const PopoverWrapper = styled.div`
  min-width: 120px;
  background-color: ${colors.SHADES_000};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  > a,
  > span {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    color: ${colors.SHADES_500};
    font-size: 16px;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
    i {
      margin-right: 12px;
    }
    &:hover {
      color: ${colors.PRIMARY_500};
      text-decoration: none;
    }
  }
`;

const MorePopover = ({ anchorEl, onClose, children }) =>
  anchorEl ? (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ marginTop: '8px' }}
    >
      <PopoverWrapper>{children}</PopoverWrapper>
    </Popover>
  ) : null;

export default memo(MorePopover);
