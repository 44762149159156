import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { fetchFilters } from 'lib/memberService';
import { dashboard_v2 } from 'theme';
import DoctorSvg from 'components/Dashboard_v2/Svg/DoctorSvg';
import Input from 'components/Dashboard_v2/Input';
import Button from 'components/Dashboard_v2/Button';
import FilterableTable from 'components/Table/FilterableTable';
import tableHeaderColumns, { tableStyles } from './table/columns';
import useAlert from 'hooks/useAlert';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  margin: 0 -30px;
`;

const Main = styled.div`
  padding: 30px 30px 30px 30px;
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SectionDescription = styled.p`
  margin: 24px 0;
  font-size: 14px;
  color: ${colors.SHADES_400};
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
`;

const Content = styled.div``;

const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
`;

const AgeTags = () => {
  const { renderAlert } = useAlert({ unmountClear: true });
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const [ageTagData, setAgeTagData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredTags, setFilteredTags] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
  };

  const handleInputClear = () => {
    setSearchKeyword('');
  };

  useEffect(() => {
    if (searchKeyword) {
      const tags = ageTagData.filter((tag) => tag.name.includes(searchKeyword));
      setFilteredTags(tags);
    } else {
      setFilteredTags(ageTagData);
    }
  }, [ageTagData, searchKeyword]);

  useEffect(() => {
    setFilteredTags(ageTagData);
  }, [ageTagData]);

  useEffect(() => {
    const getFilters = async () => {
      setIsLoading(true);
      const data = await fetchFilters({ clientId });
      const ageTags = data.filters.filter((item) => item.type === 'age_range');
      setAgeTagData(ageTags);
      setIsLoading(false);
    };
    getFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="animated fadeIn">
      {renderAlert()}
      <Main>
        <Content>
          {!isLoading && ageTagData.length ? (
            <div>
              <ToolBar>
                <Input
                  value={searchKeyword}
                  onChange={handleInputChange}
                  onInputClear={handleInputClear}
                  showClearButton={!!searchKeyword}
                  prefix={<i className="ri-search-line" />}
                  placeholder={t('searchTags')}
                />
                <Link to="/members/ageTag/create">
                  <IconButton color="primary" variant="filled">
                    <i className="ri-add-line" />
                    {t('addTag')}
                  </IconButton>
                </Link>
              </ToolBar>
              <FilterableTable
                tableStyles={tableStyles}
                tableHeaderColumns={tableHeaderColumns}
                data={filteredTags}
                shouldRowsRemoved={true}
              />
            </div>
          ) : (
            <EmptyBlock>
              <DoctorSvg />
              <SectionDescription>{t('noTagsAdded')}</SectionDescription>
              <Link to="/members/ageTag/create">
                <IconButton variant="ghost" color="primary">
                  <i className="ri-add-line"></i>
                  {t('addTag')}
                </IconButton>
              </Link>
            </EmptyBlock>
          )}
        </Content>
      </Main>
    </Container>
  );
};

export default memo(AgeTags);
