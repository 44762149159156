import styled from 'styled-components';
import { Confirm } from 'components/MaterialDesign/Buttons';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

const Time = styled.div`
  margin-top: 7px;
  width: 88px;
  height: 19px;
  font-size: 12px;
  line-height: 1.58;
  letter-spacing: 0.3px;
  color: ${(props) => props.theme.colors.textSecondary};
`;

const Button = styled(Confirm)`
  font-size: 13px;
  white-space: nowrap;
`;

export { Container, Time, Button };
