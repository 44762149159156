export const listSerializer = (res) => {
  console.log(res);
  return res.map(
    ({ id, alias: name, notificationCount: number, delaySeconds, updatedAt, status, duplicatedImport, message }) => ({
      id,
      name,
      number,
      delaySeconds,
      updatedAt,
      status,
      duplicatedImport,
      message,
    })
  );
};

export const listUserNotificationsSerializer = ({ res, clientID, campaignID }) =>
  (res.notifications || []).map(
    ({
      name,
      to: phone,
      eventTime,
      estimatedDeliveryTimestamp,
      updatedAt,
      status,
      messageApiStatus,
      smsApiStatus,
      id,
    }) => ({
      id,
      name,
      phone,
      clientID,
      status,
      campaignID,
      eventTime,
      estimatedDeliveryTimestamp,
      messageApiStatus,
      smsApiStatus,
      updatedAt,
    })
  );
