import {
  initClient,
  updateSigninStatus,
  handleGoogleSignIn,
  reloadAuth,
  handleGoogleSignOut,
  getGoogleAccountsApi,
  getGoogleLocationApi,
  getGoogleUserInfoByToken,
} from 'lib/gapi';
import { get } from 'lodash';
import { removeAuth, FB_SCOPES, isAuthRoleBrand } from '../helpers';
import { loginUser } from '../lib/authService';
import {
  fetchFBMessageUnreadNumber,
  updateClientGoogleTokenApi,
  updateClientAccountApi,
  updateClientLocationApi,
} from '../lib/clientService';
import {
  updateClientGoogleAccountToken,
  updateClientGoogleAccountInfo,
  updateClientGoogleAccountLocation,
} from './clients';
import { addAlert } from './ui';
import { t } from 'i18n/config';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const AUTH_LOGIN = 'AUTH_LOGIN';
const SET_FB_MESSAGE_UNREAD_NUMBER = 'SET_FB_MESSAGE_UNREAD_NUMBER';
const UPDATE_FB_SCOPES = 'UPDATE_FB_SCOPES';
const UPDATE_GOOGLE = 'UPDATE_GOOGLE';
const UPDATE_GOOGLE_ACCOUNT_TOKEN = 'AUTH/UPDATE_GOOGLE_ACCOUNT_TOKEN';
const UPDATE_GOOGLE_ACCOUNT_INFO = 'AUTH/UPDATE_GOOGLE_ACCOUNT_INFO';

const googleDefaultState = {
  isLogin: false,
};

const defaultState = {
  user: {},
  token: '',
  fbMessageUnreadNumber: 0,
  facebook: {
    scopes: [],
  },
  features: {
    fbNotification: true,
    negativeNotification: false,
    googleSharingOnFb: true,
    googleReply: false,
    fbReply: false, // TODO: replace isFbReplyEnabled
    reviewMapping: false,
    analytics: false,
    showDemoRadar: false, // TODO: remove Demo
    patientIdType: 'twID',
  },
  google: googleDefaultState,
};

const login = (auth) => ({
  type: AUTH_LOGIN,
  payload: auth,
});

const logout = () => ({
  type: AUTH_LOGOUT,
});

const setFbMessageUnreadNumber = (fbMessageUnreadNumber) => ({
  type: SET_FB_MESSAGE_UNREAD_NUMBER,
  payload: {
    fbMessageUnreadNumber,
  },
});

export const updateAccount = (account) => ({
  type: UPDATE_ACCOUNT,
  payload: {
    user: account,
  },
});

export const updateGoogleAccountInfo = ({ googleAccountId, googleAccountName }) => ({
  type: UPDATE_GOOGLE_ACCOUNT_INFO,
  payload: {
    googleAccountId,
    googleAccountName,
  },
});

export const updateGoogleAccountToken = (googleAccountAccessToken, googleAccountName) => ({
  type: UPDATE_GOOGLE_ACCOUNT_TOKEN,
  payload: {
    googleAccountAccessToken,
    googleAccountName,
  },
});

export const updateFBScopes = (fbScopes) => ({
  type: UPDATE_FB_SCOPES,
  payload: fbScopes,
});

export const updateGoogle = (googleStatus) => ({
  type: UPDATE_GOOGLE,
  payload: googleStatus,
});

export const isGoogleReplyEnabled =
  ({ reviewId }) =>
  (dispatch, getState) => {
    const {
      features: { googleReply },
      google: { isLogin },
      user,
    } = getState().auth;
    const isFeatureEnabled = user.features ? user.features.googleReply : false;
    return isFeatureEnabled && isLogin && googleReply && reviewId != null && reviewId.length > 0;
  };

export const isFbReplyEnabled =
  () =>
  (dispatch, getState = { auth: { user: { features: {} }, facebook: { scopes: [] } } }) => {
    const { user, facebook } = getState().auth;
    const isFeatureEnabled = user.features ? user.features.fbReply : false; // default false
    const isFbScopeValid =
      facebook.scopes.includes(FB_SCOPES.PUBLISH_PAGES) || facebook.scopes.includes(FB_SCOPES.PAGES_MANAGE_ENGAGEMENT);
    return isFeatureEnabled && isFbScopeValid;
  };

// TODO: remove Demo
export const isShowDemoRadarChart =
  () =>
  (dispatch, getState = { auth: { user: { features: {} } } }) => {
    const { user } = getState().auth;
    const isShowDemo = user.features.showDemoRadar || false;
    return isShowDemo;
  };

export const handleLogin = (username, password) => async (dispatch) => {
  await loginUser(username, password).then((res) => {
    console.log(res);
    if (!get(res, 'user.features.patientIdType')) {
      console.log(`empty patientIdType`);
    }
    localStorage.setItem('auth', JSON.stringify(res));

    dispatch(login(res));
  });
};

export const handleLogout = () => (dispatch) => {
  dispatch(logout());
  removeAuth();
};

export const fetchFBUnreadNumber = () => async (dispatch, getState) => {
  const { byId, selectedIds } = getState().clients;
  // TODO: brands user is with multiple clients
  if (byId[selectedIds[0]]) {
    const { fbFanPageToken, fbId } = byId[selectedIds[0]];
    try {
      const unreadNumber = await fetchFBMessageUnreadNumber({ fbFanPageToken, fbId });
      dispatch(setFbMessageUnreadNumber(unreadNumber));
    } catch (error) {
      console.error('fetchFBMessageUnreadNumber error:', error);
    }
  }
};

export const fetchGoogleAccountStatus = () => async (dispatch, getState) => {
  let authResponse;
  if (gapi.auth2 == null) {
    await new Promise((resolve) => {
      gapi.load('client:auth2', async () => {
        authResponse = await initClient();
        resolve();
      });
    });
  } else if (gapi.auth2.getAuthInstance() == null) {
    authResponse = await initClient();
  } else {
    authResponse = updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
  }

  if (authResponse == null) {
    dispatch(
      updateGoogle({
        ...getState().auth.google,
        isLogin: false,
      })
    );
    dispatch(
      addAlert({
        type: 'announcement',
        message: t('useNewFeatureGoogleReplyGoToPlatformAuthorizationForGoogleAdminLogin'),
      })
    );
    return console.warn('not login google');
  }

  if (authResponse.expires_at < new Date().getTime() + 2000000) {
    authResponse = await reloadAuth();
  }

  // token refresh
  if (getState().auth.user.googleAccountAccessToken !== authResponse.access_token) {
    const userInfo = await getGoogleUserInfoByToken({ accessToken: authResponse.access_token });
    const googleAccountAccessToken = authResponse.access_token;
    const updatedStore = {
      ...getState().auth.user,
      googleAccountAccessToken,
      googleAccountName: userInfo.name,
    };
    dispatch(updateGoogleAccountToken(googleAccountAccessToken, userInfo.name));
    // we need token in database for fetching by backend to create monthly report
    const updatedClient = await updateClientGoogleTokenApi({
      clientId: updatedStore.id,
      googleAccountAccessToken,
    });
    if (isAuthRoleBrand(updatedClient)) {
      const clientsPromises = updatedClient.bindingStoreIds.map((clientId) =>
        updateClientGoogleTokenApi({
          clientId,
          googleAccountAccessToken,
        })
      );
      Promise.all(clientsPromises);
    }
    dispatch(
      updateClientGoogleAccountToken({
        id: updatedClient.id,
        googleAccountAccessToken: updatedClient.googleAccountAccessToken,
      })
    );
  }

  dispatch(
    updateGoogle({
      ...getState().auth.google,
      isLogin: true,
    })
  );

  return authResponse;
};

export const getGoogleAccounts = () => async (dispatch, getState) => {
  const accessToken = getState().auth.user.googleAccountAccessToken;
  let response;
  try {
    response = await getGoogleAccountsApi({ accessToken });
  } catch (error) {
    response = [];
  }
  return response;
};

/**
 * when update google account of the client,
 * we don't update the binding stores if its brand account
 */
export const setGoogleAccount = (account) => async (dispatch, getState) => {
  const accountId = account.name.replace(/accounts\//, '');
  const { accountName } = account;
  const updatedStore = {
    ...getState().auth.user,
    googleAccountId: accountId,
    googleAccountName: accountName,
  };
  const { id, googleAccountId, googleAccountName } = await updateClientAccountApi({ client: updatedStore });
  dispatch(
    updateGoogleAccountInfo({
      googleAccountId,
      googleAccountName,
    })
  );
  dispatch(
    updateClientGoogleAccountInfo({
      id,
      googleAccountId,
      googleAccountName,
    })
  );
};

export const getGoogleLocations = (title) => async (dispatch, getState) => {
  const { googleAccountAccessToken: accessToken, googleAccountId: accountId } = getState().auth.user;
  let response;
  try {
    response = await getGoogleLocationApi({ search: title, accessToken, accountId });
  } catch (error) {
    response = [];
  }
  return response;
};

/**
 * when update location of client,
 * we update the account id in client for fetching apis
 */
export const setGoogleLocation = (location) => async (dispatch, getState) => {
  const reg = `locations/`;
  const locationId = location.name.replace(reg, '');
  const targetClient = Object.keys(getState().clients.byId)
    .map((id) => getState().clients.byId[id])
    .find(({ googlePlaceId }) => googlePlaceId !== '' && googlePlaceId === (location.metadata || {}).placeId);
  if (targetClient == null) {
    return {
      error: new Error(t('thisLoginAccountIsNotAssociatedWithSelectedMerchant')),
      message: t('thisLoginAccountIsNotAssociatedWithSelectedMerchant'),
    };
  }
  const { title: googleLocationName } = location;
  const { googleAccountId, googleAccountName } = getState().auth.user;
  const googleLocationId = locationId;
  await updateClientLocationApi({
    clientId: targetClient.id,
    googleAccountId,
    googleAccountName,
    googleLocationId,
    googleLocationName,
  });
  dispatch(
    updateClientGoogleAccountLocation({
      id: targetClient.id,
      googleAccountId,
      googleAccountName,
      googleLocationId,
      googleLocationName,
    })
  );
  return {
    error: null,
    message: t('authorizationSuccessful'),
  };
};

export const handleSignInGoogle = () => async (dispatch) => {
  let response;
  try {
    response = await handleGoogleSignIn();
    dispatch(fetchGoogleAccountStatus());
  } catch (error) {
    // TODO: login fail alert
    console.log('error', error);
  }

  console.log('response ', response);
};

export const handleSignOutGoogle = () => async (dispatch) => {
  console.log('handle sign out');
  try {
    await handleGoogleSignOut();
    dispatch(fetchGoogleAccountStatus());
  } catch (error) {
    // TODO: logout fail alert
    console.warn('error', error);
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_LOGOUT:
      return defaultState;
    case UPDATE_ACCOUNT:
      return {
        ...state,
        ...action.payload,
        features: {
          ...state.features,
          ...action.payload.user.features,
        },
        google: {
          ...state.google,
          accountId: action.payload.user.googleAccountId,
          locationId: action.payload.user.googleLocationId,
        },
      };
    case UPDATE_GOOGLE_ACCOUNT_TOKEN: {
      const { googleAccountAccessToken, googleAccountName } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          googleAccountAccessToken,
          googleAccountName,
        },
      };
    }
    case UPDATE_GOOGLE_ACCOUNT_INFO: {
      const { googleAccountId, googleAccountName } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          googleAccountId,
          googleAccountName,
        },
        google: {
          ...state.google,
          accountId: googleAccountId,
        },
      };
    }
    case SET_FB_MESSAGE_UNREAD_NUMBER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_FB_SCOPES: {
      return {
        ...state,
        facebook: {
          ...state.facebook,
          scopes: action.payload,
        },
      };
    }
    // for storing login state
    case UPDATE_GOOGLE: {
      return {
        ...state,
        google: {
          ...state.google,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
