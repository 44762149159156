import React from 'react';
import PropTypes from 'prop-types';
import { css, ThemeProvider } from 'styled-components';

const colors = {
  PRIMARY_800: '#13247c',
  PRIMARY_900: '#0b1867',
  PRIMARY_300: '#8aa8f3',
  PRIMARY_200: '#b3c9fb',
  PRIMARY_700: '#1e359a',
  PRIMARY_100: '#d8e4fd',
  SECONDARY_900: '#2f0f7a',
  PRIMARY_600: '#2c49b8',
  PRIMARY_500: '#3d61d7',
  PRIMARY_400: '#6a8ae7',
  SECONDARY_600: '#833bdb',
  SECONDARY_800: '#441993',
  SECONDARY_700: '#6128b7',
  SECONDARY_500: '#a851ff',
  SECONDARY_400: '#c47cff',
  SECONDARY_300: '#d596ff',
  SECONDARY_200: '#e6b9ff',
  SHADES_900: '#11152a',
  SECONDARY_100: '#e6b9ff',
  SHADES_800: '#1b1f3b',
  SHADES_700: '#363f55',
  SHADES_600: '#4c5469',
  SHADES_500: '#6f788b',
  SHADES_400: '#9ea7b8',
  SHADES_300: '#cfd5e1',
  SHADES_200: '#e2e7f0',
  SHADES_100: '#f1f4f9',
  SHADES_50: '#f8fafc',
  SHADES_000: '#ffffff',
  SYSTEM_ERROR_900: '#ea3b3b',
  SYSTEM_ERROR_500: '#f76d6d',
  SYSTEM_ERROR_100: '#fef0f0',
  SYSTEM_WARNING_900: '#f0af23',
  SYSTEM_WARNING_500: '#ffd86f',
  SYSTEM_WARNING_100: '#fffbf1',
  SYSTEM_SUCCESS_900: '#27c281',
  SYSTEM_SUCCESS_500: '#61e9b0',
  SYSTEM_SUCCESS_100: '#effdf7',
};

export const dental = {
  common: { primary: '#3D61D7', secondary: '#F0454E' },
  colors: {
    primary: '#3D61D7',
    secondary: '#F0454E',
    textPrimary: '#29275F',
    textSecondary: '#929CB7',
    success: '#43D092',
    info: '#6294FF',
    error: '#D32F2F',
    warning: '#F9BE04',
    default: '#929CB7',
    white: '#FFFFFF',
    disabled: '#CCD4E6',
  },
  background: {
    white: '#fff',
    default: '#F7F8FA',
  },
  boxBorder: {
    default: '1px solid #CCD4E6',
    active: '1px solid #929CB7',
  },
  logos: {
    mainLogo: '../assets/牙醫logo.jpg',
    banner: '../assets/牙醫banner.jpg',
  },
  button: {
    primary: {
      background: '#3D61D7',
      border: '0px solid #0',
      hovercolor: '#000',
      hoveropacity: '25%',
      active: '2px solid #3D61D7',
      opacity: '25%',
      disabled: '#CCD4E6',
    },
    secondary: {
      background: '#fff',
      border: '2px solid #3D61D7',
      hover: '#000',
      hoveropacity: '25%',
      active: '2px solid #3D61D7',
      opacity: '25%',
      disabled: '2px solid #CCD4E6',
    },
  },
  list: {
    default: '#ffffff',
    hover: '#E4EBF9',
  },
  icon: {
    default: '#929CB7',
    disabled: '#CCD4E6',
  },
};

export const western = {
  common: { primary: '#006DB1', secondary: '#F7C767' },
  colors: {
    primary: '#006DB1',
    secondary: '#F7C767',
    textPrimary: '#202D31',
    textSecondary: '#7A8E99',
    success: '#43D092',
    info: '#6294FF',
    error: '#F76163',
    warning: '#F9BE04',
    default: '#7A8E99',
    white: '#FFFFFF',
    disabled: '#BAD3E3',
    grey: '#c2cfd6',
  },
  background: {
    white: '#fff',
    default: '#F7F8FA',
  },
  boxBorder: {
    default: '1px solid #BAD3E3',
    active: '1px solid #7A8E99',
  },
  logos: {
    mainLogo: '../assets/西醫logo.jpg',
    banner: '../assets/西醫banner.jpg',
  },
  button: {
    primary: {
      background: '#006DB1',
      border: '0px solid #0',
      hover: '#000',
      hoveropacity: '25%',
      active: '2px solid #006DB1',
      opacity: '25%',
      disabled: '#BAD3E3',
    },
    secondary: {
      background: '#fff',
      border: '2px solid #006DB1',
      hover: '#000',
      hoveropacity: '25%',
      active: '2px solid #006DB1',
      opacity: '25%',
      disabled: '2px solid #BAD3E3',
    },
  },
  list: {
    default: '#ffffff',
    hover: '#D1E9F8',
  },
  icon: {
    default: '#7A8E99',
    disabled: '#BAD3E3',
  },
};

export const dashboard_v2 = {
  colors: {
    PRIMARY_100: '#c8f3fb',
    PRIMARY_200: '#94e3f7',
    PRIMARY_300: '#5cc2e7',
    PRIMARY_400: '#349dd0',
    PRIMARY_500: '#006db1',
    PRIMARY_600: '#005498',
    PRIMARY_700: '#003f7f',
    PRIMARY_800: '#002c66',
    PRIMARY_900: '#001f54',
    SECONDARY_100: '#fef8e0',
    SECONDARY_200: '#fef0c2',
    SECONDARY_300: '#fce5a4',
    SECONDARY_400: '#fad98c',
    SECONDARY_500: '#f7c767',
    SECONDARY_600: '#d4a24b',
    SECONDARY_700: '#b17f33',
    SECONDARY_800: '#8f5f20',
    SECONDARY_900: '#764813',
    SHADES_000: '#ffffff',
    SHADES_50: '#f8fafc',
    SHADES_100: '#f1f5f9',
    SHADES_200: '#e2e8f0',
    SHADES_300: '#cbd5e1',
    SHADES_400: '#94a3b8',
    SHADES_500: '#64748b',
    SHADES_600: '#475569',
    SHADES_700: '#334155',
    SHADES_800: '#1e293b',
    SHADES_900: '#0f172a',
    SYSTEM_ERROR_100: '#fce7cf',
    SYSTEM_ERROR_200: '#fac9a1',
    SYSTEM_ERROR_300: '#f2a270',
    SYSTEM_ERROR_400: '#e67b4c',
    SYSTEM_ERROR_500: '#d64417',
    SYSTEM_ERROR_600: '#b82c10',
    SYSTEM_ERROR_700: '#9a180b',
    SYSTEM_ERROR_800: '#7c0907',
    SYSTEM_ERROR_900: '#660409',
    SYSTEM_WARNING_100: '#fefbcc',
    SYSTEM_WARNING_200: '#fef799',
    SYSTEM_WARNING_300: '#fdf166',
    SYSTEM_WARNING_400: '#fbeb40',
    SYSTEM_WARNING_500: '#f9e102',
    SYSTEM_WARNING_600: '#d6bf01',
    SYSTEM_WARNING_700: '#b39e01',
    SYSTEM_WARNING_800: '#907e00',
    SYSTEM_WARNING_900: '#776700',
    SYSTEM_SUCCESS_100: '#ecfdd7',
    SYSTEM_SUCCESS_200: '#d3fbb0',
    SYSTEM_SUCCESS_300: '#b3f587',
    SYSTEM_SUCCESS_400: '#93ec67',
    SYSTEM_SUCCESS_500: '#64e038',
    SYSTEM_SUCCESS_600: '#46c028',
    SYSTEM_SUCCESS_700: '#2ca11c',
    SYSTEM_SUCCESS_800: '#178111',
    SYSTEM_SUCCESS_900: '#0a6b0c',
    SYSTEM_INFO_100: '#d2e5fe',
    SYSTEM_INFO_300: '#78aafa',
    SYSTEM_INFO_200: '#a6cafd',
    SYSTEM_INFO_500: '#2162ef',
    SYSTEM_INFO_400: '#568df5',
    SYSTEM_INFO_600: '#184bcd',
    SYSTEM_INFO_700: '#1037ac',
    SYSTEM_INFO_900: '#061a72',
    SYSTEM_INFO_800: '#0a268a',
  },
  fonts: {
    'EN/Heading/Title/Heavy': css`
      font-family: Inter;
      font-size: 69px;
      font-weight: bold;
      line-height: 89.7px;
    `,
    'EN/Heading/Title/Medium': css`
      font-family: Inter;
      font-size: 69px;
      font-weight: bold;
      line-height: 89.7px;
    `,
    'Heading/Title/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 69px;
      font-weight: bold;
      line-height: 89.7px;
      letter-spacing: 1.4px;
    `,
    'Heading/Title/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 69px;
      font-weight: 500;
      line-height: 89.7px;
      letter-spacing: 1.4px;
    `,
    'EN/Heading/Title/Regular': css`
      font-family: Inter;
      font-size: 69px;
      line-height: 89.7px;
    `,
    'Heading/Title/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 69px;
      line-height: 89.7px;
      letter-spacing: 1.4px;
    `,
    'EN/Heading/H1/Heavy': css`
      font-family: Inter;
      font-size: 57px;
      font-weight: bold;
      line-height: 74.1px;
    `,
    'Heading/H1/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 57px;
      font-weight: bold;
      line-height: 74.1px;
      letter-spacing: 1.1px;
    `,
    'EN/Heading/H1/Medium': css`
      font-family: Inter;
      font-size: 57px;
      font-weight: 500;
      line-height: 74.1px;
    `,
    'Heading/H1/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 57px;
      font-weight: 500;
      line-height: 74.1px;
      letter-spacing: 1.1px;
    `,
    'EN/Heading/H1/Regular': css`
      font-family: Inter;
      font-size: 57px;
      line-height: 74.1px;
    `,
    'Heading/H1/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 57px;
      line-height: 74.1px;
      letter-spacing: 1.1px;
    `,
    'EN/Heading/H2/Heavy': css`
      font-family: Inter;
      font-size: 48px;
      font-weight: bold;
      line-height: 62.4px;
    `,
    'Heading/H2/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 48px;
      font-weight: bold;
      line-height: 62.4px;
      letter-spacing: 1px;
    `,
    'EN/Heading/H2/Medium': css`
      font-family: Inter;
      font-size: 48px;
      font-weight: 500;
      line-height: 62.4px;
    `,
    'Heading/H2/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 48px;
      font-weight: 500;
      line-height: 62.4px;
      letter-spacing: 1px;
    `,
    'EN/Heading/H2/Regular': css`
      font-family: Inter;
      font-size: 48px;
      line-height: 62.4px;
    `,
    'Heading/H2/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 48px;
      line-height: 62.4px;
      letter-spacing: 1px;
    `,
    'EN/Heading/H3/Heavy': css`
      font-family: Inter;
      font-size: 40px;
      font-weight: bold;
      line-height: 52px;
    `,
    'Heading/H3/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 40px;
      font-weight: bold;
      line-height: 52px;
      letter-spacing: 0.8px;
    `,
    'EN/Heading/H3/Medium': css`
      font-family: Inter;
      font-size: 40px;
      font-weight: 500;
      line-height: 52px;
    `,
    'Heading/H3/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 40px;
      font-weight: 500;
      line-height: 52px;
      letter-spacing: 0.8px;
    `,
    'EN/Heading/H3/Regular': css`
      font-family: Inter;
      font-size: 40px;
      line-height: 52px;
    `,
    'Heading/H3/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 40px;
      line-height: 52px;
      letter-spacing: 0.8px;
    `,
    'EN/Heading/H4/Heavy': css`
      font-family: Inter;
      font-size: 33px;
      font-weight: bold;
      line-height: 42.9px;
    `,
    'Heading/H4/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 33px;
      font-weight: bold;
      line-height: 42.9px;
      letter-spacing: 0.7px;
    `,
    'EN/Heading/H4/Medium': css`
      font-family: Inter;
      font-size: 33px;
      font-weight: 500;
      line-height: 42.9px;
    `,
    'Heading/H4/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 33px;
      font-weight: 500;
      line-height: 42.9px;
      letter-spacing: 0.7px;
    `,
    'EN/Heading/H4/Regular': css`
      font-family: Inter;
      font-size: 33px;
      line-height: 42.9px;
    `,
    'Heading/H4/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 33px;
      line-height: 42.9px;
      letter-spacing: 0.7px;
    `,
    'EN/Heading/H5/Heavy': css`
      font-family: Inter;
      font-size: 28px;
      font-weight: bold;
      line-height: 36.4px;
    `,
    'Heading/H5/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 28px;
      font-weight: bold;
      line-height: 36.4px;
      letter-spacing: 0.6px;
    `,
    'EN/Heading/H5/Medium': css`
      font-family: Inter;
      font-size: 28px;
      font-weight: 500;
      line-height: 36.4px;
    `,
    'Heading/H5/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 36.4px;
      letter-spacing: 0.6px;
    `,
    'EN/Heading/H5/Regular': css`
      font-family: Inter;
      font-size: 28px;
      line-height: 36.4px;
    `,
    'Heading/H5/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 28px;
      line-height: 36.4px;
      letter-spacing: 0.6px;
    `,
    'EN/Heading/H6/Heavy': css`
      font-family: Inter;
      font-size: 23px;
      font-weight: bold;
      line-height: 29.9px;
    `,
    'Heading/H6/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 23px;
      font-weight: bold;
      line-height: 29.9px;
      letter-spacing: 0.5px;
    `,
    'EN/Heading/H6/Medium': css`
      font-family: Inter;
      font-size: 23px;
      font-weight: 500;
      line-height: 29.9px;
    `,
    'Heading/H6/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 29.9px;
      letter-spacing: 0.5px;
    `,
    'EN/Heading/H6/Regular': css`
      font-family: Inter;
      font-size: 23px;
      line-height: 29.9px;
    `,
    'Heading/H6/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 23px;
      line-height: 29.9px;
      letter-spacing: 0.5px;
    `,
    'EN/Body/19/Heavy': css`
      font-family: Inter;
      font-size: 19px;
      font-weight: bold;
      line-height: 28px;
    `,
    'EN/Heading/Large/Heavy': css`
      font-family: Inter;
      font-size: 19px;
      font-weight: bold;
      line-height: 24.7px;
    `,
    'Body/19px_Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 19px;
      font-weight: bold;
      line-height: 28px;
      letter-spacing: 0.4px;
    `,
    'Heading/Large/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 19px;
      font-weight: bold;
      line-height: 24.7px;
      letter-spacing: 0.4px;
    `,
    'EN/Body/19/Medium': css`
      font-family: Inter;
      font-size: 19px;
      font-weight: 500;
      line-height: 28px;
    `,
    'EN/Heading/Large/Medium': css`
      font-family: Inter;
      font-size: 19px;
      font-weight: 500;
      line-height: 24.7px;
    `,
    'Body/19px_Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 19px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.4px;
    `,
    'Heading/Large/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 19px;
      font-weight: 500;
      line-height: 24.7px;
      letter-spacing: 0.4px;
    `,
    'EN/Body/19/Regular': css`
      font-family: Inter;
      font-size: 19px;
      line-height: 28px;
    `,
    'EN/Heading/Large/Regular': css`
      font-family: Inter;
      font-size: 19px;
      line-height: 24.7px;
    `,
    'Body/19px_Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.4px;
    `,
    'Heading/Large/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 19px;
      line-height: 24.7px;
      letter-spacing: 0.4px;
    `,
    'EN/Body/16/Heavy': css`
      font-family: Inter;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    `,
    'EN/Heading/Medium/Heavy': css`
      font-family: Inter;
      font-size: 16px;
      font-weight: bold;
      line-height: 20.8px;
    `,
    'Body/16px_Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 0.3px;
    `,
    'Heading/Medium/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 20.8px;
      letter-spacing: 0.3px;
    `,
    'EN/Body/16/Medium': css`
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    `,
    'EN/Heading/Medium/Medium': css`
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 20.8px;
    `,
    'Body/16px_Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.3px;
    `,
    'Heading/Medium/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20.8px;
      letter-spacing: 0.3px;
    `,
    'EN/Body/16/Regular': css`
      font-family: Inter;
      font-size: 16px;
      line-height: 24px;
    `,
    'EN/Heading/Medium/Regular': css`
      font-family: Inter;
      font-size: 16px;
      line-height: 20.8px;
    `,
    'Body/16px_Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
    `,
    'Heading/Medium/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: 0.3px;
    `,
    'Body/14px_Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 0.3px;
    `,
    'Body/14px_Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.3px;
    `,
    'Body/14px_Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
    `,
    'EN/Body/13/Heavy': css`
      font-family: Inter;
      font-size: 13px;
      font-weight: bold;
      line-height: 24px;
    `,
    'EN/Heading/Small/Heavy': css`
      font-family: Inter;
      font-size: 13px;
      font-weight: bold;
      line-height: 16.9px;
    `,
    'Heading/Small/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 13px;
      font-weight: bold;
      line-height: 16.9px;
      letter-spacing: 0.3px;
    `,
    'EN/Body/13/Medium': css`
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      line-height: 24px;
    `,
    'EN/Heading/Small/Medium': css`
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      line-height: 16.9px;
    `,
    'Heading/Small/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 16.9px;
      letter-spacing: 0.3px;
    `,
    'EN/Body/13/Regular': css`
      font-family: Inter;
      font-size: 13px;
      line-height: 24px;
    `,
    'EN/Heading/Small/Regular': css`
      font-family: Inter;
      font-size: 13px;
      line-height: 16.9px;
    `,
    'Heading/Small/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 13px;
      line-height: 16.9px;
      letter-spacing: 0.3px;
    `,
    'Body/12px_Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 20px;
      letter-spacing: 0.2px;
    `,
    'Heading/X-Small/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15.6px;
      letter-spacing: 0.2px;
    `,
    'Body/12px_Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.2px;
    `,
    'Heading/X-Small/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15.6px;
      letter-spacing: 0.2px;
    `,
    'Body/12px_Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
    `,
    'Heading/X-Small/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 12px;
      line-height: 15.6px;
      letter-spacing: 0.2px;
    `,
    'EN/Body/11/Heavy': css`
      font-family: Inter;
      font-size: 11px;
      font-weight: bold;
      line-height: 20px;
    `,
    'EN/Body/11/Medium': css`
      font-family: Inter;
      font-size: 11px;
      font-weight: 500;
      line-height: 20px;
    `,
    'EN/Body/11/Regular': css`
      font-family: Inter;
      font-size: 11px;
      line-height: 20px;
    `,
    'EN/Body/9/Heavy': css`
      font-family: Inter;
      font-size: 9px;
      font-weight: bold;
      line-height: 16px;
    `,
    'Body/9px_Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 9px;
      font-weight: bold;
      line-height: 16px;
      letter-spacing: 0.2px;
    `,
    'Heading/Sub/Heavy': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 9px;
      font-weight: bold;
      line-height: 11.7px;
      letter-spacing: 0.2px;
    `,
    'EN/Body/9/Medium': css`
      font-family: Inter;
      font-size: 9px;
      font-weight: 500;
      line-height: 16px;
    `,
    'Body/9px_Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 9px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
    `,
    'Heading/Sub/Medium': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 9px;
      font-weight: 500;
      line-height: 11.7px;
      letter-spacing: 0.2px;
    `,
    'EN/Body/9/Regular': css`
      font-family: Inter;
      font-size: 9px;
      line-height: 16px;
    `,
    'Body/9px_Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.2px;
    `,
    'Heading/Sub/Regular': css`
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 9px;
      line-height: 11.7px;
      letter-spacing: 0.2px;
    `,
  },
};

export default {
  ...dental,
  colors: {
    textPrimary: '#29275f',
    textSecondary: '#929cb7',
    border: '#ccd4e6',
    borderSecondary: '#929cb7',
    white: '#fff',
    primary: '#3d61d7',
    backgroundColor: '#e4ebf9',
    success: '#43d092',
    success2: '#27c281',
    secondary: '#f0454e',
    backgroundPrimary: 'rgba(61, 97, 215, 0.16)',
    backgroundSecondary: '#d8e4fd',
    backgroundSuccess: '#effdf7',
    ...dental.colors,
    ...colors,
  },
  western,
  dashboard_v2,
};
const Wrapper = ({ children }) => <div>{children}</div>;
// eslint-disable-next-line react/no-typos
Wrapper.propTypes = {
  children: PropTypes.node,
};

export const ThemeProviderComponent = ({ theme, children }) => (
  <ThemeProvider theme={theme}>
    <Wrapper>
      <Wrapper>
        <Wrapper>{children}</Wrapper>
      </Wrapper>
    </Wrapper>
  </ThemeProvider>
);

ThemeProviderComponent.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({}),
};
