import React, { memo, useState, useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import nanoid from 'nanoid';
import Popover from '@material-ui/core/Popover';
import Tag from 'components/Dashboard_v2/Tag';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const PopoverWrapper = styled.div`
  max-width: 360px;
  overflow: auto;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  padding: 8px 16px;
  i.ri-add-circle-line {
    color: ${colors.SHADES_400};
  }
`;

const StyledHr = styled.hr`
  margin: 0;
`;

const SelectedCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  padding: 8px 16px 0 16px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: ${({ selectedWrapper }) => (selectedWrapper ? '0' : '16px')};
  font-size: 12px;
  input {
    flex: 1;
    font-size: 16px;
    border: none;
    min-width: ${({ hasTag }) => (hasTag ? '100px' : '255px')};
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.SHADES_400};
    }
    :-ms-input-placeholder {
      color: ${colors.SHADES_400};
    }
  }
`;

const TagSelectorPopover = ({ isLoading, onClose, memberTags, children }) => {
  const tags = useSelector((state) => state.member.tags);
  const keywordInputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [selectedTags, setSelectedTags] = useState(memberTags);

  const filteredTags = useMemo(() => {
    if (!keyword) {
      return tags;
    }
    return tags.filter((tag) => tag.name.includes(keyword));
  }, [tags, keyword]);

  const handleClose = () => {
    const isTagChanged = JSON.stringify(memberTags) !== JSON.stringify(selectedTags);
    if (isTagChanged) {
      onClose(selectedTags);
    }
    setAnchorEl(null);
  };

  const openPopover = useCallback(
    (e) => {
      if (isLoading) return;
      setAnchorEl(e.currentTarget);
    },
    [isLoading]
  );

  const getSelectedTagIndex = (tag) => selectedTags.findIndex((selectedTag) => selectedTag.name === tag.name);

  const removeTag = (tagIndex) => {
    setSelectedTags((prev) => {
      const newArray = [...prev];
      newArray.splice(tagIndex, 1);
      return newArray;
    });
  };

  const handleSelect = (tag) => {
    const tagIndex = getSelectedTagIndex(tag);
    if (tagIndex === -1) {
      setSelectedTags((prev) => [...prev, tag]);
    } else {
      removeTag(tagIndex);
    }
  };

  const handleRemove = (e, tag) => {
    e.stopPropagation();
    const tagIndex = getSelectedTagIndex(tag);
    if (tagIndex !== -1) {
      removeTag(tagIndex);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div onClick={openPopover}>{children}</div>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
            maxHeight: '300px',
          }}
        >
          <PopoverWrapper>
            <InputContainer>
              <TagsWrapper
                selectedWrapper
                onClick={() => keywordInputRef.current.focus()}
                hasTag={selectedTags.length > 0}
              >
                {selectedTags.map((tag) => (
                  <Tag
                    name={tag.name}
                    onClick={(e) => e.stopPropagation()}
                    onClickRemove={(e) => handleRemove(e, tag)}
                    key={nanoid()}
                  />
                ))}
                <input
                  ref={keywordInputRef}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder={selectedTags.length ? '' : t('searchTagsPlaceholder')}
                  type="text"
                />
              </TagsWrapper>
              <i className="ri-add-circle-line" />
            </InputContainer>
            <StyledHr />
            <SelectedCount>
              {t('searchResults', { info: `${filteredTags.length}/${tags.length}` })}
              <br />
              {t('trackingTag.tagHint')}
            </SelectedCount>
            <TagsWrapper>
              {filteredTags.map((tag) => (
                <Tag
                  name={tag.name}
                  onClick={() => handleSelect(tag)}
                  isSelected={getSelectedTagIndex(tag) !== -1}
                  key={nanoid()}
                />
              ))}
            </TagsWrapper>
          </PopoverWrapper>
        </Popover>
      )}
    </React.Fragment>
  );
};

const propTypes = {
  onClose: PropTypes.func,
  memberTags: PropTypes.array,
};

TagSelectorPopover.propTypes = propTypes;

export default memo(TagSelectorPopover);
