/* eslint-disable no-await-in-loop */
import {
  GOOGLE_MY_BUSINESS_CLIENT_ID,
  GOOGLE_MY_BUSINESS_API_KEY,
  GMB_API_VERSION,
  GMB_BI_API,
  GOOGLE_API_SCOPES,
  GAPI_OAUTH2_VERSION,
} from 'helpers/envHelpers';
import CustomError from 'models/error';
import { arrayChunks } from 'helpers';
import { responseHandler } from './responseHandler';
import { t } from 'i18n/config';

const apiKey = GOOGLE_MY_BUSINESS_API_KEY;
const clientId = GOOGLE_MY_BUSINESS_CLIENT_ID;
const gmb_api_version = GMB_API_VERSION;
const gmb_bi_api = GMB_BI_API;
const gapi_oauth2_version = GAPI_OAUTH2_VERSION;
const scopes = GOOGLE_API_SCOPES;

const googleInsightDataHandler = ({ locationMetrics, metricRequests, clients }) => {
  const metrics = locationMetrics;
  if (metrics == null) {
    throw CustomError({
      error: new Error(t('reauthorizeGoogleAccount')),
      redirect: '/platforms',
    });
  }
  const result = [];
  metrics.forEach((metric) => {
    const locationId = metric.locationName.split('locations/')[1];
    const client = clients.find(({ googleLocationId }) => googleLocationId === locationId) || {};
    const combineData = {
      metricValues: [...Array(metricRequests.length)],
      locationId,
      companyName: client.companyName,
      clientId: client.id,
    }; // eight metrics
    combineData.metricValues = combineData.metricValues.map((_, index) => {
      const valueObj = {};
      valueObj.metric = metric.metricValues[index].metric;
      if (metric.metricValues[index].dimensionalValues) {
        valueObj.dimensionalValues = [];
        metric.metricValues[index].dimensionalValues.forEach((dimensionalValue, j) => {
          if (valueObj.dimensionalValues[j] == null) {
            valueObj.dimensionalValues.push(dimensionalValue);
          } else {
            valueObj.dimensionalValues[j].value += dimensionalValue.value;
          }
        });
      }
      if (metric.metricValues[index].totalValue) {
        valueObj.totalValue = metric.metricValues[index].totalValue;
      }
      return valueObj;
    });
    result.push(combineData);
  });

  return result;
};

export const handleGoogleSignIn = () => gapi.auth2.getAuthInstance().signIn();

export const handleGoogleSignOut = () => gapi.auth2.getAuthInstance().signOut();

export const updateSigninStatus = (isSignedIn) => {
  if (!isSignedIn) {
    console.log('not signed in');
    return null;
  }
  console.log(
    'sign in',
    (gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().expires_at - new Date().getTime()) / 1000
  );
  return gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();
};

export const reloadAuth = async () => {
  const authResponse = await gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
  return authResponse;
};

export const initClient = async () => {
  await gapi.client.init({
    apiKey,
    clientId,
    scope: scopes,
    plugin_name: 'chat',
  });
  gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
  // Handle the initial sign-in state.
  return updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
};

export const getGoogleCurrentUser = () => gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse();

export const getGoogleAccountsApi = async ({ accessToken }) => {
  const response = await fetch(`${gmb_bi_api}/accounts`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(responseHandler);

  return response.accounts || [];
};

export const getGoogleUserInfoByToken = async ({ accessToken }) => {
  const response = await fetch(`${gapi_oauth2_version}/userinfo?access_token=${accessToken}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(responseHandler);
  return response;
};

export const getGoogleLocationApi = async ({ accessToken, accountId, search }) => {
  let response = [];
  let starting = true;
  let query = '';
  try {
    while (starting) {
      const res = await fetch(
        `${gmb_bi_api}/accounts/${accountId}/locations?readMask=title,name,metadata${query}&pageSize=100&filter=title=${search}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ).then(responseHandler);
      if (res.nextPageToken == null) {
        starting = false;
      }
      query = `&pageToken=${res.nextPageToken}`;
      response = response.concat(res.locations || []);
    }
    return response;
  } catch (error) {
    console.error('error = ', error);
    return response;
  }
};

export const getReportInsightsApi = async ({ accessToken, accountId, locationNames, startTime, endTime, clients }) => {
  const metricRequests = [
    {
      metric: 'VIEWS_MAPS',
      options: 'AGGREGATED_DAILY',
    },
    {
      metric: 'VIEWS_SEARCH',
      options: 'AGGREGATED_DAILY',
    },
    {
      metric: 'VIEWS_MAPS',
      options: 'AGGREGATED_TOTAL',
    },
    {
      metric: 'VIEWS_SEARCH',
      options: 'AGGREGATED_TOTAL',
    },
    {
      metric: 'QUERIES_INDIRECT',
      options: 'AGGREGATED_DAILY',
    },
    {
      metric: 'QUERIES_DIRECT',
      options: 'AGGREGATED_DAILY',
    },
    {
      metric: 'QUERIES_INDIRECT',
      options: 'AGGREGATED_TOTAL',
    },
    {
      metric: 'QUERIES_DIRECT',
      options: 'AGGREGATED_TOTAL',
    },
  ];
  // split by 10 elements for google api limitation
  const locationGroupBy10 = arrayChunks(locationNames, 10);
  const promises = locationGroupBy10.map((locationNamesChunk) =>
    fetch(
      `
    ${gmb_api_version}/accounts/${accountId}/locations:reportInsights`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          locationNames: locationNamesChunk,
          basicRequest: {
            metricRequests,
            timeRange: {
              startTime: new Date(startTime).toISOString(),
              endTime: new Date(endTime).toISOString(),
            },
          },
        }),
      }
    )
  );
  const response = Promise.all(promises)
    .then((resultArray) =>
      resultArray.reduce(async (sum, temp = {}) => {
        const singleChunk = await responseHandler(temp);
        const tempSum = await sum;
        if (singleChunk.locationMetrics) {
          tempSum.locationMetrics = tempSum.locationMetrics
            ? tempSum.locationMetrics.concat(singleChunk.locationMetrics)
            : singleChunk.locationMetrics;
        }
        return tempSum;
      }, {})
    )
    .catch(async (error) => {
      const errorResponse = await error;
      if (errorResponse.error.code === 400) {
        throw CustomError({
          error: new Error(t('select18MonthsInterval')),
          redirect: '/analytics',
        });
      }
      throw CustomError({
        error: new Error(t('refreshPage')),
        redirect: '/',
      });
    })
    .then((resJson) => ({
      ...resJson,
      metricRequests,
      clients,
    }))
    .then(googleInsightDataHandler);
  return response;
};
