import { t } from 'i18n/config';

export const NAME_OPTIONS = {
  NAME: t('name'),
  NAME_EN: 'name',
  CUSTOMER_NAME: t('customerName'),
  PATIENT_NAME: t('patientName'),
};

export const NAME_PRIORITY = {
  [NAME_OPTIONS.PATIENT_NAME]: 1,
  [NAME_OPTIONS.CUSTOMER_NAME]: 2,
  [NAME_OPTIONS.NAME]: 3,
  [NAME_OPTIONS.NAME_EN]: 4,
};

export const PHONE_OPTIONS = {
  PHONE: t('phone'),
  CELLPHONE: t('cellphone'),
  MOBILEPHONE: t('mobilePhone'),
  PHONE_A_TEL: 'a_tel',
};

export const PHONE_PRIORITY = {
  [PHONE_OPTIONS.MOBILEPHONE]: 1,
  [PHONE_OPTIONS.CELLPHONE]: 2,
  [PHONE_OPTIONS.PHONE]: 3,
  [PHONE_OPTIONS.PHONE_A_TEL]: 4,
};

export const EMAIL_OPTIONS = {
  EMAIL: t('email'),
  MAIL: t('mailbox'),
  EMAILBOX: t('email'),
  EMAIL_ENG: 'Email',
  EMAIL_EN_UNDERSCORE: 'e_mail',
};

export const EMAIL_PRIORITY = {
  [EMAIL_OPTIONS.EMAIL]: 1,
  [EMAIL_OPTIONS.MAIL]: 2,
  [EMAIL_OPTIONS.EMAILBOX]: 3,
  [EMAIL_OPTIONS.EMAIL_ENG]: 4,
  [EMAIL_OPTIONS.EMAIL_EN_UNDERSCORE]: 5,
};

export const SERVICE_PROVIDER_NAME_OPTIONS = {
  SERVICE_PROVIDER_NAME: t('staff'),
  DOCTOR_NAME: t('attendingPhysician'),
};

export const SERVICE_PROVIDER_NAME_PRIORITY = {
  [SERVICE_PROVIDER_NAME_OPTIONS.SERVICE_PROVIDER_NAME]: 1,
  [SERVICE_PROVIDER_NAME_OPTIONS.DOCTOR_NAME]: 2,
};

export const NOTE_OPTIONS = {
  NOTE: t('remarks'),
};

export const NOTE_PRIORITY = {
  [NOTE_OPTIONS.NOTE]: 1,
};

export const CSV_ATTRIBUTE = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  NOTE: 'note',
  SERVICE_PROVIDER_NAME: 'serviceProviderName',
};

export const FB_SCOPES = {
  MANAGE_PAGES: 'manage_pages',
  PAGES_SHOW_LIST: 'pages_show_list',
  PUBLISH_PAGES: 'publish_pages',
  PUBLIC_PROFILE: 'public_profile',
  PAGES_MANAGE_ENGAGEMENT: 'pages_manage_engagement',
};

export const OPERATION_TYPE = {
  ADMIN: 'admin',
  OPERATION: 'operation',
};

export const LOCALES = {
  ZH_TW: 'zh-TW',
  ZH_HK: 'zh-HK',
  ZH_CN: 'zh-CN',
  EN: 'en',
  JA: 'ja',
  OTHER: 'other',
};

export const AUTH_TYPE = {
  ADMIN: 'admin',
  SALES: 'sales',
  STORE: 'store',
  BRAND: 'brand',
};

export const VENDORS = {
  ALLEYPIN: 'alleypin',
  OPERA: 'opera',
  COOPER: 'cooper',
};

export const VERSION = {
  PROFESSIONAL: 'professional',
  CLASSIC: 'classic',
};

export const attributeMap = {
  age: t('age'),
  gender: t('gender'),
  tag: t('interestTags'),
  customTag: t('customTags'),
  eq: t('equalTo'),
  lt: t('lessThan'),
  gt: t('greaterThan'),
  female: t('female'),
  male: t('male'),
  both: t('notSpecified'),
  immediate: t('sendImmediately'),
  later: t('sendAtSpecifiedTime'),
  text: t('text'),
  card: t('card'),
  teethWhite: t('teethWhitening'),
  teethJiauJian: t('orthodontics'),
  teethJIUYA: t('dentalImplants'),
  custom1: 'VIP',
  custom2: '陳醫師媽媽',
  custom3: '放鳥很多次',
};
