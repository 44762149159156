/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { VendorCategory } from 'utils/vendors';
import moment from 'moment';
import { t } from 'i18n/config';

const getTags = (state, props) => {
  const {
    tags: { tagsByID },
    vendor,
  } = state.clients.byId[props.clientId] || { tags: { tagsByID: {} } };
  const customTags = {
    id: '2',
    title: t('customTags'),
    type: 'tags',
    valueType: 'text',
    values: [],
  };
  const defaultTags = {
    id: '1',
    title: t('interestTags'),
    type: 'tags',
    valueType: 'text',
    values: [],
  };
  Object.keys(tagsByID).forEach((id) => {
    const tag = tagsByID[id];
    if (tag.type === 'default') {
      defaultTags.values.push({
        ...tag,
        title: tag.name,
      });
    }
    if (tag.type === 'custom') {
      customTags.values.push({
        ...tag,
        title: tag.name,
      });
    }
  });
  const result = [];
  if (VendorCategory[vendor] === 'western') {
    if (props.tagsOverride != null) {
      result.push(...props.tagsOverride);
    }
    return result;
  }
  if (props.tagsOverride != null) {
    result.push(...props.tagsOverride);
  }
  if (defaultTags.values.length !== 0) {
    result.push(defaultTags);
  }
  if (customTags.values.length !== 0) {
    result.push(customTags);
  }
  return result;
};

export const getOptions = createSelector([getTags], (list) => list);

export const appointemntsTag = ({ selectDate }) => ({
  id: '3',
  title: t('appointmentDate'),
  type: 'appointments',
  valueType: 'date',
  values: [
    {
      id: 'bf2dd275-4f84-441c-9662-5fee9cebfa6d3',
      name: moment(selectDate).format('YYYY/MM/DD'),
      type: 'appointments',
      img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E7%89%99%E9%BD%92%E7%9F%AF%E6%AD%A3.png',
      title: moment(selectDate).format('YYYY/MM/DD'),
    },
  ],
});
