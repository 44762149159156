import { getCampaign, listUserNotifications } from 'lib/campaignService';
import { useEffect, useState } from 'react';

export const useCampaign = ({ campaignID, clientID }) => {
  const [campaign, setCampaign] = useState(null);
  useEffect(() => {
    if (!campaign) {
      getCampaign({ clientID, campaignID }).then((res) => {
        setCampaign(res);
      });
    }
  }, [campaign]); // eslint-disable-line react-hooks/exhaustive-deps
  return campaign;
};

export const useNotifications = () => {
  const [notifications, setNotifications] = useState(null);
  const listNotifications = async ({ campaignID, clientID, status, startTime, endTime }) => {
    setNotifications(null);
    const res = await listUserNotifications({
      clientID,
      campaignID,
      status,
      startTime,
      endTime,
    });
    setNotifications(res);
  };

  return [notifications, listNotifications];
};
