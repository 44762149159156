import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0px;
  min-height: calc(100vh - 55px);
  height: 100%;
  width: 56px;
  padding-top: 60px;
  background-color: ${colors.SHADES_000};
  border-right: 1px solid ${colors.SHADES_200};
`;

const ItemLink = styled(Link)`
  color: ${({ selected }) => (selected ? colors.PRIMARY_500 : colors.SHADES_400)};
  font-size: 20px;
  margin-bottom: 28px;
`;

const sidebarItems = [
  { link: '/message_center/group_message', icon: <i className="ri-mail-send-line" /> },
  { link: '/message_center/patient_tracking', icon: <i className="ri-organization-chart" /> },
  { link: '/message_center/templates', icon: <i className="ri-archive-line" /> },
];

const Sidebar = () => {
  const [currentPath, setCurrentPath] = useState(null);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <Wrapper>
      {sidebarItems.map((item, index) => (
        <ItemLink selected={item.link === currentPath} to={item.link} key={index}>
          {item.icon}
        </ItemLink>
      ))}
    </Wrapper>
  );
};

export default memo(Sidebar);
