import { t } from 'i18n/config';

export default {
  items: [
    {
      name: t('dashboard'),
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: t('memberManagement'),
      url: '/members',
      icon: 'icon-drop',
      feature: 'userManagement',
    },
    {
      name: t('messageCenter'),
      url: '/message_center/group_message',
      icon: 'icon-drop',
      feature: 'userManagement',
      badge: {
        variant: 'primary',
        text: 'BETA',
      },
    },
    {
      name: t('ratingDispatch'),
      url: '/customers',
      icon: 'icon-drop',
      popover: t('originalCustomerManagement'),
    },
    {
      name: t('appointmentCenter'),
      category: 'dental',
      url: '/appointments',
      icon: 'icon-drop',
    },
    {
      name: t('registrationCenter'),
      category: 'western',
      url: '/westernappointments',
      icon: 'icon-drop',
    },
    {
      name: t('lineSendMessage'),
      url: '/linemessage',
      icon: 'icon-drop',
    },
    {
      name: t('sendMessage'),
      category: 'dental',
      url: '/message',
      icon: 'icon-drop',
    },
    {
      name: t('onlineConsultation'),
      url: '/chat',
      icon: 'icon-drop',
      badge: {
        variant: 'info',
        text: 'NEW',
      },
    },
    {
      name: t('reportDownload'),
      url: '/report',
      icon: 'icon-drop',
    },
    {
      name: t('respondToCustomers'),
      url: '/response',
      icon: 'icon-drop',
    },
    {
      name: t('mobileVerification'),
      feature: 'passcode',
      url: '/passcode',
      icon: 'icon-speedometer',
    },
    {
      name: t('ratingSetting'),
      url: '/reviewSetting',
      icon: 'icon-speedometer',
    },
    {
      // TODO: decides if move to nav reducer
      name: t('platformAuthorization'),
      url: '/platforms',
      icon: 'icon-speedometer',
    },
    {
      name: t('logout'),
      url: '/login',
      icon: 'icon-logout',
    },
  ],
};

export const demoNav = {
  items: [
    {
      name: t('dashboard'),
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: t('memberManagement'),
      url: '/members',
      icon: 'icon-drop',
      feature: 'userManagement',
    },
    {
      name: t('messageCenter'),
      url: '/message_center/patient_tracking',
      icon: 'icon-drop',
      feature: 'userManagement',
    },
    {
      name: t('ratingDispatch'),
      url: '/customers',
      icon: 'icon-drop',
    },
    {
      name: t('appointmentCenter'),
      category: 'dental',
      url: '/appointments',
      icon: 'icon-drop',
    },
    {
      name: t('registrationCenter'),
      category: 'western',
      url: '/westernappointments',
      icon: 'icon-drop',
    },
    {
      name: t('lineSendMessage'),
      url: '/message',
      icon: 'icon-drop',
    },
    {
      name: t('onlineConsultation'),
      url: '/chat',
      icon: 'icon-drop',
      badge: {
        variant: 'info',
        text: 'NEW',
      },
    },
    {
      name: t('reportDownload'),
      url: '/report',
      icon: 'icon-drop',
    },
    {
      name: t('respondToCustomers'),
      url: '/response',
      icon: 'icon-drop',
    },
    {
      name: t('mobileVerification'),
      feature: 'passcode',
      url: '/passcode',
      icon: 'icon-speedometer',
    },
    {
      // TODO: decides if move to nav reducer
      name: t('platformAuthorization'),
      url: '/platforms',
      icon: 'icon-speedometer',
    },
    {
      name: t('logout'),
      url: '/login',
      icon: 'icon-logout',
    },
  ],
};
