import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../theme';

const CheckIcon = styled.i`
  font-size: 24px;
  margin-right: 10px;
`;

const CloseIcon = styled.div`
  font-size: 24px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  snackbar: {
    top: '88px',
  },
  snackbarContent: (props) => ({
    backgroundColor: props.status || 'red',
    width: 320,
  }),
  snackbarBehavior: {
    vertical: 'top',
  },
  alert: {
    width: '500px',
  },
}));

const iconClass = (status) => {
  switch (status) {
    case 'success':
      return 'ri-checkbox-circle-fill';
    case 'error':
      return 'ri-information-fill';
    default:
      return 'ri-checkbox-circle-fill';
  }
};

function CustomSnackbar({ open, handleClose, message, status, autoHideDuration }) {
  const classes = useStyles({ status: theme.colors[status] || theme.colors.success });
  if (!open) {
    return null;
  }
  return (
    <Snackbar
      className={classes.snackbar}
      ContentProps={{
        classes: {
          root: classes.snackbarContent,
        },
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration || 2000}
      open={open}
      onClose={handleClose}
      message={
        <MessageContainer>
          <CheckIcon className={iconClass(status)} />
          {message || 'this is a message'}
        </MessageContainer>
      }
      action={<CloseIcon onClick={handleClose} className="ri-close-fill" />}
      key="top-center"
    />
  );
}

CustomSnackbar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  status: PropTypes.string,
  autoHideDuration: PropTypes.number,
};

export default CustomSnackbar;
