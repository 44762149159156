import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { setProfile } from 'reducers/members';
import { useSelector, useDispatch } from 'react-redux';
import {
  RenderCheckboxHeader,
  RenderCheckboxCell,
  RenderLoadingCell,
} from 'components/Table/Columns/IndeterminateCheckbox';
import TagCell from './TagCell';
import PatientTrackingCell from 'components/Dashboard_v2/PatientTrackingSelectorCell';
import InfoHead from './InfoHead';
import { dashboard_v2 } from 'theme';
import LineMessageActiveIcon from 'components/icons/LineMessageActiveIcon';
import LineMessageInactiveIcon from 'components/icons/LineMessageInactiveIcon';
import LineMessageBlockedIcon from 'components/icons/LineMessageBlockedIcon';
import LineNameIcon from 'components/icons/LineNameIcon';
import LinkIcon from 'components/icons/LinkIcon';
import UnlinkIcon from 'components/icons/UnlinkIcon';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg,
  i {
    margin-left: 12px;
  }
  svg {
    min-width: 20px;
  }
`;

const Icon = styled.i`
  font-size: 18px;
  line-height: 1;
  color: ${({ color }) => color || colors.SHADES_400};
`;

const NameLink = styled.div`
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: ${colors.PRIMARY_600};
    /* text-decoration: underline; */
    text-decoration-color: ${colors.PRIMARY_600};
    text-underline-offset: 2px;
  }
`;

export const useColumns = () => {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.auth.features);
  const profile = useSelector((state) => state.member.profile);
  const { patientIdType } = features;
  const [columns, setColumns] = useState();

  useEffect(() => {
    if (!patientIdType) return;

    setColumns([
      {
        id: 'selection',
        Header: RenderCheckboxHeader,
        headDataName: 'selection',
        Cell: RenderCheckboxCell,
        Loading: RenderLoadingCell,
        maxWidth: 50,
        disableSortBy: true,
      },
      {
        Header: <InfoHead accessor="name" />,
        headDataName: t('name'),
        accessor: 'name',
        Cell: ({ row, updateMyData }) => {
          const { original } = row;
          const { id, name, lineName, hisName } = original;
          const currentMemberProfile = profile?.row?.original;
          if (currentMemberProfile?.id === id && JSON.stringify(currentMemberProfile) !== JSON.stringify(original)) {
            setTimeout(() => {
              dispatch(setProfile({ row, updateMyData }));
            });
          }
          return (
            <NameLink onClick={() => dispatch(setProfile({ row, updateMyData }))}>
              {name ? (
                name
              ) : hisName ? (
                <IconWrapper>
                  {hisName} <Icon className="ri-health-book-line" />
                </IconWrapper>
              ) : lineName ? (
                <IconWrapper>
                  {lineName} <LineNameIcon />
                </IconWrapper>
              ) : (
                t('mrMs')
              )}
            </NameLink>
          );
        },
        minWidth: 120,
        maxWidth: 120,
        settable: true,
      },
      {
        Header: () => <InfoHead accessor="lineName" />,
        headDataName: 'LINE',
        accessor: 'lineName',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        defaultHide: true,
      },
      {
        Header: () => <InfoHead accessor="hisName" />,
        headDataName: 'HIS',
        accessor: 'hisName',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        defaultHide: true,
      },
      {
        Header: <InfoHead accessor="lineBinding" />,
        headDataName: t('bindingStatus'),
        accessor: 'lineBinding',
        Cell: ({ value }) => {
          switch (value) {
            case 'binding':
              return (
                <IconWrapper center>
                  <LinkIcon tooltip />
                </IconWrapper>
              );
            case 'unbinding':
            default:
              return (
                <IconWrapper center>
                  <UnlinkIcon tooltip />
                </IconWrapper>
              );
          }
        },
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        disableSortBy: true,
      },
      {
        Header: <InfoHead accessor="lineMessage" />,
        headDataName: t('sendingChannel'),
        accessor: 'lineMessage',
        Cell: ({ value }) => {
          switch (value) {
            case 'active':
              return (
                <IconWrapper>
                  <LineMessageActiveIcon tooltip />
                </IconWrapper>
              );
            case 'blocked':
              return (
                <IconWrapper>
                  <LineMessageBlockedIcon tooltip />
                </IconWrapper>
              );
            case 'inactive':
            default:
              return (
                <IconWrapper>
                  <LineMessageInactiveIcon tooltip />
                </IconWrapper>
              );
          }
        },
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        disableSortBy: true,
      },
      {
        Header: t('mobile'),
        accessor: 'phone',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
      },
      {
        Header: t('birthDate'),
        accessor: 'birthday',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
      },
      {
        Header: t('idOrResidentNumber'),
        accessor: 'twID',
        minWidth: 160,
        maxWidth: 160,
        settable: true,
        defaultHide: 'twID' !== patientIdType,
      },
      {
        Header: t('medicalRecordNumber'),
        accessor: 'hisID',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        defaultHide: 'hisID' !== patientIdType,
      },
      {
        Header: t('memberNumber'),
        accessor: 'memberID',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        defaultHide: 'memberID' !== patientIdType,
      },
      {
        Header: t('passportNumber'),
        accessor: 'pdID',
        minWidth: 120,
        maxWidth: 120,
        settable: true,
        defaultHide: 'pdID' !== patientIdType,
      },
      {
        Header: t('tags'),
        accessor: 'tags',
        Cell: TagCell,
        minWidth: 200,
        settable: true,
        disableSortBy: true,
      },
      {
        Header: () => <InfoHead accessor="patientTrackings" />,
        headDataName: t('patientTracking'),
        accessor: 'patientTrackings',
        Cell: PatientTrackingCell,
        minWidth: 200,
        settable: true,
        disableSortBy: true,
      },
    ]);
  }, [patientIdType, profile?.row?.original, dispatch]);

  return columns
    ? () => [
        {
          Header: 'Name',
          columns,
        },
      ]
    : null;
};

export const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};
