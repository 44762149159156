import googleInsightDemoData from 'seed/googleInsightDemoData.json';
import customerDemoData from 'seed/customers.json';
import appointmentsDemoData from 'seed/appointments.json';
import { getReportInsightsApi } from 'lib/gapi';
import {
  downloadReviewXlsx,
  fetchReportDataApi,
  getMonthlyReportIframeUrlPath,
  fetchReportCSVApi,
  fetchFeedbackCSVApi,
  fetchCustomersInQueApi,
} from 'lib/customerService';
import { updateBlackListApi } from 'lib/clientService';
import { fetchFBCommentsRepliedApi, feedbackAnalyticsApi } from 'lib/reviews';
import {
  createMessageApi,
  createGroupMessageApi,
  fetchLineUsersByTagsApi,
  uploadImageApi,
  fetchMessageListApi,
  fetchTagListInClientApi,
  fetchPnpConsumptionApi,
  fetchPnpAppointmentDeliverySuccessListApi,
} from 'lib/lineService';
import { fetchAppointmentsApi, appendCustomTagApi, updateReminderApi } from 'lib/appointmentService';
import { currentAndLastMonthDigit } from 'reducers/reviews';
import { FEEDBACK_LABEL } from 'helpers/reviewHelpers';
import moment from 'moment';
import { t } from 'i18n/config';

const demoDataFormat = (startTime, endTime, clients) => {
  const daysDiff = moment(endTime).diff(moment(startTime), 'days') + 1;
  const { metricValues } = googleInsightDemoData[0];
  const newMetricValues = [];
  for (let i = 0; i < metricValues.length; i++) {
    const metricValue = { ...metricValues[i] };
    if (metricValue.dimensionalValues != null) {
      const dimensionLength = metricValue.dimensionalValues.length;
      const slicedDimensionalValues =
        daysDiff > dimensionLength
          ? Array(daysDiff - dimensionLength)
              .fill(0)
              .map(() => ({
                metricOption: 'AGGREGATED_DAILY',
                timeDimension: {
                  timeRange: {
                    startTime: '2018-07-01T16:00:00Z',
                  },
                },
                value: '0',
              }))
              .concat(metricValue.dimensionalValues)
          : metricValue.dimensionalValues.slice(dimensionLength - daysDiff, dimensionLength);
      const newDimensionalValues = [];
      for (let j = 0; j < slicedDimensionalValues.length; j++) {
        const dimensionalValue = slicedDimensionalValues[j];
        dimensionalValue.timeDimension.timeRange.startTime = new Date(moment(startTime).add(j, 'days'));
        newDimensionalValues.push(dimensionalValue);
      }
      metricValue.dimensionalValues = newDimensionalValues;
    } else if (metricValue.totalValue != null) {
      metricValue.totalValue.value *=
        daysDiff /
        moment(metricValue.totalValue.timeDimension.timeRange.endTime).diff(
          moment(metricValue.totalValue.timeDimension.timeRange.startTime),
          'days'
        );
    }
    newMetricValues.push(metricValue);
  }
  return clients.map((client) => ({
    metricValues: newMetricValues,
    locationId: '6517734693863142839',
    companyName: client.companyName,
    clientId: client.id,
  }));
};

export const getReportInsightsApiDemo = ({ endTime, startTime, clients }) =>
  demoDataFormat(startTime, endTime, clients);

export const fetchFBCommentsRepliedApiDemo = () => '';

export const currentAndLastMonthDigitDemo = () => ({
  currentMonthReviewNumber: 7,
  lastMonthReviewNumber: 21,
  lastMonthSMSNumber: 30,
  currentSMSNumber: 10,
});

export const feedbackAnalyticsApiDemo = (clientId) => ({
  clientId,
  feedbackAnalytics: {
    [FEEDBACK_LABEL.PUSH]: 60,
    [FEEDBACK_LABEL.WAITING]: 10,
    [FEEDBACK_LABEL.DOCTOR]: 30,
    [FEEDBACK_LABEL.SERVICE]: 90,
    [FEEDBACK_LABEL.ENVIRONMENT]: 10,
  },
});

export const fetchCustomersInQueApiDemo = () => customerDemoData;

export const fetchAppointmentsApiDemo = () => appointmentsDemoData;

export const updateBlackListApiDemo = ({ blackList }) => blackList;

export const appendCustomTagApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({ status: 'success' });
    }, 1000);
    // reject(new Error('update failed'));
  });

export const updateReminderApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({ status: 'success' });
    }, 1000);
    // reject(new Error('update failed'));
  });

export const fetchLineUsersByTagsApiDemo = ({ tags = [] }) =>
  new Promise((resolve) => {
    setTimeout(() => {
      const totalResponse = [
        {
          id: 'U1d2bfd8b892cfdf686ec0cce25a17f12',
          phone: '0920377233',
          name: t('debra'),
          status: 1,
        },
        {
          id: 'U30fb0f6e38f7378459a467cac2a2da5e',
          phone: '0934455022',
          name: t('zhouYiXuan'),
          status: 1,
        },
        {
          id: 'Udb48ca5569a0950634d4a60ca5b11756',
          phone: '0988518155',
          name: t('youShun'),
          status: 1,
        },
      ];

      let response = totalResponse;
      if (tags.length > 0) {
        response = totalResponse.filter(({ id }) => id === 'U30fb0f6e38f7378459a467cac2a2da5e');
      }
      resolve(response);
    }, 1000);
    // reject(new Error('update failed'));
  });

export const fetchMessageListApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      const response = {
        messages: [
          {
            id: 'aaa',
            type: t('textPlusImage'),
            title: t('lingLingTyphoonClinicClosedNotification'),
            deliveryTime: new Date().getTime(),
            expectedDeliveryNumbers: 488,
          },
          {
            id: 'bbb',
            type: t('text'),
            title: t('midAutumnFestivalGreetings'),
            deliveryTime: new Date().getTime(),
            expectedDeliveryNumbers: 503,
          },
        ],
      };
      resolve(response.messages);
    }, 1000);
  });

export const fetchTagListInClientApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      const response = [
        {
          id: 'bf2dd275-4f84-441c-9662-5fee9cebfa6d',
          name: t('orthodontics'),
          type: 'default',
          img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E7%89%99%E9%BD%92%E7%9F%AF%E6%AD%A3.png',
        },
        {
          id: '08424533-743b-4d48-ba8f-70690b9a94bf',
          name: t('maintainingDentalHealth'),
          type: 'default',
          img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E7%B6%AD%E6%8C%81%E7%89%99%E9%BD%92%E5%81%A5%E5%BA%B7.png',
        },
        {
          id: '023f9ab7-1ea9-4004-934a-ac85d116c9af',
          name: t('preventionAndTreatmentOfPeriodontalDisease'),
          type: 'default',
          img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E7%89%99%E5%91%A8%E7%97%85%E9%A0%90%E9%98%B2%E8%88%87%E6%B2%BB%E7%99%82.png',
        },
        {
          id: '5ccdbf38-f008-4f9f-aa03-2225858e46a7',
          name: t('toothacheCariesAndMissingTeeth'),
          type: 'default',
          img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E7%89%99%E7%97%9B%E8%9B%80%E7%89%99%E8%88%87%E7%BC%BA%E7%89%99.png',
        },
        {
          id: '2fc12b75-1655-463d-8d96-238232bf4d9b',
          name: t('pediatricDentistry'),
          type: 'default',
          img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E5%85%92%E7%AB%A5%E7%89%99%E7%A7%91.png',
        },
        {
          id: '87722fac-0bd4-42ae-8c29-2ee2a0be651e',
          name: t('teethWhitening'),
          type: 'default',
          img: 'https://allypin-assets.s3-ap-northeast-1.amazonaws.com/icons/%E7%89%99%E9%BD%92%E7%BE%8E%E7%99%BD.png',
        },
        {
          id: 'b1320d86-d109-4b5b-9d5d-7eddbdef44dd',
          name: 'VIP',
          type: 'custom',
        },
        {
          id: 'dc470875-ac59-4fad-aace-042b19724dad',
          name: t('doctorZhouHimself'),
          type: 'custom',
        },
        {
          id: '387828d8-63c6-4bbd-9351-112ebff0fb70',
          name: t('difficultCustomer'),
          type: 'custom',
        },
        {
          id: 'faeb52e6-cc87-4873-9a77-c058c560e9c2',
          name: t('possible'),
          type: 'custom',
        },
      ];
      resolve(response);
    }, 1000);
  });

export const createGroupMessageApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 1000);
  });

export const fetchPnpConsumptionApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        pnp: 39,
        sms: 51,
      });
    }, 1000);
  });

export const fetchPnpAppointmentDeliverySuccessListApiDemo = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          id: 21255,
          updatedAt: '2020-07-10T03:27:57.000979Z',
          status: 'done',
          messageApiStatus: '',
          pnpApiStatus: 'success',
          smsApiStatus: '',
          appointmentID: '94240649-dfe4-4613-b27e-1c499632d2eb',
          alias: 'PNPAppointmentDateChange',
          appointmentTime: 1596870000001,
          name: t('zhouYiXuan'),
          phone: '0934455022',
          doctorName: 'Dr Ethan',
        },
        {
          id: 21259,
          updatedAt: '2020-07-10T03:29:09.93292Z',
          status: 'done',
          messageApiStatus: '',
          pnpApiStatus: 'wait',
          smsApiStatus: 'success',
          appointmentID: 'f5694c9e-a2d0-4d30-abc0-0e43836889b8',
          alias: 'PNPAppointmentNotify',
          appointmentTime: 1595574000000,
          name: t('liaoPinZhu'),
          phone: '0920377233',
          doctorName: 'Dr Ethan',
        },
      ]);
    }, 1000);
  });

export const demoMiddleWare = async (clients = [{ demo: false }], args, realFunction) => {
  const { demo: isDemoAccount } = clients[0];
  if (isDemoAccount) {
    let response = null;
    switch (`${realFunction}`) {
      case `${getReportInsightsApi}`:
        response = await getReportInsightsApiDemo(args);
        break;
      case `${fetchReportDataApi}`:
        response = await fetchReportDataApi.call(this, args, { 'AP-DEMO-ACCOUNT': 'true' });
        break;
      case `${getMonthlyReportIframeUrlPath}`:
        response = await getMonthlyReportIframeUrlPath.call(this, args, true);
        break;
      case `${fetchReportCSVApi}`:
        response = await fetchReportCSVApi.call(this, args, { 'AP-DEMO-ACCOUNT': 'true' });
        break;
      case `${downloadReviewXlsx}`:
        response = await downloadReviewXlsx.call(this, args, { 'AP-DEMO-ACCOUNT': 'true' });
        break;
      case `${fetchFeedbackCSVApi}`:
        response = await fetchFeedbackCSVApi.call(this, args, { 'AP-DEMO-ACCOUNT': 'true' });
        break;
      case `${fetchFBCommentsRepliedApi}`:
        response = await fetchFBCommentsRepliedApiDemo();
        break;
      case `${currentAndLastMonthDigit}`:
        response = await currentAndLastMonthDigitDemo();
        break;
      case `${feedbackAnalyticsApi}`:
        response = await feedbackAnalyticsApiDemo(args.clientId);
        break;
      case `${fetchCustomersInQueApi}`:
        response = await fetchCustomersInQueApiDemo(args.clientId);
        break;
      case `${fetchAppointmentsApi}`:
        response = await fetchAppointmentsApiDemo(args.clientId);
        break;

      case `${updateBlackListApi}`:
        response = await updateBlackListApiDemo(args.blackList);
        break;
      case `${createMessageApi}`:
        response = await createMessageApi(args);
        break;
      case `${createGroupMessageApi}`:
        response = await createGroupMessageApiDemo({
          messages: args.messages,
          lineIDs: ['U30fb0f6e38f7378459a467cac2a2da5e'],
        });
        break;
      case `${appendCustomTagApi}`:
        response = await appendCustomTagApiDemo();
        break;
      case `${updateReminderApi}`:
        response = await updateReminderApiDemo();
        break;
      case `${fetchLineUsersByTagsApi}`:
        response = await fetchLineUsersByTagsApiDemo(args);
        break;
      case `${uploadImageApi}`:
        response = await uploadImageApi(args);
        break;
      case `${fetchMessageListApi}`:
        response = await fetchMessageListApiDemo(args);
        break;
      case `${fetchTagListInClientApi}`:
        response = await fetchTagListInClientApiDemo(args);
        break;
      case `${fetchPnpConsumptionApi}`:
        response = await fetchPnpConsumptionApiDemo(args);
        break;
      case `${fetchPnpAppointmentDeliverySuccessListApi}`:
        response = await fetchTagListInClientApiDemo(args);
        break;
      default:
        break;
    }
    return response;
  }
  const response = await realFunction(args);
  return response;
};
