import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import {
  fetchFiltersAndTags,
  fetchGroup,
  createGroup,
  updateGroup,
  getGroupPredictUserCount,
  abortGetGroupPredictUserCount,
} from 'lib/memberService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import FormControl from 'components/Dashboard_v2/FormControl';
import Input from 'components/Dashboard_v2/Input';
import Radio from 'components/Dashboard_v2/Radio';
import FieldBase from 'components/Dashboard_v2/FieldBase';
import ConditionSelector from 'components/Dashboard_v2/ConditionSelector';
import intersectionImg from './images/Intersection.png';
import unionImg from './images/Union.png';
import PredictChartBlock, { getChartSetting, defaultChartData } from './components/PredictChartBlock';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 30px 0;
  > div {
    flex: 1;
  }
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const Hint = styled.span`
  margin-left: 8px;
  color: ${colors.PRIMARY_300};
`;

const initialFormState = {
  name: '',
  tags: [],
  filterIds: [],
  intersectionType: 'U', // U or I
};

const MODE_NAME_MAP = {
  create: {
    title: t('addGroup'),
    saveButton: t('saveAndAdd'),
  },
  edit: {
    title: t('editGroup'),
    saveButton: t('updateAndClose'),
  },
};

const getConditionData = (data) => {
  const filters = data.filters.reduce(
    (obj, data) => {
      const type = data.type;
      obj[type] = [...obj[type], data];
      return obj;
    },
    { age_range: [], gender: [] }
  );

  return {
    tag: data.tags,
    ...filters,
  };
};

const mapSelectedConditions = ({ tags, filters }) => ({
  tag: tags,
  age: filters.filter((condition) => condition.type === 'age_range'),
  gender: filters.filter((condition) => condition.type === 'gender'),
});

const GroupForm = ({ mode, match }) => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const groupId = match.params.id;
  const { renderAlert, setAlert } = useAlert();
  const [formData, setFormData] = useState({});
  const [errInput, setErrInput] = useState({});
  const [redirectToList, setRedirectToList] = useState(false);
  const [conditions, setConditions] = useState({ tag: [], filter: [] });
  const [selectedConditions, setSelectedConditions] = useState({ tag: [], age: [], gender: [] });
  const [chartSetting, setChartSetting] = useState(defaultChartData);
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChartRendering, setIsChartRendering] = useState(false);
  const timeoutRef = useRef();

  const checkValidation = useCallback(() => {
    const errInput = {};
    if (formData.name === '') {
      errInput.name = t('groupNameIsRequired');
    }

    setErrInput(errInput);
    return errInput;
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      intersectionType: value,
    }));
  };

  const handleSubmit = async () => {
    if (isLoading) return;

    const errMsg = checkValidation();
    if (Object.keys(errMsg).length == 0) {
      setIsLoading(true);
      try {
        const payload = {
          name: formData.name,
          tags: selectedConditions.tag,
          filterIds: [
            ...selectedConditions.age.map((age) => age.id),
            ...selectedConditions.gender.map((gender) => gender.id),
          ],
          intersectionType: formData.intersectionType,
        };
        if (mode === 'create') {
          await createGroup({ clientId, payload });
        }
        if (mode === 'edit') {
          await updateGroup({ clientId, groupId, payload });
        }
        setAlert({ type: 'success', title: t('groupActionSuccess', { title: MODE_NAME_MAP[mode].title }) });
        setRedirectToList(true);
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('groupActionFailed', { title: MODE_NAME_MAP[mode].title }) });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(errInput).length !== 0) {
      checkValidation(formData);
    }
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getFilterAndTags = async () => {
      const data = await fetchFiltersAndTags({ clientId });

      setConditions(getConditionData(data));
    };

    if (mode === 'create') {
      setFormData(initialFormState);
    }

    if (mode === 'edit') {
      const getGroupData = async () => {
        const { name, intersectionType, tags, filters, activeLineMembers, inactiveLineMembers, totalMembers } =
          await fetchGroup({ clientId, groupId });

        setFormData({ name, intersectionType });
        setSelectedConditions(mapSelectedConditions({ tags, filters }));
        setChartSetting(() => getChartSetting({ activeLineMembers, inactiveLineMembers, totalMembers }));
      };
      getGroupData();
    }

    getFilterAndTags();
    setTimeout(() => setIsInit(true), 500);
  }, [mode, dispatch, clientId, groupId]);

  useEffect(() => {
    if (!clientId || !isInit || !formData.intersectionType) return;

    const getPredictUserCount = async () => {
      try {
        const payload = {
          tagIds: selectedConditions.tag.map((tag) => tag.id || '00000000-0000-0000-0000-000000000000'),
          filterIds: [
            ...selectedConditions.age.map((age) => age.id),
            ...selectedConditions.gender.map((gender) => gender.id),
          ],
          intersectionType: formData.intersectionType,
        };
        const { activeLineMembers, inactiveLineMembers, totalMembers } = await getGroupPredictUserCount({
          clientId,
          payload,
        });
        setChartSetting(() => getChartSetting({ activeLineMembers, inactiveLineMembers, totalMembers }));
      } catch (e) {
        const errRes = await e;
        console.error(errRes);
      } finally {
        timeoutRef.current = setTimeout(() => setIsChartRendering(false), 1000);
      }
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setIsChartRendering(true);
    setChartSetting(() => getChartSetting());
    abortGetGroupPredictUserCount();
    getPredictUserCount();
  }, [
    clientId,
    isInit,
    formData.intersectionType,
    selectedConditions.age,
    selectedConditions.gender,
    selectedConditions.tag,
  ]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (redirectToList) {
    return <Redirect push to="/members/groups" />;
  }

  return (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/members/groups">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/members/groups">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button color="primary" variant="filled" onClick={handleSubmit}>
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
          </Button>
        </FormActions>
      </Header>
      {formData.intersectionType && isInit && (
        <Main>
          <div>
            <Block title={MODE_NAME_MAP[mode].title} minWidth={435}>
              <FormControl>
                <Input
                  required
                  name="name"
                  label={t('groupGroupName')}
                  helperText={errInput.name}
                  value={formData.name}
                  onInputChange={handleChange}
                  error={!!errInput.name}
                  placeholder={t('exampleDentalCleaning')}
                />
              </FormControl>
            </Block>
            <Block title={t('groupConditionFilter')} minWidth={435}>
              <FormControl>
                <Radio
                  label={t('chooseFilterRule')}
                  name="intersectionType"
                  value={formData.intersectionType}
                  onChange={handleRadioChange}
                  items={[
                    {
                      label: (
                        <span>
                          {t('matchFollowing')}
                          <Hint>{t('atLeastOneTagMember')}</Hint>
                        </span>
                      ),
                      content: <img src={unionImg} />,
                      value: 'U',
                    },
                    {
                      label: (
                        <span>
                          {t('matchFollowing')}
                          <Hint>{t('allTagsMember')}</Hint>
                        </span>
                      ),
                      content: <img src={intersectionImg} />,
                      value: 'I',
                    },
                  ]}
                  direction="column"
                  required
                />
              </FormControl>
              <FieldBase label={t('chooseFilterTags')} fullWidth required>
                <ConditionSelector
                  conditions={conditions}
                  selectedConditions={selectedConditions}
                  setSelectedConditions={setSelectedConditions}
                />
              </FieldBase>
            </Block>
          </div>
          <PredictChartBlock isChartRendering={isChartRendering} chartSetting={chartSetting} />
        </Main>
      )}
    </Container>
  );
};

const propTypes = {
  mode: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

GroupForm.propTypes = propTypes;

export default connectData(GroupForm);
