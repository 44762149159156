import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { t } from 'i18n/config';

const useStyles = makeStyles({
  root: {
    width: '70%',
    marginTop: '1.5rem',
    marginLeft: '.5rem',
  },
});

function valuetext(age) {
  return t('review_settings.range', { age });
}

export default function RangeSlider({ handleChange, state }) {
  const classes = useStyles();
  state.customerAgeRange.min = state.customerAgeRange.min || 0;
  state.customerAgeRange.max = state.customerAgeRange.max || 100;
  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
        {t('review_settings.range')}
      </Typography>
      <Slider
        value={[state.customerAgeRange.min, state.customerAgeRange.max]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
      />
    </div>
  );
}
