import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';

export default function PopoverComponent({
  handleClose,
  anchorEl,
  open,
  children,
  anchorOrigin = {
    vertical: 'center',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
}) {
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {children}
    </Popover>
  );
}

PopoverComponent.propTypes = {
  handleClose: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  children: PropTypes.node,
  anchorOrigin: PropTypes.shape(),
  transformOrigin: PropTypes.shape(),
};
