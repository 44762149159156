import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Modal from 'components/Dashboard_v2/Modal';
import Button from 'components/Dashboard_v2/Button';
import TagSelectorInput from 'components/Dashboard_v2/TagSelectorInput';
import { useSelector } from 'react-redux';
import useAlert from 'hooks/useAlert';
import { dashboard_v2 } from 'theme';
import { assignTagToUsers } from 'lib/memberService';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Hint = styled.div`
  font-size: 14px;
  color: ${colors.SHADES_400};
  margin-top: -8px;
  margin-bottom: 32px;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  margin-bottom: 8px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    margin-left: 16px;
  }
`;

const TagSelectorModal = ({ open, onClose, getMembers, isSelectedAll, selectedMemberIds, searchQueries }) => {
  const clients = useSelector((state) => state.clients);
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { setAlert } = useAlert();
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setSelectedTags([]);
    onClose();
  };

  const handleSave = async () => {
    if (isLoading || !selectedTags.length) return;

    setIsLoading(true);
    try {
      await assignTagToUsers({
        clientId,
        tags: selectedTags,
        memberIds: isSelectedAll ? null : selectedMemberIds,
        searchQueries: isSelectedAll ? searchQueries : null,
      });
      setAlert({ type: 'success', title: t('successfullyAddedMemberTag') });
      setTimeout(() => {
        getMembers(true);
        setIsLoading(false);
        handleClose();
      }, 1000);
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: error || t('addMemberTagFailed') });
      setIsLoading(false);
      handleClose();
      console.error(errRes);
    }
  };

  return (
    <Modal title={t('addTag')} open={open} onClose={handleClose}>
      <Hint>{t('trackingTag.tagHint')}</Hint>
      <SubTitle>{t('tags')}</SubTitle>
      <TagSelectorInput selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
      <ActionButtons>
        <Button color="secondary" variant="filled" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button color="primary" variant="filled" onClick={handleSave}>
          {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : t('addTag')}
        </Button>
      </ActionButtons>
    </Modal>
  );
};

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedMemberIds: PropTypes.array,
};

TagSelectorModal.propTypes = propTypes;

export default memo(TagSelectorModal);
