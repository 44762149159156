import Cooper from 'lib/vendors/teetchScalingParser/cooper';
import Fiadigital from 'lib/vendors/teetchScalingParser/fiadigital';
import Leyan from 'lib/vendors/teetchScalingParser/leyan';
import Vintech from 'lib/vendors/teetchScalingParser/vintech';
import Yousing from 'lib/vendors/teetchScalingParser/yousing';

export default function TeethScalingList() {
  this.vendor = '';
}

export const strategies = {
  DrCooper: new Cooper(),
  vintech: new Vintech(),
  FIADigital: new Fiadigital(),
  yousing: new Yousing(),
  leyan: new Leyan(),
};

TeethScalingList.prototype = {
  setStrategy(vendor) {
    this.vendor = vendor;
  },

  parse(xls, rABS) {
    return this.vendor.parse(xls, rABS);
  },

  bindCampaign(list, campaign, options) {
    return this.vendor.bindCampaign(list, campaign, options);
  },
};
