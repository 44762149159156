import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import { statusKeyMap } from 'components/Message/campaignTable/Columns/Status/StatusCell';
import theme from 'theme';
import { updateCampaignStatus } from 'lib/campaignService';

const AntSwitch = withStyles((stheme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 4,
    color: stheme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.colors.PRIMARY_500,
      '& + $track': {
        opacity: 1,
        backgroundColor: stheme.palette.common.white,
        borderColor: theme.colors.PRIMARY_500,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `2px solid ${stheme.palette.grey[500]}`,
    borderRadius: 40 / 2,
    backgroundColor: stheme.palette.common.white,
    opacity: 1,
  },
  checked: {},
}))(Switch);

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 20px;
  border: 0px;
`;

const Container = styled.div``;

export function LoadingCell() {
  return <Box />;
}

function ActionCell({ row, updateMyData, initialState: { clientID } }) {
  const { original, index } = row;
  const [checked, setCheck] = useState(original.status === 'active');

  const handleChange = async (event) => {
    const { checked: targetChecked } = event.target;
    const status = targetChecked ? statusKeyMap.ACTIVE : statusKeyMap.INACTIVE;
    try {
      await updateCampaignStatus({ clientID, campaignID: original.id, status });
      setCheck(targetChecked);
      updateMyData(index, 'status', status);
    } catch (error) {
      alert(error.message);
    }
  };
  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <AntSwitch checked={checked} onChange={handleChange} name="checked" />
    </Container>
  );
}

ActionCell.propTypes = {
  initialState: PropTypes.shape({
    clientID: PropTypes.string,
  }),
  value: PropTypes.string,
  row: PropTypes.shape({
    index: PropTypes.number,
    values: PropTypes.object,
    original: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
  updateMyData: PropTypes.func,
};

export default ActionCell;
