import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

const propTypes = {
  clickable: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func,
  optionType: PropTypes.string,
};

const ValueOption = ({ clickable, values, defaultValue, onChange, optionType }) => (
  <Dropdown type={optionType} defaultOption={defaultValue} options={values} onChange={onChange} clickable={clickable} />
);

ValueOption.propTypes = propTypes;

export default ValueOption;
