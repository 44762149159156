import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Select from 'components/Dashboard_v2/Select';
import Text from 'components/Dashboard_v2/Text';
import { getUserPatientTrackingMessages } from 'lib/memberService';
import FilterableTable from 'components/Table/FilterableTable';
import { getFormattedDate } from '../';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const DetailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.SHADES_000};
  cursor: default;
`;

const Title = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 8px;
  gap: 4px;
  i {
    font-size: 20px;
    color: ${colors.SHADES_400};
  }
`;

const StatusDot = styled.div`
  margin-right: 12px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;

const Block = styled.div`
  ${({ flex }) => flex && `display: flex;`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ column }) => column && `flex-direction: column;`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
`;

const StyledSelect = styled(Select)`
  margin-bottom: 24px;
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${colors.SHADES_500};
  background-color: ${colors.SHADES_100};
  border-radius: 4px;
  white-space: nowrap;
`;

const StatusLabel = (status) => {
  const settingMap = {
    pending: { text: t('unprocessed'), dotColor: colors.SHADES_300 },
    nextOn: { text: t('nextStep'), dotColor: colors.SYSTEM_WARNING_500 },
    done: { text: t('sent'), dotColor: colors.SYSTEM_INFO_400 },
    failed: { text: t('failed'), dotColor: colors.SYSTEM_ERROR_500 },
    expired: { text: t('expired'), dotColor: colors.SYSTEM_ERROR_300 },
  };
  const setting = settingMap[status] || { text: status, dotColor: null };
  return (
    <StatusWrapper>
      <StatusDot color={setting.dotColor} />
      {setting.text}
    </StatusWrapper>
  );
};

const DateTimeLabel = ({ timeValue, timeUnit, executionTime }) => {
  const timeUnits = {
    hour: t('hours'),
    day: t('days'),
    week: t('weeks'),
    month: t('months'),
    season: t('quarters'),
    year: t('years'),
  };

  return (
    <Block flex column>
      <Text font="Heading/X-Small/Regular" color="SHADES_900" block>
        {`${timeValue} ${timeUnits[timeUnit]}`}
      </Text>
      <Text font="Heading/X-Small/Regular" color="SHADES_400">
        {getFormattedDate(executionTime)}
      </Text>
    </Block>
  );
};

const patientTrackingDetailColumns = () => [
  {
    Header: 'PatientTrackingDetail',
    columns: [
      {
        Header: t('messageName'),
        accessor: 'title',
      },
      {
        Header: t('sendingStatus'),
        accessor: 'status',
        Cell: ({ value: status }) => {
          switch (status) {
            case 'pending':
              return StatusLabel('pending');
            case 'nextOn':
              return StatusLabel('nextOn');
            case 'done':
              return StatusLabel('done');
            case 'failed':
              return StatusLabel('failed');
            case 'expired':
              return StatusLabel('expired');
            default:
              return StatusLabel();
          }
        },
      },
      {
        Header: t('distanceFromStartTime'),
        accessor: 'executionTime',
        Cell: ({ row, value: executionTime }) => {
          const { timeValue, timeUnit } = row.original;
          return DateTimeLabel({ timeValue, timeUnit, executionTime });
        },
      },
    ],
  },
];

const tableStyles = {
  rowHeight: '48px',
  rowPadding: '12px 18px 12px 8px',
};

const PatientTrackingDetail = ({ id, title, clientId, userId, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageIndex, setMessageIndex] = useState(0);
  const runAtOptions = messages.map((message, index) => ({
    id: index,
    value: index,
    name: getFormattedDate(message.runAt),
  }));

  useEffect(() => {
    setIsLoading(true);
    getUserPatientTrackingMessages({ clientId, userId, patientTrackingId: id })
      .then((data) => {
        data.reverse().forEach((obj) => {
          const pendingIndex = obj.messageSchedulers.findIndex(
            (messageScheduler) => messageScheduler.status === 'pending'
          );
          if (pendingIndex !== -1) {
            obj.messageSchedulers[pendingIndex].status = 'nextOn';
          }
        });
        setMessages(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId, userId, id]);

  return (
    !isLoading && (
      <DetailWrapper>
        <Button color="secondary" onClick={handleClose}>
          <i className="ri-arrow-left-line" />
        </Button>
        <Title>
          <Text font="Heading/H6/Medium" block>
            {title}
          </Text>
          <Link to={`/message_center/patient_tracking/${id}`} target="_blank">
            <i className="ri-arrow-right-up-line" color={colors.SHADES_400} />
          </Link>
        </Title>
        <StyledSelect
          options={runAtOptions}
          selectedOption={runAtOptions[messageIndex]}
          setSelectedOption={(option) => setMessageIndex(option.value)}
          margin="0 0 24px 0"
          variant="text"
        />
        <FilterableTable
          tableStyles={tableStyles}
          tableHeaderColumns={patientTrackingDetailColumns}
          data={messages[messageIndex].messageSchedulers}
        />
      </DetailWrapper>
    )
  );
};

export default memo(PatientTrackingDetail);
