import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import Popover from 'components/MaterialDesign/Popover';
import TagComplete from 'components/lib/tagInputArea';
import {
  EditContainer as Container,
  EditHeader as Header,
  EditSection,
  EditHeaderTitle as Title,
} from '../styledComponents';
import { t } from 'i18n/config';

export default function EditTagPopover({ open, anchorEl, tags, setTags, handleClose, handleChange }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateToRemote = useCallback(
    useAsyncDebounce((newTags) => {
      handleChange(newTags);
    }, 200),
    []
  );

  const onAdd = useCallback(
    (tag) => {
      const { name } = tag;
      setTags((prev) => {
        const newTags = [...prev, { id: prev.length, name }];
        updateToRemote(newTags);
        return newTags;
      });
    },
    [setTags, updateToRemote]
  );

  const onDelete = useCallback(
    (tagId) => {
      setTags((prev) => {
        prev.splice(tagId, 1);
        updateToRemote(prev);
        return prev;
      });
    },
    [setTags, updateToRemote]
  );

  return (
    <Popover open={open} anchorEl={anchorEl} handleClose={handleClose}>
      <Container>
        <Header>
          <Title>{t('customTag')}</Title>
        </Header>
        <EditSection>
          <TagComplete tags={tags} onTagDeleted={onDelete} onTagAdded={onAdd} allowNew autofocus={false} autoresize />
        </EditSection>
      </Container>
    </Popover>
  );
}

EditTagPopover.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.instanceOf(Element),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  setTags: PropTypes.func,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
};
