import React, { useReducer } from 'react';
import { useState } from 'react';
import ReviewCommonSetting from './TextInput';
import RangeSlider from './AgeSlider';
import SaveButton from './SaveButton';
// import SmsSwitch from './SmsSwitch';
import styled from 'styled-components';
import { SmsSetting } from './TextInput';
import { ReviewReceiving } from './TextInput';
import { patchSetting } from '../api/setting';
import reviewSettingReducer from './reviewSettingReducer.js';
import useAlert from 'hooks/useAlert';
import { t } from 'i18n/config';

const SettingContainer = styled.div`
  display: flex;
  margin: 3rem;
  padding: 0 3rem 3rem 3rem;
  border-radius: 4px;
  flex-direction: column;
  width: 90%;
`;

const Container = styled.div`
  display: flex;
  position: relative;
`;

const EachRegionContainer = styled.div`
  background-color: #f0f3f5;
  padding: 1.5rem 2rem 2rem 2rem;
  border: solid 1px #ccd4e6;
  border-radius: 7px;
  margin-bottom: 0.9rem;
`;
const intialValidationState = {
  taiwanPhone: true,
  email: true,
  expireDays: true,
  delayTime: true,
};

function App({ keyToRetrieve }) {
  const [state, dispatch] = useReducer(reviewSettingReducer, keyToRetrieve);
  const clientId = keyToRetrieve.id;
  const keyToUpdate = {
    feedbackEmail: state.feedbackEmail,
    cellPhoneNumber: state.cellPhoneNumber,
    customerExpireDay: Number(state.customerExpireDay),
    uploadDelayTime: Number(state.uploadDelayTime),
    smsContent: state.smsContent,
    customerAgeRange: state.customerAgeRange,
  };
  const { renderAlert, setAlert } = useAlert();
  const [validationState, setValidationState] = useState(intialValidationState);

  const handleSavingClick = async () => {
    console.log('keyToRetrieve:', keyToRetrieve);
    console.log('keyToUpdate:', keyToUpdate);
    try {
      const { success } = await patchSetting({
        keyToUpdate,
        clientId,
      });
      if (!success) {
        setAlert({ type: 'error', title: t('review_settings.update_failed') });
      } else {
        setAlert({ type: 'success', title: t('review_settings.update_success') });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <SettingContainer>
        {renderAlert({ position: 'fixed', top: '70px' })}
        <EachRegionContainer>
          <h3>{t('review_settings.share_settings')}</h3>
          <ReviewCommonSetting
            delayTimeInput={(e) => dispatch({ type: 'change_delayTimeInput', input: e.target.value })}
            daysInput={(e) => dispatch({ type: 'change_daysInput', input: e.target.value })}
            state={state}
            validationState={validationState}
            setValidationState={setValidationState}
          />
          <RangeSlider
            state={state}
            handleChange={(e, newAge) => {
              dispatch({ type: 'change_ageRange', max: newAge[1], min: newAge[0] });
            }}
          />
        </EachRegionContainer>
        <EachRegionContainer>
          <h3>{t('review_settings.sms_settings')}</h3>
          {/* <SmsSwitch smsState={smsState} handleChange={(e) => setSmsState(e.target.checked)} /> */}
          <SmsSetting keyToRetrieve={keyToRetrieve} dispatch={dispatch} />
        </EachRegionContainer>
        <EachRegionContainer>
          <h3>{t('review_settings.notification_settings')}</h3>
          <ReviewReceiving
            phoneInput={(e) => dispatch({ type: 'change_phoneInput', input: e.target.value })}
            emailInput={(e) => dispatch({ type: 'change_emailInput', input: e.target.value })}
            state={state}
            validationState={validationState}
            setValidationState={setValidationState}
          />
        </EachRegionContainer>
        <SaveButton validationState={validationState} handleClick={() => handleSavingClick()} />
      </SettingContainer>
    </Container>
  );
}
export default App;
