import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Loading } from 'components/MaterialDesign/Loading';
import TeethScalingList, { strategies } from 'lib/vendors/teetchScalingParser';
import theme from 'theme';
import { t } from 'i18n/config';

const InputArea = styled.label`
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const InputHintArea = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${(props) => props.bordercolor || '#d9d9d9'};
  background: ${(props) => props.backgroundcolor || '#f8fafc'};
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  direction: ltr;
  cursor: pointer;
  width: 120%;
  height: 100%;
`;

const UploadIcon = styled.i`
  font-size: 30px;
  cursor: pointer;
  color: ${(props) => props.color || 'inherit'};
`;

const UploadDescription = styled.div`
  margin-top: 16px;
`;

const uploadState = {
  INITIAL: 'initial',
  UPLOADING: 'uploading',
  DONE: 'done',
};

const TextClick = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

function InputHintAreaComp(props) {
  const { uploadstate } = props;
  if (uploadstate === uploadState.INITIAL) {
    return (
      <InputHintArea>
        <UploadIcon className="ri-upload-cloud-2-line" color="#9ea7b8" />
        <UploadDescription>
          {t('dragAndDropFilesHereOr')} <TextClick> {t('uploadFile')}</TextClick>
        </UploadDescription>
      </InputHintArea>
    );
  }
  if (uploadstate === uploadState.UPLOADING) {
    return (
      <InputHintArea backgroundcolor={theme.colors.backgroundSecondary} bordercolor={theme.colors.primary}>
        <Loading customcolor={theme.colors.primary} />
        <UploadDescription color={theme.colors.primary}>{t('uploading')}</UploadDescription>
      </InputHintArea>
    );
  }
  if (uploadstate === uploadState.DONE) {
    return (
      <InputHintArea backgroundcolor={theme.colors.backgroundSuccess} bordercolor={theme.colors.success2}>
        <UploadIcon className="ri-checkbox-circle-line" color={theme.colors.success2} />
        <UploadDescription color={theme.colors.success2}>{t('uploadSuccessCloseWindowToViewList')}</UploadDescription>
      </InputHintArea>
    );
  }
  return null;
}

InputHintAreaComp.propTypes = {
  uploadstate: PropTypes.string,
};

function FileUploader(props) {
  const { onUploaded, vendor } = props;
  const [upload, setUpload] = useState(uploadState.INITIAL);
  const onSelectFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onloadstart = () => {
      setUpload(uploadState.UPLOADING);
    };
    reader.onprogress = (data) => {
      if (data.lengthComputable) {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        console.log(progress);
      }
    };
    reader.onloadend = async (event) => {
      const limitSize = 1000 * 1024 * 10; // 10 MB
      if (file.size > limitSize) {
        return;
      }
      if (event.target.readyState === FileReader.DONE) {
        const ts = new TeethScalingList();
        const strategy = strategies[vendor];
        ts.setStrategy(strategy);
        const res = ts.parse(event.target.result, rABS);
        onUploaded(res);
        setUpload(uploadState.DONE);
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  return (
    <InputArea className="file">
      <InputHintAreaComp uploadstate={upload} />
      <Input type="file" className="file-input" accept=".xlsx, .xls" onChange={onSelectFile} />
    </InputArea>
  );
}

FileUploader.propTypes = {
  onUploaded: PropTypes.func,
  vendor: PropTypes.string,
};

export default FileUploader;
