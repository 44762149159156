import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const Cancel = styled(Button)`
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  width: ${(props) => (props.width ? `${props.width}px` : undefined)};
  height: ${(props) => (props.height ? `${props.height}px` : undefined)};
  color: ${(props) => props.customizedcolor || props.theme.colors.body87};
`;

export const Confirm = styled(Button)`
  background-color: ${(props) => (props.disabled ? '#fff' : props.customizedcolor || props.theme.colors.primary)};
  border: solid 1px ${(props) => (props.disabled ? '#fff' : props.customizedcolor || props.theme.colors.primary)};
  &:hover {
    background-color: ${(props) => props.customizedcolor || props.theme.colors.primary};
    opacity: 0.9;
  }
  &:focus {
    border: 0px;
    outline: none;
  }
  &:hover.Mui-disabled {
    background-color: ${(props) => props.customizedcolor || props.theme.colors.primary};
    opacity: 0.9;
  }
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  border-radius: ${(props) => (props.borderradius ? `${props.borderradius}px` : '8px')};
  width: ${(props) => (props.width ? `${props.width}px` : undefined)};
  height: ${(props) => (props.height ? `${props.height}px` : undefined)};
  color: ${(props) => props.color || 'white'};
`;

export const DisabledButton = styled(Button)`
  background-color: ${(props) => props.customizedcolor || props.theme.colors.primary};
  border: solid 1px ${(props) => props.customizedcolor || props.theme.colors.primary};
  &:hover {
    background-color: ${(props) => props.customizedcolor || props.theme.colors.primary};
    opacity: 0.9;
  }
  &:focus {
    border: 0px;
    outline: none;
  }
  &.Mui-disabled {
    color: ${(props) => props.textcolor};
  }
  &:hover.Mui-disabled {
    background-color: ${(props) => props.customizedcolor || props.theme.colors.primary};
    opacity: 0.9;
  }
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  border-radius: 8px;
  width: ${(props) => (props.width ? `${props.width}px` : undefined)};
  height: ${(props) => (props.height ? `${props.height}px` : undefined)};
`;

export const ButtonInterval = styled.div`
  width: 16px;
`;
