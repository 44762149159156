import format from 'date-fns/format';
import { getToken, MARKETING_URL, ENV_STAGE } from 'helpers';
import { listSerializer, listUserNotificationsSerializer } from 'lib/campaignService/campaignSerializer';
import { responseHandler } from '../responseHandler';
import { t } from 'i18n/config';

export const test = true;
export const fetchCampaignList = async ({ clientID }) =>
  fetch(`${MARKETING_URL}/v1/line-actions/clients/${clientID}/lineOfficialAccountActions`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .then(responseHandler)
    .then(listSerializer);

export const bulkUploadUsers = async ({ clientID, campaignID, users }) => {
  const data = users.map((u) => ({
    name: u.name,
    to: u.phone,
    estimatedDeliveryTimestamp: format(u.estimatedDeliveryTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    eventTime: format(u.lastTeethScaling, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
  }));
  console.log('bulkuploadusers', clientID, campaignID, data);
  const url = `${MARKETING_URL}/v1/line-actions/clients/${clientID}/lineOfficialAccountActions/${campaignID}/notifications`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      notifications: data,
    }),
  }).catch(() => {
    throw new Error(t('sendFailedTryAgain'));
  });
};

export const bulkRemoveUserNotifications = async ({ clientID, originals }) => {
  if (originals.length > 0) {
    const { campaignID } = originals[0];
    const ids = originals.map((o) => o.id);
    const url = `${MARKETING_URL}/v1/line-actions/clients/${clientID}/lineOfficialAccountActions/${campaignID}/bulk_delete_notifications`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'AP-stage': ENV_STAGE,
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        notificationIDs: ids,
      }),
    }).catch(() => {
      throw new Error(t('sendFailedTryAgain'));
    });
  }
  return '';
};

export const listUserNotifications = async (props) => {
  const { clientID, campaignID, status, startTime, endTime } = props;
  return fetch(
    `${MARKETING_URL}/v1/line-actions/clients/${clientID}/lineOfficialAccountActions/${campaignID}/notifications?notificationStatus=${status}&startTime=${startTime}&endTime=${endTime}`,
    {
      method: 'GET',
      headers: {
        'AP-stage': ENV_STAGE,
        Authorization: `Bearer ${getToken()}`,
      },
    }
  )
    .then(responseHandler)
    .then((res) => listUserNotificationsSerializer({ res, clientID, campaignID }));
};

export const getCampaign = async (props) => {
  const { clientID, campaignID } = props;
  return fetch(`${MARKETING_URL}/v1/line-actions/clients/${clientID}/lineOfficialAccountActions/${campaignID}`, {
    method: 'GET',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
    },
  }).then(responseHandler);
};

export const updateCampaignStatus = async ({ clientID, campaignID, status }) => {
  const url = `${MARKETING_URL}/v1/line-actions/clients/${clientID}/lineOfficialAccountActions/${campaignID}`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      'AP-stage': ENV_STAGE,
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status,
    }),
  }).catch(() => {
    throw new Error(t('switchFailed'));
  });
};
