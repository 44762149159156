import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import removeTag from 'img/remove_tag.png';
import Dropdown from './Dropdown';
import { attributeMap } from '../../../helpers/constants';
import img1 from './1.jpg';
import img2 from './2.jpg';

const propTypes = {
  type: PropTypes.string,
  onDelete: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.string,
  onTextChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  textValue: PropTypes.string,
  clickable: PropTypes.bool,
};

const defaultProps = {};

class DropdownColumn extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      type: props.type || 'tag',
      genderType: 'both',
      compareType: 'eq',
      tagType: 'teethWhite',
      customTagType: 'custom1',
      isGenderOpen: false,
      isCompareOpen: false,
      isTagOpen: false,
      isCustomTagOpen: false,
    };
  }

  setType = (type) => {
    this.setState({
      type,
    });
    switch (true) {
      case type === 'text' || type === 'card':
        this.props.onTypeChange(type);
        break;

      default:
        break;
    }
  };

  setGenderType = (genderType) => {
    this.setState({
      genderType,
    });
  };

  setCompareType = (compareType) => {
    this.setState({
      compareType,
    });
  };

  setTagType = (tagType) => {
    this.setState({
      tagType,
    });
  };

  setCustomTagType = (customTagType) => {
    this.setState({
      customTagType,
    });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleCompare = () => {
    this.setState({
      isCompareOpen: !this.state.isCompareOpen,
    });
  };

  toggleGender = () => {
    this.setState({
      isGenderOpen: !this.state.isGenderOpen,
    });
  };

  toggleTag = () => {
    this.setState({
      isTagOpen: !this.state.isTagOpen,
    });
  };

  toggleCustomTag = () => {
    this.setState({
      isCustomTagOpen: !this.state.isCustomTagOpen,
    });
  };

  renderByType = (type) => {
    const { compareType, genderType, tagType, customTagType, isCompareOpen, isGenderOpen, isTagOpen, isCustomTagOpen } =
      this.state;
    switch (type) {
      case 'age':
        return (
          <div className="line-age-column">
            <Dropdown
              title={attributeMap[compareType]}
              type={compareType}
              values={['eq', 'lt', 'gt']}
              setType={this.setCompareType}
              isOpen={isCompareOpen}
              toggle={this.toggleCompare}
            />
            <input type="number" />
          </div>
        );
      case 'gender':
        return (
          <div>
            <Dropdown
              title={attributeMap[genderType]}
              type={genderType}
              values={['female', 'male', 'both']}
              setType={this.setGenderType}
              isOpen={isGenderOpen}
              toggle={this.toggleGender}
            />
          </div>
        );
      case 'tag':
        return (
          <div className="line-age-column">
            <Dropdown
              title={attributeMap[tagType]}
              type={tagType}
              values={['teethWhite', 'teethJiauJian', 'teethJIUYA']}
              setType={this.setTagType}
              isOpen={isTagOpen}
              toggle={this.toggleTag}
            />
          </div>
        );
      case 'customTag':
        return (
          <div className="line-age-column">
            <Dropdown
              title={attributeMap[customTagType]}
              type={customTagType}
              values={['custom1', 'custom2', 'custom3']}
              setType={this.setCustomTagType}
              isOpen={isCustomTagOpen}
              toggle={this.toggleCustomTag}
            />
          </div>
        );
      case 'later':
        return <Datetime onChange={this.onChangeDate} defaultValue={new Date()} />;
      case 'text':
        return (
          <textarea
            className="line-textarea"
            name="text"
            id=""
            cols="30"
            rows="10"
            onChange={this.props.onTextChange}
            defaultValue=""
            value={this.props.textValue}
          />
        );

      case 'card':
        return (
          <div className="line-card-containers">
            <div className="line-card-container">
              <input type="radio" name="card" checked /> <img className="line-card" src={img1} alt="" />
            </div>
            <div className="line-card-container">
              <input type="radio" name="card" /> <img className="line-card" src={img2} alt="" />
            </div>
          </div>
        );
      //   <div className="line-card-container line-card-add line-card">
      //   <img className="line-add-tag" src={addTag} alt="" />
      // </div>
      default:
        return <div />;
    }
  };
  render() {
    const { type } = this.state;
    const { onDelete, values, classes, clickable } = this.props;
    return (
      <div className={classes}>
        <div className="line-age-column">
          <Dropdown
            title={attributeMap[type]}
            type={type}
            values={values}
            setType={this.setType}
            isOpen={this.state.isOpen}
            toggle={this.toggle}
            clickable={clickable}
          />
        </div>
        {this.renderByType(type)}

        {onDelete && (
          <button className="line-add-button" onClick={this.props.onDelete}>
            <img className="line-add-button-image" src={removeTag} alt="" />
          </button>
        )}
      </div>
    );
  }
}

DropdownColumn.propTypes = propTypes;
DropdownColumn.defaultProps = defaultProps;

export default DropdownColumn;
