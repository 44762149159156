import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import connectData from 'components/HOCs/connectData';
import Page from './Page';

const propTypes = {
  clients: PropTypes.shape({
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.object,
  }),
};

const defaultProps = {};

@connectData
class ReviewSetting extends PureComponent {
  isMultipleSelectedStores = () => this.props.clients.selectedIds.length > 1;

  render() {
    const { clients } = this.props;

    return (
      <React.Fragment>
        {clients.selectedIds.map((id) => {
          const { feedbackEmail, cellPhoneNumber, customerExpireDay, uploadDelayTime, customerAgeRange, smsContent } =
            clients.byId[id];

          const keyToRetrieve = {
            id,
            feedbackEmail,
            cellPhoneNumber,
            customerExpireDay,
            uploadDelayTime,
            customerAgeRange,
            smsContent,
          };

          return <Page key={id} keyToRetrieve={keyToRetrieve}></Page>;
        })}
      </React.Fragment>
    );
  }
}

ReviewSetting.propTypes = propTypes;
ReviewSetting.defaultProps = defaultProps;

export default ReviewSetting;
