import React, { useMemo, memo } from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  i {
    line-height: 1;
  }
`;

const PopoverContentWrapper = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth};` : '100%')};
  white-space: pre-line;
`;

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    margin: -4,
    padding: 12,
    backgroundColor: colors.SHADES_700,
    color: colors.SHADES_000,
    whiteSpace: 'pre',
  },
}));

const getPosition = (type) => {
  switch (type) {
    case 'TOP_LEFT':
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      };
    case 'TOP_CENTER':
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      };
    case 'TOP_RIGHT':
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      };
    case 'BOTTOM_LEFT':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      };
    case 'BOTTOM_CENTER':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      };
    case 'BOTTOM_RIGHT':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      };
    case 'CENTER_LEFT':
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
      };
    case 'CENTER_RIGHT':
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
      };
  }
};

const InfoToolTip = ({ popoverContent, type = 'TOP_RIGHT', maxWidth, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const position = useMemo(() => getPosition(type), [type]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onMouseDownCapture={handlePopoverClose}
      >
        {props.children}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        style={props.popoverStyle}
        open={open}
        anchorEl={anchorEl}
        {...position}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopoverContentWrapper maxWidth={maxWidth}>{popoverContent}</PopoverContentWrapper>
      </Popover>
    </Wrapper>
  );
};

export default memo(InfoToolTip);
