import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Draft, PencilIcon, DraftSection } from './styledComponents';
import MessagePopover from './popover/MessagePopover';
import LoadingCell from './LoadingCell';
import { t } from 'i18n/config';

const emptyDraft = t('allSingleMessagesHere');

const defaultValue = { draft: '' };
function MessageCell({
  value: initialValue = defaultValue,
  row,
  column: { id },
  updateMyData,
  state: { clientID },
  openSnackbar,
}) {
  const { index } = row;
  const { draft } = initialValue;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback((newDraft) => {
    updateMyData(index, id, { draft: newDraft });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Container>
        <DraftSection onClick={handleClick}>
          <PencilIcon className="ri-pencil-line" />
          <Draft isEmpty={!draft}>{draft || emptyDraft}</Draft>
        </DraftSection>
      </Container>
      {open && (
        <MessagePopover
          key={`${row.original.id}-Messagepopover`}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleChange={handleChange}
          openSnackbar={openSnackbar}
          appointmentID={row.original.id}
          clientID={clientID}
          value={draft}
        />
      )}
    </React.Fragment>
  );
}

MessageCell.propTypes = {
  value: PropTypes.shape({
    draft: PropTypes.string,
  }),
  row: PropTypes.shape({
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  column: PropTypes.shape({
    id: PropTypes.string,
  }),
  updateMyData: PropTypes.func,
  state: PropTypes.shape({
    clientID: PropTypes.string,
  }),
  openSnackbar: PropTypes.func,
};

export default MessageCell;

export const Loading = LoadingCell;
