import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'components/Dashboard_v2/Text';
import TextMessageInput from './TextMessageInput';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Block = styled.div`
  padding: 24px;
  margin-top: 24px;
  border: 1px solid ${colors.SHADES_300};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
`;

const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  i {
    font-size: 20px;
    margin-left: 24px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
`;

const TextEditor = ({
  data,
  handleChange,
  handleMessageMove,
  handleMessageDelete,
  handleOpenAI,
  allowMoveUp,
  allowMoveDown,
  disabled,
}) => {
  const { textContent } = data;
  return (
    <Block>
      <BlockTitle>
        <Text font="Heading/Large/Medium">{t('text')}</Text>
        {!disabled && (
          <div>
            {allowMoveUp && <i className="ri-arrow-up-s-line" onClick={() => handleMessageMove('up')} />}
            {allowMoveDown && <i className="ri-arrow-down-s-line" onClick={() => handleMessageMove('down')} />}
            <i className="ri-delete-bin-line" onClick={handleMessageDelete} />
          </div>
        )}
      </BlockTitle>
      <TextMessageInput
        handleOpenAI={handleOpenAI}
        value={textContent}
        onInputChange={(value) => handleChange('textContent', value)}
        maxLength={400}
        fullWidth
        disabled={disabled}
      />
    </Block>
  );
};

const propTypes = {
  data: PropTypes.shape({
    textContent: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleMessageMove: PropTypes.func,
  handleMessageDelete: PropTypes.func,
  allowMoveUp: PropTypes.bool,
  allowMoveDown: PropTypes.bool,
  disabled: PropTypes.bool,
  handleOpenAI: PropTypes.func,
};

TextEditor.propTypes = propTypes;

export default memo(TextEditor);
