import React from 'react';
import styled from 'styled-components';

export const Success = styled.i`
  font-size: 24px;
  color: ${(props) => props.theme.colors.success};
`;

export const Failed = styled.i`
  font-size: 24px;
  color: ${(props) => props.theme.colors.error};
`;

export const SuccessIcon = () => <Success className="ri-mail-send-fill" />;
export const FailedIcon = () => <Failed className="ri-spam-2-fill" />;
