import IconCell, {
  LoadingCell as StatusLoading,
} from 'components/WesternAppointments/table/Columns/BindingStatus/StatusCell';
import { getName } from 'components/WesternAppointments/table/Columns/BindingStatus/status';
import DeletedIconCell, {
  LoadingCell as DeletedStatusLoading,
} from 'components/WesternAppointments/table/Columns/DeletedStatus/StatusCell';
import { getName as getDeletedName } from 'components/WesternAppointments/table/Columns/DeletedStatus/status';
import ReplyIconCell, {
  LoadingCell as ReplyStatusLoading,
} from 'components/WesternAppointments/table/Columns/ReplyStatus/StatusCell';
import { getName as getReplyName } from 'components/WesternAppointments/table/Columns/ReplyStatus/status';
import {
  RenderCheckboxHeader,
  RenderCheckboxCell,
  RenderLoadingCell,
} from 'components/Table/Columns//IndeterminateCheckbox';
import ActionCell, { Loading as ActionLoading } from 'components/WesternAppointments/table/Columns/Action/ActionCell';
import NameCell from 'components/WesternAppointments/table/Columns/Name/NameCell';
import {
  getTitle,
  getPnpTitle,
  filterOptions as sendingActionFilterOptions,
} from 'components/WesternAppointments/table/Columns/Action/action';
import SendingActionFilter from 'components/WesternAppointments/table/Columns/Action/Filter';
import MoreCell, { LoadingCell as MoreCellLoading } from 'components/WesternAppointments/table/Columns/More/MoreCell';
import NoteCell from 'components/WesternAppointments/table/Columns/Note';
import { t } from 'i18n/config';

const getUnsendColumns = (isPnpEnabled) => [
  {
    id: 'selection',
    Header: RenderCheckboxHeader,
    Cell: RenderCheckboxCell,
    Loading: RenderLoadingCell,
    maxWidth: 50,
  },
  {
    Header: t('patientName'),
    accessor: 'name',
    Cell: NameCell,
    maxWidth: 100,
    minWidth: 100,
  },
  {
    Header: t('bindingStatus'),
    accessor: 'binding',
    Cell: IconCell,
    toDisplayString: getName,
    Loading: StatusLoading,
    maxWidth: 50,
  },
  {
    Header: t('deletedStatus'),
    accessor: 'deleted',
    Cell: DeletedIconCell,
    toDisplayString: getDeletedName,
    Loading: DeletedStatusLoading,
    maxWidth: 50,
  },
  {
    Header: t('registrationSource'),
    accessor: 'bookingFrom',
    maxWidth: 50,
  },
  {
    Header: t('appointmentNote'),
    accessor: 'note',
    Cell: NoteCell,
    maxWidth: 120,
  },
  {
    Header: t('mobile'),
    accessor: 'phone',
    maxWidth: 120,
  },
  {
    Header: t('doctor'),
    accessor: 'doctor',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('department'),
    accessor: 'subject',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('visitNumber'),
    accessor: 'period',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('clinicRoom'),
    accessor: 'room',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('sendingStatus'),
    accessor: 'sendingAction',
    filterable: true,
    isPnpEnabled,
    Cell: (data) => <ActionCell {...data} isPnpEnabled={isPnpEnabled} />,
    Filter: SendingActionFilter,
    filter: 'sendingActionFilter',
    filterOptions: sendingActionFilterOptions,
    toDisplayString: isPnpEnabled ? getPnpTitle : getTitle,
    Loading: ActionLoading,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: ' ',
    accessor: '',
    Cell: MoreCell,
    Loading: MoreCellLoading,
    minWidth: 40,
    maxWidth: 40,
  },
];

const getSentColumns = (isPnpEnabled) => [
  {
    id: 'selection',
    Header: RenderCheckboxHeader,
    Cell: RenderCheckboxCell,
    Loading: RenderLoadingCell,
    maxWidth: 50,
  },
  {
    Header: t('patientName'),
    accessor: 'name',
    Cell: NameCell,
    maxWidth: 100,
    minWidth: 100,
  },
  {
    Header: t('bindingStatus'),
    accessor: 'binding',
    Cell: IconCell,
    toDisplayString: getName,
    Loading: StatusLoading,
    maxWidth: 50,
  },
  {
    Header: t('deleteReplyStatus'),
    accessor: 'reply',
    Cell: ReplyIconCell,
    toDisplayString: getReplyName,
    Loading: ReplyStatusLoading,
    maxWidth: 80,
  },
  {
    Header: t('registrationSource'),
    accessor: 'bookingFrom',
    maxWidth: 50,
  },
  {
    Header: t('appointmentNote'),
    accessor: 'note',
    Cell: NoteCell,
    maxWidth: 120,
  },
  {
    Header: t('mobile'),
    accessor: 'phone',
    maxWidth: 120,
  },
  {
    Header: t('doctor'),
    accessor: 'doctor',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('department'),
    accessor: 'subject',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('visitNumber'),
    accessor: 'period',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('clinicRoom'),
    accessor: 'room',
    filterable: true,
    hidden: true,
    maxWidth: 120,
  },
  {
    Header: t('sendingStatus'),
    accessor: 'sendingAction',
    filterable: true,
    Cell: (data) => <ActionCell {...data} isPnpEnabled={isPnpEnabled} />,
    Filter: SendingActionFilter,
    filter: 'sendingActionFilter',
    filterOptions: sendingActionFilterOptions,
    toDisplayString: isPnpEnabled ? getPnpTitle : getTitle,
    Loading: ActionLoading,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: ' ',
    accessor: '',
    Cell: MoreCell,
    Loading: MoreCellLoading,
    minWidth: 40,
    maxWidth: 40,
  },
];

export const TabTitles = [t('notSent'), t('scheduledSent'), t('sendFailed')];

export const shouldRowRemovedMap = {
  [TabTitles[0]]: true,
  [TabTitles[1]]: false,
  [TabTitles[2]]: true,
};

export const headerColumnsMap = (isPnpEnabled) => ({
  0: () => [
    {
      Header: TabTitles[0],
      columns: getUnsendColumns(isPnpEnabled),
    },
  ],
  1: () => [
    {
      Header: TabTitles[1],
      columns: getSentColumns(isPnpEnabled),
    },
  ],
  2: () => [
    {
      Header: TabTitles[2],
      columns: getUnsendColumns(isPnpEnabled),
    },
  ],
});

export const tableStyles = {
  rowHeight: '100px',
};
