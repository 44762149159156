import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchUnsendNotificationList } from 'lib/westernAppointmentService';
import UnSendNotification from 'components/WesternAppointments/table/Columns/Action/UnSend/UnSendNotification';
import { Loading, EmptyInfo, Container, Header, HeaderTitle } from './styledComponents';
import { t } from 'i18n/config';

const defaultState = {
  list: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        loading: true,
      };
    case 'fetchDone':
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case 'cancelNotificationSuccess': {
      const taskID = action.payload;
      return {
        ...state,
        list: state.list.filter((n) => n.taskID !== taskID),
      };
    }
    default:
      return state;
  }
};

function UnsendList({ clientID, appointmentID, onCancelNotification }) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    async function fetchData() {
      return fetchUnsendNotificationList({ clientID, id: appointmentID });
    }

    dispatch({ type: 'fetching' });
    fetchData()
      .then((res) => {
        dispatch({ type: 'fetchDone', payload: res });
      })
      .catch(() => {
        dispatch({ type: 'fetchDone', payload: [] });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelSuccess = ({ taskID }) => {
    dispatch({ type: 'cancelNotificationSuccess', payload: taskID });
    onCancelNotification();
  };

  return (
    <Container>
      <Header>
        <HeaderTitle>{t('scheduling')}</HeaderTitle>
      </Header>
      {state.loading && <Loading size={23} />}
      {!state.loading && state.list.length === 0 && (
        <EmptyInfo>
          {t('noSendingRecord')}
          <span role="img" aria-label="cry">
            {' '}
            🥺
          </span>
        </EmptyInfo>
      )}
      {state.list.map(({ expectedDeliveryAt, id, contentformat, taskID }) => (
        <UnSendNotification
          id={id}
          clientID={clientID}
          appointmentID={appointmentID}
          expectedDeliveryAt={expectedDeliveryAt}
          contentformat={contentformat}
          taskID={taskID}
          onCancelSuccess={handleCancelSuccess}
          key={id}
        />
      ))}
    </Container>
  );
}

UnsendList.propTypes = {
  clientID: PropTypes.string,
  appointmentID: PropTypes.number,
  onCancelNotification: PropTypes.func,
};

export default UnsendList;
