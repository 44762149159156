import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import { fetchSurveyByUser } from 'lib/surveyService';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const DetailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.SHADES_000};
  cursor: default;
`;

const Title = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 8px;
  gap: 4px;
  i {
    font-size: 20px;
    color: ${colors.SHADES_400};
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid ${colors.SHADES_300}};
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const Question = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const Answer = styled.span`
  font-size: 16px;
  margin-left: 10px;
`;

const Tag = styled.div`
  font-size: ${(props) => props.size || '16px'};
  color: ${colors.PRIMARY_600};
  padding: 10px;
  border-radius: 4px;
`;

const handleNonEmptyString = (arr) => {
  return arr.filter((e) => e !== '');
};

const SurveyDetail = ({ id, title, clientId, userId, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [survey, setSurvey] = useState();

  useEffect(() => {
    setIsLoading(true);
    fetchSurveyByUser({ clientId, userId, surveyId: id })
      .then((data) => {
        // 問卷裡有一些隱藏設定，不要顯示
        const i = data.result.findIndex((item) => item.subject.includes('以上不要動'));
        const filteredResult = data.result.slice(i + 1);
        data.result = filteredResult;
        setSurvey(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId, userId, id]);

  return (
    !isLoading && (
      <DetailWrapper>
        <Button color="secondary" onClick={handleClose}>
          <i className="ri-arrow-left-line" />
        </Button>
        <Title>
          <Text font="Heading/H6/Medium" block>
            {title}
          </Text>
        </Title>
        {survey && (
          <Container>
            <p>
              <strong>{t('submissionTimePrefix')}</strong>
              {new Date(survey.submitTime + ' UTC').toLocaleString()}
            </p>
            <p>
              <strong>{t('surveyStatusPrefix')}</strong>
              {survey.status}
            </p>
            <List>
              {survey.result.map((item) => (
                <ListItem key={item.sn}>
                  {item.label && <Tag size={'18px'}>{`#${item.label}`}</Tag>}
                  <Question>{item.subject}：</Question>
                  <Answer>
                    {item.answer.length > 0 && item.answer.join(', ') != '' ? item.answer.join(', ') : t('noAnswer')}
                    {item.otherAnswer &&
                      (item.otherAnswer.length > 0 && item.otherAnswer.join(', ') != ''
                        ? ' - ' + item.otherAnswer.join(', ')
                        : '')}
                    {item.answerLabel.length > 0 && handleNonEmptyString(item.answerLabel).length !== 0 ? (
                      <Tag>{` #${handleNonEmptyString(item.answerLabel).join(', ')}`}</Tag>
                    ) : (
                      ''
                    )}
                    {item.answerAlias.length > 0 && handleNonEmptyString(item.answerAlias).length !== 0
                      ? ` aka: ${handleNonEmptyString(item.answerAlias).join(', ')}`
                      : ''}
                  </Answer>
                </ListItem>
              ))}
            </List>
          </Container>
        )}
      </DetailWrapper>
    )
  );
};

export default memo(SurveyDetail);
