import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { get } from 'lodash-es';

import { getTemplateCategories, getTemplate, createTemplate, updateTemplate } from 'lib/messageCenterService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';

import { InfoBlock } from '../components/EditBlocks';
import CategoryModal from '../components/CategoryModal';
import InfoModal from '../components/InfoModal';
import MessageModal from '../components/MessageModal/index';
import MessageBubble from '../components/MessageModal/MessageBubble';
import { conventFormDataToTemplateData, conventTemplateDataToFormData } from '../utils';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: ${colors.SHADES_000};
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const MODE_NAME_MAP = {
  create: {
    title: t('add'),
    saveButton: t('saveAndAdd'),
  },
  edit: {
    title: t('edit'),
    saveButton: t('updateAndClose'),
  },
};

const getInitialFormState = (type) => ({
  title: '',
  description: '',
  templateType: type,
  messageTemplateCategoryId: null,
  messageTemplateCategoryName: null,
  message: [],
  replies: [],
});

const TemplateForm = ({ mode, type, match }) => {
  const templateId = match.params.id;
  const { renderAlert, setAlert } = useAlert();
  const clientName = useSelector((state) => state.auth.user.companyName);
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const { openAI: openAIEnabled } = useSelector((state) => state.clients.byId[clientId]?.features);
  const [formData, setFormData] = useState(null);
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editModal, setEditModal] = useState(null);

  const disabledSubmit = useMemo(() => {
    if (!formData) {
      return true;
    }
    const { title, message } = formData;
    const isEmptyTitle = !title;
    const isEmptyMessage = !message.length;
    return isEmptyTitle || isEmptyMessage || isEmptyMessage;
  }, [formData]);

  const handleModalOpen = (type) => {
    setEditModal(type);
  };

  const handleModalClose = () => {
    setEditModal(null);
  };

  const handleCategoryChange = useCallback(
    (categoryData) =>
      setFormData((prev) => ({
        ...prev,
        messageTemplateCategoryId: get(categoryData, 'id'),
        messageTemplateCategoryName: get(categoryData, 'name'),
      })),
    []
  );

  const handleInfoChange = useCallback(
    (infoData) =>
      setFormData((prev) => ({
        ...prev,
        ...infoData,
      })),
    []
  );

  const handleMessageChange = useCallback(
    (messageData) =>
      setFormData((prev) => ({
        ...prev,
        message: messageData.message,
        replies: messageData.replies,
      })),
    []
  );

  const handleSubmit = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const payload = conventFormDataToTemplateData({ templateType: 'groupMessage', formData, mode });
      if (mode === 'create') {
        await createTemplate({ clientId, payload });
      }
      if (mode === 'edit') {
        await updateTemplate({ clientId, templateId, payload });
      }
      setAlert({ type: 'success', title: t('successAction', { title: MODE_NAME_MAP[mode].title }) });
      setRedirectToList(true);
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: error || t('failureAction', { title: MODE_NAME_MAP[mode].title }) });
      console.error(errRes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const categoryData = await getTemplateCategories({ clientId });
        setCategories(categoryData[type]);
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: t('loadDataFailed', { error: error || error.message }) });
        console.error(errRes);
      }
    };

    getData();

    if (mode === 'create') {
      setFormData(getInitialFormState(type));
    }

    if (mode === 'edit') {
      const getTemplateData = async () => {
        try {
          const data = await getTemplate({ clientId, templateId });
          setFormData(conventTemplateDataToFormData({ templateType: 'groupMessage', data }));
        } catch (e) {
          const errRes = await e;
          const { error } = errRes;
          setAlert({ type: 'error', title: t('loadTemplateFailed', { error: error || error.message }) });
          console.error(errRes);
        }
      };
      getTemplateData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return redirectToList ? (
    <Redirect push to="/message_center/templates?type=groupMessage" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/message_center/templates?type=groupMessage">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('return')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/message_center/templates?type=groupMessage">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button color="primary" variant="filled" onClick={handleSubmit} disabled={disabledSubmit}>
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
          </Button>
        </FormActions>
      </Header>
      {formData && (
        <Main>
          {/* Base Info */}
          <Block title={t('groupMessageTemplate')}>
            <InfoBlock
              contents={[
                {
                  title: t('category'),
                  value: formData.messageTemplateCategoryName,
                  placeholder: t('exampleDentistry'),
                },
              ]}
              onEditButtonClick={() => handleModalOpen('category')}
            />
            <InfoBlock
              contents={[
                { title: t('name'), value: formData.title, placeholder: t('exampleImplantSurgery') },
                {
                  title: t('description'),
                  value: formData.description,
                  placeholder: t('examplePostoperativePrecautions'),
                },
              ]}
              onEditButtonClick={() => handleModalOpen('info')}
            />
            <InfoBlock
              contents={[
                {
                  title: t('previewMessage'),
                  value: formData.message.length
                    ? formData.message.map((message, index) => (
                        <MessageBubble clientName={clientName} data={message} key={index} />
                      ))
                    : null,
                  placeholder: t('noMessageEdited'),
                },
              ]}
              onEditButtonClick={() => handleModalOpen('message')}
              flexColumn={formData.message.length > 0}
            />
          </Block>
        </Main>
      )}
      {/* Edit Modals */}
      {editModal === 'category' && (
        <CategoryModal
          data={{
            selectedCategory: {
              id: formData.messageTemplateCategoryId,
              name: formData.messageTemplateCategoryName,
            },
            categories,
          }}
          onConfirm={handleCategoryChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'info' && (
        <InfoModal
          data={{ title: formData.title, description: formData.description }}
          onConfirm={handleInfoChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'message' && (
        <MessageModal
          data={{
            message: formData.message,
            replies: formData.replies,
          }}
          hideAlias={true}
          clientName={clientName}
          clientId={clientId}
          openAIEnabled={openAIEnabled}
          onConfirm={handleMessageChange}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};

const propTypes = {
  mode: PropTypes.string,
  type: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

TemplateForm.propTypes = propTypes;

export default connectData(TemplateForm);
