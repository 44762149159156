import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import nanoid from 'nanoid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tag from 'components/Dashboard_v2/Tag';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_400};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  i.ri-add-circle-line {
    margin-left: 8px;
    color: ${colors.SHADES_400};
  }
  > div {
    width: 100%;
  }
`;

const SelectedCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  padding: 0 0 16px 0;
  margin-bottom: -4px;
`;

const PopoverWrapper = styled.div`
  position: absolute;
`;

const PopoverContent = styled.div`
  min-width: 460px;
  background-color: ${colors.SHADES_000};
  border: solid 1px ${colors.SHADES_300};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px;
`;

const ConditionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 12px;
  max-height: 400px;
  overflow: auto;
  input {
    flex: 1;
    font-size: 16px;
    border: none;
    min-width: ${({ hasTag }) => (hasTag ? '100px' : '255px')};
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.SHADES_400};
    }
    :-ms-input-placeholder {
      color: ${colors.SHADES_400};
    }
  }
`;

const ConditionMenu = styled.div`
  font-size: 12px;
  > span {
    display: block;
    margin-bottom: 8px;
    color: ${colors.SHADES_400};
  }
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid ${colors.SHADES_300};
      border-radius: 8px;
      font-size: 16px;
      color: ${colors.SHADES_400};
    }
    > div:last-child {
      margin-left: 8px;
      > div:first-child {
        color: ${colors.SHADES_900};
      }
      > div:last-child {
        color: ${colors.SHADES_400};
      }
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const ConditionBackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4px;
  background-color: ${colors.SHADES_100};
  border-radius: 8px;
  font-size: 14px;
  color: ${colors.SHADES_500};
  margin-bottom: 16px;
  cursor: pointer;
  i {
    margin-right: 8px;
  }
`;

const Placeholder = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${colors.SHADES_400};
  white-space: nowrap;
`;

const ConditionBackButton = (props) => (
  <ConditionBackButtonWrapper {...props}>
    <i className="ri-arrow-left-line" />
    {t('chooseTagsOfDifferentTypes')}
  </ConditionBackButtonWrapper>
);

export const ConditionLabel = ({ type, ...props }) => {
  let tagSettings = {};
  if (type === 'age' || type === 'age_range') {
    tagSettings = {
      icon: 'ri-time-line',
      borderColor: colors.SYSTEM_SUCCESS_600,
      color: colors.SYSTEM_SUCCESS_600,
      selectedBackgroundColor: colors.SYSTEM_SUCCESS_100,
    };
  }
  if (type === 'gender') {
    tagSettings = {
      icon: props.name === t('male') ? 'ri-men-line' : 'ri-women-line',
      borderColor: '#8155D6',
      color: '#8155D6',
      selectedBackgroundColor: '#ECE9FC',
    };
  }
  return <Tag settings={tagSettings} {...props} />;
};

const getPlaceholderText = (type) => {
  switch (type) {
    case 'menu':
      return t('clickToChooseTypeToAddTags');
    case 'age':
    case 'gender':
      return t('chooseAtLeastOneItem');
    default:
      return null;
  }
};

const ConditionSelector = ({ conditions, selectedConditions, setSelectedConditions }) => {
  const [keyword, setKeyword] = useState('');
  const [isConditionOpen, setIsConditionOpen] = useState(false);
  const [conditionType, setConditionType] = useState('menu'); // menu, tag, age, gender

  const filteredTags = useMemo(() => {
    if (!keyword) {
      return conditions.tag;
    }
    return conditions.tag.filter((tag) => tag.name.includes(keyword));
  }, [keyword, conditions]);

  const selectedConditionLabels = useMemo(() => {
    const tags = selectedConditions.tag?.map((tag) => ({ ...tag, conditionType: 'tag' }));
    const ages = selectedConditions.age?.map((age) => ({ ...age, conditionType: 'age' }));
    const genders = selectedConditions.gender?.map((gender) => ({ ...gender, conditionType: 'gender' }));
    return [...tags, ...ages, ...genders];
  }, [selectedConditions]);

  const openConditions = useCallback(() => {
    setIsConditionOpen(true);
  }, []);

  const closeCondition = useCallback(() => {
    setConditionType('menu');
    setIsConditionOpen(false);
  }, []);

  const getSelectedConditionIndex = (type, condition) =>
    selectedConditions[type].findIndex((selectedCondition) => selectedCondition.name === condition.name);

  const removeCondition = (type, conditionIndex) => {
    setSelectedConditions((prev) => {
      const newArray = [...prev[type]];
      newArray.splice(conditionIndex, 1);
      return {
        ...prev,
        [type]: newArray,
      };
    });
  };

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (!isConditionOpen) {
        openConditions();
      }
      setKeyword(value);
    },
    [isConditionOpen, openConditions]
  );

  const handleSelect = (type, condition) => {
    const conditionIndex = getSelectedConditionIndex(type, condition);
    if (conditionIndex === -1) {
      setSelectedConditions((prev) => {
        return {
          ...prev,
          [type]: [...prev[type], condition],
        };
      });
    } else {
      removeCondition(type, conditionIndex);
    }
  };

  const handleRemove = (e, type, condition) => {
    e.stopPropagation();
    const conditionIndex = getSelectedConditionIndex(type, condition);
    if (conditionIndex !== -1) {
      removeCondition(type, conditionIndex);
    }
  };

  return (
    <ClickAwayListener onClickAway={closeCondition}>
      <Container>
        <InputContainer onClick={openConditions}>
          {!selectedConditionLabels.length && <Placeholder>{getPlaceholderText(conditionType)}</Placeholder>}
          <ConditionWrapper hasTag={selectedConditionLabels.length > 0}>
            {selectedConditionLabels?.map((condition) => (
              <ConditionLabel
                type={condition.conditionType}
                name={condition.name}
                onClick={(e) => e.stopPropagation()}
                onClickRemove={(e) => handleRemove(e, condition.conditionType, condition)}
                key={nanoid()}
              />
            ))}
            <input
              value={keyword}
              onChange={handleInputChange}
              placeholder={selectedConditions.tag.length ? '' : t('searchTagsPlaceholder')}
              hidden={conditionType !== 'tag'}
              type="text"
            />
          </ConditionWrapper>
          <i className="ri-add-circle-line" />
        </InputContainer>
        {isConditionOpen && (
          <PopoverWrapper>
            <PopoverContent>
              {conditionType === 'menu' && (
                <ConditionMenu>
                  <span>{t('tags')}</span>
                  <div onClick={() => setConditionType('tag')}>
                    <div>
                      <i className="ri-price-tag-3-fill" />
                    </div>
                    <div>
                      <div>{t('tags')}</div>
                      <div>{t('futureApplicationInSegmentationAndMessageCenter')}</div>
                    </div>
                  </div>
                  <span>{t('data')}</span>
                  <div onClick={() => setConditionType('age')}>
                    <div>
                      <i className="ri-time-fill" />
                    </div>
                    <div>
                      <div>{t('age')}</div>
                      <div>{t('ageBasedMessagingForVariousEvents')}</div>
                    </div>
                  </div>
                  <div onClick={() => setConditionType('gender')}>
                    <div>
                      <i className="ri-group-fill" />
                    </div>
                    <div>
                      <div>{t('gender')}</div>
                      <div>{t('genderBasedMessagingForVariousEvents')}</div>
                    </div>
                  </div>
                </ConditionMenu>
              )}
              {conditionType === 'tag' && (
                <React.Fragment>
                  <ConditionBackButton onClick={() => setConditionType('menu')} />
                  <SelectedCount>
                    {t('searchResults', { info: `${filteredTags.length}/${conditions.tag.length}` })}
                  </SelectedCount>
                  <ConditionWrapper>
                    {filteredTags?.map((tag) => (
                      <ConditionLabel
                        type="tag"
                        name={tag.name}
                        onClick={() => handleSelect('tag', tag)}
                        isSelected={getSelectedConditionIndex('tag', tag) !== -1}
                        key={nanoid()}
                      />
                    ))}
                  </ConditionWrapper>
                </React.Fragment>
              )}
              {conditionType === 'age' && (
                <React.Fragment>
                  <ConditionBackButton onClick={() => setConditionType('menu')} />
                  <ConditionWrapper>
                    {conditions.age_range?.map((age) => (
                      <ConditionLabel
                        type="age"
                        name={age.name}
                        onClick={() => handleSelect('age', age)}
                        isSelected={getSelectedConditionIndex('age', age) !== -1}
                        key={nanoid()}
                      />
                    ))}
                  </ConditionWrapper>
                </React.Fragment>
              )}
              {conditionType === 'gender' && (
                <React.Fragment>
                  <ConditionBackButton onClick={() => setConditionType('menu')} />
                  <ConditionWrapper>
                    {conditions.gender?.map((gender) => (
                      <ConditionLabel
                        type="gender"
                        name={gender.name}
                        onClick={() => handleSelect('gender', gender)}
                        isSelected={getSelectedConditionIndex('gender', gender) !== -1}
                        key={nanoid()}
                      />
                    ))}
                  </ConditionWrapper>
                </React.Fragment>
              )}
            </PopoverContent>
          </PopoverWrapper>
        )}
      </Container>
    </ClickAwayListener>
  );
};

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedMemberIds: PropTypes.array,
};

ConditionSelector.propTypes = propTypes;

export default memo(ConditionSelector);
